import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { OperatorUtils } from '../../../../utils/operator.utils';
import { BaseFormField } from '../../base-form-field.component';

@Component({
  selector: 'v-date-field',
  templateUrl: './date-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFieldComponent extends BaseFormField implements OnInit, OnChanges {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() forceEndOfDay: boolean;
  @Input() forceStartOfDay: boolean;
  @Input() touchUI: boolean = true;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() appendTo: string = '';

  constructor(private readonly _cd: ChangeDetectorRef) {
    super();
  }

  protected get filterDisplayLabel(): string {
    return this.label;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.firstChange) {
      this.updateDateFilterDisplay();
      this.control.valueChanges.pipe(OperatorUtils.takeUntilDisposed(this)).subscribe((date: Date) => {
        const mDate = this.forceStartOfDay
          ? moment(date).startOf('day')
          : this.forceEndOfDay
          ? moment(date).endOf('day')
          : null;

        if (mDate && !mDate.isSame(date)) {
          this.control.setValue(mDate.toDate());
          return;
        }

        this.updateDateFilterDisplay();
      });
      this.control.registerOnDisabledChange((isDisabled: boolean) => this._cd.markForCheck());
    }
  }

  onDateSelected(date: Date) {
    this.dateSelected.emit(new Date(date));
  }
}
