<v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
  <div class="container-fluid dashboard-page">
    <div class="row flex-xl-nowrap">
      <div *ngIf="errorMessage" class="text-danger">Unable to Supplier: {{ errorMessage }}</div>
      <div class="col-xl-12">
        <div class="">
          <form novalidate [formGroup]="inviteSupplierGroup" (submit)="submitInviteSupplier(); (false)">
            <div class="sep-form">
              <h2 data-cy="invitedSupplier-inviteSupplierHeader">Invite Supplier</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row mb-3">
                      <div class="custom-3">
                        <label for="invitedCompany" data-cy="invitedSupplier-invitedCompanyLabel">
                          Company Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="invitedCompany"
                          maxlength="50"
                          formControlName="invitedCompany"
                          required
                          data-cy="invitedSupplier-invitedCompanyInput"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        />
                        <div *ngIf="invitedCompanyControl.touched && invitedCompanyControl.errors">
                          <span
                            *ngIf="
                              invitedCompanyControl.touched &&
                              (invitedCompanyControl.hasError('required') ||
                                invitedCompanyControl.hasError('invitedCompany'))
                            "
                            class="text-danger"
                            data-cy="invitedSupplier-accountNameRequired"
                          >
                            A valid Account Name is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <div class="custom-3">
                        <label for="accountNumber" data-cy="invitedSupplier-walmartSupplierNumberLabel">
                          {{ retailerConfig.retailer }} Supplier Number
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          (blur)="checkAccountNoAlreadyExist()"
                          [(ngModel)]="accountNumber"
                          (keypress)="isNumberKey($event)"
                          id="accountNumber"
                          formControlName="accountNumber"
                          maxlength="50"
                          data-cy="invitedSupplier-walmartSupplierNumberInput"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        />

                        <div class="spacer" *ngIf="vendorIdMsg && !accountNumberControl.hasError('required')">
                          <p-message
                            severity="warn"
                            text="{{ retailerConfig.retailer }} Supplier Number Already Exists"
                            data-cy="invitedSupplier-walmartNumberExists"
                          ></p-message>
                        </div>

                        <div class="spacer" *ngIf="vendorIdExistSupplier && !accountNumberControl.hasError('required')">
                          <p-message
                            data-cy="invitedSupplier-assignedToSupplier"
                            severity="warn"
                            text="Our records show this {{
                              retailerConfig.retailer
                            }} supplier number is assigned to supplier {{ vendorIdSupplierMsg }}."
                          ></p-message>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row  mb-3">
                      <div class="custom-3">
                        <label for="invitedName" data-cy="invitedSupplier-contactNameLabel">
                          Contact Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="invitedName"
                          maxlength="50"
                          formControlName="invitedName"
                          required
                          data-cy="invitedSupplier-contactNameInput"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        />
                        <div *ngIf="invitedNameControl.touched && invitedNameControl.errors">
                          <span
                            *ngIf="
                              invitedNameControl.touched &&
                              (invitedNameControl.hasError('required') || invitedNameControl.hasError('invitedName'))
                            "
                            class="text-danger"
                            data-cy="invitedSupplier-contactNameRequired"
                          >
                            A valid Contact Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row  mb-3">
                      <div class="custom-3">
                        <label for="invitedEmail" data-cy="invitedSupplier-contactEmailLabel">
                          Contact Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="100"
                          (blur)="checkEmailAlreadyExist()"
                          [(ngModel)]="invitedEmail"
                          id="invitedEmail"
                          formControlName="invitedEmail"
                          required
                          data-cy="invitedSupplier-contactEmailInput"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        />
                        <div *ngIf="invitedEmailControl.touched && invitedEmailControl.errors">
                          <span
                            *ngIf="
                              invitedEmailControl.touched &&
                              (invitedEmailControl.hasError('required') || invitedEmailControl.hasError('invitedEmail'))
                            "
                            class="text-danger"
                            data-cy="invitedSupplier-contactEmailRequired"
                          >
                            A valid Contact Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          data-cy="emailNotValid"
                          *ngIf="
                            inviteSupplierGroup.controls['invitedEmail'].touched &&
                            !inviteSupplierGroup.controls['invitedEmail'].valid &&
                            !inviteSupplierGroup.controls['invitedEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>

                        <div
                          class="text-danger"
                          *ngIf="emailMsg && !invitedEmailControl.hasError('required')"
                          data-cy="invitedSupplier-emailExists"
                        >
                          Email Already Exists
                        </div>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <div class="custom-3">
                        <label for="invitedNotes" data-cy="invitedSupplier-personalizedMessageLabel">
                          Personalized Message
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <textarea
                          class="form-control rounded-0"
                          id="invitedNotes"
                          formControlName="invitedNotes"
                          data-cy="invitedSupplier-personalizedMessageInput"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group row mb-3" *ngIf="showAnote">
                      <div class="custom-3">
                        <label for="empNotes" data-cy="invitedSupplier-companyNotesLabel">
                          Company Notes
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <textarea
                          class="form-control rounded-0"
                          id="empNotes"
                          maxlength="255"
                          formControlName="empNotes"
                          data-cy="invitedSupplier-companyNotesInput"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <div class="custom-3">
                        <label for="fieldGroupType" data-cy="invitedSupplier-fieldGroupTypeLabel">
                          Field Group Type
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="fieldTypeDropModel"
                          styleClass="w-100"
                          formControlName="fieldGroupType"
                          (onChange)="updateInvitedCert($event)"
                          [filter]="true"
                          data-cy="invitedSupplier-fieldGroupTypeDropdown"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <div class="custom-3">
                        <label data-cy="invitedSupplier-certificationLabel">
                          Certification
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="certificationDropModel"
                          styleClass="w-100"
                          formControlName="certification"
                          [filter]="true"
                          data-cy="invitedSupplier-certificationDropdown"
                          [disableControlPermission]="[pc.names.AccountsManageInviteSupplier, pc.privilege.Create]"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="submit-btn" style="text-align: center">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        [disabled]="!inviteSupplierGroup.valid || emailExist"
                        data-cy="invitedSupplier-submitButton"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        (click)="cancel()"
                        class="btn btn-primary"
                        data-cy="invitedSupplier-cancelButton"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
