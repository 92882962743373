import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { GetResponse } from '@volt/api';
import { PermissionCollection } from '@volt/shared/services/permissions.service';

@Component({
  selector: 'delete-relationship',
  templateUrl: './delete-relationship.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteRelationshipComponent {
  @Input() data: GetResponse;
  @Input() pc: PermissionCollection;
  @Output() deleteClicked = new EventEmitter<number>();
}
