<div class="container-fluid dashboard pt-3">
  <h3>Welcome Emergency Operations Center User</h3>
  <h5>
    As an EOC User, you have the ability to create an emergency notification and assign stores.
  </h5>
  <p>&nbsp;</p>
  <h5>Upload Emergency:</h5>
  <ul class="ps-4">
    <li>Navigate to Emergency Management option on the left menu.</li>
    <li>
      Download the emergency template and fill in the required information - emergency name, store numbers, start date,
      end date and emergency type.
    </li>
    <li>
      Upload the master emergency list in csv format. Your spreadsheet is the master source of data. When you upload a
      new sheet, all Emergency Events in the system are replaced by your sheet. Store locations specified in the master
      list will see the emergency notification on the dashboard.
    </li>
  </ul>

  <h5>View Emergency notification:</h5>
  <ul class="ps-4">
    <li>Click on the Locations menu option.</li>
    <li>Filter stores affected by emergencies by checking 'Under Emergencies'.</li>
    <li>
      For stores affected by the emergency, you will see the emergency banner on store detail view. The notification
      banner has the option to create an order and view existing orders.
    </li>
  </ul>
</div>
