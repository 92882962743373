<ng-container>
  <div class="modal-footer mt-3">
    <div *ngIf="overrideContent; else originalContent">
      <ng-content></ng-content>
    </div>
    <ng-template #originalContent>
      <button
        *ngIf="showCancel"
        type="button"
        class="btn btn-outline-secondary min-btn-width me-2"
        (click)="onClickButtonClose()"
      >
        {{ cancelLabel }}
      </button>
      <button
        id="modalSubmitBtn"
        type="submit"
        [ngClass]="saveBtnClass"
        [disabled]="disabled"
        (click)="onClickButtonSave()"
      >
        {{ saveLabel }}
      </button>
    </ng-template>
  </div>
</ng-container>
