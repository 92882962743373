<label *ngIf="!hideLabel" data-cy="accounts-label" for="account">{{ translatedData?.accounts }}</label>
<p-multiSelect
  [formControl]="control"
  [maxSelectedLabels]="displayLength"
  [options]="items"
  [selectionLimit]="maxNumberOfSelections"
  id="account"
  [showToggleAll]="false"
  [virtualScroll]="true"
  [itemSize]="30"
  styleClass="w-100"
  [placeholder]="translatedData.allAccounts"
>
  <ng-template pTemplate="selectedItems">
    <ng-container *ngIf="control.value == null || control.value?.length == 0; else hasElements">
      <div>{{ translatedData?.allAccounts }}</div>
    </ng-container>

    <ng-template #hasElements>
      <ng-container *ngIf="maxNumberOfSelections === undefined; else hasLimitedSelection">
        <div *ngIf="control.value?.length >= displayLength; else showItem">
          {{ control.value.length }} {{ translatedData?.itemsSelected }}
        </div>
      </ng-container>
    </ng-template>

    <ng-template #hasLimitedSelection>
      <ng-container *ngIf="control.value?.length >= maxNumberOfSelections; else showItem">
        {{ control.value.length }} {{ translatedData?.itemsSelected }} ({{ translatedData.maxLimitReached }})
      </ng-container>
    </ng-template>

    <ng-template #showItem>
      <span *ngFor="let item of selectedItems; last as isLast">
        {{ item.label }}
        <span *ngIf="!isLast">,</span>
      </span>
    </ng-template>
  </ng-template>

  <ng-template pTemplate='footer'>
    <multi-select-clear-button [visible]="control?.value?.length" (clear)="resetSelection()"></multi-select-clear-button>
  </ng-template>
</p-multiSelect>
