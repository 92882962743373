import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, UrlSegment } from '@angular/router';
import { RedirectUserService } from '@volt/shared/services/redirect-user.service';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { AuthService } from '../../auth';
import { AppClient } from '@volt/api';

@Injectable({ providedIn: 'root' })
export class AppListGuard implements CanLoad, CanActivate {
  private userAppList: Observable<string[]>;

  constructor(
    private readonly _authService: AuthService,
    private readonly redirectUserService: RedirectUserService,
    private _appClient: AppClient,
  ) {
    this.userAppList = this._authService.authUser$.pipe(
      distinctUntilChanged((a, b) => a?.id != b?.id),
      mergeMap(user => (user ? this._appClient.getAccountAppList(user.accountId) : of([]))),
      shareReplay(1),
      take(1),
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.hasAppAccess$(route.data['appId'], true);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasAppAccess$(route.data['appId'], true);
  }

  hasAppAccess$(app: AppList, redirectOnFailure = false): Observable<boolean> {
    if (!app) {
      return of(true);
    }

    return this.userAppList.pipe(
      map(appList => appList.some(appId => appId === app)),
      tap(hasPermission => {
        if (redirectOnFailure && !hasPermission) {
          this.redirectUserService.redirectUnauthorizedUser();
        }
      }),
    );
  }
}

export enum AppList {
  Training = '1',
  CheckInCheckOut = '2',
  TeamLead = '8192',
  PFSUpload = '8193',
}
