import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TaskResult } from '@volt/api';
import { ServiceTaskResultsService } from '../services/task-results.service';

@Component({
  selector: 'service-task-results',
  templateUrl: 'task-results.component.html',
  styleUrls: ['./task-results.component.scss'],
})
export class ServiceTaskResultsComponent implements OnInit, OnDestroy {
  public serviceDeploymentRefId: string;
  public taskRefId: string;
  public taskLocationRefId: string;
  public serviceDeploymentRefNumber: string;
  public serviceDeploymentTaskRefNumber: string;
  public serviceTaskLocationRefNumber: string;
  public accountId: number;
  public fieldGroupName: string;
  public serviceDeploymentName: string;
  public serviceTaskName: string;
  public loading = false;
  public showData = false;
  public data: TaskResult[];

  private readonly _onDestroy$ = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _taskResultService: ServiceTaskResultsService,
  ) {}

  ngOnInit() {
    combineLatest([
      this._route.paramMap.pipe(filter(p => p.has('refid'))),
      this._route.queryParamMap.pipe(filter(qp => qp.has('accountId')))
    ]).subscribe(([params, queryParams]: [ParamMap, ParamMap]) => {
      this.serviceDeploymentRefNumber = queryParams.get('serviceDeploymentRefNumber');
      this.serviceDeploymentTaskRefNumber = queryParams.get('taskRefNumber');
      this.serviceTaskLocationRefNumber = queryParams.get('serviceTaskLocationRefNumber');
      this.fieldGroupName = queryParams.get('fieldGroupName');
      this.serviceDeploymentName = queryParams.get('serviceDeploymentName');
      this.serviceTaskName = queryParams.get('taskName');
      this.accountId = +queryParams.get('accountId');

      this.serviceDeploymentRefId = params.get('refid');
      this.taskRefId = params.get('taskId');
      this.taskLocationRefId = params.get('taskLocationId');

      if (this.fieldGroupName == undefined || this.fieldGroupName === '' || this.fieldGroupName == null) {
        this._router.navigate(['/servicedeployments/data']);
      }

      this.pullData();
    })
  }

  pullData() {
    this.loading = true;
    this._taskResultService
      .getServiceTaskResult(this.accountId, this.serviceDeploymentRefId, this.taskRefId, this.taskLocationRefId)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((data: any) => {
        this.data = data;

        if (this.data.length > 0) {
          this.showData = true;
        }
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
  }

  public GotoTaskLocaiton() {
    const queryParams = {
      taskRefNumber: this.serviceDeploymentTaskRefNumber,
      status: this._route.snapshot.queryParams['status'],
      taskName: this.serviceTaskName,
      serviceDeploymentName: this.serviceDeploymentName,
      fieldGroupName: this.fieldGroupName,
      serviceDeploymentRefNumber: this.serviceDeploymentRefNumber,
      accountId: this.accountId
    };
    this._router.navigate(['/servicedeployments', this.serviceDeploymentRefId, 'task', this.taskRefId], {queryParams});
  }
}
