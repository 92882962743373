<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="ser-dep-end-date-label">
      End Date
      <span class="required" aria-required="true">*</span>
      <span data-cy="ser-dep-end-date-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-calendar
      [(ngModel)]="endDate"
      (onSelect)="onSelectEndDate(ref.value)"
      [disabled]="showEndDate"
      [showIcon]="true"
      [minDate]="minDate"
      [readonlyInput]="true"
      name="serviceEndDate"
      dateFormat="mm/dd/yy"
      [formControlName]="'serviceEndDate'"
      #ref
      data-cy="ser-dep-end-date"
    ></p-calendar>

    <div *ngIf="submitted" data-cy="ser-dep-end-date-error">
      <span *ngIf="submitted && serviceEndDateControl.hasError('required')" class="text-danger">
        A valid end date is required.
      </span>
    </div>

    <div *ngIf="serviceDeploymentDateMsg" data-cy="ser-dep-end-date-error">
      <span *ngIf="serviceDeploymentDateMsg" class="text-danger">End date cannot be less than start date</span>
    </div>
  </div>
</div>
