import { Reducer } from 'redux';
import { UserFilterParameters } from '../user-filter-parameters';
import { IAction } from '../../store/actions/tile.actions';
import { UserFilterActions } from '../actions/user.constants';

export const UserFilterReducer: Reducer<UserFilterParameters> = (
  state: UserFilterParameters = new UserFilterParameters(),
  action: IAction<UserFilterParameters>,
): UserFilterParameters => {
  const params = action.payload;

  switch (action.type) {
    case UserFilterActions.SetFilter: {
      return Object.assign({}, state, params);
    }
    default:
      return state;
  }
};
