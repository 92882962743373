import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege, PermissionsService } from '@volt/shared/services/permissions.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth';
import { ApplicationConfiguration, APPLICATION_CONFIGURATION } from 'src/configuration';
import { routeFade } from '../../animations/fade.animation';
import { OperatorUtils } from './../../shared/utils/operator.utils';
import { LayoutService, PanelType } from './services/layout.service';

@Component({
  selector: 'admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: [
    '../../../assets/core/core.scss',
    '../../../assets/walmart-theme/walmart-light.scss',
    '../../../assets/walmart-theme/walmart-dark.scss',
    './admin-layout.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [routeFade],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('flyouts') private _flyouts: ElementRef;

  public showMainNav = true;
  public showSideNav = true;
  public panelType = PanelType;
  public panelShow: PanelType = null;
  public userAccount: string;
  public userAccountLogo;
  public canViewChatBot$: Observable<boolean>;
  public privacyPolicyUrl: string;

  constructor(
    private _authService: AuthService,
    private _layoutService: LayoutService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _permissionService: PermissionsService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  ngOnInit(): void {
    this.canViewChatBot$ = this._permissionService.hasPermission$(PermissionNames.SupportBotManage, Privilege.Read);
    const user = this._authService.getCurrentUser();
    this.userAccountLogo = user.account.imageUrl;
    this.userAccount = user.account.name;
    this.privacyPolicyUrl = this._appConfig.privacyPolicyUrl;
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.closeNav());
    // TODO: This may change in future versions to show menu for store manager JAS.
    // this.showMainNav = this._authService.getCurrentRole() !== RoleConstant.RetailerStoreManager;
    this.showMainNav = true;

    this._route.data
      .pipe(OperatorUtils.takeUntilDisposed(this))
      .subscribe(v => (this.showSideNav = v.showSideNav ?? true));

    if (!this.showMainNav) {
      this.showSideNav = false;
    }

    this._layoutService.onTogglePanel.subscribe((panelType: PanelType) => {
      this.panelShow = this.panelShow === panelType ? null : panelType;
    });
  }

  public closeNav() {
    this._layoutService.togglePanel(null);
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public goToMyAccount() {
    this._router.navigate(['/edit-account', this._authService.getCurrentUser().accountId]);
  }

  ngOnDestroy() {}
}
