<v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
  <ng-container>
    <div class="successful-modal">
      <modal-header [title]="'Add Notes'" (onClose)="formReset()"></modal-header>
      <div class="modal-body">
        <div class="dashboard-page">
          <div class="flex-xl-nowrap">
            <form novalidate [formGroup]="invitedGroup" (submit)="submit(); (false)">
              <div class="d-flex flex-column">
                <label>
                  Notes
                  <span class="required" aria-required="true">*</span>
                </label>
                <div>
                  <textarea
                    class="form-control rounded-0"
                    id="aNote"
                    maxlength="200"
                    [(ngModel)]="invitedModel.invitedNote"
                    formControlName="aNote"
                    style="width: 350px;"
                  ></textarea>

                  <div *ngIf="aNoteControl.touched && aNoteControl.errors">
                    <span
                      *ngIf="
                        aNoteControl.touched &&
                        (aNoteControl.hasError('required') || aNoteControl.hasError('aNote'))
                      "
                      class="text-danger"
                    >
                      A valid note is required.
                    </span>
                  </div>
                </div>
              </div>
              <modal-footer [disabled]="!invitedGroup.valid" (onClose)="formReset()"></modal-footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</v-loading-container>
