import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { GetResponse } from '@volt/api';

@Component({
  selector: 'relationship',
  templateUrl: './relationship.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipComponent {
  @Input() data: GetResponse;
}
