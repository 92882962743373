<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-supplier-list-label">
      Supplier(s)
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-supplier-list-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-autoComplete
      field="label"
      (onClear)="onClearEvent($event)"
      [(ngModel)]="initialSupplierList"
      [suggestions]="filteredSupplierList"
      (completeMethod)="getSupplierListByFilter($event)"
      [formControlName]="'supplier'"
      [size]="50"
      [style]="{ width: '100%' }"
      [minLength]="3"
      placeholder="Type at least 3 characters"
      [multiple]="true"
      [delay]="500"
      required
      data-cy="task-supplier-list"
    ></p-autoComplete>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && supplierControl.hasError('required')"
        class="text-danger"
        data-cy="task-supplier-list-error"
      >
        Supplier is required.
      </span>
    </div>
    <!--
        <div class="text-danger" *ngIf="serviceTaskSupplierMsg && !supplierControl.hasError('required')">
            Supplier does not exist
        </div> -->

    <div class="text-danger" *ngIf="serviceTaskSupplierMsg" data-cy="task-supplier-list-error">
      Supplier does not exist
    </div>
  </div>
</div>
