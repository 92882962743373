import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { PC, PermissionCollection } from '@volt/shared/services/permissions.service';
import { Subject } from 'rxjs';
import { ImportLogImportLogClient, ImportLogViewModel } from 'src/app/api-import-log.client';
import { first } from 'rxjs/operators';

@Component({
  selector: 'v-last-import',
  template: `
    <div class="card" *ngIf="lastImport$ | async as lastImport">
      <div class="card-body">
        <table class="table table-sm table-borderless really-borderless w-auto mb-0">
          <thead>
            <tr>
              <th scope="col" class="pe-3">Last Updated</th>
              <th scope="col" class="pe-3">Updated By</th>
              <th scope="col" class="pe-3">File Uploaded</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pe-3">{{ lastImport.uploadedDate | date: 'short' }}</td>
              <td class="pe-3">{{ lastImport.uploadedBy }}</td>
              <td class="pe-3">
                <a [href]="lastImport.fileUploadedUrl">{{ lastImport.fileUploadedName }}</a>
              </td>
              <td *permissions="[pc.names.ReportsImportLog, pc.privilege.Read]">
                <a href="/reports/import-log">More Logs</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  `,
  styles: [
    `
      .really-borderless td {
        border-bottom: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastImportComponent implements OnInit {
  lastImport$ = new Subject<ImportLogViewModel>();

  @Input() pageName: string;

  constructor(private importLogClient: ImportLogImportLogClient, @Inject(PC) public pc: PermissionCollection) {}

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.importLogClient
      .getLatestForPage(this.pageName)
      .pipe(first())
      .subscribe(
        i => this.lastImport$.next(i),
        err => this.lastImport$.next(null),
      );
  }
}
