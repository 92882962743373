import { ProductModel } from '../../api.client';
import { ActionType, createAction } from '../../shared/typesafe-actions';

export const settingsActions = {
  setProductLists: createAction('SET_PRODUCT_LISTS', actionCallback => (products: ProductModel[]) =>
    actionCallback(products),
  ),
};

export type SettingsActions = ActionType<typeof settingsActions>;
