import { RETAILER_CONFIG, RetailerConfigType } from './../../../../../retailer-config';
import { SearchableRoute, SearchSection } from './../models/searchable-route.types';
import { PermissionsService } from './../../../../shared/services/permissions.service';
import { Translations } from '@volt/shared/services/translations.types';
import { Observable, combineLatest, of, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap, shareReplay } from 'rxjs/operators';
import { Route } from '@angular/router';
import { StateSubject } from '@volt/shared/extensions/state-subject';
import { Inject, Injectable } from '@angular/core';
import { TranslationService } from '@volt/shared/services/translation.service';
import { GlobalSearchClient, GlobalSearchHistoryViewModel } from '@volt/api';
import { reportRoutes } from '../../../../reports/reports.routes';
import { registrationsRoutes } from '../../../../registration/registration.routes';
import { activityRoutes } from '../../../../activity/activity.routes';
import { assemblerPriorityRoutes } from '../../../../assembler-priority/assembler-priority.routes';
import { emergenciesRoutes } from '../../../../emergencies/emergencies.routes';
import { ServiceDeploymentsTasksLocationsRoutes } from '../../../../servicedeployments-tasks-locations/servicedeployments-tasks-locations.routes';
import { ActionLogsRoutes } from '../../../../action-log/action-log.routes';
import { retailLinkRegistrationsRoutes } from '../../../../retaillink-registration/retaillink-registration.routes';
import { marketingSignageRoutes } from '../../../../marketing-signage/marketing-signage-routes';
import { productsRoutes } from '../../../../products/products.routes';
import { appRoutes } from 'src/app/app-routing.module';

function mapGlobalSearchToSearchableRoute(items: GlobalSearchHistoryViewModel[]): SearchableRoute[] {
  return items.map(item => new SearchableRoute(item.route, null, item.search));
}

export interface GlobalSearchResults {
  history: SearchSection;
  results: SearchSection;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  private _routes$: StateSubject<Route[][]> = new StateSubject<Route[][]>([]);
  private _history$: BehaviorSubject<SearchableRoute[]> = new BehaviorSubject<SearchableRoute[]>([]);
  private _searchable$: Observable<GlobalSearchResults>;
  private _searchTerm$: StateSubject<string> = new StateSubject<string>('');

  constructor(
    private readonly _translationService: TranslationService,
    private readonly _permissionsService: PermissionsService,
    private readonly _globalSearchClient: GlobalSearchClient,
    @Inject(RETAILER_CONFIG) private readonly _retailerConfig: RetailerConfigType,
  ) {
    this._initRoutes();
  }

  private _initRoutes() {
    this._routes$.setState([
      appRoutes,
      this._addPathToSubRoutes(reportRoutes, 'reports/'),
      this._addPathToSubRoutes(registrationsRoutes, 'registration/'),
      this._addPathToSubRoutes(activityRoutes, 'activities/'),
      this._addPathToSubRoutes(assemblerPriorityRoutes, 'assembler-priority/'),
      this._addPathToSubRoutes(emergenciesRoutes, 'emergencies/'),
      this._addPathToSubRoutes(productsRoutes, 'emergencies/products/'),
      this._addPathToSubRoutes(ServiceDeploymentsTasksLocationsRoutes, 'servicedeployments/'),
      this._addPathToSubRoutes(ActionLogsRoutes, 'servicedeployments/action-log/'),
      this._addPathToSubRoutes(retailLinkRegistrationsRoutes, 'retaillink-registration/'),
      this._addPathToSubRoutes(marketingSignageRoutes, 'marketing-signage/'),
    ]);

    this._globalSearchClient
      .getGlobalSearchHistory()
      .pipe(map(mapGlobalSearchToSearchableRoute))
      .subscribe(hist => this._history$.next(hist));

    this._searchable$ = combineLatest([
      this._translationService.getTranslatedObject(
        {
          key: 'serch.reports.walmartUsageReport',
          params: { retailer: this._retailerConfig.retailer },
        },
        {
          key: 'walmartUsageReport',
          params: { retailer: this._retailerConfig.retailer },
        },
      ),
      this._routes$.asObservable(),
      this._history$.asObservable(),
    ]).pipe(
      switchMap(([translated, availableResults, hist]: [Translations, Route[][], SearchableRoute[]]) => {
        const results = new SearchSection('searchResults', []);
        const history = new SearchSection('recent', hist);

        const _checkResults = (route: Route) => {
          if (route.data?.searchKey != null) {
            if (route.data?.requiredPermission?.length === 2) {
              if (
                this._permissionsService.hasPermission(
                  route.data.requiredPermission[0],
                  route.data.requiredPermission[1],
                )
              ) {
                _addSearchableRoute(route, translated);
              }
            } else {
              _addSearchableRoute(route, translated);
            }
          }
        };

        const _addSearchableRoute = (route: Route, td: Translations) => {
          results.items.push(
            new SearchableRoute(
              '/' + route.path,
              td[route.data?.searchKey],
              td[route.data?.titleKey] || route.data?.title || 'Missing Title',
            ),
          );
        };

        if (availableResults?.length > 0) {
          availableResults.forEach(routeArr => {
            if (routeArr.length > 0) {
              routeArr.forEach((route: Route) => {
                _checkResults(route);
                if (route.children?.length > 0) {
                  route.children.forEach(child => _checkResults(child));
                }
              });
            }
          });
        }
        return of({ results, history });
      }),
      shareReplay(1),
    );
  }

  setSearchTerm(search: string): void {
    this._searchTerm$.setState(search);
  }

  get searchResults$(): Observable<GlobalSearchResults> {
    return combineLatest([this._searchTerm$, this._searchable$]).pipe(
      map(([searchTerm, { results, history }]) => {
        if (!searchTerm) {
          return { history, results: null };
        }
        const filteredHistory = {
          ...history,
          items: history.items.filter(route => route.title?.toLowerCase().includes(searchTerm?.toLowerCase())),
        };
        const filteredResults = {
          ...results,
          items: results.items.filter(
            route =>
              route.title?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
              route.searchTerms?.toLowerCase().includes(searchTerm?.toLowerCase()),
          ),
        };
        return { history: filteredHistory, results: filteredResults };
      }),
    );
  }

  saveSearchHistory(search: string, route: string): Observable<void> {
    return this._globalSearchClient.saveSearchHistory(search, route).pipe(
      map(mapGlobalSearchToSearchableRoute),
      tap(hist => {
        this._history$.next(hist);
      }),
      switchMap(() => of(null)),
    );
  }

  get translatedData$() {
    return this._translationService.getTranslatedObject();
  }

  private _addPathToSubRoutes(routes: Route[], parentRoute: string): Route[] {
    const result = [];
    routes.forEach((route: Route) => {
      const newRoute = { ...route, path: `${parentRoute}${route.path}` };
      if (newRoute.children?.length > 0) {
        const children = [];
        newRoute.children.forEach((child: Route) => {
          children.push({ ...child, path: `${parentRoute}${child.path}` });
        });
        newRoute.children = children;
      }
      result.push(newRoute);
    });
    return result;
  }
}
