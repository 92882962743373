import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropDownModel } from '../../../../../api.client';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'employee-number',
  templateUrl: './employee-number.component.html',
  styleUrls: ['../../addservicedeployment-task-location.component.scss'],
})
export class EmployeeNumberComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public serviceTaskLocationEmpMsg: boolean;
  @Input() public filteredEmpList: DropDownModel[] = [];
  @Output() private employeeNumberEntered: EventEmitter<any> = new EventEmitter<any>();
  @Output() private employeeNumberExist: EventEmitter<any> = new EventEmitter<any>();
  @Input() public selectedEmployee: DropDownModel[];

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Employee can be searched for by name or number.',
          'Multiple employees can be assigned to a task location.',
        ],
      },
    });
  }

  public get employeeNoControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('employeeNo');
  }

  public onClearEvent(event: any) {
    this.serviceTaskLocationEmpMsg = false;
    this.employeeNumberExist.emit(event);
  }

  public getEmployeeListByFilter(event: any) {
    this.employeeNumberEntered.emit(event);
  }
}
