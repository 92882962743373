import { Reducer } from 'redux';
import { IAction } from '../../store/actions/tile.actions';
import { LocationFilterParameters } from '../location-filter-parameters';
import { LocationFilterActions } from '../actions/location.constants';

export const LocationFilterReducer: Reducer<LocationFilterParameters> = (
  state: LocationFilterParameters = new LocationFilterParameters(),
  action: IAction<LocationFilterParameters>,
): LocationFilterParameters => {
  const params = <LocationFilterParameters>action.payload;
  switch (action.type) {
    case LocationFilterActions.SetFilter: {
      const newState = Object.assign({}, state, params);
      return newState;
    }
    default:
      return state;
  }
};
