import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SharedImportComponent } from '@volt/shared/components/shared-import/shared-import.component';
import { MessageService } from 'primeng/api';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../../configuration';
import { environment } from '../../../../environments/environment';
import { FileParameter, ProductClient, ProductModel } from '../../../api.client';
import { ProductListParams } from '../models/product-list-params';
import { BlobService } from '@volt/shared/services/blob.service';

@Component({
  selector: 'product-management',
  templateUrl: './product-management.component.html',
  providers: [MessageService],
})
export class ProductManagementComponent implements OnInit {
  public working = false;
  public products: ProductModel[] = [];
  public pageName = 'Product Management';

  @ViewChild(SharedImportComponent) sharedImport: SharedImportComponent;

  constructor(
    private _productClient: ProductClient,
    private _messageService: MessageService,
    private readonly _blobService: BlobService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  ngOnInit() {
    this._loadProducts();
  }

  public uploadFile(files: File[]) {
    this.working = true;
    const file: FileParameter = {
      data: files[0],
      fileName: files[0].name,
    };

    this._productClient
      .uploadProducts(this.pageName, file)
      .pipe(
        catchError(err => {
          return observableOf(err);
        }),
      )
      .subscribe((err: any) => {
        this.working = false;
        if (err) {
          this._messageService.add({
            severity: 'error',
            summary: 'Error Uploading File',
            detail: err.error,
          });
        } else {
          this._messageService.add({
            severity: 'success',
            summary: 'File Uploaded Successfully',
          });
          this._loadProducts();
          this.sharedImport.reset();
        }
      });
  }

  private _loadProducts() {
    this._productClient.get().subscribe((prods: ProductModel[]) => {
      this.products = prods;
    });
  }

  public filterProducts(event) {
    const product = new ProductListParams();
    product.filterText = event;
    this._productClient
      .get(product.sort, product.sortColumn, product.ascending, product.page, product.pageSize, product.filterText)
      .subscribe((prods: ProductModel[]) => {
        this.products = prods;
      });
  }

  public clearSearch() {
    const product = new ProductListParams();
    this._productClient
      .get(product.sort, product.sortColumn, product.ascending, product.page, product.pageSize, product.filterText)
      .subscribe((prods: ProductModel[]) => {
        this.products = prods;
      });
  }

  downloadTemplate() {
    this._blobService
      .downloadBlobFromContainer(environment.uploadsSecureContainer, environment.eoc.productTemplate)
      .subscribe(
        _ => {},
        err => {
          console.error(err);

          this._messageService.add({
            severity: 'error',
            detail: 'Could not download file',
          });
        },
      );
  }
}
