import { AbstractControl } from '@angular/forms';
import { Params } from '@angular/router';
import { Translations } from '../../shared/services/translations.types';
import { saveAs } from 'file-saver';
import isArray from 'lodash-es/isArray';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constructable } from '../types';
import { isMoment } from 'moment';
import * as _ from 'lodash-es';

export class CommonUtils {
  public static getHoursDuration(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainder = minutes - hours * 60;
    return hours;
  }

  public static listToCsvBlob(items: any[]): Blob {
    if (!items || items.length <= 0) {
      console.error('No items passed to downloadCsv function');
      return;
    }

    const firstItem = items[0];
    let file = this.buildHeader(firstItem);
    items.forEach(item => {
      file += this.buildLine(item);
    });

    return new Blob([file], { type: 'text/csv' });
  }

  public static toQueryParams(obj: any, prefix: string = '', ignoreEmptyValues = true): Params {
    const params: Params = {};
    for (let [key, value] of Object.entries(obj)) {
      if (ignoreEmptyValues && isEmpty(value)) {
        continue;
      }
      key = prefix + key;

      if (value instanceof Date) {
        params[key] = value.toISOString();
      } else {
        params[key] = value;
      }
    }

    return params;
  }

  public static toQueryString(obj: any) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] != undefined) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  public static fromParams<T>(queryParams: any, prefix: string): T {
    return Object.entries(queryParams).reduce((params, [key, value]) => {
      const start = key.indexOf(prefix);
      if (start > -1 && start + prefix.length < key.length) {
        params[key.substring(start + prefix.length)] = value;
      } else {
        params[key] = value;
      }
      return params;
    }, {} as T);
  }

  public static downloadCsv(items: any[], fileName: string) {
    const blob = CommonUtils.listToCsvBlob(items);
    saveAs(blob, fileName);
  }

  private static buildHeader(item) {
    let header = '';
    Object.keys(item).forEach((prop, idx) => {
      if (idx > 0) header += `,${prop}`;
      else header += prop;
    });
    header += '\r\n';
    return header;
  }

  private static buildLine(item) {
    let line = '';
    Object.keys(item).forEach((prop, idx) => {
      if (idx > 0) line += `,${item[prop]}`;
      else line += item[prop];
    });
    line += '\r\n';
    return line;
  }

  public static download(blob: Blob, fileName: string) {
    saveAs(blob, fileName);
  }

  public static downloadUrl(url: string, fileName: string) {
    saveAs(url, fileName);
  }

  public static stringComparer(s1: string, s2: string, isAscending: boolean = true, toNumber: boolean = false): number {
    if (toNumber) {
      if (Number(s1) > Number(s2)) {
        return isAscending ? 1 : -1;
      }

      if (Number(s1) < Number(s2)) {
        return isAscending ? -1 : 1;
      }

      return 0;
    }

    if (s1 > s2) {
      return isAscending ? 1 : -1;
    }

    if (s1 < s2) {
      return isAscending ? -1 : 1;
    }

    return 0;
  }

  /**
   *
   * @param defaultLabel
   * @param defaultValue
   * @param labelGetter
   * @param valueGetter
   *
   * @deprecated Use mapToMultiSelectItem() instead
   * @see mapToMultiSelectItem
   */
  public static mapToSelectItem<T>(
    defaultLabel: string,
    defaultValue: any,
    labelGetter: (item: T) => string,
    valueGetter: (item: T) => any,
  ) {
    return this.mapToMultiSelectItem(labelGetter, valueGetter, false, defaultLabel, defaultValue);
  }

  /**
   *
   * @param labelGetter
   * @param valueGetter
   * @param {boolean} [multi = true] - Set to false to include defaultLabel and defaultValue for Single Dropdown
   * @param defaultLabel
   * @param defaultValue
   * @param stringifyValue
   */
  public static mapToMultiSelectItem<T>(
    labelGetter: (item: T) => string,
    valueGetter: (item: T) => any,
    multi: boolean = true,
    defaultLabel?: string,
    defaultValue?: any,
    stringifyValue: boolean = true,
  ): OperatorFunction<T[], SelectItem[]> {
    return map((value: T[]) => {
      return CommonUtils.mapArrayToMultiSelectItem(
        value,
        labelGetter,
        valueGetter,
        multi,
        defaultLabel,
        defaultValue,
        stringifyValue,
      );
    });
  }

  public static mapArrayToMultiSelectItem<T>(
    values: T[],
    labelGetter: (item: T) => string,
    valueGetter: (item: T) => any,
    multi: boolean = true,
    defaultLabel?: string,
    defaultValue?: any,
    stringifyValue: boolean = true,
  ): SelectItem[] {
    const _items = this.toSelectItem(values, labelGetter, valueGetter, stringifyValue);

    if (multi) {
      return _items;
    }

    if (defaultValue || defaultLabel) {
      return [{ label: defaultLabel, value: defaultValue }, ..._items];
    }

    return _items;
  }

  public static disableControls(controls: { [key: string]: AbstractControl }) {
    Object.values(controls).forEach(control => control.disable());
  }

  public static mapParamsToFilters<T extends Object>(
    params: Params,
    filterType?: Constructable<T>,
    startDateKey: keyof T = 'startDate' as keyof T,
    endDateKey: keyof T = 'endDate' as keyof T,
  ) {
    const filters = new filterType();

    const paramsKeys = Object.keys(params);
    if (!paramsKeys.length) {
      return filters;
    }

    for (let i = 0; i < paramsKeys.length; i++) {
      const key = paramsKeys[i];
      const param = params[key];

      if (param) {
        if (key === startDateKey || key === endDateKey) {
          filters[key] = moment(new Date(param)).toDate();
        } else {
          filters[key] = isArray(param)
            ? [...param]
            : isArray(filters[key])
            ? [param]
            : typeof filters[key] === 'boolean'
            ? param === 'true'
            : typeof filters[key] === 'string'
            ? param
            : isNaN(Number(param))
            ? param
            : Number(param);
        }
      }
    }
    return filters;
  }

  public static mapToFilters<T>(
    filterType: Constructable<T>,
    startDateKey: keyof T = 'startDate' as keyof T,
    endDateKey: keyof T = 'endDate' as keyof T,
  ): OperatorFunction<Params, T> {
    return map((params: Params) => this.mapParamsToFilters(params, filterType, startDateKey, endDateKey));
  }

  public static toSelectItem<T>(
    items: T[],
    labelGetter: (item: T) => string,
    valueGetter: (item: T) => any,
    stringify: boolean,
  ): SelectItem[] {
    return items
      .filter(item => !!item)
      .map(
        (item: T): SelectItem => ({
          label: labelGetter(item),
          value: stringify ? valueGetter(item).toString() : valueGetter(item),
        }),
      ).sort((l, r) => {
        // If both labels are numbers, then compare numerically
        if(!isNaN(Number(l.label)) && !isNaN(Number(r.label))) {
          return parseInt(l.label) - parseInt(r.label);
        }

        return l.label.localeCompare(r.label)
      });
  }

  public static CapDateRangeToIncludeFullDaysUtc(startDate: Date, endDate: Date) {
    return {
      startUtc: Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0),
      endUtc: Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999),
    };
  }
}

export function isEquivalent(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    // console.log("isEquivalent", true, obj1, obj2);
    return true;
  }

  const typeOfObject = typeof obj1;

  if (typeOfObject !== typeof obj2) return false;

  // they have the same type

  if (typeOfObject === 'undefined') return true;

  if (obj1 == null && obj2 == null) {
    return true;
  }

  if ((obj1 !== null && obj2 === null) || (obj1 === null && obj2 !== null)) {
    return false;
  }

  if (obj1 instanceof Array) {
    if (obj1.length !== obj2.length) return false;

    const length = obj1.length;

    for (let i = 0; i < length; i++) {
      if (!isEquivalent(obj1[i], obj2[i])) {
        return false;
      }
    }
    // console.log("isEquivalent", true, obj1, obj2);
    return true;
  } else if (obj1 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  } else if (typeOfObject === 'object') {
    const obj1Props = Object.keys(obj1);
    const obj2Props = Object.keys(obj2);

    if (obj1Props.length !== obj2Props.length) return false;

    for (let i = 0; i < obj1Props.length; i++) {
      const propName = obj1Props[i];
      if (!isEquivalent(obj1[propName], obj2[propName])) {
        // console.log(propName, false, obj1[propName], obj2[propName]);
        return false;
      }
      // console.log(propName, true, obj1[propName], obj2[propName])
    }
    return true;
  }
  return false;
}

export function isEmpty(obj: any) {
  return obj == null || obj === '' || (obj instanceof Array && obj.length === 0);
}

export function nameOf<T>(key: keyof T): keyof T {
  return key;
}

export function translationKey(key: keyof Translations): keyof Translations {
  return nameOf<Translations>(key);
}

export function toPascalCasing(s: string) {
  return s?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ') || s;
}

export function sort<T>(l: T, r: T, key: string, ascending: boolean) {
  const left = l[key];
  const right = r[key];

  let result = 1;
  if (left == null || right == null) {
    result = left == null ? -1 : 1;
  }
  if (isMoment(left)) {
    result = left < right ? -1 : 1;
  }
  if (_.isNumber(left)) {
    result = left - right;
  }
  if (_.isString(left)) {
    result = left.toString().toUpperCase() < (right || '').toString().toUpperCase() ? -1 : 1;
  }

  return ascending ? result : -result;
}


export function isTimeoutError(error: any) {
  return error?.detail?.toLowerCase()?.includes('execution timeout expired') === true
}
