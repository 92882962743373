import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingContainerModule } from '../shared/components/containers/loading-container/loading-container.module';
import { AuthRoutes } from './auth.routes';
import { ExpiredLinkComponent } from './components/expired-link.component';
import { LoginComponent } from './components/login.component';
import { NotAuthorizedComponent } from './components/not-authorized.component';
import { AuthorizedDirective } from './directives/authorized.directive';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from './services/auth.service';
import { LoginCallbackComponent } from './components/login-callback.component';
import { storageFactory } from './authConfig';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    RouterModule.forChild(AuthRoutes),
    LoadingContainerModule,
    OAuthModule.forRoot(),
  ],
  exports: [LoginComponent, AuthorizedDirective],
  declarations: [
    LoginComponent,
    LoginCallbackComponent,
    NotAuthorizedComponent,
    AuthorizedDirective,
    ExpiredLinkComponent,
  ],
})

export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }
}
