import { Reducer } from 'redux';
import { FeedbackFilterParameters } from '../feedback-filter-parameter';
import { FeedBackFilterActions } from '../actions/feedback.constants';
import { IAction } from '../../../store/actions/tile.actions';

// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:variable-name
export const FeedBackReducer: Reducer<FeedbackFilterParameters> = (
  state: FeedbackFilterParameters = new FeedbackFilterParameters(),
  action: IAction<FeedbackFilterParameters>,
) => {
  const params = <FeedbackFilterParameters>action.payload;
  switch (action.type) {
    case FeedBackFilterActions.SetFilter: {
      const newstate = Object.assign({}, state, params);
      return newstate;
    }
    default:
      return state;
  }
};
