import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InvitedAccountService } from '../../../services/invitedsupplier.service';
import { AccountModel } from '../../../../api.client';

@Component({
  selector: 'retailer-list',
  templateUrl: './retailer-account-list.component.html',
})
export class RetailerListComponent implements OnInit {
  cols: any[];
  public retailerAccountList: AccountModel[] = [];
  selectedRetailer: string[] = [];

  @Output() retailerData: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  public retailerSelected: string;
  constructor(private _InvitedAccountService: InvitedAccountService) {}

  ngOnInit(): void {
    // this.selectedRetailer.push('5582');
  }

  getRetailerId() {
    this.retailerData.emit(this.selectedRetailer);
  }
  public getRetailerList() {
    this.getColumns();

    this._InvitedAccountService.getRetailerAccountList().subscribe(response => {
      if (response) {
        this.retailerAccountList = response;
        if (this.retailerSelected) {
          this.selectedRetailer = this.retailerSelected.split(',');
          this.selectedRetailer = this.selectedRetailer.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
          });
        }
      }
    });
  }

  getColumns() {
    this.cols = [
      { field: 'checkBoxes', header: 'Retailer Assigned' },
      { field: 'aname', header: 'Retailer Name' },
    ];
  }
}
