import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProductsModel } from '../../../../api.client';
import { AccountViewService } from '../../../services/accountview.service';

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit {
  public data: ProductsModel[] = [];
  cols: any[];
  public totalRecords: number;
  selectedProduct: string[] = [];
  @Output() productData: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  public productselected: string;
  constructor(private _accountViewService: AccountViewService) {}

  ngOnInit(): void {}

  public getProductsList() {
    this.getColumns();

    this._accountViewService.getProductList().subscribe(response => {
      if (response) {
        this.data = response;
        this.totalRecords = this.data.length;
        if (this.productselected) this.selectedProduct = this.productselected.split(',');
      }
    });
  }

  getColumns() {
    this.cols = [];

    this.cols = [
      { field: 'checkBoxes', header: 'Product Assigned' },
      { field: 'productName', header: 'Product Name' },
      { field: 'cost', header: 'Cost' },
      { field: 'productTypeName', header: 'Product Type' },
      { field: 'statusName', header: 'Status' },
    ];
  }

  getProductId() {
    this.productData.emit(this.selectedProduct);
  }
}
