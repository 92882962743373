import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layouts/admin/services/layout.service';

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent implements OnInit {
  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {}

  public close() {
    this.layoutService.togglePanel(null);
  }
}
