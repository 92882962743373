<div class="container-fluid">
  <header class="page-header d-flex align-items-center">
    <h2 data-cy="emergency-management-title" class="page-title">
      <span>Emergency Management</span>
    </h2>
  </header>
  <p-toast position="top-right" [baseZIndex]="9999"></p-toast>
  <div class="row">
    <shared-import
      class="col-12 mb-3"
      (importClicked)="uploadFile($event)"
      (downloadTemplateClicked)="downloadTemplate()"
      [accept]="'.text/csv,.csv'"
      [maxSizeBytes]="5000000"
      [pageName]="pageName"
      [showLastImport]="true"
    >
      <v-info-box
        data-cy="shared-import-description-emergencies"
        class="shared-import-description"
        [infoTitle]="'Your spreadsheet is the primary source of data.'"
        [infoMessage]="
          'When you upload a new sheet, data in the system is replaced by your sheet. If you delete data from your sheet, then upload it, the data will also be deleted from the system.'
        "
      >
        <p class="mb-0">
          NOTE: After uploading the sheet, view the Location page and choose the “Under Emergency” filter to confirm the
          count of stores that are under emergency.
        </p>
      </v-info-box>
    </shared-import>
  </div>

  <div class="row">

    <div class="col-12 col-md-6 h-100">
      <div class="card rounded-0">
        <div class="card-header">
          <h2 data-cy="download-store-status-updates-title" class="card-title">Download Store Status Updates</h2>
        </div>
        <div class="card-body">
          <div class="mb-2">
            <v-checkbox-control
              [labelPosition]="'right'"
              labelAlignment="left"
              [label]="'Include Previous Emergencies'"
              [control]="showAllStatus"
              [controlId]="'showAllStatus'"
            ></v-checkbox-control>
          </div>

          <button
            data-cy="download-status-updates-button"
            class="btn btn-sm btn-primary me-1"
            (click)="downloadStatusUpdates()"
          >
            <i class="fa fa-file-export me-1"></i>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
