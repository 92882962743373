import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AddServiceDeployment } from '../../../../models/addServiceDeployment';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'service-deployment-start-date',
  templateUrl: './service-deployment-start-date.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class ServiceDeploymentStartDateComponent implements OnInit {
  public startDate: Date;
  minDate: Date;
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Output() public setEndDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() set setStartDate(currentServiceDeployment: AddServiceDeployment) {
    this.startDate = currentServiceDeployment.serviceStart
      ? new Date(currentServiceDeployment.serviceStart.toDate())
      : null;
  }
  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: ['Start of Service Deployment.'],
      },
    });
  }

  public get serviceStartDateControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('serviceStartDate');
  }

  public onSelectStartDate(date: Date) {
    this.minDate = new Date();
    this.minDate = date;
    this.setEndDate.emit(this.minDate);
  }
}
