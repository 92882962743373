import { Injectable } from '@angular/core';
import { StateSubject } from '@volt/shared/extensions/state-subject';
import { Tag3, TagClient} from '@volt/api';
import { ReplaySubject, of } from 'rxjs';
import { AuthService } from '../../auth';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class TagsGuardService {
  private $currentTags: ReplaySubject<Tag3[]> = new ReplaySubject<Tag3[]>();
  public tagsLoaded$ = new StateSubject<boolean>(false);

  constructor(private readonly _tagClient: TagClient, private readonly _authService: AuthService) {}

  public get tagsLoaded(): boolean {
    return this.tagsLoaded$.getValue();
  }

  public loadTags() {
    this.$currentTags = new ReplaySubject(1);

    if (this._authService.hasValidToken()) {
      this._tagClient
        .getAssignedPersonTags()
        .pipe(
          catchError(() => {
            this.tagsLoaded$.next(false);
            console.error('Unable to load tags');
            return of([]);
          }),
        )
        .subscribe(tags => {
          this.$currentTags.next(tags as Tag3[]);
          this.tagsLoaded$.next(true);
        });
    } else {
      this.$currentTags.next([]);
      this.tagsLoaded$.next(false);
    }
  }

  public hasTag$(tagToCheck: string): Observable<boolean> {
    if (tagToCheck == null) {
      return of(true);
    } else {
      return this.$currentTags.asObservable().pipe(
        map(tags => tags.some(tag => tag.name === tagToCheck)),
      );
    }
  }
}
