import { Injectable } from '@angular/core';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { Observable } from 'rxjs';
import {
  GenericTableReportOfServiceDeploymentTasksViewModel,
  ServiceTaskRetrievalClient,
} from '../../../../api.client';

@Injectable()
export class ServicedeploymentTaskService {
  constructor(private _serviceDeploymentTaskClient: ServiceTaskRetrievalClient) {}

  public getServiceDeploymentTask(
    params: ServiceDeploymentReportParams,
  ): Observable<GenericTableReportOfServiceDeploymentTasksViewModel> {
    let offset = params.page;
    const limit = params.pageSize;
    offset = (offset - 1) * limit;
    return this._serviceDeploymentTaskClient.retrieveTasks(
      params.serviceDeploymentRefId,
      offset,
      limit,
      params.sortColumn,
      params.servicedeploymentTaskSearchParameters.nameSearch,
      params.servicedeploymentTaskSearchParameters.activitySearch,
      params.servicedeploymentTaskSearchParameters.referenceNumberSearch,
      params.servicedeploymentTaskSearchParameters.suppliersSearch,
      params.servicedeploymentTaskSearchParameters.departmentsSearch,
      params.servicedeploymentTaskSearchParameters.noteSearch,
      params.servicedeploymentTaskSearchParameters.serviceModelSearch,
      params.servicedeploymentTaskSearchParameters.statusSearch,
    );
  }
}
