<v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
  <div class="top-search d-flex align-items-center justify-content-end" style="margin-bottom: 5px">
    <input class="align-self-stretch" type="text" [(ngModel)]="searchFilter" style="width: 150px" />

    <button (click)="SearchFilter()" class="btn btn-primary btn-sm" style="cursor: pointer" title="Search">
      Search
      <i class="fa fa-search"></i>
    </button>
    <button (click)="clear()" class="btn btn-primary btn-sm" style="cursor: pointer" title="Clear">Clear</button>

  </div>

  <p-table
    [columns]="cols"
    [value]="data"
    [rows]="20"
    [totalRecords]="totalRecords"
    [responsive]="true"
    [lazy]="true"
    (onLazyLoad)="sortdata($event)"
  >
    <ng-template pTemplate="caption">
      <div>
        <p-tabMenu
          [model]="items"
          [activeItem]="items[checkTabsId]"
          (click)="Onclick($event)"
          class="checkclass"
        ></p-tabMenu>
      </div>

      <p-toast [baseZIndex]="100"></p-toast>
    </ng-template>

    <br />

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div *ngIf="col.field === 'invitedEmail'" style="overflow: scroll; overflow-y: hidden; overflow-x: auto">
            {{ rowData[col.field] }}
          </div>

          <div *ngIf="col.field === 'supplierAdmin'" style="overflow: scroll; overflow-y: hidden; overflow-x: auto">
            {{ rowData[col.field] }}
          </div>

          <div
            *ngIf="
              col.field != 'aimageUrl' &&
              col.field != 'accountName' &&
              col.field != 'supplierAdmin' &&
              col.field != 'lastInvitedDate' &&
              col.field != 'retailerApprovalDate' &&
              col.field != 'registrationCompletionDate' &&
              col.field != 'invitedEmail'
            "
          >
            {{ rowData[col.field] }}
          </div>

          <div
            *ngIf="
              col.field == 'lastInvitedDate' ||
              col.field == 'retailerApprovalDate' ||
              col.field == 'registrationCompletionDate'
            "
          >
            {{ rowData[col.field] | date: 'short' }}
          </div>

          <div *ngIf="col.field == 'aimageUrl'">
            <img
              style="max-height: 25px"
              *ngIf="col.field == 'aimageUrl' && rowData[col.field] != null"
              [src]="rowData[col.field] | imageFlow: 'h=25' | async"
            />
          </div>

          <div *ngIf="col.field === 'accountName'">
            <a (click)="GetAccountDetail(rowData)" style="cursor: pointer; color: peru">{{ rowData[col.field] }}</a>
          </div>

          <div *ngIf="col.field == 'Resend' && resendFlag && rowData['statusID'] == 4">
            <button
              *ngIf="resendFlag"
              (click)="resendInvitationEmail(rowData['invitedAccountId'])"
              class="btn btn-primary btn-sm"
              style="cursor: pointer"
              title="Clear"
            >
              Resend
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="row">
    <div class="col-md-12" *ngIf="data.length > 0">
      <ngb-pagination
        [collectionSize]="totalRecords"
        class="d-flex justify-content-center"
        [pageSize]="pageSize"
        [page]="page"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="true"
        (pageChange)="onPageChanged($event)"
      ></ngb-pagination>
    </div>
  </div>
</v-loading-container>
