import { Routes } from '@angular/router';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';
import { EmergenciesComponent } from './emergencies/emergencies.component';
import { EmergencyOverviewComponent } from './emergency-overview/emergency-overview.component';

export const emergenciesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'management',
        component: EmergenciesComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Emergencies',
          titleKey: translationKey('emergencies'),
          requiredPermission: [PermissionNames.EmergenciesManage, Privilege.Read],
          searchKey: translationKey('search.emergencies.management'),
        },
      },
      {
        path: 'products',
        loadChildren: () => import('../products/products.module').then(m => m.ProductsModule),
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.EmergenciesManageProducts, Privilege.Read],
          title: 'Product Management',
          titleKey: translationKey('productManagement'),
        },
      },
      {
        path: 'overview',
        component: EmergencyOverviewComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Emergency Overview',
          titleKey: translationKey('emergencyOverview'),
          requiredPermission: [PermissionNames.EmergenciesManage, Privilege.Read],
          searchKey: translationKey('search.emergencies'),
        },
      },
    ],
  },
];
