import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataPassingService {
  private columns = new Subject<any>();
  private advancedSearchParamsSubject = new Subject<object>();
  private serviceDeploymentRefNumberSubject = new Subject<string>();
  private serviceDeploymentTaskInstructionSubject = new Subject<string>();
  private serviceDeploymentEndDateSubject = new Subject<boolean>();

  public advancedSearchParams = this.advancedSearchParamsSubject.asObservable();
  public serviceDeploymenttaskInstructionParams = this.serviceDeploymentTaskInstructionSubject.asObservable();
  public serviceDeploymentRefNumber = this.serviceDeploymentRefNumberSubject.asObservable();
  public serviceDeploymentEndDate = this.serviceDeploymentEndDateSubject.asObservable();

  constructor() {}

  passColumns(columns: any) {
    this.columns.next(columns);
  }

  passAdvancedSearchParams(params: object) {
    this.advancedSearchParamsSubject.next(params);
  }

  public passServiceDeploymentTaskInstructionName(param: string) {
    this.serviceDeploymentTaskInstructionSubject.next(param);
  }

  public passServiceDeploymentEndDate(param: boolean) {
    this.serviceDeploymentEndDateSubject.next(param);
  }
}
