export enum StatusTabEnum {
  Approved = 'Approved',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Invited = 'Invited',

  // statusId enums
  ApprovedStatusId = 1,
  PendingStatusId = 2,
  InactiveStatusId = 3,
  InvitedStatusId = 4,

  // tabId enums
  InvitedTabId = 0,
  PendingTabId = 1,
  InactiveTabId = 2,
  ApprovedTabId = 3,
}
