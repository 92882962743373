<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-dep-list-label">
      Department(s)
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-dep-list-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-autoComplete
      field="label"
      (onClear)="onClearEvent($event)"
      [style]="{ width: '100%' }"
      [(ngModel)]="initialDepartmentList"
      [suggestions]="filteredDepartmentList"
      (completeMethod)="getDepartmentListByFilter($event)"
      [formControlName]="'department'"
      placeholder="Type at least 1 characters"
      [multiple]="true"
      required
      data-cy="task-dep-list"
    ></p-autoComplete>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && departmentControl.hasError('required')"
        class="text-danger"
        data-cy="task-dep-list-error"
      >
        Department is required.
      </span>
    </div>

    <div class="text-danger" *ngIf="serviceTaskDepartmentMsg" data-cy="task-dep-list-error">
      Department does not exist
    </div>
  </div>
</div>
