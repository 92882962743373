<div class="site-wrapper">
  <header>
    <navbar [showMainNav]="true" [showGlobalNav]="false"></navbar>
  </header>
  <div class="site-content">
    <div>
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
</div>
