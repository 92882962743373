/**
 * @file tenant-id.interceptor.ts
 * @description {@link https://jira.walmart.com/browse/WDE-6256}
 *  An interceptor to add the 'dv-tenant-id' header to all outgoing API calls (monolith & watt) from Dashboard.
 *  As per {@link https://jira.walmart.com/browse/WDE-6036}, the value of the header is
 *  a hard-coded value retrieved through TenantIdService.
 */

import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantIdService } from '../services/tenant-id.service';

@Injectable()
export class TenantIdInterceptor implements HttpInterceptor {
    constructor(private readonly _tenantIdService: TenantIdService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: { 'dv-tenant-id': this._tenantIdService.tenantId }
        });

        return next.handle(request);
    }
}

export const tenantIdInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantIdInterceptor,
    multi: true,
};
