import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  Output,
} from '@angular/core';
import { SharedTableColumn } from '@volt/shared/components/table/models/shared-table-column';
import * as tz from 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'shared-table-cell',
  templateUrl: './shared-table-cell.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedTableCellComponent implements OnInit, DoCheck {
  @Input() column: SharedTableColumn<any>;
  @Input() data: any;
  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  dataKvDiffers: KeyValueDiffer<any, any>;

  constructor(private cdr: ChangeDetectorRef, private kvDiffers: KeyValueDiffers) {}

  ngOnInit() {
    this.dataKvDiffers = this.kvDiffers.find({}).create();
  }

  ngDoCheck() {
    const changes = this.dataKvDiffers.diff(this.data);
    if (changes) {
      this.cdr.detectChanges();
    }
  }

  get timezone() {
    if (this.column.timeZoneField == null && this.column.timeZone == null) {
      return null;
    }

    if (!!this.column.timeZone) {
      return this.column.timeZone;
    }

    // This is a weird way to get the hour difference. Moment.diff was not working as expected
    // The date pipe expects timezone to be UTC + an hour offset
    const value: moment.Moment = this.data[this.column.field];
    const timezone: moment.Moment = tz.tz(value, this.data[this.column.timeZoneField]);
    const utc = moment(timezone).utc(false);
    const utcHour = utc.hours();
    const timezoneHour = timezone.hours();
    const hourDiff = -(utc.dayOfYear() > timezone.dayOfYear() ? utcHour + (24 - timezoneHour) : utcHour - timezoneHour);
    return `UTC ${hourDiff <= 0 ? '-' : '+'}${Math.abs(hourDiff)}`;
  }
}
