import { Routes } from '@angular/router';
import { ExpiredLinkComponent } from './components/expired-link.component';
import { LoginComponent } from './components/login.component';
import { NotAuthorizedComponent } from './components/not-authorized.component';
import { LoginCallbackComponent } from './components/login-callback.component';

export const AuthRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-callback',
    component: LoginCallbackComponent,
    // canActivate: [SsoTokenGuard],
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: 'expired-link',
    component: ExpiredLinkComponent,
  },
];
