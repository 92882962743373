import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PC, PermissionCollection } from '@volt/shared/services/permissions.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';

import { AccountViewModel } from '@volt/api';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { AccountViewService } from '../../../services/accountview.service';
import { InvitedAccountService } from '../../../services/invitedsupplier.service';
import { StatusTabEnum } from '../../models/StatusTabEnum';
import { AuthService } from '../../../../auth';

@Component({
  selector: 'accountview-list',
  styleUrls: ['./accountview-list.scss'],
  templateUrl: './accountview-list.component.html',
  providers: [MessageService],
})
export class AccountViewListComponent implements OnInit {
  public data: AccountViewModel[] = [];
  public approvedAccounts: AccountViewModel[] = [];
  public pendingAccounts: AccountViewModel[] = [];
  public inactiveAccounts: AccountViewModel[] = [];
  public invitedAccounts: AccountViewModel[] = [];
  public loading = false;
  public totalRecords: number;
  public page = 1;
  public pageSize = 20;
  cols: any[];
  items: any[];
  public activeCount = 0;
  public pendingCount = 0;
  public invitedCount = 0;
  public inActiveCount = 0;
  public resendFlag = false;
  public approveFlag = false;
  public inActiveFlag = false;
  public invitedFlag = false;
  public pendingFlag = false;
  public activePage = 1;
  public inActivePage = 1;
  public pendingPage = 1;
  public invitedPage = 1;
  public searchFilterFlag = false;
  public searchFilterValue = '';
  public checkTabs: string = StatusTabEnum.Approved;
  public checkStatusId: number = StatusTabEnum.ApprovedStatusId;
  public checkTabsId: number = StatusTabEnum.ApprovedTabId;

  public searchFilter = '';
  public sortFieldwithOrdering = '';

  public isCurrentTabPendingVolt = false;

  constructor(
    private _accountViewService: AccountViewService,
    private _invitedSupplierService: InvitedAccountService,
    private _router: Router,
    private _route: ActivatedRoute,
    private readonly monitoringService: MonitoringService,
    private readonly _messageService: MessageService,
    @Inject(PC) public pc: PermissionCollection,
    private readonly _authService: AuthService,
  ) {}

  ngOnInit(): void {
    const user = this._authService.getCurrentUser();
    this.getAccountsListForApproved(StatusTabEnum.ApprovedStatusId);
    this.getAccountsListForPending(StatusTabEnum.PendingStatusId);
    this.getAccountsListForInactive(StatusTabEnum.InactiveStatusId);
    this.getAccountsListForInvited(StatusTabEnum.InvitedStatusId);
    this.getStatusTabs();
    this.getColumns();
    this.selectInitialTab();
  }

  private selectInitialTab() {
    this._route.params.subscribe(params => {
      const selectedTab = params['selectedTab'];

      if (selectedTab === 'approved') {
        this.selectApprovedTab();
      } else if (selectedTab === 'inactive') {
        this.selectInactiveTab();
      } else if (selectedTab === 'pending') {
        this.selectPendingTab();
      } else if (selectedTab === 'invited') {
        this.selectInvitedTab();
      } else {
        this.selectApprovedTab();
      }
    });
  }

  Onclick(event: any) {
    this.data = [];
    this.cols = [];

    const tabValue = event.target.innerText.trim();

    if (tabValue === 'Approved (' + this.activeCount + ')') {
      this.selectApprovedTab();
    }

    if (tabValue === 'Pending Approval (' + this.pendingCount + ')') {
      this.selectPendingTab();
    }

    if (tabValue === 'Inactive (' + this.inActiveCount + ')') {
      this.selectInactiveTab();
    }

    if (tabValue === 'Invited (' + this.invitedCount + ')') {
      this.selectInvitedTab();
    }
  }

  private selectInvitedTab() {
    this.isCurrentTabPendingVolt = false;
    this.getInvitedColumns();
    this.resendFlag = true;
    this.page = this.invitedPage;
    this.loading = true;
    this.sortFieldwithOrdering = 'lastInvitedDate';

    this._accountViewService
      .getAccountsListByStatusId(StatusTabEnum.InvitedStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.loading = false;
          this.invitedAccounts = [];
          this.invitedAccounts = response;

          this.data = this.invitedAccounts;
          if (this.invitedAccounts.length > 0) {
            this.totalRecords = this.invitedAccounts[0].totalCount;
          } else {
            this.totalRecords = 0;
          }

          this.page = this.invitedPage;
          this.checkTabs = StatusTabEnum.Invited;
          this.checkStatusId = StatusTabEnum.InvitedStatusId;
          this.checkTabsId = StatusTabEnum.InvitedTabId;
          this.searchFilterValue = '';
          this.searchFilter = '';
          this.searchFilterFlag = false;
        }
      });
  }

  private selectInactiveTab() {
    this.isCurrentTabPendingVolt = false;
    this.getInactiveColumns();
    this.resendFlag = false;
    this.page = this.inActivePage;
    this.loading = true;
    this.sortFieldwithOrdering = '';

    this._accountViewService
      .getAccountsListByStatusId(StatusTabEnum.InactiveStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.loading = false;
          this.inactiveAccounts = [];
          this.inactiveAccounts = response;
          this.data = this.inactiveAccounts;
          if (this.inactiveAccounts.length > 0) {
            this.totalRecords = this.inactiveAccounts[0].totalCount;
          } else {
            this.totalRecords = 0;
          }

          this.page = this.inActivePage;
          this.checkTabs = StatusTabEnum.Inactive;
          this.checkStatusId = StatusTabEnum.InactiveStatusId;
          this.checkTabsId = StatusTabEnum.InactiveTabId;
          this.searchFilterValue = '';
          this.searchFilter = '';
          this.searchFilterFlag = false;
        }
      });
  }

  private selectPendingTab() {
    this.isCurrentTabPendingVolt = true;
    this.getPendingColumns();
    this.resendFlag = false;
    this.page = this.pendingPage;
    this.loading = true;
    this.sortFieldwithOrdering = '';

    this._accountViewService
      .getAccountsListByStatusId(StatusTabEnum.PendingStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.loading = false;
          this.pendingAccounts = [];
          this.pendingAccounts = response;
          this.data = this.pendingAccounts;
          if (this.pendingAccounts.length > 0) {
            this.totalRecords = this.pendingAccounts[0].totalCount;
          } else {
            this.totalRecords = 0;
          }

          this.page = this.pendingPage;
          this.checkTabs = StatusTabEnum.Pending;
          this.checkStatusId = StatusTabEnum.PendingStatusId;
          this.checkTabsId = StatusTabEnum.PendingTabId;
          this.searchFilterValue = '';
          this.searchFilter = '';
          this.searchFilterFlag = false;
        }
      });
  }

  private selectApprovedTab() {
    this.isCurrentTabPendingVolt = false;
    this.getColumns();
    this.resendFlag = false;
    this.page = this.activePage;
    this.loading = true;
    this.sortFieldwithOrdering = 'accountName';
    this._accountViewService
      .getAccountsListByStatusId(StatusTabEnum.ApprovedStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.loading = false;
          this.approvedAccounts = [];
          this.approvedAccounts = response;
          this.data = this.approvedAccounts;
          if (this.approvedAccounts.length > 0) {
            this.totalRecords = this.approvedAccounts[0].totalCount;
          } else {
            this.totalRecords = 0;
          }
          this.checkTabs = StatusTabEnum.Approved;
          this.checkStatusId = StatusTabEnum.ApprovedStatusId;
          this.checkTabsId = StatusTabEnum.ApprovedTabId;
          this.searchFilterValue = '';
          this.searchFilter = '';
          this.searchFilterFlag = false;
        }
      });
  }

  getAccountsListForApproved(statusId: number) {
    this.loading = true;
    this.sortFieldwithOrdering = 'accountName';
    this._accountViewService
      .getAccountsListByStatusId(statusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.approvedAccounts = [];
          this.approvedAccounts = response;
          this.loading = false;
          if (this.approvedAccounts.length > 0) {
            this.activeCount = this.approvedAccounts[0].totalCount;
          }
          this.getStatusTabs();
        }
      });
  }

  getAccountsListForInvited(statusId: number) {
    this.sortFieldwithOrdering = 'lastInvitedDate';
    this._accountViewService
      .getAccountsListByStatusId(statusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.invitedAccounts = [];
          this.invitedAccounts = response;
          if (this.invitedAccounts.length > 0) {
            this.invitedCount = this.invitedAccounts[0].totalCount;
          }
          this.getStatusTabs();
        }
      });
  }

  getAccountsListForPending(statusId: number) {
    this.sortFieldwithOrdering = '';
    this._accountViewService
      .getAccountsListByStatusId(statusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.pendingAccounts = [];
          this.pendingAccounts = response;
          if (this.pendingAccounts.length > 0) {
            this.pendingCount = this.pendingAccounts[0].totalCount;
          }
          this.getStatusTabs();
        }
      });
  }

  getAccountsListForInactive(statusId: number) {
    this.sortFieldwithOrdering = '';
    this._accountViewService
      .getAccountsListByStatusId(statusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.inactiveAccounts = [];
          this.inactiveAccounts = response;
          if (this.inactiveAccounts.length > 0) {
            this.inActiveCount = this.inactiveAccounts[0].totalCount;
          }
          this.getStatusTabs();
        }
      });
  }

  public search() {
    this.loading = true;
    this.page = 1;
    this.activePage = 1;
    this.inActivePage = 1;
    this.pendingPage = 1;
    this.invitedPage = 1;

    if (this.checkTabs === StatusTabEnum.Approved) {
      this.approveFlag = true;
      this.inActiveFlag = false;
      this.invitedFlag = false;
      this.pendingFlag = false;
      this.sortFieldwithOrdering = 'accountName';
      this.getColumns();
    } else if (this.checkTabs === StatusTabEnum.Inactive) {
      this.approveFlag = false;
      this.inActiveFlag = true;
      this.invitedFlag = false;
      this.pendingFlag = false;
      this.sortFieldwithOrdering = '';
      this.getInactiveColumns();
    } else if (this.checkTabs === StatusTabEnum.Invited) {
      this.approveFlag = false;
      this.inActiveFlag = false;
      this.invitedFlag = true;
      this.pendingFlag = false;
      this.sortFieldwithOrdering = 'lastInvitedDate';
      this.getInvitedColumns();
    } else if (this.checkTabs === StatusTabEnum.Pending) {
      this.approveFlag = false;
      this.inActiveFlag = false;
      this.invitedFlag = false;
      this.pendingFlag = true;
      this.sortFieldwithOrdering = '';
      this.getPendingColumns();
    }

    this._accountViewService
      .getAccountsListByStatusId(this.checkStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.data = [];
          this.data = response;

          if (this.approveFlag === true) {
            this.approvedAccounts = [];
            this.approvedAccounts = this.data;
          }
          if (this.inActiveFlag === true) {
            this.inactiveAccounts = [];
            this.inactiveAccounts = this.data;
          }
          if (this.invitedFlag === true) {
            this.invitedAccounts = [];
            this.invitedAccounts = this.data;
          }
          if (this.pendingFlag === true) {
            this.pendingAccounts = [];
            this.pendingAccounts = this.data;
          }

          this.loading = false;
          if (this.inActiveFlag === true) {
            if (this.data.length > 0) {
              this.totalRecords = this.data[0].totalCount;
            }
          } else {
            if (this.data.length > 0) {
              this.totalRecords = this.data[0].totalCount;
            }
          }
          this.getStatusTabs();
        }
      });

    if (this.approveFlag === false) {
      this.sortFieldwithOrdering = 'accountName';
      this._accountViewService
        .getAccountsListByStatusId(StatusTabEnum.ApprovedStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
        .subscribe(response => {
          if (response) {
            this.approvedAccounts = [];
            this.approvedAccounts = response;
            if (this.approvedAccounts.length > 0) {
              this.totalRecords = this.approvedAccounts[0].totalCount;
            }
          }
        });
    }

    if (this.inActiveFlag === false) {
      this.sortFieldwithOrdering = '';
      this._accountViewService
        .getAccountsListByStatusId(StatusTabEnum.InactiveStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
        .subscribe(response => {
          if (response) {
            this.inactiveAccounts = [];
            this.inactiveAccounts = response;
            if (this.inactiveAccounts.length > 0) {
              this.totalRecords = this.approvedAccounts[0].totalCount;
            }
          }
        });
    }

    if (this.invitedFlag === false) {
      this.sortFieldwithOrdering = 'lastInvitedDate';
      this._accountViewService
        .getAccountsListByStatusId(StatusTabEnum.InvitedStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
        .subscribe(response => {
          if (response) {
            this.invitedAccounts = [];
            this.invitedAccounts = response;
            if (this.invitedAccounts.length > 0) {
              this.totalRecords = this.invitedAccounts[0].totalCount;
            }
          }
        });
    }

    if (this.pendingFlag === false) {
      this.sortFieldwithOrdering = '';
      this._accountViewService
        .getAccountsListByStatusId(StatusTabEnum.PendingStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
        .subscribe(response => {
          if (response) {
            this.pendingAccounts = [];
            this.pendingAccounts = response;
            if (this.pendingAccounts.length > 0) {
              this.totalRecords = this.pendingAccounts[0].totalCount;
            }
          }
        });
    }
  }

  sortdata(event: LazyLoadEvent) {
    const self = this;

    if (event.sortField !== undefined) {
      self.loading = true;
      const sortValue = event.sortField;
      self.sortFieldwithOrdering = '';

      if (event.sortOrder === -1) {
        self.sortFieldwithOrdering = self.sortFieldwithOrdering.concat('-').concat(event.sortField);
      } else {
        self.sortFieldwithOrdering = self.sortFieldwithOrdering.concat(event.sortField);
      }

      if (self.searchFilterFlag) {
        if (self.searchFilter !== '' && self.searchFilter !== null && self.searchFilter !== undefined) {
          self.page = 1;
          self.searchFilterValue = self.searchFilter;

          self.getSearchColumns();
          self._accountViewService
            .getAccountsListBySearchFilter(
              self.checkStatusId,
              self.searchFilter,
              self.sortFieldwithOrdering,
              self.page,
              self.pageSize,
            )
            .subscribe(response => {
              if (response) {
                self.loading = false;
                self.data = response;
                self.resendFlag = true;
                self.searchFilterFlag = true;
                self.totalRecords = 1;

                if (
                  sortValue === 'activeReps' ||
                  sortValue === 'aimageUrl' ||
                  sortValue === 'certifiedNo' ||
                  sortValue === 'Resend' ||
                  sortValue === 'status' ||
                  sortValue === 'rejectReason' ||
                  sortValue === 'supplierAdmin' ||
                  sortValue === 'invitedEmail'
                ) {
                  self.sortFieldwithOrdering = '';
                }
              }
            });
        }
      } else {
        self._accountViewService
          .getAccountsListByStatusId(self.checkStatusId, self.sortFieldwithOrdering, self.page, self.pageSize)
          .subscribe(response => {
            if (response) {
              self.loading = false;
              self.data = [];
              self.data = response;
              if (self.data.length > 0) {
                self.totalRecords = self.data[0].totalCount;
              }

              if (
                sortValue === 'activeReps' ||
                sortValue === 'aimageUrl' ||
                sortValue === 'certifiedNo' ||
                sortValue === 'Resend' ||
                sortValue === 'status' ||
                sortValue === 'rejectReason' ||
                sortValue === 'supplierAdmin' ||
                sortValue === 'invitedEmail'
              ) {
                self.sortFieldwithOrdering = '';
              }
            }
          });
      }
    }
  }

  public SearchFilter() {
    if (this.searchFilter !== '' && this.searchFilter !== null && this.searchFilter !== undefined) {
      this.page = 1;
      this.searchFilterValue = this.searchFilter;
      this.loading = true;
      this.getSearchColumns();
      this.sortFieldwithOrdering = '';

      this._accountViewService
        .getAccountsListBySearchFilter(
          this.checkStatusId,
          this.searchFilter,
          this.sortFieldwithOrdering,
          this.page,
          this.pageSize,
        )
        .subscribe(response => {
          if (response) {
            this.data = response;
            this.loading = false;
            this.resendFlag = true;
            this.searchFilterFlag = true;
            this.totalRecords = 1;
            this.getStatusTabs();
          }
        });
    }
  }

  public clear() {
    if (this.searchFilter !== '' && this.searchFilter !== null && this.searchFilter !== undefined) {
      this.searchFilter = '';
      this.searchFilterValue = '';
      this.searchFilterFlag = false;
      this.search();
    }
  }

  public onPageChanged(pageNumber: number) {
    this.page = pageNumber;

    this.loading = true;

    if (this.searchFilterFlag) {
      this._accountViewService
        .getAccountsListBySearchFilter(
          this.checkStatusId,
          this.searchFilterValue,
          this.sortFieldwithOrdering,
          this.page,
          this.pageSize,
        )
        .subscribe(response => {
          if (response) {
            this.data = [];
            this.data = response;
            this.loading = false;
          }
        });
    } else {
      if (this.checkStatusId === StatusTabEnum.ApprovedStatusId) {
        if (this.sortFieldwithOrdering === '') {
          this.sortFieldwithOrdering = 'accountName';
        }
      }

      if (this.checkStatusId === StatusTabEnum.InvitedStatusId) {
        if (this.sortFieldwithOrdering === '') {
          this.sortFieldwithOrdering = 'lastInvitedDate';
        }
      }

      this._accountViewService
        .getAccountsListByStatusId(this.checkStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
        .subscribe(response => {
          if (response) {
            this.data = [];
            this.data = response;
            this.loading = false;
            if (this.data.length > 0) {
              this.totalRecords = this.data[0].totalCount;
            }

            if (this.checkStatusId === StatusTabEnum.ApprovedStatusId) {
              this.approvedAccounts = [];
              this.approvedAccounts = this.data;
              this.activePage = pageNumber;
            }

            if (this.checkStatusId === StatusTabEnum.InvitedStatusId) {
              this.invitedAccounts = [];
              this.invitedAccounts = this.data;
              this.invitedPage = pageNumber;
            }
            if (this.checkStatusId === StatusTabEnum.PendingStatusId) {
              this.pendingAccounts = [];
              this.pendingAccounts = this.data;
              this.pendingPage = pageNumber;
            }
            if (this.checkStatusId === StatusTabEnum.InactiveStatusId) {
              this.inactiveAccounts = [];
              this.inactiveAccounts = this.data;
              this.inActivePage = pageNumber;
            }
          }
        });
    }
  }

  public resendInvitationEmail(id: any) {
    this.loading = true;
    this._invitedSupplierService.resendInvitationAccountEmail(id).subscribe(response => {
      if (response) {
        this.loading = false;
        this._messageService.add({
          severity: 'success',
          summary: '',
          detail: 'Email Sent Successfully',
        });
        this.getInvitedTabList();
      } else {
        this.loading = false;
        this._messageService.add({
          severity: 'error',
          summary: '',
          detail: 'Error in sending email',
        });
      }
    });
  }

  public getInvitedTabList() {
    this.data = [];
    this.cols = [];
    this.getInvitedColumns();
    this.resendFlag = true;
    this.page = 1;
    this.inActivePage = 1;
    this.loading = true;
    this.sortFieldwithOrdering = 'lastInvitedDate';

    this._accountViewService
      .getAccountsListByStatusId(StatusTabEnum.InvitedStatusId, this.sortFieldwithOrdering, this.page, this.pageSize)
      .subscribe(response => {
        if (response) {
          this.loading = false;
          this.invitedAccounts = [];
          this.invitedAccounts = response;

          this.data = this.invitedAccounts;
          if (this.invitedAccounts.length > 0) {
            this.totalRecords = this.invitedAccounts[0].totalCount;
          } else {
            this.totalRecords = 0;
          }

          this.checkTabs = StatusTabEnum.Invited;
          this.checkStatusId = StatusTabEnum.InvitedStatusId;
          this.checkTabsId = StatusTabEnum.InvitedTabId;
          this.searchFilterValue = '';
          this.searchFilter = '';
          this.searchFilterFlag = false;
        }
      });
  }

  getStatusTabs() {
    let tabs = [
      { label: 'Invited (' + this.invitedCount + ')' },
      { label: 'Pending Approval (' + this.pendingCount + ')' },
      { label: 'Inactive (' + this.inActiveCount + ')' },
      { label: 'Approved (' + this.activeCount + ')' },
    ];

    this.items = tabs;
  }

  getInvitedColumns() {
    this.cols = [];
    this.cols = [
      { field: 'accountName', header: 'Name' },
      { field: 'invitedEmail', header: 'Invited User' },
      { field: 'lastInvitedDate', header: 'Last Invited Date' },
      { field: 'Resend', header: 'Resend' },
    ];
  }

  getColumns() {
    this.cols = [];
    this.cols = [
      { field: 'aimageUrl', header: 'Logo' },
      { field: 'accountName', header: 'Name' },
      { field: 'activeReps', header: 'Active Reps' },
    ];
  }

  getSearchColumns() {
    this.cols = [];
    this.cols = [
      { field: 'aimageUrl', header: 'Logo' },
      { field: 'accountName', header: 'Name' },
      { field: 'accountNumber', header: 'Supplier Number' },
      { field: 'status', header: 'Status' },
      { field: 'invitedName', header: 'Invited User' },
      { field: 'supplierAdmin', header: 'Supplier Admin' },
      { field: 'lastInvitedDate', header: 'Last Invited Date' },
      {
        field: 'registrationCompletionDate',
        header: 'Registration Completion Date',
      },
      { field: 'rejectReason', header: 'Reject Reason' },
      { field: 'activeReps', header: 'Active Reps' },
      { field: 'Resend', header: 'Resend' },
    ];
  }

  getInactiveColumns() {
    this.cols = [];
    this.cols = [
      { field: 'aimageUrl', header: 'Logo' },
      { field: 'accountName', header: 'Name' },
      { field: 'supplierAdmin', header: 'Supplier Admin' },
      { field: 'rejectReason', header: 'Reject Reason' },
    ];
  }

  getPendingColumns() {
    this.cols = [];
    this.cols = [
      { field: 'aimageUrl', header: 'Logo' },
      { field: 'accountName', header: 'Name' },
      { field: 'supplierAdmin', header: 'Supplier Admin' },
      { field: 'lastInvitedDate', header: 'Last Invited Date' },
      {
        field: 'registrationCompletionDate',
        header: 'Registration Completion Date',
      },
    ];
  }

  GetAccountDetail(acc: any) {
    const selectedTab = this.items[this.checkTabsId];
    const id = acc.id;
    const statusId = acc.statusID;
    if (statusId === StatusTabEnum.ApprovedStatusId) {
      this.checkTabs = 'AccountId';
    } else if (statusId !== StatusTabEnum.InactiveStatusId && statusId !== StatusTabEnum.ApprovedStatusId) {
      this.checkTabs = 'InvitedAccountId';
    } else if (statusId === StatusTabEnum.InactiveStatusId) {
      this.checkTabs =
        acc.accountId !== 0 && (acc.invitedAccountId === 0 || acc.invitedAccountId === null)
          ? 'AccountId'
          : 'InvitedAccountId';
    }
    const type = this.checkTabs;
    this._router.navigate(['/registration/view-account/' + id + '/' + type], {
      queryParams: {
        showAccountHierarchy: selectedTab.label.toUpperCase().indexOf('APPROVED') !== -1,
      },
    });
  }
}
