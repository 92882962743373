import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitedAccountClient, InvitedAccountModel } from '../../api.client';

@Injectable()
export class RetailLinkRegistrationService {
  constructor(private _invitedAccountClient: InvitedAccountClient) {}

  public getInvitedAccountById(id: string): Observable<any> {
    return this._invitedAccountClient.getInvitedAccountById(id);
  }

  public saveInvitedAccount(params: InvitedAccountModel): Observable<any> {
    return this._invitedAccountClient.registerInvitedAccount(params);
  }

  public registerFileUpload(file: any): Observable<any> {
    return this._invitedAccountClient.registerFileUpload(file);
  }

  public getStateDropDownList(): Observable<any> {
    return this._invitedAccountClient.getStateList();
  }

  public getCountryDropDownList(): Observable<any> {
    return this._invitedAccountClient.getCountryList();
  }

  public checkEmailAlreadyExist(email: string, id: number): Observable<any> {
    return this._invitedAccountClient.checkEmailAlreadyExist(email, id);
  }
}
