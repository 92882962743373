export class VoltAccountViewModel {
  name: string = '';
  imageUrl: string | null = null;
  websiteUrl: string | null = null;
  shipping: Shipping = {
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: null,
      zip: null
    },
    shouldShipDirectToRep: false,
    contact: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null
    }
  }
  billing: Billing = {
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: null,
      zip: null
    },
    contact: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null
    }
  }
  abbreviation: string = ''
  address: Address = {
    address1: null,
    address2: null,
    city: null,
    state: null,
    country: null,
    zip: null
  }
  note: string | null = null;
  support: Support = {
    email: null,
    phone: null,
    note: null
  }
  it: IT = {
    email: null,
    phone: null
  };
  status: string | null = null

  constructor() {}
}

export interface Shipping {
  address: Address;
  shouldShipDirectToRep: boolean;
  contact: Contact;
}

export interface Billing {
  address: Address;
  contact: Contact;
}

export interface Address {
  address1: string | null;
  address2: string  | null;
  city: string | null;
  state: string | null;
  country: string | null;
  zip: string | null;
}

export interface Support {
  email: string | null;
  phone: string | null;
  note: string | null;
}

export interface IT {
  email: string | null;
  phone: string | null;
}

export interface Contact {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
}
