import { Injectable } from '@angular/core';
import { iif, Observable } from 'rxjs';
import { ActionLogClient, ActionLogType, ImportStatus } from '../../api.client';
import { ActionLogFilterParams } from '../action-log-filter-parameters';

@Injectable()
export class ActionLogsService {
  constructor(private _actionLogClient: ActionLogClient) {}

  public getServiceLogData(params: ActionLogFilterParams): Observable<any> {
    if (params.searchType === 'reference') {
      return this.getServiceLogDataByReference(params);
    }

    if (params.searchType === 'statusAndType') {
      return this.getServiceLogDataByStatusAndType(params);
    }

    return this.getServiceLogDataByTransaction(params);
  }

  public getServiceLogDataByTransaction(params: ActionLogFilterParams): Observable<any> {
    const offset = params.page;
    const limit = params.pageSize;
    return this._actionLogClient.searchByTransactionId(
      params.accountId,
      params.transactionId,
      params.start,
      params.sortColumn,
      params.ascending,
      offset,
      limit,
    );
  }

  public getServiceLogDataByReference(params: ActionLogFilterParams): Observable<any> {
    const offset = params.page;
    const limit = params.pageSize;
    return this._actionLogClient.searchByReferenceNumber(
      params.accountId,
      params.referenceNumber,
      params.start,
      params.sortColumn,
      params.ascending,
      offset,
      limit,
    );
  }

  public getServiceLogDataByStatusAndType(params: ActionLogFilterParams): Observable<any> {
    const offset = params.page;
    const limit = params.pageSize;
    return this._actionLogClient.searchByStatusAndType(
      params.accountId,
      ImportStatus[params.status],
      ActionLogType[params.type],
      params.start,
      params.sortColumn,
      params.ascending,
      offset,
      limit,
    );
  }
}
