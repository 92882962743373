import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  EventEmitter, Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'select-button',
  templateUrl: './select-button.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: UntypedFormControl;
  @Input() translations: Translations;
  @Input() disabled = false;
  @Output() selectionChanged = new EventEmitter<boolean>();

  options: SelectItem[];
  value = null;

  constructor() { }

  ngOnInit(): void {
    this.value = this.control.value;
    this.control.valueChanges.pipe(
      OperatorUtils.takeUntilDisposed(this),
    ).subscribe(v => {
      this.value = v
      this.selectionChanged.emit(this.value);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.options =  [
      {
        label: this.translations.yes,
        value: true
      },
      {
        label: this.translations.no,
        value: false
      }
    ]
  }

  ngOnDestroy() {
  }
}
