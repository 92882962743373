import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogBaseComponent } from '../../dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { ImageEditComponent } from './image-edit.component';

@Component({
  selector: 'v-image-edit-dialog',
  template: `
    <ng-container class="image-edit-modal">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
      </div>
      <div class="modal-body">
        <v-image-edit [imageUrl]="imageUrl"></v-image-edit>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="onClickSave()">Save</button>
        <button type="button" class="btn btn-outline-danger" (click)="onClose()">Cancel</button>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditDialogComponent extends DynamicDialogBaseComponent implements OnInit {
  @ViewChild(ImageEditComponent) imageEditComponent: ImageEditComponent = null;

  imageUrl: string = null;
  title = 'Edit Image';

  constructor(private readonly _dialogRef: DynamicDialogRef, private readonly _dialogConfig: DynamicDialogConfig) {
    super(_dialogRef, _dialogConfig);
  }

  ngOnInit() {
    const { title, imageUrl } = this._dialogConfig.data;
    this.title = title;
    this.imageUrl = imageUrl;
  }

  public onClickSave() {
    this._dialogRef.close(this.imageEditComponent.getCanvas());
  }
}
