import * as moment from 'moment';
import { DateRange } from '../../../../api.client';

export interface IRangeSetting {
  id: any;
  name: string;
  days: number;
  isDefault?: boolean;
  selected?: boolean;
}

export enum RangeDirection {
  Past,
  Future,
}

export class RangeSetting implements IRangeSetting {
  id: any;
  name: string;
  days: number;
  isDefault?: boolean;
  selected?: boolean;

  static buildDateRange(rangeSetting: RangeSetting, direction: RangeDirection = RangeDirection.Past) {
    if (rangeSetting.days === null) {
      const rangeD = new DateRange();
      rangeD.startDate = null;
      rangeD.endDate = null;
      return rangeD;
    }

    const now = moment(new Date()).startOf('day');
    const then =
      direction === RangeDirection.Past
        ? moment(new Date())
            .startOf('day')
            .subtract(rangeSetting.days, 'd')
        : moment(new Date())
            .startOf('day')
            .add(rangeSetting.days, 'd');
    const range = new DateRange();
    range.endDate = direction === RangeDirection.Past ? now : then;
    range.startDate = direction === RangeDirection.Past ? then : now;
    return range;
  }

  static buildDateRangeAsHours(rangeSetting: RangeSetting, direction: RangeDirection = RangeDirection.Past): DateRange {
    if (rangeSetting.days === null) {
      const rangeD = new DateRange();
      rangeD.startDate = null;
      rangeD.endDate = null;
      return rangeD;
    }

    const totalHours = rangeSetting.days * 24;
    const now = moment();
    const then =
      direction === RangeDirection.Past ? moment().subtract(totalHours, 'hours') : moment().add(totalHours, 'hours');
    const range = new DateRange();
    range.endDate = direction === RangeDirection.Past ? now : then;
    range.startDate = direction === RangeDirection.Past ? then : now;
    return range;
  }

  constructor(data?: IRangeSetting) {
    this.name = data ? data.name : '';
    this.days = data ? data.days : 0;
    this.id = data ? data.id : null;
    this.isDefault = data ? data.isDefault : false;
  }
}
