import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SharedImportComponent } from '@volt/shared/components/shared-import/shared-import.component';
import { ICustomFile } from 'file-input-accessor';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../../auth';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../../configuration';
import { environment } from '../../../../environments/environment';
import { AssemblerClient, FileParameter, FileResponse, UploadStatus, UserInformation } from '../../../api.client';
import { EVENTS } from '@volt/shared/constants/events.constants';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { BlobService } from '@volt/shared/services/blob.service';

@Component({
  selector: 'assembler',
  templateUrl: './assembler.component.html',
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssemblerComponent implements OnInit {
  public loading = false;
  public currentUserInformation: UserInformation;
  public pageName = 'Assembler Priority';

  @ViewChild(SharedImportComponent) sharedImport: SharedImportComponent;

  ngOnInit(): void {
    this._authService.authUser$.subscribe(user => {
      this.currentUserInformation = user;
    });
  }

  constructor(
    private readonly _assemblerClient: AssemblerClient,
    private readonly _messageService: MessageService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _monitoringService: MonitoringService,
    private readonly _authService: AuthService,
    private readonly _blobService: BlobService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  public downloadAssemblers() {
    this._monitoringService.trackEvent(EVENTS.AssemblerDownload, {
      Activity: `User ${this.currentUserInformation.email} from company ${this.currentUserInformation.account} is downloading assembler`,
    });
    this.loading = true;
    this._assemblerClient.download().subscribe(
      (file: FileResponse) => {
        this._cdr.markForCheck();
        this.loading = false;
        const date = new Date();
        const fileName = `assemblers_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`;
        CommonUtils.download(file.data, fileName);
      },
      (err: any) => {
        this._cdr.markForCheck();
        this.loading = false;
        console.error(err);
        this._monitoringService.logApiException(err);
        this._messageService.add({
          severity: 'error',
          summary: 'Error in Downloading file',
          detail: err.errors,
        });
      },
    );
  }

  public uploadAssemblers(files: ICustomFile[]) {
    if (!files || !files.length) {
      return;
    }

    const file: FileParameter = {
      data: files[0],
      fileName: files[0].name,
    };

    this._monitoringService.trackEvent(EVENTS.AssemblerUpload, {
      Activity: `User ${this.currentUserInformation.email} from company ${this.currentUserInformation.account} is uploading assembler file ${file.fileName}`,
    });
    this.loading = true;
    this._assemblerClient
      .upload(this.pageName, file)
      .pipe(
        catchError(err => {
          this._cdr.markForCheck();
          this._monitoringService.logApiException(err);
          this.loading = false;
          console.error(err);
          this._messageService.add({
            severity: 'error',
            summary: 'Error in input format. Please correct your input file',
            detail: err.errors,
          });
          return of(null);
        }),
      )
      .subscribe((data: UploadStatus) => {
        this._cdr.markForCheck();
        this.loading = false;
        const errors = data?.errors && data.errors.length;
        if (errors) {
          this._messageService.add({
            severity: 'error',
            summary: 'There was an error uploading your file',
            detail: data.errors[0],
          });
        } else {
          this._messageService.add({
            severity: 'success',
            summary: 'All records are added successfully',
          });
          this.sharedImport.reset();
        }
      });
  }

  downloadTemplate() {
    this._blobService
      .downloadBlobFromContainer(environment.uploadsSecureContainer, environment.assembler.assemblerTemplate)
      .subscribe(
        _ => {},
        err => {
          console.error(err);

          this._messageService.add({
            severity: 'error',
            detail: 'Could not download file',
          });
        },
      );
  }
}
