/**
 * This file monkey patches some Models generated by NSwag
 */

import { ERoleType, UserInformation } from './app/api.client';
import { RoleConstant } from './app/auth/shared/roles.constants';
import { nameOf } from './app/shared/utils/common.utils';

/**
 * Patch UserInformation class so we can run `isInRole` function
 */
Object.defineProperty(UserInformation.prototype, nameOf<UserInformation>('isInRole'), {
  value: function (this: UserInformation, ...roles: RoleConstant[]) {
    return roles.some(r => r === (this.roles as RoleConstant));
  },
  configurable: false,
  writable: false,
});

Object.defineProperty(UserInformation.prototype, nameOf<UserInformation>('canViewMultipleAccounts'), {
  value: function () {
    return this.roleType === ERoleType.SystemAdmin || this.roleType === ERoleType.Retailer;
  },
  configurable: false,
  writable: false,
});
