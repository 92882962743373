import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../../auth';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';

@Directive({
  selector: '[userCanViewMultipleBanners]',
})
export class UserCanViewMultipleBannersDirective implements OnInit, OnDestroy {
  // Pass a reference to another element you want to share the result of this directive with
  // Ideal for styling a parent component
  @Input() userCanViewMultipleBanners?: HTMLElement;

  constructor(
    private readonly _authService: AuthService,
    private readonly _viewRef: ViewContainerRef,
    private readonly _thenTemplateRef: TemplateRef<any>,
  ) {}

  ngOnInit() {
    this._authService.authUser$.pipe(OperatorUtils.takeUntilDisposed(this)).subscribe(user => {
      if (user.banners?.length > 1) {
        this._viewRef.createEmbeddedView(this._thenTemplateRef);
        this.userCanViewMultipleBanners?.setAttribute('userCanViewMultipleBanners', 'true');
      } else {
        this._viewRef.clear();
        this.userCanViewMultipleBanners?.removeAttribute('userCanViewMultipleBanners');
      }
    });
  }

  ngOnDestroy() {}
}
