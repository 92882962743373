export class ServicedeploymnetTaskSearchParameters {
  constructor() {
    this.nameSearch = '';
    this.referenceNumberSearch = '';
    this.activitySearch = '';
    this.suppliersSearch = '';
    this.departmentsSearch = '';
    this.noteSearch = '';
    this.serviceModelSearch = '';
  }

  public nameSearch: string;
  public referenceNumberSearch: string;
  public activitySearch: string;
  public suppliersSearch: string;
  public departmentsSearch: string;
  public noteSearch: string;
  public serviceModelSearch: string;
  public statusSearch: string;
}
