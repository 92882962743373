import { OnInit, Input, Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'time-estimate',
  templateUrl: './time-estimate.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})

// tslint:disable-next-line:component-class-suffix
export class TimeEstimateComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;

  constructor(private _dynamicDialogService: DynamicDialogService) {}
  ngOnInit(): void {}

  public get timeEstimateControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('timeEstimate');
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'The average amount of time in minutes that the task should take at each store.',
          'No decimals, round to the nearest minute.',
        ],
      },
    });
  }

  isNumberKey(evt) {
    // tslint:disable-next-line:prefer-const
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
}
