<p-toolbar>
  <div class="v-filter-grid">
    <v-activities-field [control]="controls.activities"></v-activities-field>
    
    <v-date-field
      [control]="controls.startDate"
      [label]="translations.startDate"
      [forceStartOfDay]="true"
    ></v-date-field>
    
    <v-date-field
      [control]="controls.endDate"
      [minDate]="controls.startDate.value"
      [maxDate]="maxEndDate"
      [label]="translations.endDate"
      [forceStartOfDay]="true"
    ></v-date-field>
    
  </div>
  
  <div class="filter-footer">
    <button pButton class="btn-primary p-button-sm"
            [label]="translations.clearFilters"
            (click)="clearFilters()"
    ></button>
  </div>
</p-toolbar>

