<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="service-model-label">
      Service Model
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="service-model-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-dropdown
      [filter]="true"
      [style]="{ width: '100%' }"
      [options]="serviceModelList"
      [(ngModel)]="selectedServiceModel"
      name="serviceType"
      [formControlName]="'serviceType'"
      data-cy="service-model"
    ></p-dropdown>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && serviceModelTypeControl.hasError('required')"
        class="text-danger"
        data-cy="service-model-error"
      >
        Service Model is required
      </span>
    </div>
  </div>
</div>
