<div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; margin-bottom: 1rem">
    <div>
        <input-label [label]="translations.email" [tooltip]="translations['accounts.registration.it.email.tooltip']"></input-label>
        <div class="p-inputgroup w-100">
            <span class="p-inputgroup-addon"><i class="fa fa-envelope"></i></span>
            <input autocomplete="email" pInputText [formControl]="controls.email" class="w-100">
        </div>
    </div>

    <div>
        <input-label [label]="translations.phone" [tooltip]="translations['accounts.registration.it.phone.tooltip']"></input-label>
        <div class="p-inputgroup w-100">
            <span class="p-inputgroup-addon"><i class="fa fa-phone"></i></span>
            <p-inputMask [formControl]="controls.phone" mask="(999) 999-9999? x99999" class="w-100" styleClass="w-100"></p-inputMask>
        </div>
    </div>
</div>
