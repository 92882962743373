import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { UserAccessReviewClient } from '@volt/api';
import { RedirectUserService } from '@volt/shared/services/redirect-user.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserAccessReviewReportGuard implements CanActivate, CanLoad {
  constructor(
    private readonly userAccessReviewClient: UserAccessReviewClient,
    private readonly redirectUserService: RedirectUserService,
  ) {}
  canLoad(): Observable<boolean> {
    return this.canAccessUserAccessReviewReport();
  }
  canActivate(): Observable<boolean> {
    return this.canAccessUserAccessReviewReport();
  }

  private canAccessUserAccessReviewReport(): Observable<boolean> {
    return this.userAccessReviewClient.hasAccessToReport().pipe(
      tap((hasPermission: boolean) => {
        if (!hasPermission) this.redirectUserService.redirectUnauthorizedUser();
      }),
    );
  }
}
