<v-container-wrapper [showHeader]="false">
  <ng-template vContainerTemplate="content" let-translated="translated">
    <header class="page-header d-flex align-items-center justify-content-between">
      <h2 class="page-title">{{ translated.serviceLogs }}</h2>
    </header>

    <search-filter
      [pspAccountList]="pspAccountList"
      [minDate]="minDate"
      [currentApiLogParams]="actionlogfilterParams"
      [translations]="translated"
    ></search-filter>

    <v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
      <div class="row mt-3">
        <div class="col-12">
          <p-table
            #dt
            [columns]="cols"
            [value]="data"
            [rows]="20"
            [totalRecords]="totalRecords"
            [responsive]="true"
            [lazy]="true"
            (onLazyLoad)="sortdata($event)"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{ col.header }}

                  <div *ngIf="col.field != 'transactionId' && col.field != 'aname' && col.field != 'message'">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <span class="p-column-title">{{ col.header }}</span>

                  <div *ngIf="col.field === 'updatedDate'; else notADate">
                    {{ rowData[col.field] | date: 'short' }}
                  </div>
                  <ng-template #notADate>
                    {{ rowData[col.field] }}
                  </ng-template>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="6">
                  {{ translated.noDataToDisplay }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="col-md-12" *ngIf="totalRecords">
          <ngb-pagination
            [collectionSize]="totalRecords"
            class="d-flex justify-content-center"
            [pageSize]="actionlogfilterParams.pageSize"
            [page]="actionlogfilterParams.page"
            [maxSize]="1"
            [rotate]="true"
            [ellipses]="false"
            (pageChange)="pagination($event)"
          >
          </ngb-pagination>
        </div>
      </div>
    </v-loading-container>
  </ng-template>
</v-container-wrapper>

