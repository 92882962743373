import { Input, ChangeDetectionStrategy, Component, OnInit, Output, EventEmitter } from '@angular/core';

type styleOption = 'single_btn' | 'double_btn' | 'delete_btn';

@Component({
  selector: 'modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFooterComponent implements OnInit {
  private _defaultSaveLabel: string = 'Save';
  private _defaultCancelLabel: string = 'Cancel';

  @Input() disabled: boolean = false;
  @Input() saveLabel: string = this._defaultSaveLabel;
  @Input() cancelLabel: string = this._defaultCancelLabel;
  @Input() footerStyle: styleOption = 'double_btn';
  @Input() overrideContent = false;

  @Output() onClose = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<void>();

  showCancel: boolean = true;
  saveBtnClass: string = 'btn min-btn-width btn-primary';

  constructor() {}

  ngOnInit(): void {
    this._formatFooterStyle();
  }

  private _formatFooterStyle(): void {
    if (this.footerStyle === 'single_btn') {
      this.showCancel = false;
    } else if (this.footerStyle === 'delete_btn') {
      this.saveBtnClass = this.saveBtnClass.replace('btn-primary', 'btn-danger');
      this.saveLabel = this.saveLabel === this._defaultSaveLabel ? 'Delete' : this.saveLabel;
    } else {
      this.saveLabel = this.saveLabel ? this.saveLabel : this._defaultSaveLabel;
      this.cancelLabel = this.cancelLabel ? this.cancelLabel : this._defaultCancelLabel;
    }
  }

  onClickButtonClose(): void {
    this.onClose.emit();
  }

  onClickButtonSave(): void {
    this.onSubmit.emit();
  }
}
