<p-toolbar>
  <div class="v-filter-grid">
    <v-banner-field *userCanViewMultipleBanners [control]="controls.banner"></v-banner-field>

    <div *authorize="[allowedView.RetailerAdmin, allowedView.Admin, allowedView.RetailerCalendarAdmin]">
      <v-accounts-field [control]="controls.accountIds"></v-accounts-field>
    </div>

    <v-date-field
      [control]="controls.startDate"
      [label]="translations.startDate"
      [placeholder]="translations.startDate"
    ></v-date-field>

    <v-date-field
      [control]="controls.endDate"
      [label]="translations.endDate"
      [placeholder]="translations.endDate"
    ></v-date-field>

    <v-search-field [control]="controls.name" [placeholder]="translations.searchByName"></v-search-field>

    <div class="d-flex justify-content-between grid-row">
      <p-button
        [label]="translations.clearAll"
        icon="fa fa-repeat"
        styleClass="p-button-danger p-button-sm"
        data-cy="clear-all-button"
        (click)="clearFilters()"
      ></p-button>
      <div>
        <p-button
          [label]="translations.advancedSearch"
          icon="fa fa-search"
          styleClass="p-button-primary p-button-sm me-2"
          data-cy="ser-dep-adv-search-button"
          (click)="showAdvancedFilterDialog()"
        ></p-button>
        <p-button
          [label]="translations.addServiceDeployment"
          icon="fa fa-file"
          styleClass="p-button-primary p-button-sm me-2"
          data-cy="ser-dep-add-button"
          (click)="createDeployment.emit()"
        ></p-button>
        <p-button
          [label]="translations.export"
          icon="fa fa-file-export"
          styleClass="p-button-primary p-button-sm"
          (click)="export.emit()"
        ></p-button>
      </div>
    </div>
  </div>
</p-toolbar>
