import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseFilterForm } from "@volt/shared/components/form-fields/base-filter-form";
import { Translations } from "@volt/shared/services/translations.types";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";
import { ActivityAggregateFilterParams } from "../../../models/activity-aggregate-filter-params";

@Component({
  selector: 'activity-aggregate-filters',
  templateUrl: './activity-aggregate-filters.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityAggregateFiltersComponent extends BaseFilterForm<ActivityAggregateFilterParams> implements OnInit, OnDestroy {
  @Input() translations: Translations;
  @Input() filters: ActivityAggregateFilterParams;
  @Output() filtersChanged = new EventEmitter<ActivityAggregateFilterParams>();
  
  maxEndDate: Date;
  
  private _onDestroy$ = new Subject();

  constructor(private _fb: UntypedFormBuilder, 
              private _router: Router, 
              private _activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.maxEndDate = this.filters.endDate;
    this.initFormSharedValues(({previous, current}) => {
      if (!_.isEqual(previous, current)) {
        this.maxEndDate = new Date(current.startDate);
        this.maxEndDate.setDate(current.startDate.getDate() + 30);
        
        if (current.endDate.getTime() > this.maxEndDate.getTime()) {
          console.log("maxEndDate: ", this.maxEndDate);
          console.log("end date past maxEndDate: ", current.endDate);
          this.controls.endDate.setValue(this.maxEndDate);
        } else if (current.endDate.getTime() < current.startDate.getTime()) {
          this.controls.endDate.setValue(current.startDate);
        } else {
          this.filtersChanged.emit(current);
        }
      }
    });
    
    this._activatedRoute.queryParams
      .pipe(
        takeUntil(this._onDestroy$),
        debounceTime(500),
        filter(
          () =>
            this._activatedRoute.snapshot.paramMap.get('tabIndex') === '1' ||
            this._activatedRoute.snapshot.paramMap.get('tabIndex') === '2',
        ),
      )
      .subscribe(() => {
        this.filters = ActivityAggregateFilterParams.fromParams(this._activatedRoute.snapshot.queryParams);
        this._updateForm();
      });
  }
  
  ngOnDestroy() {
    super.ngOnDestroy();
    this._onDestroy$.next();
  }

  clearFilters() {
    this.form.reset(this.form.defaultValues);
  }

  initForm(): void {
    this.filters = new ActivityAggregateFilterParams();
    this.form = this._fb.group({
      activities: [],
      startDate: [this.filters.startDate],
      endDate: [this.filters.endDate]
    });
    
    this.form.defaultValues = new ActivityAggregateFilterParams();
  }
  
  private _updateForm(): void {
    this.controls.startDate.setValue(this.filters.startDate);
    this.controls.endDate.setValue(this.filters.endDate);
  }
}
