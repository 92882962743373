import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'accountImage'
})
export class AccountImagePipe implements PipeTransform {

  constructor(private readonly _http: HttpClient) {
  }

  transform(url: string): Observable<string> {
    if(url == null) return of(null);

    return this._http.get(url, { responseType: 'text' }).pipe(
      map(res => res as string)
    );
  }

}
