import { Inject, Injectable } from '@angular/core';
import {
  AlternativeNumber,
  AlternativeNumbersBatch,
  AlternativeNumbersBatchItem,
  AlternativeNumbersBatchItemOperation,
  AlternativeNumbersClient,
} from '@volt/api';
import { StateSubject } from '@volt/shared/extensions/state-subject';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { PC, PermissionCollection, PermissionsService } from '@volt/shared/services/permissions.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const SUPPLIER_SELF: unique symbol = Symbol('supplier.self');

@Injectable()
export class SupplierNumberService {
  private $loading = new StateSubject<boolean>(false);
  private $supplierNumbers = new BehaviorSubject<AlternativeNumber[]>([]);

  private _batch: AlternativeNumbersBatchItem[] = [];
  private _accountId?: number;

  get loading$() {
    return this.$loading.asObservable();
  }

  get supplierNumbers$() {
    return this.$supplierNumbers.asObservable();
  }

  constructor(
    @Inject(PC) public pc: PermissionCollection,
    private readonly _numbersClient: AlternativeNumbersClient,
    private readonly _monitoringService: MonitoringService,
    private readonly _permissionsService: PermissionsService,
  ) {}

  get(accountId?: number): void {
    if (!accountId) {
      this._accountId = accountId;
      this.$supplierNumbers.next([]);
      return;
    }

    this.loadNumbers(accountId).subscribe(
      v => {
        this._accountId = accountId;
        this._batch = [];
        this.$supplierNumbers.next(v.numbers);
      },
      err => {
        this._monitoringService.logError(err);
        this.$supplierNumbers.error(err);
      },
    );
  }

  isNumberAlreadyUsed(
    supplierNumber: string,
  ): Observable<{ supplierNumber: string; supplier: string | typeof SUPPLIER_SELF } | null> {
    if (this.$supplierNumbers.value.some(n => n.number === supplierNumber)) {
      return of({
        supplier: SUPPLIER_SELF,
        supplierNumber: supplierNumber,
      });
    }

    return this._numbersClient.getSupplierNumber(supplierNumber, this._accountId);
  }

  add(alternativeNumber: AlternativeNumber): void {
    const existing = this.$supplierNumbers.value;
    const next = [...existing, alternativeNumber];
    this.$supplierNumbers.next(next);

    this._batch.push(
      new AlternativeNumbersBatchItem({
        operationType: AlternativeNumbersBatchItemOperation.Add,
        number: alternativeNumber.number,
      }),
    );
  }

  delete(alternativeNumber: AlternativeNumber): void {
    const existing = this.$supplierNumbers.value;
    const next = existing.filter(r => r !== alternativeNumber);
    this.$supplierNumbers.next(next);

    this._batch.push(
      new AlternativeNumbersBatchItem({
        operationType: AlternativeNumbersBatchItemOperation.Remove,
        number: alternativeNumber.number,
      }),
    );
  }

  save(): Observable<unknown> {
    if (!this._accountId) {
      return of(null);
    }

    if (this._batch.length === 0) {
      return of(null);
    }

    const accountId = this._accountId;
    const request = new AlternativeNumbersBatch({ operations: this._batch });
    return this._numbersClient.updateAlternativeNumbers(accountId, request).pipe(
      catchError(err => {
        this._monitoringService.logError(err);
        throw err;
      }),
      map(r => {
        this._accountId = accountId;
        this._batch = [];
        this.$supplierNumbers.next(r.numbers);
        return of(null);
      }),
    );
  }

  private loadNumbers(accountId: number) {
    return this._permissionsService.hasPermission(this.pc.names.AccountsManageAlternativeSupplierNumbers, this.pc.privilege.Read)
      ? this._numbersClient.getAlternativeNumbers(accountId)
      : this._numbersClient.getMyAlternativeNumbers();
  }
}
