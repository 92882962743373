<v-loading-container>
  <p-table [columns]="cols" [value]="certificationList" [rows]="20" [responsive]="true" [lazy]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngStyle]='{width: col.width || "100px"}'>
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngStyle]='{width: col.width || "100px"}'>
          <div *ngIf="col.field == 'checkBoxes'">
            <div class="p-g-12">
              <p-checkbox
                name="group"
                value="{{ rowData['courseId'] }}"
                (onChange)="getCertificationId()"
                [(ngModel)]="selectedCertification"
                inputId="{{ rowData['courseId'] }}"
              ></p-checkbox>
            </div>
          </div>
          <div>
            {{ rowData[col.field] }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</v-loading-container>
