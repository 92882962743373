import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslationService } from '@volt/shared/services/translation.service';

@Component({
  selector: 'use-personal-contact',
  templateUrl: './use-personal-contact.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsePersonalContactComponent implements OnInit {
  @Input() selected = true;

  @Input() set type(t: string) {
    this.label = this._translationService.translate('contactInfoSameAsPersonalContact', {name: t});
  }

  label: string;

  constructor(private readonly _translationService: TranslationService) { }

  ngOnInit(): void {
  }
}
