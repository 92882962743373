import { Reducer } from 'redux';
import { createFeatureSelector } from '../../shared/redux-utils';
import { ServiceDeploymentsReportActions } from '../actions/service-deployments-report.actions';
import { ServiceDeploymentReportParams } from '../models/servicedeployment-report-parameters';

export const serviceDeploymentsReportReducer: Reducer<
  ServiceDeploymentReportParams,
  ServiceDeploymentsReportActions
> = (state = new ServiceDeploymentReportParams(), action) => {
  if (action.type === 'SET_REPORT_FILTER') {
    return { ...state, ...action.payload };
  }

  return state;
};

export const serviceDeploymentsReportSelector = createFeatureSelector('serviceDeploymentsReportFilters');
