<v-loading-container [working]="loading">
  <div class="container-fluid">
    <div class="row flex-xl-nowrap">
      <div class="col-md-12">
        <div class="">
          <form (submit)="processProduct()" [formGroup]="_addProductFormGroup">
            <div class="sep-form">
              <h2>Add Product</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label>
                          WIC/Part#
                          <span aria-required="true" class="required">*</span>
                          <span><i aria-hidden="true" class="fa fa-info-circle"></i></span>
                        </label>
                      </div>
                      <div class="col-md-6">
                        <input
                          #partN
                          (blur)="checkForExistingPartNumber(partN.value)"
                          [attr.disabled]="'disabled' ? productId : null"
                          class="form-control rounded-0"
                          formControlName="partNumber"
                        />
                        <div *ngIf="partNumberAlreadyExist" class="text-danger">
                          Part Number Already Exists
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>
                          Part Name
                          <span aria-required="true" class="required">*</span>
                          <span><i aria-hidden="true" class="fa fa-info-circle"></i></span>
                        </label>
                      </div>
                      <div class="col-md-6">
                        <input class="form-control rounded-0" formControlName="partName" />
                      </div>
                    </div>
                    <div class="row mt-3"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>
                          Category
                          <span aria-required="true" class="required">*</span>
                          <span><i aria-hidden="true" class="fa fa-info-circle"></i></span>
                        </label>
                      </div>
                      <div class="col-md-6">
                        <input class="form-control rounded-0" formControlName="category" />
                      </div>
                    </div>
                    <div class="row mt-3"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Status</label>
                      </div>
                      <div class="col-md-6">
                        <p-inputSwitch formControlName="status" offLabel="Inactive" onLabel="Active"></p-inputSwitch>
                      </div>
                    </div>
                    <div class="row mt-3"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Updated By :</label>
                      </div>
                      <div *ngIf="productId && product" class="col-md-6">
                        {{ product.createdByName }},{{ product.createdByEmail }}
                      </div>
                    </div>
                    <div class="row mt-3"></div>

                    <div class="row">
                      <div class="col-md-6">
                        <label>Updated :</label>
                      </div>
                      <div *ngIf="productId && product" class="col-md-6">
                        {{ product.updatedDate }}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label>Product</label>
                        <span class="image-text">
                          Upload an image file with the extension of
                          <br />
                          JPG or PNG.
                          <br />
                          The image size limit is
                          <br />
                          8MB.
                        </span>
                      </div>
                      <div class="col-md-6">
                        <div class="img-upload">
                          <div *ngIf="!file.length; else hasFiles">
                            <img
                              *ngIf="product && product.imageUrl; else noImage"
                              [src]="product.imageUrl + '?height=25'"
                            />
                          </div>
                          <ng-template #hasFiles>
                            <div *ngFor="let f of file" class="mt-1">
                              <img *ngIf="f.imgSrc; else noImage" [src]="f.imgSrc" />
                            </div>
                          </ng-template>
                          <ng-template #noImage>
                            <img src="../../../assets/img/noimage.gif" />
                          </ng-template>
                        </div>
                        <div>
                          <input
                            [allowedExt]="'(.png|.jpg|.jpeg)'"
                            [size]="8000000"
                            [withMeta]="true"
                            class="form-control-file"
                            formControlName="image"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3"></div>
                <div class="row">
                  <div class="col-xl-7 col-sm-12 col-md-12">
                    <div class="submit-btn" style="text-align: center;">
                      <button [disabled]="!_addProductFormGroup.valid" class="btn btn-primary" type="submit">
                        Submit
                      </button>
                      &nbsp;&nbsp;

                      <button (click)="cancel()" class="btn btn-primary" type="button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
