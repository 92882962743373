<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-6">
      <header class="page-header d-flex">
        <h2 class="page-title">
          <span>Accounts</span>
        </h2>
      </header>
    </div>
    <div class="col-md-6">
      <div class="d-flex">
        <p-menu [model]="menuItems" #menu [popup]="true" appendTo="body" styleClass="w-auto"></p-menu>
        <button class="btn btn-sm btn-primary ms-auto" type="button" (click)="menu.toggle($event)">
          Actions
          <i class="fa fa-chevron-down"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row row mt-1">
    <div class="col-12">
      <div class="card rounded-0">
        <div class="card-body">
          <accountview-list></accountview-list>
        </div>
      </div>
    </div>
  </div>
</div>
