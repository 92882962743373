import { NgRedux } from '@angular-redux/store';
import { Injectable, InjectionToken } from '@angular/core';
import { EffectivePermissionViewModel, PermissionClient } from '../../api.client';
import { PermissionNames } from '../../shared/services/permissionNames';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IAppState } from '../../store';
import { AuthService } from '../../auth';
import { StateSubject } from '../../shared/extensions/state-subject';

export enum Privilege {
  Read = 1,
  Create = 2,
  Update = 4,
  Delete = 8,
  All = 15,
}

export interface PermissionCollection {
  names: typeof PermissionNames;
  privilege: typeof Privilege;
}

export const PC = new InjectionToken<PermissionCollection>('@@permissionCollection');

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private $currentPermissions: ReplaySubject<EffectivePermissionViewModel[]> = new ReplaySubject<
    EffectivePermissionViewModel[]
  >();
  public permissionsLoaded$ = new StateSubject<boolean>(false);

  constructor(
    private readonly permissionClient: PermissionClient,
    private readonly _authService: AuthService,
    private readonly _appState: NgRedux<IAppState>,
  ) {}

  public get permissionsLoaded(): boolean {
    return this.permissionsLoaded$.getValue();
  }

  public get currentPermissions$(): Observable<EffectivePermissionViewModel[]> {
    return this.$currentPermissions.asObservable();
  }

  public loadPermissions() {
    this.$currentPermissions = new ReplaySubject(1);
    if (this._authService.hasValidToken()) {
      this.permissionClient
        .retrievePermissionsForUser()
        .pipe(
          catchError(() => {
            this.permissionsLoaded$.next(false);
            console.error('Unable to load permissions');
            return of([]);
          }),
        )
        .subscribe(permissions => {
          this.$currentPermissions.next(permissions);
          this.permissionsLoaded$.next(true);
        });
    } else {
      this.$currentPermissions.next([]);
      this.permissionsLoaded$.next(false);
    }
  }

  public hasPermission$(permissionToCheck: PermissionNames, privilege: Privilege): Observable<boolean> {
    return permissionToCheck == null || privilege == null
      ? of(true)
      : this.currentPermissions$.pipe(
          map(permissions =>
            permissions.some(
              permission =>
                permission.permissionId === permissionToCheck && (permission.activity & privilege) === privilege,
            ),
          ),
          take(1),
        );
  }

  public hasPermission(permissionToCheck: PermissionNames, privilege: Privilege): boolean {
    let permission = false;

    this.hasPermission$(permissionToCheck, privilege).subscribe(value => {
      permission = value;
    });

    return permission;
  }

  public hasPermissionForLocation$(
    permissionToCheck: PermissionNames,
    privilege: Privilege,
    storeNumber: string,
  ): Observable<boolean> {
    if (permissionToCheck == null || privilege == null || storeNumber == null) {
      return of(true);
    }

    return this.currentPermissions$.pipe(
      map(permissions => {
        return permissions.some(
          permission =>
            (permission.storeNumber === null || permission.storeNumber === storeNumber) &&
            permission.permissionId === permissionToCheck &&
            (permission.activity & privilege) === privilege,
        );
      }),
      take(1),
    );
  }

  public hasPermissionForLocation(
    permissionToCheck: PermissionNames,
    privilege: Privilege,
    storeNumber: string,
  ): boolean {
    let permission = false;
    this.hasPermissionForLocation$(permissionToCheck, privilege, storeNumber).subscribe(value => {
      permission = value;
    });
    return permission;
  }
}
