<v-loading-container [working]="loading" [spinnerColor]="'#ffc220'">
  <div class="container-fluid dashboard-page">
    <div class="row flex-xl-nowrap">
      <div class="col-xl-12">
        <div class="">
          <form
            novalidate
            [formGroup]="addServiceTaskLocationFormGroup"
            (submit)="submitServiceDeploymentTaskLocation()"
          >
            <div class="sep-form">
              <h2 data-cy="task-loc-page-title">Add Task Location</h2>

              <div class="form-fields">
                <div class="row">
                  <div
                    class="col-sm-12 top-head"
                    *ngIf="
                      fieldGroupName != null && fieldGroupName != '' && taskRefNumber != null && taskRefNumber != ''
                    "
                  >
                    <p>
                      Field Group:
                      <span
                        style="font-style: italic; color: #b3b3b3; font-weight: normal"
                        data-cy="task-loc-field-group"
                      >
                        {{ fieldGroupName }}
                      </span>
                      <span style="padding-left: 30px" data-cy="task-loc-name-label">Task:&nbsp;&nbsp;</span>
                      <span style="font-style: italic; color: #b3b3b3; font-weight: normal" data-cy="task-loc-name">
                        {{ serviceTaskName }}&nbsp;({{ taskRefNumber }})
                      </span>
                    </p>
                    <p></p>
                  </div>
                </div>

                <div>
                  <store-number
                    [group]="addServiceTaskLocationFormGroup"
                    [storeNumberLocationTimeZoneExist]="storeNumberLocationTimeZoneExist"
                    (storeNumberEntered)="checkStoreNumberAlreadyExist($event)"
                    [storeNumberExist]="storeNumberMsg"
                    [submitted]="submitted"
                  ></store-number>

                  <task-location-ref-number
                    [group]="addServiceTaskLocationFormGroup"
                    [isEdit]="currentaddedServiceDeploymentTaskLocation.edit"
                    (taskLocationRefNumberEntered)="checkTaskLocationRefNumberAlreadyExist($event)"
                    [taskLocationRefNumberExist]="taskLocationRefNumberMsg"
                    [submitted]="submitted"
                  ></task-location-ref-number>

                  <employee-number
                    [group]="addServiceTaskLocationFormGroup"
                    [submitted]="submitted"
                    (employeeNumberEntered)="getEmployeeListByFilter($event)"
                    [filteredEmpList]="filteredEmpList"
                    [serviceTaskLocationEmpMsg]="serviceTaskLocationEmpMsg"
                    [selectedEmployee]="selectedemployees"
                  ></employee-number>

                  <task-location-start-date
                    [group]="addServiceTaskLocationFormGroup"
                    (setEndDate)="setEndDate($event)"
                    [submitted]="submitted"
                    [setStartDate]="currentaddedServiceDeploymentTaskLocation"
                  ></task-location-start-date>

                  <div *ngIf="locationTimeZone != null && locationTimeZone != ''" class="timezone-block">
                    <div class="form-group row">
                      <div class="col-sm-4"></div>
                      <div class="col-sm-8">
                        <span>
                          <b>{{ locationTimeZone }}</b>
                        </span>
                      </div>
                    </div>
                  </div>

                  <task-location-end-date
                    (setEndDateMsg)="setEndDateMsg($event)"
                    [serviceDeploymentDateMsg]="serviceDeploymentDateMsg"
                    [group]="addServiceTaskLocationFormGroup"
                    [minDate]="minDate"
                    [submitted]="submitted"
                    [setEndDate]="currentaddedServiceDeploymentTaskLocation"
                  ></task-location-end-date>

                  <status-list
                    [group]="addServiceTaskLocationFormGroup"
                    [statusDropDownModel]="statusDropDownModel"
                    [selectedStatus]="selectedStatus"
                  ></status-list>

                  <div class="row mt-3">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8">
                      <button type="submit" class="btn btn-primary" data-cy="task-loc-submit-button">Submit</button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        (click)="formReset()"
                        class="btn btn-cancel"
                        data-cy="task-loc-cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
