import { ActionType, createAction } from '../../shared/typesafe-actions';
import { ServiceDeploymentReportParams } from '../models/servicedeployment-report-parameters';

export const serviceDeploymentsReportActions = {
  setFilter: createAction('SET_REPORT_FILTER', actionCallback => (filters: ServiceDeploymentReportParams) =>
    actionCallback(filters),
  ),
};

export type ServiceDeploymentsReportActions = ActionType<typeof serviceDeploymentsReportActions>;
