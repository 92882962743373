<div class="row align-items-center mt-3">
  <div class="col-sm-4" style="text-align: right;">
    <label class="mb-0" data-cy="task-loc-start-label">
      Start Date Time
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-loc-start-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-calendar
      [(ngModel)]="startDate"
      showTime="true"
      hourFormat="12"
      [showIcon]="true"
      (onSelect)="onSelectStartDate(ref.value)"
      name="startDate"
      dateFormat="mm/dd/yy"
      [formControlName]="'startDate'"
      [baseZIndex]="1030"
      #ref
      required
      data-cy="task-loc-start"
    ></p-calendar>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && startDateControl.hasError('required')"
        class="text-danger"
        data-cy="task-loc-start-error"
      >
        A valid Start Date Time is required.
      </span>
    </div>
  </div>
</div>
