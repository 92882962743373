import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class PermissionsLoggingInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(environment.envName === 'prod') {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if(err.status === 403 && err.error) {
          const reader = new FileReader();
          reader.onload = event => {
            console.warn((<any>event.target).result);
          };
          reader.readAsText(err.error);
        }

        throw err;
      })
    );
  }
}

export const permissionsLoggingInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: PermissionsLoggingInterceptor,
  multi: true,
};
