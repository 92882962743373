import { Routes } from '@angular/router';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { AssemblerComponent } from './components/assembler/assembler.component';
import { translationKey } from '@volt/shared/utils/common.utils';

export const assemblerPriorityRoutes: Routes = [
  {
    path: 'management',
    component: AssemblerComponent,
    canActivate: [PermissionsGuard],
    data: {
      requiredPermission: [PermissionNames.AssemblerPriorityManage, Privilege.Read],
      title: 'Assembler Priority',
      titleKey: translationKey('assemblerPriority'),
      searchKey: translationKey('search.assemblerPriority'),
    },
  },
];
