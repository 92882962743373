import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataPassingService } from '../../services/data-passing.service';
import { ServiceTasksClient } from '@volt/api';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'delete-instructionurl-modal',
  templateUrl: './delete-instructionurl-modal.component.html',
  styleUrls: [],
})
export class DeleteInstructionurlModalComponent extends DynamicDialogBaseComponent implements OnInit {
  name: string;
  serviceTaskReferenceNumber: string;
  public loading = false;

  constructor(
    public readonly _dialogReference: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    public _dataPassingService: DataPassingService,
    private _router: Router,
    private readonly _serviceTasksClient: ServiceTasksClient,
  ) {
    super(_dialogReference, _dialogConfig);
  }

  ngOnInit() {
    this._router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => this._dialogReference.close(false));

    this.name = this._dialogConfig.data.name;
    this.serviceTaskReferenceNumber = this._dialogConfig.data.serviceTaskReferenceNumber;
  }

  public deleteInstruction(name: string, serviceTaskReferenceNumber: string) {
    this.loading = true;
    this._serviceTasksClient.deleteServiceTaskFile(name, serviceTaskReferenceNumber).subscribe(
      () => window.location.reload(),
      () => {
        this._dialogReference.close(false);
        this._dataPassingService.passServiceDeploymentTaskInstructionName(name);
        this.loading = false;
      },
    );
  }

  public closeModal() {
    this._dialogReference.close();
  }
}
