import { Input, Output, EventEmitter, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-header',
  templateUrl: './modal-header.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() closeable: boolean = true;

  @Output() onClose = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClickButtonClose(): void {
    this.onClose.emit();
  }
}
