import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Input, Component, ChangeDetectorRef, Inject } from '@angular/core';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { AddServiceTask } from '../../../../models/addServiceTask';
import { ServiceModels } from '../../../../../shared/constants/service-models.constant';
import { RetailerConfigType, RETAILER_CONFIG } from 'src/retailer-config';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'service-model',
  templateUrl: './service-model.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})
export class ServiceModelComponent {
  @Input() public serviceModelList;
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  public selectedServiceModel;

  @Input() set setServiceModel(currentServiceTask: AddServiceTask) {
    if (currentServiceTask.serviceModel !== null) {
      this.selectedServiceModel = '' + ServiceModels[this.capitalizeFirstLetter(currentServiceTask.serviceModel)];
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private _dynamicDialogService: DynamicDialogService,
    @Inject(RETAILER_CONFIG) public readonly retailerConfig: RetailerConfigType,
  ) {}

  public capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Service Model can be one of the type identified below:',
          'Coordinated: Only for use by some Field Groups with the approval of ' +
            this.retailerConfig.retailer +
            '. Managed and directed by ' +
            this.retailerConfig.retailer +
            ' Merchants.\n',
          'Continuity: Suppliers establish strategic partnerships that are ongoing. Typically funded by Suppliers.\n',
          'Project: Suppliers hire Field Groups to execute approved Activities within specific time frame and store set.',
        ],
      },
    });
  }

  public get serviceModelTypeControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('serviceType');
  }
}
