import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BaseFormField } from '@volt/shared/components/form-fields/base-form-field.component';
import { TranslationService } from '@volt/shared/services/translation.service';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { combineLatest } from 'rxjs';
import { AuthService } from '../../../../../../auth';

@Component({
  selector: 'v-banner-field',
  templateUrl: './banner-field.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerFieldComponent extends BaseFormField implements OnInit, OnChanges  {
  protected get filterDisplayLabel(): string {
      return this.translatedData?.banner;
  }
  constructor(private _authService: AuthService,private readonly translationService: TranslationService, private readonly cdr: ChangeDetectorRef) {
    super(translationService)
  }

  ngOnInit(): void {
    this._authService.authUser$.pipe(
      OperatorUtils.takeUntilDisposed(this)
    ).subscribe(user => {
      this.items = CommonUtils.mapArrayToMultiSelectItem(
        user.banners,
        banner => banner,
        banner => banner,
        false
      );
      this.control.setValue(user.banners[0] || null);
      this.updateSelectedItem();
      this.cdr.markForCheck();
    })

    this.translatedData$.pipe(
      OperatorUtils.takeUntilDisposed(this)
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.firstChange) {
      this.updateSelectedItem();
      this.control.valueChanges.pipe(OperatorUtils.takeUntilDisposed(this)).subscribe(_ => this.updateSelectedItem());
      this.control.registerOnDisabledChange((isDisabled: boolean) => this.cdr.markForCheck());
    }
  }
}
