import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layouts/admin/services/layout.service';

@Component({
  selector: 'site-settings',
  templateUrl: './site-settings.component.html',
})
export class SiteSettingsComponent implements OnInit {
  private _isDark = false;

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {}

  public closeSettings() {
    this.layoutService.togglePanel(null);
  }

  public toggleTheme() {
    this._isDark = !this._isDark;
    this.layoutService.setTheme(this._isDark ? 'dark' : 'light');
  }
}
