import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Contact, VoltAccountViewModel } from '../../../models/volt-account';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Translations } from '@volt/shared/services/translations.types';
import { AccountShippingComponent } from './components/account-shipping/account-shipping.component';
import { AccountBillingComponent } from './components/account-billing/account-billing.component';
import { AccountAddressComponent } from './components/account-address/account-address.component';
import { AccountSupportComponent } from './components/account-support/account-support.component';
import { AccountItComponent } from './components/account-it/account-it.component';

@Component({
  selector: 'volt-account-info',
  templateUrl: './volt-account-info.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoltAccountInfoComponent extends BaseFilterForm<VoltAccountViewModel> implements OnInit {
  @Input() translations: Translations;
  @Input() personalContact: Contact;

  @Input() set disabled(d: boolean) {
    if(d) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  _account: VoltAccountViewModel;
  @Input() set account(a: VoltAccountViewModel) {
    this._account = a;
    this.controls.name.setValue(a?.name);
    this.controls.abbreviation.setValue(a?.abbreviation);
    this.controls.note.setValue(a?.note);
    this.controls.imageUrl.setValue(a?.imageUrl);
    this.controls.websiteUrl.setValue(a?.websiteUrl);
  }

  @Output() inited = new EventEmitter();

  @ViewChild(AccountShippingComponent) shippingComponent: AccountShippingComponent;
  @ViewChild(AccountBillingComponent) billingComponent: AccountBillingComponent;
  @ViewChild(AccountAddressComponent) addressComponent: AccountAddressComponent;
  @ViewChild(AccountSupportComponent) supportComponent: AccountSupportComponent;
  @ViewChild(AccountItComponent) itComponent: AccountItComponent;

  constructor(private readonly _fb: UntypedFormBuilder) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
    this.inited.emit();
  }

  initForm(): void {
    this.form = this._fb.group({
      name: ['', Validators.required],
      abbreviation: ['', Validators.required],
      note: [],
      imageUrl: ['', Validators.required],
      websiteUrl: []
    })
  }

  get value() {
    return { ...this._account,
      imageUrl: this.controls.imageUrl.value,
      name: this.controls.name.value,
      abbreviation: this.controls.abbreviation.value,
      note: this.controls.note.value,
      websiteUrl: this.controls.websiteUrl.value,
      address: this.addressComponent.value,
      shipping: this.shippingComponent.value,
      billing: this.billingComponent.value,
      support: this.supportComponent.value,
      it: this.itComponent.value,
    } as VoltAccountViewModel;
  }

  get invalid() {
    return this.form.invalid
      || this.shippingComponent == null || this.shippingComponent.invalid
      || this.billingComponent == null || this.billingComponent.invalid
      || this.addressComponent == null || this.addressComponent.invalid
      || this.supportComponent == null || this.supportComponent.invalid
      || this.itComponent == null || this.itComponent.invalid;
  }
}
