import { Params } from "@angular/router";
import * as moment from "moment";

export class ActivityAggregateFilterParams {
  activities: any[] = [];
  startDate: Date = moment().startOf('day').subtract(1, 'days').toDate();
  endDate: Date = moment().startOf('day').toDate();
  
  static fromParams(params: Params): ActivityAggregateFilterParams {
    const filter = new ActivityAggregateFilterParams();
    filter.activities = params['activities'];

    const startDate = params['startDate'] ? moment.utc(params['startDate']) : moment().startOf('day');
    filter.startDate = startDate.toDate();
    
    let endDate = params['endDate'] ? moment.utc(params['endDate']) : moment(filter.startDate).add(30, 'days');
    if (endDate.isAfter(startDate.clone().add(30, 'days'))) {
      endDate = startDate.clone().add(30, 'days');
    } else if (endDate.isBefore(startDate)) {
      endDate = startDate;
    }
    filter.endDate = endDate.toDate();
    
    return filter;
  }
}
