import { InjectionToken } from '@angular/core';
export interface RetailerConfigType {
  retailer: 'Walmart';
  retailerSystemAppName: string;
  retailerSupportEmail: string;
  voltSupportEmail: string;
  voltSupportPhone: string;
  voltSupportNote: string;
  retailerPhone: string;
  largeLightLogoUrl: string;
  largeDarkLogoUrl: string;
  smallLogoUrl: string;
}

export const RETAILER_CONFIG = new InjectionToken<RetailerConfigType>('RetailerConfig');
