import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appCustomDynamicContentDialog]',
})
export class CustomDynamicContentDialogDirective {
  constructor(public viewContainerRef: ViewContainerRef) {
    // contentInsertionPoint
  }
}
