import { Routes } from '@angular/router';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { UserAccessReviewReportGuard } from './report-overview/guards/user-access-review-report.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';
import { PowerBiTokenResolver } from './powerbi/powerbi.resolvers';
import { SyndicatedRepReportComponent } from './powerbi/syndicated-rep-report.component';
import { PspAdminReportComponent } from './powerbi/psp-admin-report.component';
import { TagsGuard } from '@volt/shared/guards/tags.guard';

export const reportRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        data: {
          title: 'Reports',
          titleKey: translationKey('reports'),
          iconClass: 'fa fa-chart-bar',
          searchKey: translationKey('search.reports'),
          visible: true,
        },
        loadChildren: () => import('./report-overview/report-overview.module').then(m => m.ReportOverviewModule),
      },
      {
        path: 'exceptions',
        canLoad: [PermissionsGuard],
        data: {
          title: 'Exceptions',
          titleKey: translationKey('exceptions'),
          iconClass: 'fa fa-list',
          searchKey: translationKey('search.reports.exceptions'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsExceptions, Privilege.Read],
          feature: 'exceptions',
        },
        loadChildren: () => import('./exceptions/exception.module').then(m => m.ReportExceptionsModule),
      },
      {
        path: 'store-feedback',
        canLoad: [PermissionsGuard],
        data: {
          title: 'Store Feedback',
          titleKey: translationKey('storeFeedback'),
          iconClass: 'fa fa-thumbs-up',
          visible: true,
          requiredPermission: [PermissionNames.ReportsStoreFeedback, Privilege.Read],
          feature: 'feedback',
          searchKey: translationKey('search.reports.storeFeedback'),
        },
        loadChildren: () => import('./feedback/feedback-route.module').then(m => m.FeedbackRouteModule),
      },
      {
        path: 'syndicated-rep',
        component: SyndicatedRepReportComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Syndicated Rep Report',
          titleKey: translationKey('syndicatedRepReport'),
          iconClass: 'fa fa-flag-o',
          searchKey: translationKey('search.reports.syndicatedRepReport'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsSyndicatedRep, Privilege.Read],
        },
        resolve: {
          token: PowerBiTokenResolver,
        },
      },
      {
        path: 'task-export',
        data: {
          title: 'Task Export',
          titleKey: translationKey('taskExport'),
          iconClass: 'fa fa-download',
          searchKey: translationKey('search.reports.taskExport'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsTaskExport, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () => import('./task-export/task-export.module').then(m => m.TaskExportModule),
      },
      {
        path: 'due-date-performance',
        data: {
          title: 'Due Date Performance',
          titleKey: translationKey('dueDatePerformance'),
          iconClass: 'fa fa-clock-o',
          searchKey: translationKey('search.reports.dueDatePerformance'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsDueDatePerformance, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () =>
          import('./due-date-performance/due-date-performance-report.module').then(
            m => m.DueDatePerformanceReportModule,
          ),
      },
      {
        path: 'laptop-restore',
        data: {
          title: 'Laptop Restore',
          titleKey: translationKey('laptopRestore'),
          iconClass: 'fa fa-clock-o',
          searchKey: translationKey('search.reports.laptopRestore'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsLaptopRestore, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () =>
          import('./laptop-restore/laptop-restore-report.module').then(m => m.LaptopRestoreReportModule),
      },
      {
        path: 'coverage',
        data: {
          title: 'Coverage Report',
          titleKey: translationKey('coverageReport'),
          iconClass: 'fa fa-clock-o',
          searchKey: translationKey('search.reports.coverage'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsCoverage, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () => import('./coverage/coverage-report.module').then(m => m.CoverageReportModule),
      },
      {
        path: 'task-detail-analytics',
        data: {
          title: 'Task Detail Analytics',
          titleKey: translationKey('taskDetailAnalytics'),
          searchKey: translationKey('search.reports.taskDetailAnalytics'),
          iconClass: 'fa-chart-line',
          visible: true,
          requiredPermission: [PermissionNames.ReportsTaskDetailAnalytics, Privilege.Read],
          feature: 'task-detail-analytics',
        },
        canLoad: [PermissionsGuard],
        loadChildren: () =>
          import('./task-detail-analytics/task-detail-analytics.module').then(m => m.TaskDetailAnalyticsModule),
      },
      {
        path: 'checkin-location',
        data: {
          title: 'Checkin Location',
          titleKey: translationKey('checkinLocation'),
          iconClass: 'fa fa-globe-americas',
          searchKey: translationKey('search.reports.checkinLocation'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsCheckInLocation, Privilege.Read],
          feature: 'checkin-location',
        },
        canLoad: [PermissionsGuard],
        loadChildren: () =>
          import('./checkin-location/check-in-check-out-location.module').then(m => m.CheckInCheckOutLocationModule),
      },
      {
        path: 'user-change-log',
        data: {
          title: 'User Change Log',
          titleKey: translationKey('userChangeLog'),
          iconClass: 'fa fa-history',
          searchKey: translationKey('search.reports.userChangeLog'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsUserChangeLog, Privilege.Read],
          feature: 'user-change-log',
        },
        canLoad: [PermissionsGuard],
        loadChildren: () => import('./user-change-log/user-change-log.module').then(m => m.UserChangeLogModule),
      },
      {
        path: 'real-time-checkin-report',
        data: {
          title: 'Real-Time Check-In Report',
          visible: true,
          searchKey: translationKey('search.reports.realTimeCheckIn'),
          feature: 'real-time-checkin',
          requiredPermission: [PermissionNames.ReportsRealTimeCheckin, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () =>
          import('./real-time-checkin/real-time-checkin-and-history.module').then(
            m => m.RealTimeCheckinAndHistoryModule,
          ),
      },
      {
        path: 'communication-log',
        data: {
          title: 'Communication Log Report',
          visible: true,
          titleKey: translationKey('communicationLog'),
          searchKey: translationKey('search.reports.communicationLog'),
          requiredPermission: [PermissionNames.ReportsCommunicationLog, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () => import('./communication-log/communication-log.module').then(m => m.CommunicationLogModule),
      },
      {
        path: 'pos-access',
        data: {
          title: 'POS Access Report',
          visible: true,
          titleKey: translationKey('posAccess'),
          searchKey: translationKey('search.reports.posAccess'),
          requiredPermission: [PermissionNames.ReportsPosAccess, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () => import('./pos-access/pos-access.module').then(m => m.PosAccessModule),
      },
      {
        path: 'import-log',
        data: {
          title: 'Import Log Report',
          visible: true,
          titleKey: translationKey('importLog'),
          searchKey: translationKey('search.reports.importLog'),
          requiredPermission: [PermissionNames.ReportsImportLog, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () => import('./import-log/import-log.module').then(m => m.ImportLogModule),
      },
      {
        path: 'user-access-review',
        canLoad: [PermissionsGuard, UserAccessReviewReportGuard],
        data: {
          title: 'User Access Review',
          titleKey: translationKey('userAccessReview'),
          searchKey: translationKey('search.reports.userAccessReview'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsUserAccessReview, Privilege.Read],
        },

        loadChildren: () =>
          import('./user-access-review/user-access-review.module').then(m => m.UserAccessReviewModule),
      },
      {
        path: 'checkin-by-account',
        data: {
          title: 'Checkin By Account',
          titleKey: translationKey('checkInByAccount'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsCheckinByAccount, Privilege.Read],
        },
        canLoad: [PermissionsGuard],
        loadChildren: () =>
          import('./checkin-by-account/checkin-by-account.module').then(m => m.CheckinByAccountModule),
      },
      {
        path: 'wsp-coverage',
        data: {
          title: 'WSP Coverage',
          titleKey: translationKey('wspCoverage'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsWspCoverage, Privilege.Read],
        },
        canLoad: [],
        loadChildren: () => import('./wsp-coverage/wsp-coverage.module').then(m => m.WspCoverageModule),
      },
      {
        path: 'psp-admin',
        component: PspAdminReportComponent,
        canActivate: [PermissionsGuard, TagsGuard],
        data: {
          title: 'PSP Admin Report',
          titleKey: translationKey('pspAdminReport'),
          iconClass: 'fa fa-flag-o',
          searchKey: translationKey('search.reports.PspAdminReport'),
          visible: true,
          requiredPermission: [PermissionNames.ReportsPspAdmin, Privilege.Read],
          requiredTag: 'Volt PowerBI Access',
        },
        resolve: {
          token: PowerBiTokenResolver,
        },
      },
    ],
  },
];
