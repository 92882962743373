import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GetResponse, LimitedResultOfGetResponse } from '@volt/api';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { ConfirmModalComponent } from '@volt/shared/components/modal/confirm-modal/confirm-modal.component';
import { SharedTableColumn } from '@volt/shared/components/table/models/shared-table-column';
import { SharedTableState } from '@volt/shared/components/table/models/shared-table-state';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { PermissionCollection } from '@volt/shared/services/permissions.service';
import { CommonUtils, nameOf } from '@volt/shared/utils/common.utils';
import { MessageService, SelectItem } from 'primeng/api';
import { forkJoin, Observable, PartialObserver, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AccountHierarchyService } from '../../../services/account-hierarchy.service';

@Component({
  selector: 'account-hierarchy',
  templateUrl: './account-hierarchy.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class AccountHierarchyComponent implements OnInit, OnChanges {
  @Input() selectedAccountId: number;
  @Input() pc: PermissionCollection;
  columns: SharedTableColumn<GetResponse>[] = [];
  data: GetResponse[] = [];
  tableState: SharedTableState = {
    offset: 0,
    limit: 5,
    sortBy: nameOf<GetResponse>('accountName'),
    sortAscending: true,
    totalCount: 0,
  };
  relationshipList: SelectItem[];
  accountList: SelectItem[];
  loading$: Observable<boolean>;
  form: UntypedFormGroup;
  getObserver: PartialObserver<LimitedResultOfGetResponse>;

  constructor(
    private readonly _accountHierarchyService: AccountHierarchyService,
    private readonly _monitoringService: MonitoringService,
    private readonly _fb: UntypedFormBuilder,
    private readonly _messageService: MessageService,
    private readonly _dynamicDialogService: DynamicDialogService,
  ) {}

  ngOnInit(): void {
    this.loading$ = this._accountHierarchyService.loading$;
    const accountList$ = this._accountHierarchyService.getAllAccountsExceptServiceChannel().pipe(
      CommonUtils.mapToMultiSelectItem(
        item => item.accountName,
        item => item.accountId,
        false,
        'Select Account',
        null,
      ),
    );

    const relationshipList$ = this._accountHierarchyService.getAllRelationships().pipe(
      CommonUtils.mapToMultiSelectItem(
        item => item,
        item => item,
        false,
        'Select Relationship',
        null,
      ),
    );

    this._accountHierarchyService.setLoader(true);
    forkJoin([accountList$, relationshipList$]).subscribe(
      ([accountList, relationshipList]: [SelectItem[], SelectItem[]]) => {
        this.relationshipList = relationshipList;
        this.accountList = accountList;
        this._accountHierarchyService.setLoader(false);
      },
      error => {
        this._monitoringService.logApiException(error);
        this._accountHierarchyService.setLoader(false);
      },
    );

    this.initForm();

    this.getObserver = {
      next: async response => {
        if (response) {
          this.tableState = {
            ...this.tableState,
            limit: response.limit,
            totalCount: response.totalCount,
            offset: response.offset,
          };
          this.data = response.results;
        }
        this.form.reset();
        this._accountHierarchyService.setLoader(false);
      },
      error: err => {
        this.form.reset();
        this._monitoringService.logApiException(err);
        this.showMessage('error', err.error || 'Serverside error');
        this._accountHierarchyService.setLoader(false);
      },
    };
  }

  private initForm() {
    this.form = this._fb.group({
      relationship: this._fb.control('', Validators.required),
      account: this._fb.control('', Validators.required),
    });
  }

  getColumns() {
    this.columns = [
      {
        fieldSelector: 'relationship',
        header: 'Relationship',
        sortable: true,
      },
      {
        field: 'accountName',
        header: 'Account Name',
        sortable: true,
      },
      {
        field: 'accountNumber',
        header: 'Account Number',
        sortable: true,
      },
      {
        field: 'primaryAdmin',
        header: 'Primary Admin',
        sortable: true,
      },
      {
        field: 'updatedDate',
        header: 'Updated Date',
        sortable: true,
        dateFormat: 'shortDate',
      },
      {
        field: 'updatedBy',
        header: 'Updated By',
        sortable: true,
      },
      {
        fieldSelector: 'deleteRecord',
      },
    ];
  }

  private showMessage(severity: string, summary: string) {
    this._messageService.add({
      severity: severity,
      summary: summary,
    });
  }

  addRelationship() {
    const relationship = this.form.get('relationship').value;
    const targetAccountId = this.form.get('account').value;
    const sourceAccountId = this.selectedAccountId;
    this._accountHierarchyService.setLoader(true);
    this._accountHierarchyService
      .add(sourceAccountId, targetAccountId, relationship)
      .pipe(
        catchError(err => {
          return throwError(err);
        }),
        switchMap(() => {
          this.showMessage('success', 'Relationship added successfully');
          return this.get(this.tableState, this.selectedAccountId);
        }),
      )
      .subscribe(this.getObserver);
  }

  onDeleteRecord(id: number) {
    this._dynamicDialogService.open(ConfirmModalComponent, {
      data: {
        message: 'Are you sure you want to delete this relationship?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this._accountHierarchyService.setLoader(true);
          this._accountHierarchyService
            .delete(id)
            .pipe(
              catchError(err => {
                return throwError(err);
              }),
              switchMap(() => {
                this.showMessage('success', 'Relationship deleted successfully');
                return this.get(this.tableState, this.selectedAccountId);
              }),
            )
            .subscribe(this.getObserver);
        },
      },
    });
  }

  onTableStateChanged(state: SharedTableState) {
    this.tableState = {
      ...this.tableState,
      sortBy: state.sortBy,
      sortAscending: state.sortAscending,
      offset: state.offset,
      limit: state.limit,
      totalCount: state.totalCount,
    };
    this.get(this.tableState, this.selectedAccountId).subscribe(this.getObserver);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAccountId.currentValue) {
      this.getColumns();
      this.get(this.tableState, this.selectedAccountId).subscribe(this.getObserver);
    }
  }

  private get(tableState: SharedTableState, accountId: number): Observable<LimitedResultOfGetResponse> {
    this._accountHierarchyService.setLoader(true);
    return this._accountHierarchyService.getRelationsForAccountDetailsPage(tableState, accountId);
  }
}
