<v-loading-container [working]="loading">
  <div class="container-fluid">
    <header class="page-header d-flex align-items-center">
      <h2 class="page-title">
        <span>Task Results</span>
      </h2>
      <div class="page-title-bar">
        <a (click)="GotoTaskLocaiton()" class="">
          <i class="fa fa-chevron-left"></i>
          Back to Task Location
        </a>
      </div>
    </header>

    <div class="d-flex flex-row row mt-3">
      <div class="col-12">
        <div class="card rounded-0">
          <div class="card-header">
            <h2 class="card-title">
              Field Group:
              <span style="font-style: italic; color: #b3b3b3;">{{ fieldGroupName }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Service Deployment:
              <span style="font-style: italic; color: #b3b3b3;">
                {{ serviceDeploymentName }}&nbsp;({{ serviceDeploymentRefNumber }})
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Task :
              <span style="font-style: italic; color: #b3b3b3;">
                {{ serviceTaskName }}&nbsp;({{ serviceDeploymentTaskRefNumber }})
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Task Location :
              <span style="font-style: italic; color: #b3b3b3;">{{ serviceTaskLocationRefNumber }}</span>
            </h2>
          </div>
        </div>
        <br />
        <br />
      </div>

      <div class="col-12" *ngIf="showData">
        <div class="addQuestion">
          <div class="col-4">
            <div *ngFor="let item of data" class="parent-block">
              <p-accordion>
                <p-accordionTab>
                  <ng-template pTemplate='header'>
                    <span>{{ item.referenceNumber }}</span>
                  </ng-template>
                  <div class="accordion-cont">
                    <div class="content-block">
                      <b>Reference Number:&nbsp;&nbsp;&nbsp;&nbsp;</b>

                      <span>{{ item.referenceNumber }}</span>
                      <br />
                      <br />
                      <b>Completion Date:&nbsp;&nbsp;&nbsp;&nbsp;</b>

                      <span>{{ item.completionDate | date: 'short' }}</span>
                      <br />
                      <br />
                      <b>Actual Minutes:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                      <span>{{ item.actualMinutes }}</span>
                      <br />
                      <br />
                      <b>Employee Number(s):&nbsp;&nbsp;&nbsp;&nbsp;</b>
                      <span>{{ item.employeeNumber }}</span>
                      <br />
                      <br />
                      <b>Reason:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                      <span>{{ item.reason }}</span>
                      <br />
                      <br />
                      <b>Other Reason:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                      <span>{{ item.otherReason }}</span>
                      <br />
                      <br />
                      <b>Create Date:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                      <span>{{ item.createdDate | date: 'short' }}</span>
                    </div>
                    <div *ngFor="let item of item.results" class="parent-block">
                      <p-accordion>
                        <p-accordionTab>
                          <ng-template pTemplate='header'>
                            <span>{{ item.questionReferenceNumber }}</span>
                          </ng-template>
                          <div class="accordion-cont">
                            <div class="content-block">
                              <b>Question Reference Number:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                              <span>{{ item.questionReferenceNumber }}</span>
                              <br />
                              <br />
                              <b>Question Text:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                              <span>{{ item.question.text }}</span>
                              <br />
                              <br />

                              <div *ngIf="item.type == 'Comment'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Value:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.commentValue }}</span>
                              </div>
                              <div *ngIf="item.type == 'Count'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Value:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.countValue }}</span>
                              </div>
                              <div *ngIf="item.type == 'Multiple'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Values:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <div *ngFor="let multiple of item.multipleValues">
                                  <span>{{ multiple }}</span>
                                  <br />
                                </div>
                              </div>
                              <div *ngIf="item.type == 'Photo'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Values:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <div *ngFor="let photo of item.photoValues">
                                  <span>
                                    <a
                                      href="{{ photo }}"
                                      target="_blank"
                                      style="color: cornflowerblue; text-decoration: underline;"
                                    >
                                      {{ photo }}
                                    </a>
                                  </span>
                                  <br />
                                </div>
                              </div>
                              <div *ngIf="item.type == 'Price'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Value:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.countValue }}</span>
                              </div>
                              <div *ngIf="item.type == 'Single'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Value:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.countValue }}</span>
                              </div>
                              <div *ngIf="item.type == 'Signature'">
                                <b>Question Type:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <span>{{ item.type }}</span>
                                <br />
                                <b>Values:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                <div *ngFor="let signature of item.signatureValues">
                                  <span>{{ signature }}</span>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </p-accordionTab>
                      </p-accordion>
                    </div>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
