<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-name-label">
      Task Name
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-name-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <input class="form-control rounded-0" [formControlName]="'taskName'" id="taskName" required data-cy="task-name" />

    <div *ngIf="submitted">
      <span *ngIf="submitted && taskNameControl.hasError('required')" class="text-danger" data-cy="task-name-error">
        A valid Task Name is required.
      </span>
    </div>
  </div>
</div>
