import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { AddServiceDeploymentFilterActions } from '../../../actions/servicedeployment.constants';

@Injectable()
export class AddServicedeploymentReduxService {
  constructor() {}

  public setFilter(filter: object) {
    this._setFilter(filter);
  }

  @dispatch()
  private _setFilter = (filter: object) => ({
    type: AddServiceDeploymentFilterActions.SetFilter,
    payload: filter,
  });
}
