<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" for="serviceNote" data-cy="ser-dep-note-label">
      Note
      <span class="required" aria-required="true"></span>
      <span data-cy="ser-dep-note-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <textarea
      class="form-control rounded-0"
      id="serviceNote"
      [formControlName]="'serviceNote'"
      data-cy="ser-dep-note"
    ></textarea>
  </div>
</div>
