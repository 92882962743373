import { Pipe, PipeTransform } from '@angular/core';
import isNil from 'lodash-es/isNil';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import { TranslationService } from '@volt/shared/services/translation.service';

/**
 * LT   - 3:09 PM
 * LTS  - 3:09:16 PM
 * L    - 11/17/2016
 * l    - 11/17/2016
 * LL   - November 17, 2016
 * ll   - Nov 17, 2016
 * LLL  - November 17, 2016 3:09 PM
 * lll  - Nov 17, 2016 3:09 PM
 * LLLL - Thursday, November 17, 2016 3:09 PM
 * llll - Thu, Nov 17, 2016 3:09 PM
 */
@Pipe({ name: 'moment' })
export class MomentDatePipe implements PipeTransform {
  transform(value: string, format) {
    if (!value) {
      return '';
    }
    if (format === 'fromNow') {
      return moment(value).fromNow();
    }
    return moment(value).format(format);
  }
}

@Pipe({ name: 'dtFormat' })
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: moment.Moment, timeZone: string, format: string = 'lll z') {
    if (!value) {
      return '';
    }

    if (isNil(timeZone) || timeZone.length === 0) return value.format(format);

    return moment.tz(value, timeZone).format(format);
  }
}
