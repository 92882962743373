import { Injectable } from '@angular/core';
import {
  DepartmentClient,
  DepartmentModel,
  DepartmentTaskCountViewModel,
  FileResponse,
} from '@volt/api';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslationService } from '../../shared/services/translation.service';
import { Translations } from '../../shared/services/translations.types';
import { DepartmentFilterParams } from '../models/department-filter-params';
import { Departmentmode } from '../models/departmentmode';
import { TaskCountFilterParams } from '../models/task-count-filter-params';

@Injectable()
export class DepartmentService {
  private $selectedMode = new BehaviorSubject<Departmentmode>(Departmentmode.List);
  private $isLoading = new BehaviorSubject<boolean>(false);

  strategicBusinessUnits = this._departmentClient.getStrategicBusinessUnits();
  isLoading$ = this.$isLoading.asObservable();
  selectedMode$ = this.$selectedMode.asObservable();

  constructor(
    private readonly _translationService: TranslationService,
    private readonly _departmentClient: DepartmentClient,
  ) {}

  getTranslatedData$(): Observable<Translations> {
    return this._translationService.getTranslatedObject();
  }

  setDepartmentMode(mode: number) {
    this.$selectedMode.next(mode);
  }

  getDepartmentList(params: DepartmentFilterParams): Observable<DepartmentModel[]> {
    this.$isLoading.next(true);
    return this._departmentClient
      .getDepartments(
        null,
        params.strategicBusinessUnits,
        params.departmentGroups,
        params.status,
        params.search,
        params.sortKey,
        params.isAscending,
      )
      .pipe(
        OperatorUtils.logAndRethrowError(() => this.$isLoading.next(false)),
        tap(() => {
          this.$isLoading.next(false);
        }),
      );
  }

  getDepartmentTaskCount(params: TaskCountFilterParams): Observable<DepartmentTaskCountViewModel[]> {
    this.$isLoading.next(true);
    return this._departmentClient
      .getDepartmentTaskCount(
        null,
        params.accountId,
        moment(params.startDate),
        moment(params.endDate),
        params.strategicBusinessUnits,
        params.departmentGroups,
        params.status,
        params.search,
      )
      .pipe(
        OperatorUtils.logAndRethrowError(() => this.$isLoading.next(false)),
        tap(() => {
          this.$isLoading.next(false);
        }),
      );
  }

  export(params: DepartmentFilterParams): Observable<FileResponse> {
    this.$isLoading.next(true);
    return this._departmentClient
      .export(
        null,
        params.strategicBusinessUnits,
        params.departmentGroups,
        params.status,
        params.search,
        params.sortKey,
        params.isAscending,
      )
      .pipe(
        OperatorUtils.logAndRethrowError(err => {
          this.$isLoading.next(false);
        }),
        tap(() => this.$isLoading.next(false)),
      );
  }
}
