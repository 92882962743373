import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { StatusTypes } from '@volt/api';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { Translations } from '@volt/shared/services/translations.types';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { ActivityFilterParams } from '../../../models/activity-filter-params';

@Component({
  selector: 'activity-list-filters',
  templateUrl: './activity-list-filters.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListFiltersComponent extends BaseFilterForm<ActivityFilterParams> implements OnInit {
  @Input() translations: Translations;
  @Input() filters: ActivityFilterParams;

  @Output() filtersChanged = new EventEmitter<ActivityFilterParams>();

  statusOptions: SelectItem[] = [];

  constructor(private _fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initFormSharedValues(({ previous, current }) => {
      if (!_.isEqual(previous, current)) {
        this.filtersChanged.emit(current);
      }
    });
  }

  initForm(): void {
    this.statusOptions = [
      { label: this.translations.all, value: null },
      { label: this.translations['activeStatuses.active'], value: StatusTypes.Active },
      { label: this.translations['activeStatuses.pending'], value: StatusTypes.Pending },
      { label: this.translations['activeStatuses.inactive'], value: StatusTypes.Inactive },
    ];

    this.form = this._fb.group({
      filterText: [this.filters.filterText],
      status: [this.filters.status],
    });

    this.form.defaultValues = new ActivityFilterParams();
  }
  
  clearFilters() {
    this.form.reset(this.form.defaultValues);
  }
}
