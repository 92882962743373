import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { UntypedFormControl } from '@angular/forms';
import { Country } from '../../models/models';

@Component({
  selector: 'account-zip',
  templateUrl: './account-zip.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountZipComponent implements OnInit {
  @Input() translations: Translations;
  @Input() control: UntypedFormControl;
  @Input() set selectedCountry(c: Country) {
    this.mask = c === Country.Canada ? "aaa?-aaa" : "99999?-9999";
  }

  mask: string;

  constructor() { }

  ngOnInit(): void {
  }

}
