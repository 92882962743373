<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="ser-dep-name-label">
      Service Deployment Name
      <span class="required" aria-required="true">*</span>
      <span data-cy="ser-dep-name-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <input
      class="form-control rounded-0"
      id="serviceDeploymentName"
      [formControlName]="'serviceDeploymentName'"
      required
      data-cy="ser-dep-name"
    />
    <div *ngIf="submitted">
      <span
        *ngIf="submitted && serviceDeploymentNameControl.hasError('required')"
        class="text-danger"
        data-cy="ser-dep-name-error"
      >
        A valid Name is required.
      </span>
    </div>
  </div>
</div>
