<ng-container>
  <modal-header [title]="header" (onClose)="onClose()"></modal-header>

  <div class="modal-body expand-width">{{ message }}</div>

  <modal-footer
    *ngIf="showFooter"
    [footerStyle]="'double_btn'"
    (onSubmit)="onSubmit()"
    (onClose)="onClose()"
    [saveLabel]="acceptLabel"
    [cancelLabel]="rejectLabel"
  ></modal-footer>
</ng-container>
