import { dispatch, NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { serviceDeploymentsReportActions } from '../../../actions/service-deployments-report.actions';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { Observable } from 'rxjs';
import {
  GenericTableReportOfServiceDeploymentViewModel,
  ServiceDeploymentsClient,
  AccountsClient,
  InvitedAccountClient,
  ActivityClient,
  DepartmentClient,
  SupplierClient,
  ServiceTasksClient,
  ServiceDeploymentInput,
  Log,
  DropDownModel,
  ImportStatusFilter,
  FileResponse,
} from '@volt/api';
import { IAppState } from '../../../../store';
import * as moment from 'moment';
import { ServiceDeploymentSearchParameters } from 'src/app/servicedeployments-tasks-locations/models/servicedeployment-search-parameters';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServiceDeploymentsService {
  @dispatch()
  private _setFilter = serviceDeploymentsReportActions.setFilter;

  constructor(
    private _serviceDeploymentClient: ServiceDeploymentsClient,
    private _accountClient: AccountsClient,
    private _invitedAccountClient: InvitedAccountClient,
    private _activityClient: ActivityClient,
    private _deparmentClient: DepartmentClient,
    private _supplierClient: SupplierClient,
    private _serviceTaskClient: ServiceTasksClient,
    private readonly _appState: NgRedux<IAppState>,
  ) {}

  public getServiceDeployments(
    params: ServiceDeploymentReportParams,
  ): Observable<GenericTableReportOfServiceDeploymentViewModel> {
    let offset = params.page;
    const limit = params.pageSize;
    offset = (offset - 1) * limit;

    return this._serviceDeploymentClient.getServiceDeployments(
      offset,
      limit,
      params.sortColumn,
      params.servicedeploymentSearchParameters.banner,
      params.servicedeploymentSearchParameters.accountIds,
      params.servicedeploymentSearchParameters.startDate
        ? moment(params.servicedeploymentSearchParameters.startDate).startOf('day')
        : null,
      params.servicedeploymentSearchParameters.endDate
        ? moment(params.servicedeploymentSearchParameters.endDate).endOf('day')
        : null,
      params.servicedeploymentSearchParameters.accountName,
      params.servicedeploymentSearchParameters.name,
      params.servicedeploymentSearchParameters.note,
      params.servicedeploymentSearchParameters.referenceNumber,
      params.servicedeploymentSearchParameters.visitOverview,
      params.servicedeploymentSearchParameters.serviceDeploymentId,
      params.servicedeploymentSearchParameters.status,
    );
  }

  public export(params: ServiceDeploymentSearchParameters): Observable<FileResponse> {
    return this._serviceDeploymentClient.getFile(
      params.banner,
      params.accountIds,
      params.startDate ? moment(params.startDate).startOf('day') : null,
      params.endDate ? moment(params.endDate).endOf('day') : null,
      params.accountName,
      params.name,
      params.note,
      params.referenceNumber,
      params.visitOverview,
      params.serviceDeploymentId,
      params.status,
    );
  }

  public checkServiceRefNumberAlreadyExist(referenceNumber: string, accountId: number): Observable<any> {
    return this._serviceDeploymentClient.checkServiceDepRefNumberAlreadyExist(referenceNumber, accountId);
  }

  public checkServiceTaskRefNumberAlreadyExist(referenceNumber: string, accountId: number): Observable<any> {
    return this._serviceTaskClient.checkServiceDepTaskRefNumberAlreadyExist(referenceNumber, accountId);
  }

  public getStatusList(): Observable<any> {
    return this._invitedAccountClient.getStatusList();
  }

  public getAccountDropDownList(): Observable<any> {
    return this._accountClient.getAccountList();
  }

  public getAccountDropList(): Observable<any> {
    return this._accountClient.getAccountDropDownList();
  }

  public getAccountByAccountId(id: number): Observable<any> {
    return this._accountClient.getAccountByAccountId(id);
  }

  public getServiceModelDropDownList(): Observable<DropDownModel[]> {
    return this._serviceDeploymentClient.getServiceModels();
  }

  public getActivityDropdownList(accountId: number): Observable<DropDownModel[]> {
    return this._activityClient.getActivitiesForAccount(accountId).pipe(
      map(activityList => {
        return activityList.map(a => {
          return new DropDownModel({
            label: a.activityName,
            value: a.activityName,
          });
        });
      }),
    );
  }

  public getDepartmentListByFilterText(searchFilterText: string): Observable<any> {
    return this._deparmentClient.getDepartments(null, null, null, null, searchFilterText).pipe(
      map(departmentList => {
        return departmentList.map(department => {
          return new DropDownModel({
            label: department.displayDepartmentName,
            value: department.departmentId.toString(),
          });
        });
      }),
    );
  }

  public getSupplierListByFilterText(searchFilterText): Observable<DropDownModel[]> {
    return this._supplierClient.getSupplierListByFilterText(searchFilterText);
  }

  public createServiceDeployment(params: ServiceDeploymentInput, accountId: number): Observable<Log> {
    return this._serviceDeploymentClient.createServiceDeployment(accountId, params);
  }
  public updateServiceDeployment(params: ServiceDeploymentInput, accountId: number): Observable<Log> {
    return this._serviceDeploymentClient.updateServiceDeployment(accountId, params);
  }

  public getServiceDeploymentLog(accountId: number, transactionId: string): Observable<Log> {
    return this._serviceDeploymentClient.retrieveServiceDeploymentLog(accountId, transactionId);
  }

  public getServiceDeploymentDetails(
    transactionId: string,
    accountId: number,
    referenceNumber: string,
  ): Observable<any> {
    return this._serviceDeploymentClient.retrieveServiceDeploymentDetails(
      transactionId,
      accountId,
      ImportStatusFilter.Failed,
      referenceNumber,
      100,
      0,
    );
  }

  public setFilter(filters: ServiceDeploymentReportParams): void {
    this._setFilter(filters);
  }
}
