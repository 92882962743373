<div style="display: flex; flex-direction: column; gap: 1rem">
    <div *ngIf="personalizedMessage" style="padding: 1rem; background-color: lightblue; border-radius: 5px">
        <h5 style="margin: 0"><i class="fa fa-sticky-note"></i> {{personalizedMessage}}</h5>
    </div>

    <account-contact
            [translations]="translations"
            [contact]="contact"
            [emailRequired]="true"
            [phoneRequired]="true"
            [inline]="false"
            [disabled]="false"
    ></account-contact>
</div>
