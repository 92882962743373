import { Translations } from '@volt/shared/services/translations.types';

export class SearchableRoute {
  route: string;
  searchTerms: string;
  title: string;

  constructor(route: string, searchTerms: string, title: string) {
    this.route = route;
    this.searchTerms = searchTerms;
    this.title = title;
  }
}

export class SearchSection {
  titleKey: keyof Translations;
  items: SearchableRoute[];

  constructor(titleKey: keyof Translations, items: SearchableRoute[]) {
    this.titleKey = titleKey;
    this.items = items;
  }
}
