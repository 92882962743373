import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';
import { BadgeService } from '../../../../badges/services/badge.service';
import { OperatorUtils } from '../../../utils/operator.utils';

const DEFAULT_OPTIONS: Cropper.Options = {
  viewMode: 1,
  aspectRatio: 1,
  movable: false,
  scalable: false,
  background: false,
};

@Component({
  selector: 'v-image-edit',
  template: `
    <div class="cropper" [hidden]="!isLoaded">
      <div class="resize">
        <img
          #cropperImage
          [src]="imageUrl | secureUrl | async"
          (load)="onImageLoaded()"
          alt=""
          data-cy="image-edit-img"
        />
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center" *ngIf="isLoaded; else spinner">
      <button
        type="button"
        class="btn btn-lg"
        (click)="onClickRotate(-90)"
        data-cy="image-edit-rotate-counterClockwise"
      >
        <i class="fa fa-undo"></i>
      </button>
      <button type="button" class="btn btn-lg" (click)="onClickRotate(90)" data-cy="image-edit-rotate-clockwise">
        <i class="fa fa-redo"></i>
      </button>
      <button type="button" class="btn btn-lg" (click)="onZoom(true)" data-cy="image-edit-zoomIn">
        <i class="fa fa-search-plus"></i>
      </button>
      <button type="button" class="btn btn-lg" (click)="onZoom(false)" data-cy="image-edit-zoomOut">
        <i class="fa fa-search-minus"></i>
      </button>
    </div>
    <ng-template #spinner>
      <v-loading-spinner></v-loading-spinner>
    </ng-template>
  `,
  styleUrls: ['./image-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditComponent implements OnInit, OnDestroy {
  @Input() imageUrl = null;

  @Input() set cropOptions(value: Cropper.Options) {
    if (value != null || !!Object.keys(value).length) {
      this._defaultOptions = Object.assign(this._defaultOptions, value);
    }
  }

  @ViewChild('cropperImage') imgElement: ElementRef<HTMLImageElement> = null;

  isLoaded = false;
  private _cropper: Cropper = null;
  private _defaultOptions: Cropper.Options = DEFAULT_OPTIONS;

  constructor(private readonly _badgeService: BadgeService) {}

  ngOnInit(): void {
    this._badgeService.shouldCropperReload$.pipe(OperatorUtils.takeUntilDisposed(this)).subscribe();
  }

  onImageLoaded() {
    this._cropper && this._cropper.destroy();
    this._cropper = new Cropper(this.imgElement.nativeElement, this._defaultOptions);
    this.isLoaded = true;
  }

  onClickRotate(degrees: number) {
    this._cropper.rotate(degrees);
  }

  onZoom(isZoomIn: boolean) {
    this._cropper.zoom(isZoomIn ? 0.1 : -0.1);
  }

  getCanvas(): HTMLCanvasElement {
    return this._cropper.getCroppedCanvas({
      minWidth: 200,
      minHeight: 200,
    });
  }

  getContainerData(): Cropper.ContainerData {
    return this._cropper.getContainerData();
  }

  setDefaultOptions(opts: Cropper.Options) {
    this._defaultOptions = Object.assign({}, this._defaultOptions, opts);
  }

  getCropBoxData(): Cropper.CropBoxData {
    return this._cropper.getCropBoxData();
  }

  ngOnDestroy(): void {
    if (this._cropper) {
      this._cropper.destroy();
      this._cropper = null;
    }
  }
}
