import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { Address, Billing, Contact } from '../../../../../models/volt-account';
import { AccountAddressComponent } from '../account-address/account-address.component';
import { AccountContactComponent } from '../account-contact/account-contact.component';

@Component({
  selector: 'account-billing',
  templateUrl: './account-billing.component.html',
  styles: [
    `
        .hidden {
            display: none;
        }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountBillingComponent {
  @Input() translations: Translations;
  @Input() disabled = false;
  @Input() personalContact: Contact;

  _billing: Billing;
  @Input() set billing(b: Billing) {
    this._billing = b;
  }

  @Input() companyAddress: Address;
  @ViewChild(AccountAddressComponent) address: AccountAddressComponent;
  @ViewChild(AccountContactComponent) contact: AccountContactComponent;

  get invalid() {
    return this.address == null || this.address.invalid || this.contact == null || this.contact.invalid;
  }

  get value() {
    return {
      address: this.address.value,
      contact: this.contact.value
    } as Billing;
  }
}
