<div class="row align-items-center mt-3">
  <div class="col-sm-4" style="text-align: right;">
    <label class="mb-0" for="storeNumber" data-cy="store-num-label">
      Store Number
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="store-num-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <input
      class="form-control rounded-0"
      id="storeNumber"
      [formControlName]="'storeNumber'"
      (blur)="checkStoreNumberAlreadyExist(ref.value)"
      required
      #ref
      data-cy="store-num"
    />

    <div *ngIf="submitted">
      <span *ngIf="submitted && storeNumberControl.hasError('required')" class="text-danger" data-cy="store-num-error">
        A valid store number is required.
      </span>
    </div>

    <div
      class="text-danger"
      *ngIf="storeNumberExist && !storeNumberControl.hasError('required')"
      data-cy="store-num-error"
    >
      Store number Already Exist
    </div>

    <div
      class="text-danger"
      *ngIf="storeNumberLocationTimeZoneExist && !storeNumberControl.hasError('required')"
      data-cy="store-num-error"
    >
      Store number does not exists
    </div>
  </div>
</div>
