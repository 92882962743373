import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { RETAILER_CONFIG, RetailerConfigType } from '../retailer-config';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private readonly http: HttpClient,
    @Inject(RETAILER_CONFIG) private readonly _retailerConfig: RetailerConfigType,
  ) {}

  getTranslation(lang: string): Observable<Translation> {
    const genericRequest = this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    const tenantSpecificRequest = this.http
      .get<Translation>(`/assets/i18n/${lang}.${this._retailerConfig.retailer}.json`)
      .pipe(catchError(e => of({})));
    return forkJoin([genericRequest, tenantSpecificRequest]).pipe(
      mergeMap(val => {
        const generic = val[0];
        const tenant = val[1];
        return of({ ...generic, ...tenant });
      }),
    );
  }
}
