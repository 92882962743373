<div class="position-relative" role="button">
  <i class="pi pi-bell text-light" style="font-size: 2em" (click)="clicked($event)"></i>
  <span
    class="badge rounded-pill badge-danger position-absolute pointer-transparent"
    style="left: -2px; top: -2px"
    *ngIf="(alerts$ | async).length as alertLength"
  >
    {{ alertLength }}
  </span>
</div>

<p-overlayPanel *ngIf="translations$ | async as translations">
  <ng-template pTemplate>
    <div class="overlay-container">
      <ng-template [ngIf]="(alertRollups$ | async).length > 0" [ngIfElse]="isEmpty">
        <div class="row border-bottom header pt-1">
          <div class="col-12 card-title mb-0">{{ translations.alerts }}</div>
          <div class="col-12 text-muted mb-2">{{ translations.actionsYouAreRequiredToTake }}</div>
        </div>

        <a
          *ngFor="let alertRollup of alertRollups$ | async"
          class="text-reset text-decoration-none row"
          [href]="alertRollup.link"
          (click)="alertClicked($event, alertRollup)"
        >
          <div class="col-auto align-self-center">
            <span class="badge rounded-pill badge-danger textsize-medium">{{ alertRollup.count }}</span>
          </div>
          <div class="col py-2">
            <div class="title">
              {{ translations['alert.' + alertRollup?.title?.replaceAll(' ', '.')] || alertRollup.title }}
            </div>
            <div class="subtitle">
              {{ translations['alert.' + alertRollup?.subtitle?.replaceAll(' ', '.')] || alertRollup.subtitle }}
            </div>
          </div>
          <div class="col-auto align-self-center">
            <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="deleteAlert($event, alertRollup)"></button>
          </div>
        </a>
      </ng-template>

      <ng-template #isEmpty>
        <div class="card-title m-3">{{ translations.youHaveNoActionsAtThisTime }}</div>
      </ng-template>
    </div>
  </ng-template>
</p-overlayPanel>
