<div class="row align-items-center mt-3">
  <div class="col-sm-4" style="text-align: right;">
    <label class="mb-0" for="LocationRefNo" data-cy="task-loc-ref-num-label">
      Task Location Reference Number
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-loc-ref-num-tooltip"></i>
      </span>
    </label>
  </div>

  <div class="col-sm-8" [formGroup]="group">
    <div *ngIf="!isEdit">
      <input
        class="form-control rounded-0"
        id="LocationRefNo"
        [formControlName]="'locationRefNumber'"
        (blur)="checkTaskLocationRefNumberAlreadyExist(ref.value)"
        required
        #ref
        data-cy="task-loc-ref-num"
      />
    </div>
    <div *ngIf="isEdit">
      <input
        class="form-control rounded-0"
        id="LocationRefNo"
        [formControlName]="'locationRefNumber'"
        readonly
        #ref
        data-cy="task-loc-ref-num"
      />
    </div>

    <div *ngIf="submitted">
      <span *ngIf="LocationRefNoControl.hasError('required')" class="text-danger" data-cy="task-loc-ref-num-error">
        A valid Task Location Reference Number is required.
      </span>
      <span *ngIf="LocationRefNoControl.hasError('pattern')" class="text-danger" data-cy="task-loc-ref-num-error">
        Invalid Reference Number. Allowed characters: A-Z a-z 0-9 . - _
      </span>
    </div>

    <div
      class="text-danger"
      *ngIf="
        taskLocationRefNumberExist &&
        !LocationRefNoControl.hasError('required') &&
        !LocationRefNoControl.hasError('pattern')
      "
      data-cy="task-loc-ref-num-error"
    >
      Task Location Reference Number Already Exist
    </div>
  </div>
</div>
