<label for="startDate">{{ label }}</label>
<p-calendar
  [formControl]="control"
  [maxDate]="maxDate"
  [minDate]="minDate"
  [monthNavigator]="true"
  [placeholder]="placeholder"
  [readonlyInput]="true"
  [touchUI]="touchUI"
  [yearNavigator]="true"
  id="startDate"
  inputStyleClass="w-100"
  styleClass="w-100"
  yearRange="1999:2099"
  (onSelect)="onDateSelected($event)"
  [appendTo]="appendTo"
></p-calendar>
