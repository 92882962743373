import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Translations } from '../../services/translations.types';

@Component({
  selector: 'v-control-error',
  template: `
    <ng-container
      *ngIf="
        error.key === 'required' ||
        error.key === 'requiredWhen' ||
        error.key === 'requiredWhenNull' ||
        error.key === 'requiredWithRole' ||
        error.key === 'nonEmpty' ||
        error.key === 'nonNull'
      "
    >
      {{ controlLabel }} {{ translations.validationRequiredError }}
    </ng-container>
    <ng-container *ngIf="error.key === 'email'">
      {{ controlLabel }} {{ translations.validationEmailError }}
    </ng-container>

    <ng-container *ngIf="error.key === 'invalidUpcs' || error.key === 'uniqueUpcs'">
      <ul>
        <li *ngFor="let errorRow of error.value">
          {{ errorRow.currentValue }}:&nbsp;&nbsp;{{ translations[errorRow.errorMessageKey] }}
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="error.key === 'min'">
      {{ controlLabel }} {{ translations.validationMinError }} {{ error.value.min }}
    </ng-container>
    <ng-container *ngIf="error.key === 'pattern'"></ng-container>
    <ng-container *ngIf="error.key === 'unique'">
      {{ controlLabel }} {{ translations.validationUniqueError }}
    </ng-container>
    <ng-container *ngIf="error.key === 'maxlength'">
      {{ controlLabel }} {{ translations.canNotExceed }} {{ error.value.max }}
      {{ translations.characters }}
    </ng-container>
    <ng-container *ngIf="error.key === 'mustBe'">
      {{ error.value.useLabel ? controlLabel : translations.thisField }} {{ translations.mustBe }}
      {{ error.value.target }}
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent {
  @Input() error: KeyValue<string, any> = { key: '', value: null };
  @Input() controlLabel: string;
  @Input() translations: Translations;
}
