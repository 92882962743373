import { EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class DebounceEventEmitter<T> extends EventEmitter<T> {
  private emitSubject: Subject<T>;

  constructor(private readonly _debounceTime: number) {
    super();

    this.emitSubject = new Subject<T>();
  }

  subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this.emitSubject.asObservable().pipe(debounceTime(this._debounceTime)).subscribe(next, error, complete);
  }

  public emit(value: T) {
    this.emitSubject.next(value);
  }
}
