import { SupportBotClient } from '@volt/api';
import { Inject, Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from 'src/configuration';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportBotService {
  constructor(
    private _supportBotClient: SupportBotClient,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  buildChatBotUrl(): Observable<string> {
    return this._supportBotClient
      .requestToken()
      .pipe(switchMap(response => of(`${this._appConfig.chatBotUrl}?t=${response}`)));
  }
}
