import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { InvitedAccountService } from '../../../services/invitedsupplier.service';
import { InvitedAccountModel } from '../../../../api.client';
import {  MessageService } from 'primeng/api';
import { MonitoringService } from '../../../../shared/services/monitoring.service';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'account-rejection-modal',
  templateUrl: './account-rejection-modal.component.html',
  styleUrls: ['./account-rejection-modal.component.scss'],
  providers:[MessageService]
})
export class AccountRejectionModalComponent extends DynamicDialogBaseComponent implements OnInit {
  public name;
  public invitedAccountId;
  public accountId;

  public invitedAccount = new InvitedAccountModel();
  public reject: string;
  public loading = false;
  constructor(
    public readonly _dialogReference: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    private _router: Router,
    private _invitedAccountService: InvitedAccountService,
    private readonly monitoringService: MonitoringService,
    private readonly _messageService: MessageService
  ) {
    super(_dialogReference, _dialogConfig);
    this.name = this._dialogConfig.data.name;
    this.invitedAccountId = this._dialogConfig.data.invitedAccountId;
    this.accountId = this._dialogConfig.data.accountId
  }

  ngOnInit() {
    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this._dialogReference.close(false));
  }

  public closeModal() {
    this._dialogReference.close({rejected: false});
    this._invitedAccountService.emitChangeStatus(1);
  }

  public approvedOrRejectInvitedAccount() {
    this._dialogReference.close({rejected: true, reason: this.reject});
  }
}
