import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Translations } from '../../services/translations.types';
import { ColSpanOption } from '../../types';
import { BaseControlService } from './base-control.service';

export const defaultLabelColSpan: ColSpanOption = {
  xs: 12,
  md: 2,
};

export const defaultControlColSpan: ColSpanOption = {
  xs: 12,
  md: 10,
};

@Component({
  selector: 'v-base-control',
  template: `
    <ng-template #templateRef>
      <div
        class="text-start"
        [ngClass]="{
          'p-0': noPadLabel,
          'text-md-end': labelAlignment === 'right',
          'text-md-center': labelAlignment === 'center',
          'text-md-start': labelAlignment === 'left'
        }"
        [colSpan]="labelColSpan"
        *ngIf="!!label && labelPosition == 'left'"
      >
        <label [for]="controlId" class="mb-0" [ngClass]="{ 'mb-2': labelMargin }">
          <span [ngClass]="{ 'fw-bold': boldLabel }">{{ label }}</span>
          <span class="text-danger" [ngStyle]="{ visibility: isRequired ? 'visible' : 'hidden' }">*</span>
        </label>
      </div>
      <div [ngClass]="{ 'p-0': noPad }" [colSpan]="controlColSpan">
        <ng-content></ng-content>
      </div>
      <div
        class="text-start"
        [ngClass]="{
          'p-0': noPadLabel,
          'text-md-end': labelAlignment === 'right',
          'text-md-center': labelAlignment === 'center',
          'text-md-start': labelAlignment === 'left'
        }"
        [colSpan]="labelColSpan"
        *ngIf="!!label && labelPosition == 'right'"
      >
        <label [for]="controlId" class="mb-0" [ngClass]="{ 'mb-2': labelMargin }">
          <span [ngClass]="{ 'fw-bold': boldLabel }">{{ label }}</span>
          <span class="text-danger" [ngStyle]="{ visibility: isRequired ? 'visible' : 'hidden' }">*</span>
        </label>
      </div>

      <ng-container *ngIf="!!hintText">
        <div [colSpan]="labelColSpan"></div>
        <small [colSpan]="controlColSpan" class="text-muted">{{ hintText }}</small>
      </ng-container>

      <ng-content select="errors-slot"></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseControlComponent implements OnDestroy {
  @ViewChild('templateRef', { static: true }) template: TemplateRef<any>;

  @Input() noPad = false;
  @Input() noPadLabel = false;
  @Input() label: string;
  @Input() labelColSpan: ColSpanOption = defaultLabelColSpan;
  @Input() labelPosition: 'left' | 'right' = 'left';
  @Input() labelAlignment: 'center' | 'right' | 'left' = 'right';
  @Input() labelMargin = false;
  @Input() boldLabel = false;
  @Input() control: UntypedFormControl;
  @Input() controlId: string;
  @Input() controlColSpan: ColSpanOption = defaultControlColSpan;
  @Input() isRequired: boolean;
  @Input() hintText: string;
  @Input() tooltipText: string;

  protected _baseControlDestroy$: Subject<null> = new Subject<null>();
  public translatedFormData$: Observable<Translations>;

  constructor(baseControlService: BaseControlService) {
    this.translatedFormData$ = baseControlService.translatedData$;
  }

  ngOnDestroy(): void {
    this._baseControlDestroy$.next();
    this._baseControlDestroy$.complete();
  }
}
