import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'service-deployment-name',
  templateUrl: './service-deployment-name.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class ServiceDeploymentNameComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public get serviceDeploymentNameControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('serviceDeploymentName');
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: { messages: ['Unique Business friendly name that identifies this Service Deployment.'] },
    });
  }
}
