import 'hammerjs';

import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';

import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localEsMx from '@angular/common/locales/es-MX';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TranslocoModule } from '@ngneat/transloco';
import { TranslocoPreloadLangsModule } from '@ngneat/transloco-preload-langs';
import { ApprovalStatus2, StatusTypes } from '@volt/api';
import { LoadingContainerModule } from '@volt/shared/components/containers/loading-container/loading-container.module';
import { APPROVAL_STATUS_TYPES, ROLE_TYPES, STATUS_TYPES } from '@volt/shared/constants/enum.constant';
import { FeatureModule } from '@volt/shared/directives/features/feature.module';
import { MonitoringErrorHandler } from '@volt/shared/error-handlers/monitoring-error-handler';
import { apiCancelInterceptorProvider } from '@volt/shared/interceptors/api-cancel.interceptor';
import { languageInterceptorProvider } from '@volt/shared/interceptors/language.interceptor';
import { tenantIdInterceptorProvider } from '@volt/shared/interceptors/tenant-id.interceptor';
import { LocalStorageService, localStorageServiceInstance } from '@volt/shared/services/local-storage.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { INVOICE_CONFIG, invoiceConfig } from '../invoice-config';
import { USER_CONSTRAINTS_CONFIG, userConstraintsConfig } from '../user-constraints-config';
import { AppComponent } from './app.component';
import { AuthService } from './auth';
import { AuthModule } from './auth/auth.module';
import { authInterceptorProvider } from './auth/services/auth.interceptor';
import { RoleConstant } from './auth/shared/roles.constants';
import { NotFoundComponent } from './common/not-found.component';
import { RedirectComponent } from './dashboard/components/redirect.component';
import { DepartmentService } from './department/services/department.service';
import { LayoutsModule } from './layouts/layouts.module';
import { MonitoringService } from './shared/services/monitoring.service';
import { IAppState, initialState } from './store';
import { rootReducer } from './store/reducers/root.reducer';
import { TranslocoHttpLoader } from './transloco-http.loader';
import { AppRoutingModule } from './app-routing.module';
import Quill from 'quill';
import { permissionsLoggingInterceptorProvider } from '@volt/shared/interceptors/permissions-logging.interceptor';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

registerLocaleData(localEsMx);

@NgModule({
  declarations: [AppComponent, NotFoundComponent, RedirectComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AuthModule.forRoot(),
    FeatureModule,
    LayoutsModule,
    NgReduxModule,
    LoadingContainerModule,
    AppRoutingModule,
    MarkdownModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TranslocoModule,
    TranslocoPreloadLangsModule.forRoot(['en', 'es']),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: LocalStorageService, useValue: localStorageServiceInstance },
    { provide: INVOICE_CONFIG, useValue: invoiceConfig },
    { provide: USER_CONSTRAINTS_CONFIG, useValue: userConstraintsConfig },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: ErrorHandler, useClass: MonitoringErrorHandler },
    DepartmentService,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        prodMode: environment.production,
        reRenderOnLangChange: true,
        defaultLang: 'en',
        fallbackLang: 'en',
        availableLangs: ['en', 'es'],
      },
    },
    apiCancelInterceptorProvider,
    authInterceptorProvider,
    languageInterceptorProvider,
    permissionsLoggingInterceptorProvider,
    tenantIdInterceptorProvider,
    { provide: STATUS_TYPES, useFactory: () => StatusTypes },
    { provide: APPROVAL_STATUS_TYPES, useFactory: () => ApprovalStatus2 },
    { provide: ROLE_TYPES, useFactory: () => RoleConstant },
    {
      provide: ErrorHandler,
      useClass: environment.isDevelopment ? ErrorHandler : ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [AuthService, MonitoringService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(store: NgRedux<IAppState>, devTools: DevToolsExtension) {
    store.configureStore(rootReducer, initialState, [], devTools.isEnabled() ? [devTools.enhancer()] : []);
  }
}

function initialize(authService: AuthService, monitoringService: MonitoringService) {
  return async () => {
    monitoringService.initialize();
    await authService.runInitialLoginSequence();
  };
}
