<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" for="timeEstimate" data-cy="task-time-estimate-label">
      Time Estimate in Minutes
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-time-estimate-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <input
      class="form-control rounded-0"
      [formControlName]="'timeEstimate'"
      (keypress)="isNumberKey($event)"
      id="timeEstimate"
      required
      data-cy="task-time-estimate"
    />

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && timeEstimateControl.hasError('required')"
        class="text-danger"
        data-cy="task-time-estimate-error"
      >
        Time Estimate in Minutes is required.
      </span>
    </div>
  </div>
</div>
