<v-loading-container [working]="working">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <header class="page-header">
          <h4 class="page title">Import Task Locations</h4>
        </header>
      </div>
    </div>

    <p-toast position="top-right" [baseZIndex]="9999"></p-toast>

    <div class="d-flex flex-column flex-sm-row h5">
      <ng-container *ngIf="fieldGroupName">
        <div>
          <label class="text-dark">Field Group:</label>
          <span class="fst-italic text-muted">{{ fieldGroupName }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="fieldGroupName">
        <div class="ms-lg-3">
          <label class="text-dark">Service Deployment:</label>
          <span class="fst-italic text-muted">{{ serviceDeploymentRefNumber }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="fieldGroupName">
        <div class="ms-lg-3">
          <label class="text-dark">Task:</label>
          <span class="fst-italic text-muted">{{ taskRefNumber }}</span>
        </div>
      </ng-container>
    </div>

    <shared-import
      [maxSizeBytes]="fileSize"
      (importClicked)="uploadFile($event)"
      (downloadTemplateClicked)="downloadTemplate()"
      [accept]="'.csv,text/csv'"
    >
      <v-info-box
        class="shared-import-description"
        [infoTitle]="'If a store does not exist, the import will fail.'"
      ></v-info-box>
    </shared-import>
  </div>
</v-loading-container>
