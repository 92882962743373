import { Injectable } from '@angular/core';
import { ActivityClient } from '@volt/api';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(private _activitiesClient: ActivityClient) {}

  public getActivities() {
    return this._activitiesClient.getAllActivities();
  }
}
