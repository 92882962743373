import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { SharedTableColumn } from '@volt/shared/components/table/models/shared-table-column';
import { SharedTableState } from '@volt/shared/components/table/models/shared-table-state';
import { Observable } from 'rxjs';
import { Translations } from '@volt/shared/services/translations.types';
import { TranslationService } from '@volt/shared/services/translation.service';
import { SortEvent } from 'primeng/api';
import { PrimePaginatorEvent } from '@volt/shared/types';
import { SharedTableDirective } from '@volt/shared/components/table/shared-table.directive';

@Component({
  selector: 'shared-table',
  templateUrl: './shared-table.component.html',
  styleUrls: ['./shared-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedTableComponent implements OnInit {

  private _initialState: SharedTableState;
  private _state: SharedTableState;

  @Input() set state(value: SharedTableState) {
    this._initialState = Object.assign({}, value);
    this._state = value;
  }

  get state(): SharedTableState {
    return this._state;
  }

  @Input() columns: SharedTableColumn<any>[];
  @Input() data: any[];
  @Input() showPaginator = true;
  @Input() scrollable = false;
  @Input() scrollHeight = null;

  @Output() stateChanged = new EventEmitter<SharedTableState>();
  @Output() export = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<any>();
  @ContentChildren(SharedTableDirective) templates: QueryList<SharedTableDirective>;

  translations$: Observable<Translations>;

  constructor(
    private readonly _translationService: TranslationService,
    private readonly cd: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.translations$ = this._translationService.getTranslatedObject();
  }

  onSortChanged(e: SortEvent): void {
    // The state reference may change, but could have the same values
    if (this.state.sortBy === e.field && this.state.sortAscending === (e.order === 1)) {
      return;
    }

    this.state.sortBy = e.field;
    this.state.sortAscending = e.order === 1;
    this.stateChanged.emit(this.state);
  }

  onPageChanged(e: PrimePaginatorEvent): void {
    if (this.state.offset === e.first) {
      return;
    }

    this.state.offset = e.first;
    this.stateChanged.emit(this.state);
  }

  min(l, r): number {
    return Math.min(l, r);
  }

  getTemplate(name: string): TemplateRef<any> {
    return this.templates.find(t => t.sharedTable === name).template;
  }

  get enabledColumns() {
    return this.columns?.filter(col => col.disabled !== true);
  }

  reset() {
    this.state = this._initialState;
    this.cd.markForCheck();
  }
}
