import { OnInit, Input, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DeleteInstructionurlModalComponent } from '../../../delete-instructionurl-modal/delete-instructionurl-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'instruction',
  templateUrl: './instructions.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})
export class InstructionComponent implements OnInit {
  public instruction: string;
  @Input() serviceTaskReferenceNumber;
  @Input() selectedInstructions;
  @Input() public group: UntypedFormGroup;
  @Input() serviceTaskInstructionMsg;
  @Output() public instructionName: EventEmitter<string> = new EventEmitter<string>();
  public files: any;
  @Input() canEdit: boolean;

  constructor(private _dynamicDialogService: DynamicDialogService) {}
  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'The field group can store instruction guide for a service task.',
          'Multiple instruction guides can be uploaded for a task.',
        ],
      },
    });
  }

  public get instructionControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('files');
  }

  public get instructionNameControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('instructionName');
  }

  public deleteInstruction(name: string, referenceNumber: string) {
    this._dynamicDialogService.open(DeleteInstructionurlModalComponent, {
      data: { name: name, serviceTaskReferenceNumber: referenceNumber },
    });
  }

  public checkInstructionExist(event: string) {
    this.instructionName.emit(event);
  }
}
