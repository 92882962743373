<nav
  class="navbar navbar-dark bg-primary fixed-top"
  [ngClass]="{ mobile: !templateData.inLargeViewport }"
  *ngIf="templateData$ | async as templateData"
>
  <a
    class="navbar-brand navbar-brand-responsive"
    [routerLink]="['']"
    [ngClass]="{ mobile: !templateData.inLargeViewport }"
  >
    <img [src]="retailer.largeLightLogoUrl" class="d-inline" />
  </a>

  <div class="navbar-nav navbar-expand d-flex flex-row align-items-center d-lg-none">
    <global-search
      [isMobileView]="true"
      [isMinimized]="templateData.searchMinimized"
      (searchMinimized)="onSearchMinimizedChange($event)"
    ></global-search>
    <ng-container *ngIf="templateData.searchMinimized">
      <p-dropdown
        [options]="templateData.langOptions"
        [ngModel]="templateData.activeLang"
        styleClass="language-dropdown mobile"
        (onChange)="onLangChange($event)"
      ></p-dropdown>

      <alert-icon class="ms-1 me-1 align-self-end"></alert-icon>

      <ng-container *ngIf="showMainNav">
        <a id="nav-toggle" class="text-light" (click)="toggleNav()" aria-label="Toggle navigation">
          <i class="fa fa-bars" [ngClass]="{ mobile: !templateData.inLargeViewport }"></i>
        </a>
      </ng-container>
      <ng-container *ngIf="!templateData.inLargeViewport && showGlobalNav">
        <profile-menu [mobileView]="!templateData.inLargeViewport"></profile-menu>
      </ng-container>
    </ng-container>
  </div>

  <div class="d-none d-lg-flex flex-row align-items-center px-1">
    <global-search></global-search>
    <p-dropdown
      [options]="templateData.langOptions"
      [ngModel]="templateData.activeLang"
      styleClass="language-dropdown"
      (onChange)="onLangChange($event)"
    ></p-dropdown>

    <alert-icon class="ms-2 me-2 align-self-end"></alert-icon>

    <profile-menu *ngIf="templateData.inLargeViewport && showGlobalNav"></profile-menu>
  </div>
</nav>
