<ng-container>
  <modal-header [title]="'Service Deployment Status'" (onClose)="onClose()" [closeable]="false"></modal-header>

  <div class="modal-body expand-width">
    <div class="form-fields form-fields-box">
      <div class="form-group row">
        <div class="col-xl-6">
          <label data-cy="status-modal-transactionId-label">
            Transaction Id:
            <span aria-required="true"></span>
          </label>
        </div>
        <div class="col-xl-6">
          <span data-cy="status-modal-transactionId">
            <strong>{{ logData.transactionId }}</strong>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-xl-6">
          <label data-cy="status-modal-status-label">
            {{ logData.serviceDeploymentStatusName }}:
            <span aria-required="true"></span>
          </label>
        </div>
        <div class="col-xl-6">
          <span
            style="font-weight: bold"
            [ngClass]="{
              'text-success': logData.status === 'Success',
              'text-warning': logData.status === 'Pending',
              'text-danger': logData.status === 'Failed'
            }"
            data-cy="status-modal-status"
          >
            <strong>{{ logData.status }}</strong>
          </span>
        </div>
      </div>
      <v-loading-container [working]="loading">
        <div class="form-group row" *ngIf="data && showReferenceNumber">
          <div class="col-xl-6">
            <label data-cy="status-modal-ref-num-label">{{ logData.serviceDeploymentReferenceNumber }}:</label>
          </div>
          <div class="col-xl-6">
            <span
              style="font-weight: bold"
              [ngClass]="{
                'text-success': logData.status === 'Success',
                'text-danger': logData.status === 'Failed'
              }"
            >
              <strong data-cy="status-modal-ref-num">{{ logData.referenceNumber }}</strong>
            </span>
          </div>
        </div>

        <div class="form-group row" *ngIf="data && logData.status === 'Failed'">
          <div class="col-xl-6">
            <label>Reason :</label>
          </div>
          <div class="col-xl-6">
            <span
              style="font-weight: bold"
              [ngClass]="{
                'text-danger': logData.status === 'Failed'
              }"
            >
              <strong>{{ rejectReason }}</strong>
            </span>
          </div>
        </div>
      </v-loading-container>
      <p style="font-size: 13px; font-weight: bold; margin-top: 15px" *ngIf="logData.status !== 'Failed'">
        (data will continue to submit, but you will need to check your transaction log)
      </p>
    </div>
  </div>
  <modal-footer [footerStyle]="'single_btn'" (onSubmit)="formReset()" [saveLabel]="'Close'"></modal-footer>
</ng-container>
