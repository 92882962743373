<v-loading-container [working]="loading">
  <div class="container-fluid">
    <header class="page-header d-flex align-items-center">
      <h2 data-cy="assembler-priority-title" class="page-title">
        <span>Assembler Priority</span>
      </h2>
    </header>
    <p-toast position="top-right" [baseZIndex]="9999"></p-toast>

    <shared-import
      (importClicked)="uploadAssemblers($event)"
      (exportClicked)="downloadAssemblers()"
      (downloadTemplateClicked)="downloadTemplate()"
      [multiple]="false"
      [maxSizeBytes]="5000000"
      [pageName]="pageName"
      [showLastImport]="true"
    >
      <v-info-box
        data-cy="shared-import-description-assembler"
        class="shared-import-description"
        [infoTitle]="'Your spreadsheet is the primary source of data.'"
        [infoMessage]="
          'When you upload a new sheet, data in the system is replaced by your sheet. If you delete data from your sheet, then upload it, the data will also be deleted from the system.'
        "
      >
        <p>
          Use the EXPORT button to download the existing data from the system. Modify that sheet and upload it as a CSV
          to replace the data in the system.
        </p>
      </v-info-box>
    </shared-import>
  </div>
</v-loading-container>
