import { RoleConstant } from '../auth/shared/roles.constants';

export enum AllowedView {
  RetailerOperationalManager,
  Admin,
  Provider,
  Supplier,
  RetailerAdmin,
  RetailerAdminWithoutRetailerOperationalManager,
  RetailerSuperAdmin,
  RetailerStore,
  RetailerMarketManager,
  RetailerCalendarAdmin,
  Emergency,
  EmergencyView,
  EocAdmin,
  FieldEmployee,
  RetailOperationsAdmin,
  Reports,
  VoltUsage,
  SyndicatedRepReport,
  Faq,
  PSPScoreCard,
  Support,
  ExternalUser,
  SurveyResult,
  ImportUsers,
  WalmartUsageReport,
  EditOwnAccount,
  TaskExport,
  FeedbackReport,
  EventReview,
  EventReviewDetail,
  DueDatePerformanceReport,
  LaptopRestoreReport,
  BikeBuildReport,
  ProviderSchedule,
  ProviderAdminDashboard,
  TaskDetailAnalyticsReport,
  DataManagement,
  Eoc,
}

export const allowedViews = [
  {
    AllowedView: AllowedView.Admin,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.VoltAccounting, RoleConstant.VoltCustomerService],
  },
  {
    AllowedView: AllowedView.RetailerSuperAdmin,
    Roles: [RoleConstant.RetailerAdmin],
  },
  {
    AllowedView: AllowedView.ProviderSchedule,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.ProviderAdmin, RoleConstant.ProviderScheduleAdmin],
  },
  {
    AllowedView: AllowedView.Provider,
    Roles: [
      RoleConstant.ProviderAdmin,
      RoleConstant.ProviderCustomerService,
      RoleConstant.ProviderDistrictManager,
      RoleConstant.ProviderExecutive,
      RoleConstant.ProviderRegionalManager,
    ],
  },
  {
    AllowedView: AllowedView.RetailerAdmin,
    Roles: [RoleConstant.RetailerAdmin, RoleConstant.RetailerOperationalManager],
  },
  {
    AllowedView: AllowedView.RetailerAdminWithoutRetailerOperationalManager,
    Roles: [RoleConstant.RetailerAdmin],
  },
  {
    AllowedView: AllowedView.Supplier,
    Roles: [RoleConstant.SupplierAdmin],
  },
  {
    AllowedView: AllowedView.RetailerStore,
    Roles: [RoleConstant.RetailerStoreManager],
  },
  {
    AllowedView: AllowedView.Emergency,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
      RoleConstant.EOCAdmin,
      RoleConstant.EOCManager,
    ],
  },
  {
    AllowedView: AllowedView.EmergencyView,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerStoreManager,
      RoleConstant.RetailerMarketManager,
      RoleConstant.RetailerOperationalManager,
      RoleConstant.EOCAdmin,
      RoleConstant.EOCManager,
      RoleConstant.RetailOperationsAdmin,
    ],
  },
  {
    AllowedView: AllowedView.RetailerMarketManager,
    Roles: [RoleConstant.RetailerMarketManager],
  },
  {
    AllowedView: AllowedView.RetailerOperationalManager,
    Roles: [RoleConstant.RetailerMarketManager, RoleConstant.RetailerOperationalManager],
  },
  {
    AllowedView: AllowedView.EocAdmin,
    Roles: [RoleConstant.EOCAdmin],
  },
  {
    AllowedView: AllowedView.Eoc,
    Roles: [RoleConstant.EOCAdmin, RoleConstant.EOCManager],
  },
  {
    AllowedView: AllowedView.FieldEmployee,
    Roles: [RoleConstant.FieldEmployee],
  },
  {
    AllowedView: AllowedView.RetailOperationsAdmin,
    Roles: [RoleConstant.RetailOperationsAdmin],
  },
  {
    AllowedView: AllowedView.Reports,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerMarketManager,
      RoleConstant.RetailerOperationalManager,
      RoleConstant.RetailerCalendarAdmin,
      RoleConstant.ProviderAdmin,
      RoleConstant.RetailOperationsAdmin,
    ],
  },
  {
    AllowedView: AllowedView.VoltUsage,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.RetailerMarketManager],
  },
  {
    AllowedView: AllowedView.PSPScoreCard,
    Roles: [
      RoleConstant.ProviderAdmin,
      RoleConstant.SupplierAdmin,
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
    ],
  },
  {
    AllowedView: AllowedView.SyndicatedRepReport,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin],
  },
  {
    AllowedView: AllowedView.Faq,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.VoltAccounting,
      RoleConstant.VoltCustomerService,
      RoleConstant.RetailerAdmin,
      RoleConstant.FieldEmployee,
      RoleConstant.RetailerCalendarAdmin,
    ],
  },
  {
    AllowedView: AllowedView.Support,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.VoltAccounting,
      RoleConstant.VoltCustomerService,
      RoleConstant.ProviderAdmin,
      RoleConstant.ProviderCustomerService,
      RoleConstant.ProviderDistrictManager,
      RoleConstant.ProviderExecutive,
      RoleConstant.ProviderRegionalManager,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerOperationalManager,
      RoleConstant.RetailOperationsAdmin,
      RoleConstant.RetailerMarketManager,
      RoleConstant.FieldEmployee,
      RoleConstant.EOCAdmin,
      RoleConstant.SupplierAdmin,
      RoleConstant.RetailerCalendarAdmin,
    ],
  },
  {
    AllowedView: AllowedView.ExternalUser,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.RetailerOperationalManager],
  },
  {
    AllowedView: AllowedView.SurveyResult,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.ProviderAdmin],
  },
  {
    AllowedView: AllowedView.ImportUsers,
    Roles: [RoleConstant.ProviderAdmin],
  },
  {
    AllowedView: AllowedView.WalmartUsageReport,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerMarketManager,
      RoleConstant.RetailerStoreManager,
    ],
  },
  {
    AllowedView: AllowedView.EditOwnAccount,
    Roles: [RoleConstant.ProviderAdmin],
  },
  {
    AllowedView: AllowedView.TaskExport,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.ProviderAdmin,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerOperationalManager,
      RoleConstant.RetailOperationsAdmin,
      RoleConstant.RetailerMarketManager,
    ],
  },
  {
    AllowedView: AllowedView.FeedbackReport,
    Roles: [
      RoleConstant.ProviderAdmin,
      RoleConstant.SystemAdministrator,
      RoleConstant.VoltAccounting,
      RoleConstant.VoltCustomerService,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerOperationalManager,
    ],
  },
  {
    AllowedView: AllowedView.EventReview,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
      RoleConstant.RetailerCalendarAdmin,
      RoleConstant.ProviderAdmin,
      RoleConstant.ProviderScheduleAdmin,
    ],
  },
  {
    AllowedView: AllowedView.EventReviewDetail,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.RetailerCalendarAdmin],
  },
  {
    AllowedView: AllowedView.RetailerCalendarAdmin,
    Roles: [RoleConstant.RetailerCalendarAdmin],
  },
  {
    AllowedView: AllowedView.DueDatePerformanceReport,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.ProviderAdmin],
  },
  {
    AllowedView: AllowedView.LaptopRestoreReport,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.ProviderAdmin],
  },
  {
    AllowedView: AllowedView.BikeBuildReport,
    Roles: [
      RoleConstant.SystemAdministrator,
      RoleConstant.RetailerAdmin,
      RoleConstant.ProviderAdmin,
      RoleConstant.RetailerOperationalManager,
      RoleConstant.RetailerCalendarAdmin,
    ],
  },
  {
    AllowedView: AllowedView.ProviderAdminDashboard,
    Roles: [RoleConstant.ProviderAdmin],
  },
  {
    AllowedView: AllowedView.TaskDetailAnalyticsReport,
    Roles: [RoleConstant.ProviderAdmin, RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin],
  },
  {
    AllowedView: AllowedView.DataManagement,
    Roles: [RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin],
  },
];
