import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BlobClient, EmergencyClient, EOCStoreStatusClient, FileParameter, FileResponse } from '@volt/api';
import { SharedImportComponent } from '@volt/shared/components/shared-import/shared-import.component';
import { MessageService } from 'primeng/api';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../configuration';
import { environment } from '../../../environments/environment';
import { CommonUtils } from '../../shared/utils/common.utils';

import * as moment from 'moment';
import { BlobService } from '@volt/shared/services/blob.service';

@Component({
  selector: 'emergencies-page',
  templateUrl: './emergencies.component.html',
  providers: [MessageService],
})
export class EmergenciesComponent implements OnInit {
  public working = false;
  public showExtra = new UntypedFormControl(false);
  public showCompleted = new UntypedFormControl(false);
  public showAllOrder = new UntypedFormControl(false);
  public showAllStatus = new UntypedFormControl(false);
  public pageName = 'Emergency Management';

  @ViewChild(SharedImportComponent) sharedImport: SharedImportComponent;

  constructor(
    private _emergencyClient: EmergencyClient,
    private _eOCStoreStatusClient: EOCStoreStatusClient,
    private readonly _messageService: MessageService,
    private readonly _blobService: BlobService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  ngOnInit() {}

  public uploadFile(files: File[]) {
    this.working = true;
    const file: FileParameter = {
      data: files[0],
      fileName: files[0].name,
    };

    this._emergencyClient
      .uploadEmergencies(this.pageName, file)
      .pipe(
        catchError(err => {
          return observableOf(err);
        }),
      )
      .subscribe((err: any) => {
        this.working = false;
        if (err) {
          this._messageService.add({
            severity: 'error',
            summary: 'Error Uploading File',
            detail: err.error,
          });
        } else {
          this._messageService.add({
            severity: 'success',
            summary: 'File Uploaded Successfully',
          });
          this.sharedImport.reset();
        }
      });
  }

  public downloadStatusUpdates() {
    this._eOCStoreStatusClient.getFile(this.showAllStatus.value).subscribe((file: FileResponse) => {
      const date = new Date();
      const fileName = `StoreStatusReport__${moment.utc().format('YYYY_MM_DD_hh_mm')}_UTC.csv`;

      CommonUtils.download(file.data, fileName);
    });
  }

  downloadTemplate() {
    this._blobService
      .downloadBlobFromContainer(environment.uploadsSecureContainer, environment.eoc.emergencyTemplate)
      .subscribe(
        _ => {},
        err => {
          console.error(err);

          this._messageService.add({
            severity: 'error',
            detail: 'Could not download file',
          });
        },
      );
  }
}
