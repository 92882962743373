import { ElementRef, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AccountsClient } from '../../../api.client';

export enum PanelType {
  Alert,
  Settings,
  Support,
  MainNav,
}

@Injectable()
export class LayoutService {
  private _setTheme = new Subject<string>();
  public onSetTheme = this._setTheme.asObservable();

  private _togglePanel = new Subject<PanelType>();
  public onTogglePanel = this._togglePanel.asObservable();

  constructor(private _accountsClient: AccountsClient) {}

  public getAccountByAccountId(id: number): Observable<any> {
    return this._accountsClient.getAccountByAccountId(id);
  }

  public getAccountHideTransactionByAccountId(id: number): Observable<any> {
    return this._accountsClient.checkAccountHideTransaction(id);
  }

  public togglePanel(panelType: PanelType) {
    this._togglePanel.next(panelType);
  }

  public setTheme(theme: string) {
    this._setTheme.next(theme);
  }

  public isClickInsideElement(event: any, elRef: ElementRef, exceptionId: string = null): boolean {
    const contains = elRef.nativeElement.contains(event.target);
    if (contains) return true;
    else if (exceptionId && exceptionId === event.target.id) return true;

    return false;
  }

  public isClickInElement(event: any, id: string) {
    if (event.target.id === id) {
      return true;
    } else if (event.parent.id === id) {
      return true;
    }
    return false;
  }
}
