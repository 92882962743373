import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { AlternativeNumber } from '@volt/api';
import { SharedTableColumn } from '@volt/shared/components/table/models/shared-table-column';
import { SharedTableState } from '@volt/shared/components/table/models/shared-table-state';
import { PC, PermissionCollection } from '@volt/shared/services/permissions.service';
import { nameOf } from '@volt/shared/utils/common.utils';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth';
import { SUPPLIER_SELF, SupplierNumberService } from 'src/app/registration/services/supplier-number.service';

@Component({
  selector: 'account-supplier-numbers',
  templateUrl: './supplier-numbers.component.html',
  styleUrls: ['../viewaccount.component.scss'],
  providers: [MessageService],
})
export class SupplierNumbersComponent implements OnChanges {
  SUPPLIER_SELF = SUPPLIER_SELF;

  @Input() accountId: number;
  @Input() primaryNumber: string;
  @Input() editable: boolean = true;

  columns: SharedTableColumn<AlternativeNumber>[] = [
    {
      field: nameOf<AlternativeNumber>('number'),
      header: 'Alternative Supplier Number',
      sortable: true,
    },
    {
      field: nameOf<AlternativeNumber>('updatedDate'),
      header: 'Updated Date',
      sortable: true,
      dateFormat: 'shortDate',
    },
    {
      field: nameOf<AlternativeNumber>('updatedBy'),
      header: 'Updated By',
      sortable: true,
    },
    {
      fieldSelector: 'deleteRecord',
      width: '50px',
    },
  ];

  get loading$() {
    return this._supplierNumberService.loading$;
  }

  tableState$: BehaviorSubject<SharedTableState> = new BehaviorSubject({
    offset: 0,
    limit: 5,
    sortBy: 'number',
    sortAscending: true,
    totalCount: 0,
  });

  data$ = combineLatest([this._supplierNumberService.supplierNumbers$, this.tableState$]).pipe(
    map(([numbers, tableState]) => {
      if (tableState.totalCount !== numbers.length) {
        this.updateTotalCount(tableState, numbers);
      }

      return this.pageAndSortData(tableState, numbers);
    }),
    catchError(err => {
      this._messageService.add({
        severity: 'error',
        summary: 'There was a server error retrieving the alternative supplier numbers',
      });
      return of([]);
    }),
  );

  addForm = this._fb.group({
    number: this._fb.control('', {
      validators: [Validators.required],
      asyncValidators: [this.uniqueNumberValidator()],
      updateOn: 'blur',
    }),
  });

  constructor(
    @Inject(PC) public pc: PermissionCollection,
    private _supplierNumberService: SupplierNumberService,
    private readonly _fb: UntypedFormBuilder,
    private readonly _authService: AuthService,
    private readonly _messageService: MessageService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accountId?.currentValue !== changes.accountId?.previousValue) {
      this._supplierNumberService.get(this.accountId);
    }

    if (changes.editable) {
      this.showOrHideDeleteColumn(changes);
    }
  }

  onAddRecord() {
    this.addForm.markAllAsTouched();

    if (!this.addForm.valid) {
      return;
    }

    const supplierNumber = this.addForm.value.number;

    this._supplierNumberService.add(
      new AlternativeNumber({
        number: supplierNumber,
        updatedBy: this._authService.getCurrentUser().email,
        updatedDate: moment(),
      }),
    );

    this.addForm.reset();
  }

  onDeleteRecord(record) {
    this._supplierNumberService.delete(record);
  }

  onTableStateChanged(state: SharedTableState) {
    this.tableState$.next({
      ...this.tableState$.value,
      sortBy: state.sortBy,
      sortAscending: state.sortAscending,
      offset: state.offset,
      limit: state.limit,
      totalCount: state.totalCount,
    });
  }

  private uniqueNumberValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this._supplierNumberService.isNumberAlreadyUsed(control.value).pipe(
        map(result => {
          if (result) {
            return {
              unique: result,
            };
          }
          return null;
        }),
      );
    };
  }

  private updateTotalCount(tableState: SharedTableState, numbers: AlternativeNumber[]) {
    tableState.totalCount = numbers.length;
    this.tableState$.next(tableState);
  }

  private pageAndSortData(tableState: SharedTableState, alternativeNumbers: AlternativeNumber[]): AlternativeNumber[] {
    let numbers = [...alternativeNumbers];
    switch (tableState.sortBy) {
      case 'number':
        numbers = numbers.sort((n1, n2) => n1.number.localeCompare(n2.number, undefined, { numeric: true }));
        break;
      case 'updatedDate':
        numbers = numbers.sort((n1, n2) => n2.updatedDate.valueOf() - n1.updatedDate.valueOf());
        break;
      case 'updatedBy':
        numbers = numbers.sort((n1, n2) => n1.updatedBy.localeCompare(n2.updatedBy));
        break;
    }

    if (tableState.sortAscending === false) {
      numbers = numbers.reverse();
    }

    numbers = numbers.slice(tableState.offset, tableState.offset + tableState.limit);
    return numbers;
  }

  private showOrHideDeleteColumn(changes: SimpleChanges) {
    if (changes.editable.currentValue) {
      this.showDeleteColumn();
    } else {
      this.hideDeleteColumn();
    }
  }

  private hideDeleteColumn() {
    this.columns = this.columns.filter(c => c.fieldSelector !== 'deleteRecord');
  }

  private showDeleteColumn() {
    if (!this.columns.some(c => c.fieldSelector === 'deleteRecord')) {
      this.columns.push({
        fieldSelector: 'deleteRecord',
        width: '50px',
      });
    }
  }
}
