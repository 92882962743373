import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asHours',
})
export class AsHoursPipe implements PipeTransform {
  transform(value: number): string {
    if (value <= 0) {
      return '0';
    }
    if (value < 60) {
      return `${value}m`;
    }

    const hours = Math.floor(value / 60);
    if (hours >= 1000) {
      return `${hours.toLocaleString()}h`;
    }
    const minutes = value - hours * 60;
    return `${hours.toLocaleString()}h ${minutes.toLocaleString()}m`;
  }
}
