import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PowerBiToken, SecurityClient } from '@volt/api';
import { catchError } from 'rxjs/operators';
import { MonitoringService } from '../../shared/services/monitoring.service';

@Injectable()
export class PowerBiTokenResolver implements Resolve<PowerBiToken> {
  constructor(private readonly securityClient: SecurityClient, private readonly monitoringService: MonitoringService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<PowerBiToken> {
    return this.securityClient.getPowerBiToken().pipe(
      catchError(err => {
        console.error(err);
        this.monitoringService.logApiException(err);
        return of(null);
      }),
    );
  }
}
