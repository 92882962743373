import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'emergency-overview',
  templateUrl: './emergency-overview.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
