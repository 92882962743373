import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import startCase from "lodash-es/startCase";
import { combineLatest, iif } from "rxjs";
import {
  ActivityClient,
  ActivityFilterParams,
  ActivityModel,
  LimitedResultOfActivityModel,
  PagedFilterOfActivityFilterParams,
  StatusTypes
} from "../../../../../api.client";
import { TranslationService } from "../../../../services/translation.service";
import { Translations } from "../../../../services/translations.types";
import { CommonUtils } from "../../../../utils/common.utils";
import { OperatorUtils } from "../../../../utils/operator.utils";
import { BaseFormField } from "../../base-form-field.component";

@Component({
  selector: 'v-activities-field',
  templateUrl: './activities-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitiesFieldComponent extends BaseFormField implements OnInit, OnChanges {
  @Input() maxNumberOfSelections?: number = undefined;
  @Input() useDataWarehouse = false;

  constructor(
    private readonly _translationService: TranslationService,
    private readonly _activityClient: ActivityClient,
    private readonly _cd: ChangeDetectorRef,
  ) {
    super(_translationService);
  }

  protected get filterDisplayLabel(): string {
    return this.translatedData?.activities;
  }

  ngOnInit() {
    combineLatest([
      this.translatedData$,
      iif(
        () => this.useDataWarehouse,
        this._activityClient.getFilteredActivityListDw(null),
        this._activityClient.getFilteredActivityList(new PagedFilterOfActivityFilterParams({
          offset: 0,
          limit: 2_147_483_647,
          filter: new ActivityFilterParams({ status: StatusTypes.Active, sortAscending: false })
        }))
      ),
    ])
      .pipe(OperatorUtils.takeUntilDisposed(this))
      .subscribe(([_, items]: [Translations, LimitedResultOfActivityModel]) => {
        this.items = CommonUtils.mapArrayToMultiSelectItem<ActivityModel>(
          items.results,
          item => startCase(item.activityName),
          item => item.activityName,
          true,
        );
        this.updateSelectedItems();
        this._cd.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.firstChange) {
      this.updateSelectedItems();
      this.control.valueChanges.pipe(OperatorUtils.takeUntilDisposed(this)).subscribe(_ => this.updateSelectedItems());
      this.control.registerOnDisabledChange((isDisabled: boolean) => this._cd.markForCheck());
    }
  }

  reset() {
    this.control.setValue([]);
    this._cd.markForCheck();
  }
}
