<p-toolbar styleClass="mb-2">
  <v-loading-container [working]="false">
    <div class="row mb-2 w-100">
      <ng-container *ngIf="!!pspAccountList">
        <div
          class="col-12 col-md-6 col-xl-4 mb-2 mb-lg-0"
          *authorize="[allowedView.RetailerAdmin, allowedView.Admin, allowedView.RetailerCalendarAdmin]"
        >
          <label for="accounts" data-cy="accounts-filter-label">Accounts</label>
          <p-dropdown
            id="accounts"
            [filter]="true"
            styleClass="w-100"
            [options]="pspAccountList"
            [(ngModel)]="selectedPSP"
            (onChange)="FilterByPSP()"
            data-cy="accounts-filter"
            [virtualScroll]="true"
            itemSize="30"
          ></p-dropdown>
        </div>
      </ng-container>
      <ng-container *ngIf="showDateFilter">
        <div
          class="col-12 col-md-6 col-xl-4 mb-2 mb-lg-0 ms-auto"
          [ngStyle]="{ visibility: showDateFilter ? 'visible' : 'hidden' }"
        >
          <label for="startDate" data-cy="start-date-filter-label">Start Date</label>
          <p-calendar
            id="startDate"
            [ngModel]="startDate"
            [showIcon]="true"
            [placeholder]="'Select start date'"
            (onSelect)="onSelectStartDate($event)"
            [readonlyInput]="true"
            data-cy="start-date-filter"
          ></p-calendar>
        </div>
        <div
          class="col-12 col-md-6 col-xl-4 mb-2 mb-lg-0"
          [ngStyle]="{ visibility: showDateFilter ? 'visible' : 'hidden' }"
        >
          <label for="endDate" data-cy="end-date-filter-label">End Date</label>
          <p-calendar
            id="endDate"
            [ngModel]="endDate"
            [showIcon]="true"
            [minDate]="minDate"
            [disabled]="!showEndDate"
            [placeholder]="'Select end date'"
            (onSelect)="onSelectEndDate($event)"
            [readonlyInput]="true"
            data-cy="end-date-filter"
          ></p-calendar>
        </div>
      </ng-container>
      <div class="col-12 col-md-6 col-xl-4 mb-2 mb-lg-0">
        <label for="filterText" data-cy="search-by-filter-label">Search By</label>
        <input
          #queryInput
          tabindex="1"
          type="text"
          id="filterText"
          class="form-control"
          ngbTooltip="Search by name"
          triggers="focus:blur"
          tooltipClass="name-query-tooltip"
          [placeholder]="searchBy"
          [(ngModel)]="filterText"
          (input)="onFilterTextChanged($event.target.value)"
          data-cy="search-by-filter"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 d-flex">
        <button
          class="btn btn-primary btn-sm ms-auto"
          type="button"
          (click)="createFilteredList()"
          data-cy="ser-dep-adv-search-button"
        >
          <i class="fa fa-search"></i>
          Advanced Search
        </button>
        <button
          class="btn btn-primary btn-sm ms-2"
          type="button"
          (click)="clearSearch(queryInput)"
          data-cy="ser-dep-clear-button"
        >
          <i class="fa fa-times"></i>
          Clear
        </button>
      </div>
    </div>
  </v-loading-container>
</p-toolbar>
