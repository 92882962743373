import { OnInit, Directive } from '@angular/core';
import { ActionLogFilterParams } from '../../action-log-filter-parameters';

@Directive()
export abstract class TableDisplay implements OnInit {
  public totalRecords = 0;
  public cols: any[];
  public data: any;
  public loading = false;
  public searchBy: string;
  public actionlogfilterParams: ActionLogFilterParams;
  ngOnInit() {}

  protected constructor() {
    this.actionlogfilterParams = new ActionLogFilterParams();
  }

  public abstract pullData($event: ActionLogFilterParams);

  pagination(page: number) {
    const updateFilter = Object.assign(this.actionlogfilterParams, {
      page: page,
    });
    this.pullData(updateFilter);
  }
}
