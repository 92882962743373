<v-loading-container [working]="loading">
  <ng-container>
    <div>
      <modal-header
        [title]= " name"
        (onClose)="closeModal()"
      >
      </modal-header>
      <div class="modal-body">
        <p-toast [baseZIndex]="100"></p-toast>
        <div class="form-group row">
          <div class="col-xl-12">
            <div>
              <label>Reason<span aria-required="true"></span></label>
            </div>

            <div>
              <textarea
                class="form-control rounded-0"
                maxlength="200"
                [(ngModel)]="reject"
                id="rejectionReason"
              ></textarea>
            </div>
          </div>
        </div>
        <modal-footer
          [saveLabel]="'Submit'"
          (onSubmit)="approvedOrRejectInvitedAccount()"
          (onClose)="closeModal()">
        </modal-footer>
      </div>
    </div>
  </ng-container>
</v-loading-container>
