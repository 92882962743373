import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { Contact, VoltAccountViewModel } from '../models/volt-account';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AccountsAccountsClient, CreateAccountViewModel } from '@volt/api-accounts';
import { InvitedAccountClient, InvitedAccountModel } from '@volt/api';
import { mapToInvitedAccountModel, mapToRetailerAccountInfo } from './utils';
import { RegistrationRetailerAccountViewModel } from '../models/registration-retailer-account-view-model';

export interface Invitation {
  invitedAccount: InvitedAccountModel,
  voltAccount: VoltAccountViewModel,
  retailerAccount: RegistrationRetailerAccountViewModel,
  contact: Contact,
  personalizedMessage: string;
}

@Injectable()
export class RegistrationService {
  constructor(private readonly _accountsClient: AccountsAccountsClient,
              private readonly _invitedAccountClient: InvitedAccountClient,
              private readonly _activatedRoute: ActivatedRoute) { }

  get invitation$() {
    return this._activatedRoute.queryParams.pipe(
      map(params => params['id']),
      switchMap(id => {
        if(id != null) {
          return this._invitedAccountClient.getInvitedAccountById(id).pipe(
            map(invitedAccount => this.mapInvitedAccount(invitedAccount))
          )
        }

        return of(this.mapInvitedAccount(null));
      })
    )
  }

  private mapInvitedAccount(invitedAccount: InvitedAccountModel) {
    const voltAccountViewModel = new VoltAccountViewModel();
    voltAccountViewModel.name = invitedAccount?.aname;
    voltAccountViewModel.note = invitedAccount?.aNote;

    const retailerAccountViewModel = new RegistrationRetailerAccountViewModel();
    retailerAccountViewModel.supplierNumber = invitedAccount?.accountNumber;

    return {
      invitedAccount: invitedAccount,
      voltAccount: voltAccountViewModel,
      retailerAccount: retailerAccountViewModel,
      contact: {
        firstName: invitedAccount?.fName,
        lastName: invitedAccount?.lName,
        email: invitedAccount?.invitedEmail,
        phone: invitedAccount?.pPhone
      },
      personalizedMessage: invitedAccount?.invitedNote
    } as Invitation;
  }

  createVoltAccount(account: VoltAccountViewModel): Observable<string> {
    const model = new CreateAccountViewModel();
    model.init(account)

    return this._accountsClient.createAccount(model).pipe(
      switchMap((accountId) => {
        if((account.imageUrl as any) instanceof File) {
          const file = (account.imageUrl as unknown) as File;
          return this._accountsClient.setLogo(accountId, {
            data: file,
            fileName: file.name
          }).pipe(
            tap(imageUrl => account.imageUrl = imageUrl),
            map(() => accountId)
          )
        } else {
          return of(accountId);
        }
      })
    )
  }

  createRetailerAccount(accountId: string, recipientContact: Contact, voltAccount: VoltAccountViewModel, retailerAccount: RegistrationRetailerAccountViewModel, invitedAccount: InvitedAccountModel): Observable<any> {
    invitedAccount = mapToInvitedAccountModel(recipientContact, voltAccount, mapToRetailerAccountInfo(retailerAccount), invitedAccount);
    return this._invitedAccountClient.registerInvitedAccount(invitedAccount);
  }

  accountNameIsTaken(name: string) {
    return this._accountsClient.accountNameIsTaken(name);
  }
}
