<ng-template #loading>
  <v-loading-spinner></v-loading-spinner>
</ng-template>

<v-container-wrapper [withToast]="true">
  <ng-template vContainerTemplate="headerLeft" let-translated="translated">
    <h2 data-cy="container-title" class="page-title">{{ translated.activity_plural }}</h2>
  </ng-template>

  <ng-template vContainerTemplate="headerRight" let-translated="translated">
    <ng-container *permissions="[pc.names.ActivitiesManageAssociateActivities, pc.privilege.Read, canAssignActivities]">
      <a data-cy="assign-activities" class="btn btn-primary" routerLink="/activities/associate-activities">
        {{ translated.associateActivities }}
      </a>
    </ng-container>
  </ng-template>

  <ng-template vContainerTemplate="content" let-translated="translated">
    
      <p-tabView [activeIndex]="currentTab"
                 (activeIndexChange)="onTabChanged($event)">
        <activity-aggregate-filters
          *ngIf="currentTab === 1 || currentTab === 2"
          [filters]="aggregateFilters"
          [translations]="translated"
          (filtersChanged)="onAggregateFiltersChanged($event)"
          class="d-block mb-3"
        ></activity-aggregate-filters>

        <p-tabPanel [header]="translated.list">
          <ng-template pTemplate="content">
            <activity-list-filters
              [filters]="activityFilters"
              [translations]="translated"
              (filtersChanged)="onActivityFiltersChanged($event)"
              class="d-block mb-3"
            ></activity-list-filters>
            <activity-list
              *ngIf="activityData$ | async as activityData; else loading"
              (tableStateChange)="onTableStateChange($event)"
              [activityData]="activityData"
              [areActivitiesLoading]="isTableLoading$ | async"
            ></activity-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel [header]="translated.taskCount">
          <ng-template pTemplate="content">
            <activity-count-chart
              [aggregateData]="aggregateData$ | async"
              [translations]="translated"
              [isLoading]="isAggregateLoading$ | async"
            ></activity-count-chart>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel [header]="translated.taskHours">
          <ng-template pTemplate="content">
            <activity-hours-chart
              [aggregateData]="aggregateData$ | async"
              [translations]="translated"
              [isLoading]="isAggregateLoading$ | async"
            ></activity-hours-chart>
          </ng-template>
        </p-tabPanel>

      </p-tabView>
    
  </ng-template>
</v-container-wrapper>
