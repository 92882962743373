<ng-container *ngIf="translatedData$ | async as translatedData">
  <a
    #profilefab
    id="profilefab"
    class="btn btn-fab-img"
    [ngClass]="{ mobile: mobileView }"
    role="button"
    (click)="showProfile()"
    aria-haspopup="true"
  >
    <img
      [src]="!!profileImageUrl ? (profileImageUrl | secureUrl | async) : noProfileImageUrl"
      [ngClass]="{ mobile: mobileView }"
    />
  </a>
  <div [ngClass]="{ open: profileOpen }" #profiledropdown id="profiledropdown" class="profile-dropdown" role="menu">
    <div class="profile-card-header">
      <div class="d-flex flex-row">
        <div class="avatar avatar-lg profile-card-avatar d-inline-flex">
          <img [src]="!!profileImageUrl ? (profileImageUrl | secureUrl | async) : noProfileImageUrl" />
        </div>
        <div class="profile-card-title d-flex flex-column align-self-center">
          <div>{{ userDisplayName }}</div>
          <div class="profile-card-subtitle">{{ userRole }}</div>
        </div>
      </div>
      <div class="d-flex flex-row spacer">
        <div class="profile-card-subtitle">E: {{ userEmail }}</div>
      </div>
      <div class="d-flex flex-row spacer" *permissions="[pc.names.MyAccountManage, pc.privilege.Read]">
        <a class="profile-card-subtitle clickable" (click)="goToMyAccount()">{{ translatedData.myAccount }}</a>
      </div>
      <div class="d-flex flex-row spacer" *permissions="[pc.names.OnboardingGuideManage, pc.privilege.Read]">
        <a class="profile-card-subtitle clickable" [href]="clientOnboardingUrl | secureUrl | async" target="_blank"> {{ translatedData.adminOnboardingGuide }}
        </a>
      </div>
    </div>
    <div class="profile-card-content">
      <ul>
        <li>
          <a class="text-danger clickable" (click)="confirmSignOut()">
            <i class="fa fa-sign-out"></i>
            {{ translatedData.signOut }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
