import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProductsModel } from '../../../api.client';

@Injectable()
export class DataPassingService {
  public dataPassingObservable: Observable<any>;
  private dataPassingSubject = new Subject<ProductsModel>();

  constructor() {
    this.dataPassingObservable = this.dataPassingSubject.asObservable();
  }

  public passData(product: ProductsModel) {
    this.dataPassingSubject.next(product);
  }
}
