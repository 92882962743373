import { StateSubject } from '@volt/shared/extensions/state-subject';
import { Injectable } from '@angular/core';
import { Tag2, TagClient } from '@volt/api';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import { SelectItem } from 'primeng/api';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

export interface ReservedTag {
  [tagName: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private reservedTagDictionary: ReservedTag = {};
  private reservedTags: string[] = [];
  private $userTagList: ReplaySubject<Tag2[]> = new ReplaySubject<Tag2[]>();
  public userTagsLoaded$ = new StateSubject<boolean> (false);

  public get userTagList$(): Observable<Tag2[]> {
    return this.$userTagList.asObservable();
  }
  public get userTagsLoaded(): boolean{
    return this.userTagsLoaded$.getValue();
  }

  constructor(private _tagClient: TagClient) {}

  public isReservedTag(tag: string) {
    return tag in this.reservedTagDictionary;
  }

  public getReservedTagColor(tag: string) {
    return this.reservedTagDictionary[tag];
  }

  private populateReservedTagDictionary(tagList: Tag2[]) {
    tagList.forEach((tag: Tag2) => {
      this.reservedTagDictionary[tag.name] = tag.colorClass;
    });
  }

  public extractReservedTags(userTags: string[]): string[] {
    return userTags?.filter(t => this.reservedTags.includes(t)) ?? [];
  }

  public getTagList(): Observable<Tag2[]> {
    return this._tagClient.getTagList().pipe(
      OperatorUtils.logAndRethrowError(),
      map(t => t.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })),
      tap((tags: Tag2[]) => {
        this.populateReservedTagDictionary(tags);
        this.reservedTags = tags.map(t => t.name);
      }),
    );
  }

  public getUserTagList() {
    return this._tagClient.getPersonAndReservedTags(5610).subscribe(tags => {
      this.$userTagList.next(tags);
      this.userTagsLoaded$.next(true);
    })
  }

  public get currentUserTags(): Observable<Tag2[]> {
    return this.$userTagList.asObservable();
  }

  public hasUserTag$(userTag: string): Observable<boolean> {
    return userTag == null ? of(true) : this.$userTagList.pipe(
      map(tags => 
        tags.some(
          tag => tag.name == userTag
        )),
      take(1)
    );
  }

  public getTagListDropdown(accountId?:number): Observable<SelectItem[]> {
    return this._tagClient.getAccountTags(accountId ? [accountId] : null).pipe(
      map(t => t.sort((a, b) => {
        if(a.name === "Select") {
          return -1
        }
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })),
      map(tagList =>
        tagList.map(tag => {
          return {
            label: tag.name,
            value: tag.name,
          };
        }),
      ),
    );
  }
}
