import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'visit-overview',
  templateUrl: './visit-overview.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class VisitOverviewComponent implements OnInit {
  @Input() public group: UntypedFormGroup;

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: ['An optional field for important details and a description of the Service Deployment.'],
      },
    });
  }
}
