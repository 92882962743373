import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'stepper-controls',
  templateUrl: './stepper-controls.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperControlsComponent implements OnInit {
  @Input() translations: Translations;
  @Input() canGoNext = true;
  @Input() nextDisabled = false;
  @Input() canGoBack = true;
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  @Input() nextLabel = null;
  @Input() nextButtonStyleClass = null;
  @Input() nextButtonIcon = 'pi pi-angle-right';

  constructor() { }

  ngOnInit(): void {
  }

}
