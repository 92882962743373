import { InvitedAccountModel } from '@volt/api';
import { Contact, VoltAccountViewModel } from '../models/volt-account';
import { RetailerAccountViewModel } from '../models/retailer-account-view-model';
import { RegistrationRetailerAccountViewModel } from '../models/registration-retailer-account-view-model';

export function mapToInvitedAccountModel(recipientContact: Contact, voltAccount: VoltAccountViewModel, retailerAccount: RetailerAccountViewModel, invitedAccount: InvitedAccountModel) {
  invitedAccount = invitedAccount ||  new InvitedAccountModel();

  invitedAccount.invitedAccountId = invitedAccount.invitedAccountId || 0;
  invitedAccount.accountId = invitedAccount.accountId || 0;
  invitedAccount.accountNumber = retailerAccount.supplierNumber || invitedAccount.accountNumber;
  invitedAccount.retailLink = invitedAccount.invitedAccountId === 0;
  invitedAccount.invitedCompany = voltAccount.name;
  invitedAccount.invitedName = voltAccount.name;
  invitedAccount.invitedEmail = recipientContact?.email || invitedAccount.invitedEmail;

  invitedAccount.email1 = recipientContact?.email || invitedAccount.email1;
  invitedAccount.pPhone = recipientContact?.phone || invitedAccount.pPhone;
  invitedAccount.fName = recipientContact?.firstName || invitedAccount.fName;
  invitedAccount.lName = recipientContact?.lastName || invitedAccount.lName;

  invitedAccount.aname = voltAccount.name;
  invitedAccount.aabbrev = voltAccount.abbreviation;
  invitedAccount.aNote = voltAccount.note;
  invitedAccount.aimageUrl = voltAccount.imageUrl;
  invitedAccount.awebsite = voltAccount.websiteUrl;

  invitedAccount.aaddress1 = voltAccount.address.address1;
  invitedAccount.aaddress2 = voltAccount.address.address2;
  invitedAccount.acity = voltAccount.address.city;
  invitedAccount.acountry = voltAccount.address.country;
  invitedAccount.astate = voltAccount.address.state;
  invitedAccount.azip = voltAccount.address.zip;

  invitedAccount.shipAttnTo = voltAccount.shipping.contact.firstName;
  invitedAccount.shipDirectToRep = voltAccount.shipping.shouldShipDirectToRep ? 1 : 0;
  invitedAccount.saddress1 = voltAccount.shipping.address.address1;
  invitedAccount.saddress2 = voltAccount.shipping.address.address2;
  invitedAccount.scity = voltAccount.shipping.address.city;
  invitedAccount.scountry = voltAccount.shipping.address.country;
  invitedAccount.sstate = voltAccount.shipping.address.state;
  invitedAccount.szip = voltAccount.shipping.address.zip;

  invitedAccount.baddress1 = voltAccount.billing.address.address1;
  invitedAccount.baddress2 = voltAccount.billing.address.address2;
  invitedAccount.bcity = voltAccount.billing.address.city;
  invitedAccount.bcountry = voltAccount.billing.address.country;
  invitedAccount.bstate = voltAccount.billing.address.state;
  invitedAccount.bzip = voltAccount.billing.address.zip;
  invitedAccount.billAttnToFname = voltAccount.billing.contact.firstName;
  invitedAccount.billAttnToLname = voltAccount.billing.contact.lastName;
  invitedAccount.billingEmail = voltAccount.billing.contact.email;
  invitedAccount.bphone = voltAccount.billing.contact.phone;

  invitedAccount.itcontactEmail = voltAccount.it.email;
  invitedAccount.itcontactPhone = voltAccount.it.phone;

  invitedAccount.supportEmail = voltAccount.support.email;
  invitedAccount.supportPhone = voltAccount.support.phone;
  invitedAccount.supportNote = voltAccount.support.note;

  invitedAccount.estimatedUsers = retailerAccount.estimatedNumberOfUsers.toString();
  invitedAccount.uDirectlyEmployed = retailerAccount.usersAreDirectlyEmployed ? '1' : '0';
  invitedAccount.uMobileEnabled = retailerAccount.usersAreMobileEnabled ? '1' : '0';
  invitedAccount.userSpecificEmailsForCommunication = retailerAccount.usersHaveUniqueEmails;
  invitedAccount.provideBadgeWithPhoto = retailerAccount.usersHaveBadges;

  invitedAccount.fieldGroupType = retailerAccount.fieldGroupName;
  invitedAccount.selectedProducts = retailerAccount.productIds?.join(',');
  invitedAccount.selectedCertifications = retailerAccount.courseIds?.join(',');
  invitedAccount.selectedApps = retailerAccount.appIds?.join(',');

  return invitedAccount;
}

export function mapToRetailerAccountInfo(retailerAccount: RegistrationRetailerAccountViewModel) {
  return {
    ...retailerAccount
  } as RetailerAccountViewModel;
}
