import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllowedView } from '@volt/shared/allowed-view';
import { FeatureGuard } from '@volt/shared/directives/features/feature.guard';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';

import { AuthenticatedGuard } from './auth';
import { AuthorizedForModule } from './auth/guards/auth-module.guard';
import { NotFoundComponent } from './common/not-found.component';
import { RedirectComponent } from './dashboard/components/redirect.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { SideNavResolver } from './layouts/admin/admin-sidenav.resolvers';
import { MinimalComponent } from './layouts/minimal/minimal.component';
import { PublicLayoutComponent } from './layouts/public/public-layout.component';
import { AppList, AppListGuard } from '@volt/shared/guards/app-list.guard';
import { RegistrationContainerComponent } from './accounts/components/registration/registration-container.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    component: AdminLayoutComponent,
    resolve: {
      sidenav: SideNavResolver,
    },
    canLoad: [AuthorizedForModule],
    canActivateChild: [AuthenticatedGuard],
    children: [
      {
        path: 'dashboard',
        data: {
          title: 'Dashboard',
          titleKey: translationKey('dashboard'),
          searchKey: translationKey('search.dashboard'),
        },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'edit-account',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.MyAccountManage, Privilege.Read],
          title: 'Edit Account',
          titleKey: translationKey('editAccount'),
          searchKey: translationKey('search.editAccount'),
        },
        loadChildren: () => import('./edit-account/edit-account.module').then(m => m.EditAccountModule),
      },
      {
        path: 'demo',
        data: {
          title: 'Demo / Retailtainment',
          titleKey: translationKey('demoRetailtainment'),
          searchKey: translationKey('search.demoRetailtainment'),
        },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'accounts',
        data: {
          titleKey: translationKey('accounts'),
          title: 'Accounts',
        },
        loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
      },
      {
        path: 'limited-account-overview',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.AccountsLimitedAccountOverview, Privilege.Read],
          title: 'Limited Account Overview',
          titleKey: translationKey('limitedAccountOverview'),
        },
        loadChildren: () =>
          import('./limited-account-overview/limited-account-overview.module').then(
            m => m.LimitedAccountOverviewModule,
          ),
      },
      {
        path: 'locations',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.LocationsManage, Privilege.Read],
          title: 'Locations',
          searchKey: translationKey('search.locations'),
          titleKey: translationKey('locations'),
        },
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
      },
      {
        path: 'locations-watt',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.LocationsManage, Privilege.Read],
          title: 'Locations',
          titleKey: translationKey('locations'),
        },
        loadChildren: () => import('./locations-watt/locations.module').then(m => m.LocationsModule),
      },
      {
        path: 'emergencies',
        canLoad: [PermissionsGuard, FeatureGuard],
        loadChildren: () => import('./emergencies/emergencies.module').then(m => m.EmergenciesModule),
        data: {
          requiredPermission: [PermissionNames.EmergenciesManage, Privilege.Read],
          feature: 'emergencies',
          title: 'Emergencies',
          titleKey: translationKey('emergencies'),
        },
      },
      {
        path: 'activities',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.ActivitiesManage, Privilege.Read],
          title: 'Activities',
          titleKey: translationKey('activities'),
        },
        loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
      },
      {
        path: 'suppliers',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.SuppliersManage, Privilege.Read],
          title: 'Suppliers',
          titleKey: translationKey('suppliers'),
          searchKey: translationKey('search.suppliers'),
        },
        loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule),
      },

      {
        path: 'feature-troubleshooting',
        data: {
          title: 'Feature Troubleshooting',
          titleKey: translationKey('featureTroubleshooting'),
        },
        loadChildren: () =>
          import('./feature-troubleshooting/feature-troubleshooting.module').then(m => m.FeatureTroubleshootingModule),
      },
      {
        path: 'departments',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.DepartmentsManage, Privilege.Read],
          title: 'Departments',
          titleKey: translationKey('departments'),
          searchKey: translationKey('search.departments'),
        },
        loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule),
      },
      {
        path: 'faq-data',
        canLoad: [PermissionsGuard, FeatureGuard],
        data: {
          requiredPermission: [PermissionNames.FaqManage, Privilege.Read],
          feature: 'faq-data',
          title: 'FAQ',
          titleKey: translationKey('faq'),
          searchKey: translationKey('search.faq'),
        },
        loadChildren: () => import('./faq-data/faq-data.module').then(m => m.FaqDataModule),
      },
      {
        path: 'field-notifications',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.FieldNotificationsManage, Privilege.Read],
          title: 'Field Notifications',
          titleKey: translationKey('fieldNotifications'),
          searchKey: translationKey('search.inventoryNotifications'),
        },
        loadChildren: () =>
          import('./inventory-notifications/inventory-notifications.module').then(m => m.InventoryNotificationsModule),
      },
      {
        path: 'vendorlog',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.VendorLogManage, Privilege.Read],
          title: 'Vendor Log',
          titleKey: translationKey('vendorLog'),
          searchKey: translationKey('search.vendorLog'),
        },
        loadChildren: () => import('./vendor-log/vendor-log.module').then(m => m.VendorLogModule),
      },
      {
        path: 'tags',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.TagsManagePage, Privilege.Read],
          title: 'Tags',
          titleKey: translationKey('tags'),
        },
        loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule),
      },
      {
        path: 'assembler-priority',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.AssemblerPriorityManage, Privilege.Read],
          title: 'Assembler Priority',
          titleKey: translationKey('assemblerPriority'),
        },
        loadChildren: () =>
          import('./assembler-priority/assembler-priority.module').then(m => m.AssemblerPriorityModule),
      },
      {
        path: 'pfs-inventory',
        canLoad: [PermissionsGuard, AppListGuard],
        data: {
          requiredPermission: [PermissionNames.PfsInventoryManage, Privilege.Read],
          appId: AppList.PFSUpload,
          title: 'PFS Inventory',
          titleKey: translationKey('pfsInventory'),
          searchKey: translationKey('search.pfsInventory'),
        },
        loadChildren: () => import('./pfs-inventory/pfs-inventory.module').then(m => m.PfsInventoryModule),
      },
      {
        path: 'registration',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.AccountsManage, Privilege.Read],
          title: 'Registration',
          titleKey: translationKey('registration'),
          searchKey: translationKey('search.registration'),
        },
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
      },
      {
        path: 'servicedeployments',
        canLoad: [AuthorizedForModule],
        loadChildren: () =>
          import('./servicedeployments-tasks-locations/servicedeployments-tasks-locations.module').then(
            m => m.ServiceDeploymentsTasksLocationsModule,
          ),
      },
      {
        path: 'users',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.UsersManage, Privilege.Read],
          title: 'Manage Users',
          titleKey: translationKey('manageUsers'),
          searchKey: translationKey('search.users'),
        },
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'badges',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.BadgesManage, Privilege.Read],
          title: 'Badges',
          titleKey: translationKey('badges'),
          searchKey: translationKey('search.badges'),
        },
        loadChildren: () => import('./badges/badges.module').then(m => m.BadgesModule),
      },
      {
        path: 'reports-download',
        loadChildren: () => import('./report-downloads/report-downloads.module').then(m => m.ReportDownloadsModule),
      },
      {
        path: 'reports',
        canLoad: [PermissionsGuard, FeatureGuard],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: {
          requiredPermission: [PermissionNames.ReportsManage],
          feature: 'reports',
          title: 'Reports',
          titleKey: translationKey('reports'),
        },
      },
      {
        path: 'marketing-signage',
        data: {
          title: 'Supplier Signage',
          titleKey: translationKey('supplierSignage'),
        },
        loadChildren: () => import('./marketing-signage/marketing-signage.module').then(m => m.MarketingSignageModule),
      },
      {
        path: 'todo-list',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.TodoListManage, Privilege.Read],
          title: 'Todo List',
          titleKey: translationKey('todoList'),
          searchKey: translationKey('search.todoList'),
        },
        loadChildren: () => import('./todo-list/todo-list.module').then(m => m.TodoListModule),
      },
      {
        path: 'tasks',
        canLoad: [AuthorizedForModule],
        data: {
          requiredView: [AllowedView.Admin],
        },
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule),
      },
      {
        path: 'badge-reprint',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.BadgeReprintManage, Privilege.Read],
          title: 'Badge Reprint',
          titleKey: translationKey('badgeReprint'),
          searchKey: translationKey('search.badgeReprint'),
        },
        loadChildren: () => import('./badge-reprint/badge-reprint.module').then(m => m.BadgeReprintModule),
      },
      {
        path: 'activity-responsibility',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.ActivityResponsibilityManage, Privilege.Read],
          title: 'Activity Responsibility',
          titleKey: translationKey('activityResponsibility'),
          searchKey: translationKey('search.activityResponsibility'),
        },
        loadChildren: () =>
          import('./activity-responsibility/activity-responsibility.module').then(m => m.ActivityResponsibilityModule),
      },
      {
        path: 'invoice-list',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.InvoicesManage, Privilege.Read],
          title: 'Invoices',
          titleKey: translationKey('invoices'),
          searchKey: translationKey('search.invoiceList'),
        },
        loadChildren: () => import('./invoices/invoice.module').then(m => m.InvoiceModule),
      },
      {
        path: 'licenses',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.InvoicesManage, Privilege.Read],
          title: 'Licenses',
          titleKey: translationKey('licenses'),
          searchKey: translationKey('licenses'),
        },
        loadChildren: () => import('./licenses/licenses.module').then(m => m.LicensesModule),
      },
      {
        path: 'roles',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.RolesManage, Privilege.Read],
          title: 'Roles',
          titleKey: translationKey('roles'),
          searchKey: translationKey('search.roles'),
        },
        loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
      },
      {
        path: 'retailerRoleMapping',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.RolesManage, Privilege.Read],
          title: 'Retailer Role Mapping',
          titleKey: translationKey('retailerRoleMappingTitle'),
          searchKey: translationKey('search.roles'),
        },
        loadChildren: () =>
          import('./retailer-role-mapping/retailer-role-mapping.module').then(m => m.RetailerRoleMappingModule),
      },
      {
        path: 'retailerTagMapping',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.RolesManage, Privilege.Read],
          title: 'Retailer Tag Mapping',
          titleKey: translationKey('retailerTagMapping'),
          searchKey: translationKey('search.roles'),
        },
        loadChildren: () =>
          import('./retailer-tag-mapping/retailer-tag-mapping.module').then(m => m.RetailerTagMappingModule),
      },
      {
        path: 'photo-upload',
        canLoad: [PermissionsGuard],
        data: {
          requiredPermission: [PermissionNames.UsersManageBadge, Privilege.Read],
          title: 'Photo Upload',
          titleKey: translationKey('photoUpload'),
          searchKey: translationKey('search.photoUpload'),
        },
        loadChildren: () => import('./photo-upload/photo-upload.module').then(m => m.PhotoUploadModule),
      },
      {
        path: 'certifications',
        canLoad: [PermissionsGuard],
        data: {
          feature: 'certifications',
          requiredPermission: [PermissionNames.CertificationsManage, Privilege.Read],
          title: 'Certifications',
          titleKey: translationKey('certifications'),
          searchKey: translationKey('search.certifications'),
        },
        loadChildren: () => import('./certifications/certifications.module').then(m => m.CertificationsModule),
      },
      {
        path: 'certifications/certification-creation',
        canLoad: [PermissionsGuard],
        data: {
          feature: 'Certification Creation',
          requiredPermission: [PermissionNames.CertificationsManage, Privilege.Create],
          title: 'Certification Creation',
          titleKey: translationKey('certification'),
          searchKey: translationKey('search.certifications'),
        },
        loadChildren: () =>
          import('./certifications/certification-creation/certification-creation.module').then(
            m => m.CertificationCreationModule,
          ),
      },
      {
        path: 'certifications/certification-details/:id',
        canLoad: [PermissionsGuard],
        data: {
          feature: 'Certification Details',
          requiredPermission: [PermissionNames.CertificationsManage, Privilege.Update],
          title: 'Certification Details',
          titleKey: translationKey('certification'),
          searchKey: translationKey('search.certifications'),
        },
        loadChildren: () =>
          import('./certifications/certification-details/certification-details.module').then(
            m => m.CertificationDetailsModule,
          ),
      },
      {
        path: 'support',
        data: {
          feature: 'support',
          title: 'Support',
          titleKey: translationKey('support'),
          searchKey: translationKey('search.support'),
        },
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'location-features',
        data: {
          feature: 'locationfeature',
          title: 'Allow Features',
          titleKey: translationKey('allowFeatures'),
        },
        loadChildren: () => import('./location-feature/location-feature.module').then(m => m.LocationFeatureModule),
      },
      {
        path: 'location-grouping',
        data: {
          feature: 'locationgrouping',
          title: 'Location Grouping',
          titleKey: translationKey('locationGrouping'),
          searchKey: translationKey('search.locationGrouping'),
        },
        loadChildren: () => import('./location-grouping/location-grouping.module').then(m => m.LocationGroupingModule),
      },
      {
        path: 'communications',
        canLoad: [PermissionsGuard],
        data: {
          title: 'Communications',
          titleKey: translationKey('communications'),
          requiredPermission: [PermissionNames.MassCommunicationsManage, Privilege.Read],
        },
        loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule),
      },
      {
        path: 'field-group-type',
        canLoad: [PermissionsGuard],
        data: {
          title: 'Field Group Type',
          titleKey: translationKey('fieldGroupType'),
          searchKey: translationKey('search.fieldGroupType'),
          requiredPermission: [PermissionNames.ReportsFieldGroupTypeSummary, Privilege.Read],
        },
        loadChildren: () => import('./field-group-type/field-group-type.module').then(m => m.FieldGroupTypeModule),
      },
      {
        path: 'diagnostics',
        canLoad: [PermissionsGuard],
        data: {
          title: 'Diagnostics',
          titleKey: translationKey('diagnostics'),
          requiredPermission: [PermissionNames.DiagnosticsManage, Privilege.Read],
        },
        loadChildren: () => import('./diagnostics/diagnostics.module').then(m => m.DiagnosticsModule),
      },
    ],
  },
  {
    path: 'global-filter',
    outlet: 'dialog',
    loadChildren: () => import('./global-filter/global-filter.module').then(m => m.GlobalFilterModule),
  },
  {
    path: 'style-guide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
  },
  {
    path: 'legacy-dashboard',
    component: MinimalComponent,
    canLoad: [AuthorizedForModule],
    canActivateChild: [AuthenticatedGuard],
    children: [
      {
        path: '',
        component: RedirectComponent,
        data: { path: '/' },
      },
    ],
  },
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'eula',
    component: AdminLayoutComponent,
    data: { showSideNav: false },
    loadChildren: () => import('./eula/eula.module').then(m => m.EulaModule),
  },
  {
    path: 'marketing-signage-request',
    loadChildren: () =>
      import('./marketing-signage/marketing-signage-request/msr-standalone/msr-standalone.module').then(
        m => m.MsrStandaloneModule,
      ),
  },
  {
    path: 'marketing-signage-request/:id',
    loadChildren: () =>
      import('./marketing-signage/marketing-signage-request-details/msrd-standalone/msrd-standalone.module').then(
        m => m.MsrdStandaloneModule,
      ),
  },
  {
    path: 'account-registration/:id',
    canLoad: [],
    loadChildren: () =>
      import('./account-registration/account-registration.module').then(m => m.AccountRegistrationModule),
  },
  {
    path: 'retaillink-registration',
    loadChildren: () =>
      import('./retaillink-registration/retaillink-registration.module').then(m => m.RetailLinkRegistrationModule),
  },
  {
    path: 'registration-confirm',
    canLoad: [],
    loadChildren: () =>
      import('./registration-confirm/registration-confirm.module').then(m => m.RegistrationConfirmModule),
  },
  {
    path: 'download-import-log',
    canActivateChild: [AuthenticatedGuard],
    loadChildren: () => import('./download-import-log/download-import-log.module').then(m => m.DownloadImportLogModule),
  },
  {
    path: 'accounts/registration',
    component: RegistrationContainerComponent,
    data: {
      title: 'Registration',
      titleKey: translationKey('registration'),
    },
  },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
