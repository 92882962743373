import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskResultClient } from '../../../../api.client';

@Injectable()
export class ServiceTaskResultsService {
  constructor(private _taskResultClient: TaskResultClient) {}

  public getServiceTaskResult(
    accountId: number,
    serviceDeploymentRefId: string,
    taskRefId: string,
    taskLocationRefId: string,
  ): Observable<any> {
    return this._taskResultClient.retrieveTaskResultsByLocation(
      serviceDeploymentRefId,
      taskRefId,
      taskLocationRefId,
      accountId,
    );
  }
}
