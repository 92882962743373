import { Input, OnInit, Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { AddServiceTask } from '../../../../models/addServiceTask';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
@Component({
  selector: 'activity-type',
  templateUrl: './activity.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})
export class ActivityModelComponent implements OnInit {
  @Input() public activeModel;
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  public selectedActivity;

  @Input() set setActivityName(currentServiceTask: AddServiceTask) {
    if (currentServiceTask.activity !== null) {
      this.selectedActivity = currentServiceTask.activity;
    }
  }

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Choose the Activity that describes your Task. The dropdown list is limited to the Activities that your field group is allowed to use.',
        ],
      },
    });
  }

  public get activeTypeControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('activeType');
  }

  public test(p) {
    console.log(p.value);
  }
}
