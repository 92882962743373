import startCase from 'lodash-es/startCase';

export class EnumUtils {
  static find<T = any>(enumToSearch: T, value: string, sensitivity: string = 'base'): any {
    for (const key in enumToSearch) {
      if (0 === key.localeCompare(value, 'en', { sensitivity })) {
        return enumToSearch[key];
      }
    }
    return value;
  }

  static nameOf<T = any>(enumToSearch: T, value: string, isProperCase = false): string {
    const name = Object.keys(enumToSearch).find(enumKey => enumToSearch[enumKey] === value) || '';

    if (isProperCase) {
      return startCase(name);
    }

    return name;
  }
}
