import { Routes } from '@angular/router';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';

import { AccountsListComponent } from './components/account/account-list/account-list.component';
import { ViewAccountComponent } from './components/account/viewaccount.component';
import { InvitedSupplierComponent } from './components/invitedsupplier/invitedsupplier.component';

export const registrationsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'Invite-Supplier',
        component: InvitedSupplierComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Invite Suppliers',
          requiredPermission: [PermissionNames.AccountsManageInviteSupplier, Privilege.Read],
        },
      },
      {
        path: 'account-list',
        component: AccountsListComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Accounts',
          titleKey: translationKey('accounts'),
          requiredPermission: [PermissionNames.AccountsManage, Privilege.Read],
          searchKey: translationKey('search.accounts'),
        },
      },
      {
        path: 'account-list/:selectedTab',
        component: AccountsListComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Accounts',
          titleKey: translationKey('accounts'),
          requiredPermission: [PermissionNames.AccountsManage, Privilege.Read],
          searchKey: translationKey('search.accounts'),
        },
      },
      {
        path: 'view-account/:id/:type',
        component: ViewAccountComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'view-account',
          requiredPermission: [PermissionNames.AccountsManageMoreDetails, Privilege.Read],
        },
      },
    ],
  },
];
