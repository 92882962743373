import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from 'src/configuration';
import { Translations } from '@volt/shared/services/translations.types';
import { TranslationService } from '@volt/shared/services/translation.service';
import { AlertNotificationClient } from '@volt/api';

@Component({
  selector: 'alert-icon',
  templateUrl: './alert-icon.component.html',
  styleUrls: ['./alert-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertIconComponent implements OnInit, OnDestroy {
  private _hub: HubConnection;

  alerts$ = new BehaviorSubject<AlertNotification[]>([]);
  alertRollups$ = this.alerts$.pipe(
    map(alerts => {
      // Group by title and subtitle and include the group count, sorting by title then subtitle

      const sorted = alerts.sort((a, b) => {
        if (a.title < b.title || (a.title === b.title && a.subtitle < b.subtitle)) {
          return -1;
        }

        if (a.title > b.title || (a.title === b.title && a.subtitle > b.subtitle)) {
          return 1;
        }

        return 0;
      });

      const aggregated = sorted.reduce((accumulator, alert) => {
        const existing = accumulator.find(
          a => a.title === alert.title && a.subtitle === alert.subtitle && a.link === alert.link,
        );

        if (existing) {
          existing.count++;
        } else {
          accumulator.push({
            title: alert.title,
            subtitle: alert.subtitle,
            link: alert.link,
            count: 1,
          });
        }

        return accumulator;
      }, []);

      return aggregated;
    }),
  );

  @ViewChild(OverlayPanel) op: OverlayPanel;

  translations$: Observable<Translations>;

  constructor(
    private readonly _authService: AuthService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
    private readonly _translationService: TranslationService,
    private readonly _alertNotificationClient: AlertNotificationClient
  ) {}

  ngOnInit(): void {
    this.translations$ = this._translationService.getTranslatedObject();
    this._hub = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.alertNotificationUrl}/hubs/alert-notifications`, {
        // We've been having issues with the server responding with a 404 during the negotiation phase,
        // even though we have sticky sessions turned on, so skipping the entire negotiation process
        // and forcing web sockets
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,

        accessTokenFactory: () => {
          return this._authService.accessToken;
        },
      })
      .withAutomaticReconnect()
      .build();

    this._hub.on('alertAdded', alert => this.alertAdded(alert));
    this._hub.on('alertRemoved', alert => this.alertRemoved(alert));
    this._hub.start();
  }

  ngOnDestroy(): void {
    this._hub.stop();
  }

  clicked($event: any) {
    this.op.toggle($event);
  }

  private alertAdded(alert: AlertNotification) {
    this.alertRemoved(alert);
    const nextAlerts = [...this.alerts$.value, alert];
    this.alerts$.next(nextAlerts);
  }

  private alertRemoved(removedAlert: AlertNotification) {
    const nextAlerts = [
      ...this.alerts$.value.filter(
        a =>
          a.title !== removedAlert.title ||
          a.subtitle !== removedAlert.subtitle ||
          a.identifier !== removedAlert.identifier,
      ),
    ];
    this.alerts$.next(nextAlerts);
  }

  alertClicked(e, alertRollup) {
    if(alertRollup.link == null) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  deleteAlert(e, alertRollup) {
    e.preventDefault()
    e.stopPropagation()

    const alertsToRemove = this.alerts$.value.filter(
      a =>
        a.title === alertRollup.title
        && a.subtitle === alertRollup.subtitle
        && a.link === alertRollup.link
    );

    for(const alert of alertsToRemove) {
      this.alertRemoved(alert);

      this._alertNotificationClient.deleteAlertNotification(alert.id)
        .subscribe();
    }
  }
}

interface AlertNotification {
  id: string;
  title: string;
  subtitle: string;
  link: string;
  identifier: any;
}
