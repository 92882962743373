import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, Type } from '@angular/core';
import isObject from 'lodash-es/isObject';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomDynamicDialogConfig } from './custom-dynamic-dialog-config';
import { CustomDynamicDialogRef } from './custom-dynamic-dialog-ref';
import { DynamicConfirmDialogBaseComponent } from './dynamic-confirm-dialog-base.component';
import { DynamicDialogBaseComponent } from './dynamic-dialog-base.component';
import { DynamicDialogContainerComponent } from './dynamic-dialog-container.component';
import { GenericErrorDialogComponent } from './generic-error-dialog.component';

@Injectable()
export class DynamicDialogService {
  private readonly defaultDialogConfig: CustomDynamicDialogConfig;

  constructor(
    private readonly _dialogService: DialogService,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {
    this.defaultDialogConfig = new CustomDynamicDialogConfig();
    this.defaultDialogConfig.overlayConfig = new OverlayConfig({
      disposeOnNavigation: true,
      hasBackdrop: true,
      panelClass: 'volt-dialog-panel',
      backdropClass: 'volt-dialog-backdrop',
      scrollStrategy: overlay.scrollStrategies.block(),
      positionStrategy: overlay.position().global().centerVertically().centerHorizontally(),
    });
  }

  open<TReturnType = any, T extends DynamicDialogBaseComponent = DynamicDialogBaseComponent>(
    component: Type<T>,
    config?: DynamicDialogConfig,
  ): Observable<TReturnType> {
    const _ref = this._dialogService.open(component, {
      ...config,
      data: config ? (isObject(config.data) ? { ...config.data } : config.data) : null,
      closable: config && config.closable != null ? config.closable : true,
      showHeader: false,
      styleClass: config && config.styleClass ? config.styleClass + ' no-pad' : 'no-pad',
    });

    return _ref.onClose as Observable<TReturnType>;
  }

  confirm<T extends DynamicConfirmDialogBaseComponent>(
    component: Type<T>,
    config?: DynamicDialogConfig,
  ): Observable<boolean> {
    return this.open<boolean, T>(component, config).pipe(map(Boolean), take(1));
  }

  showGenericError(error?: any): Observable<null> {
    return this.open(GenericErrorDialogComponent, {
      data: { error: error.error || error.message || error.toString() },
    });
  }

  openCdk<TReturnType = any, TContentComponent = any>(
    component: Type<TContentComponent>,
    config?: Partial<CustomDynamicDialogConfig>,
  ): CustomDynamicDialogRef<TReturnType, TContentComponent> {
    const mergeConfig = this.getMergeConfig(config);
    const overlayRef = this.overlay.create(mergeConfig.overlayConfig);
    const cdkDialogRef = new CustomDynamicDialogRef<TReturnType, TContentComponent>(overlayRef);
    cdkDialogRef.componentInstance = this.attachDialogContainer(overlayRef, component, cdkDialogRef, mergeConfig);
    return cdkDialogRef;
  }

  private getMergeConfig(config: Partial<CustomDynamicDialogConfig>): CustomDynamicDialogConfig {
    if (config == null) {
      return this.defaultDialogConfig;
    }

    return {
      ...this.defaultDialogConfig,
      ...config,
      overlayConfig: { ...this.defaultDialogConfig.overlayConfig, ...(config?.overlayConfig || {}) },
    };
  }

  private attachDialogContainer<TContentComponent = any>(
    overlayRef: OverlayRef,
    component: Type<TContentComponent>,
    dialogRef: CustomDynamicDialogRef,
    dialogConfig: CustomDynamicDialogConfig,
  ) {
    const injector = this.createInjector(dialogRef, dialogConfig);
    const portal = new ComponentPortal(DynamicDialogContainerComponent, null, injector);
    const containerRef = overlayRef.attach(portal);
    containerRef.instance.contentComponentType = component;
    return containerRef.instance;
  }

  private createInjector(dialogRef: CustomDynamicDialogRef, dialogConfig: CustomDynamicDialogConfig): PortalInjector {
    const injectionTokenMap = new WeakMap();
    injectionTokenMap.set(CustomDynamicDialogRef, dialogRef);
    injectionTokenMap.set(CustomDynamicDialogConfig, dialogConfig);
    return new PortalInjector(this.injector, injectionTokenMap);
  }
}
