
<div style="display: flex; flex-direction: column; gap: 2rem">
    <div style="display: grid; grid-template-columns: 3fr 2fr; gap: 1rem">
        <div style="display: flex; flex-direction: column; gap: 1rem;">
            <div class="w-100">
                <input-label [label]="translations.accountName"></input-label>
                <div class="p-inputgroup w-100">
                    <span class="p-inputgroup-addon"><i class="fa fa-university"></i></span>
                    <input type="text" pInputText [placeholder]="translations.name" [formControl]="controls.name">
                </div>
            </div>

            <div class="w-100">
                <input-label [label]="translations.abbreviation"></input-label>
                <div class="p-inputgroup w-100">
                    <span class="p-inputgroup-addon"><i class="fa fa-signature"></i></span>
                    <input type="text" pInputText [placeholder]="translations.abbreviation" [formControl]="controls.abbreviation">
                </div>
            </div>

            <div class="w-100">
                <label>{{translations.websiteUrl}}</label>
                <div class="p-inputgroup w-100">
                    <span class="p-inputgroup-addon">www</span>
                    <input type="text" pInputText [placeholder]="translations.url" [formControl]="controls.websiteUrl">
                </div>
            </div>

            <div>
                <input-label [label]="translations.note" [required]="false" [tooltip]="translations['accounts.registration.note.tooltip']"></input-label>
                <textarea #note pInputTextarea [formControl]="controls.note" class="w-100" [maxLength]="400"></textarea>
                <small *ngIf="form.enabled">{{note.textLength}} / {{note.maxLength}}</small>
            </div>
        </div>

        <div>
            <account-logo [translations]="translations" [control]="controls.imageUrl" [disabled]="form.disabled"></account-logo>
        </div>
    </div>

    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div>
                <strong><i class="fa fa-map-marked"></i> {{translations.address}}</strong>
            </div>
        </ng-template>

        <account-address #addressComponent [translations]="translations" [address]="_account?.address" [disabled]="form.disabled"></account-address>
    </p-panel>

    <p-panel [toggleable]="true" >
        <ng-template pTemplate="header">
            <div>
                <strong><i class="fa fa-truck"></i> {{translations.shipping}}</strong>
            </div>
        </ng-template>

        <account-shipping #shippingComponent
                          [translations]="translations"
                          [shipping]="_account?.shipping"
                          [companyAddress]="addressComponent.value"
                          [personalContact]="personalContact"
                          [disabled]="form.disabled"
        ></account-shipping>
    </p-panel>

    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div>
                <strong><i class="fa fa-file-invoice"></i> {{translations.billing}}</strong>
            </div>
        </ng-template>
        <account-billing
          #billingComponent
          [translations]="translations"
          [billing]="_account?.billing"
          [companyAddress]="addressComponent.value"
          [personalContact]="personalContact"
          [disabled]="form.disabled"
        ></account-billing>
    </p-panel>

    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div>
                <strong><i class="fa fa-question-square"></i> {{translations.support}}</strong>
            </div>
        </ng-template>

        <account-support #supportComponent [translations]="translations" [support]="_account?.support" [disabled]="form.disabled"></account-support>
    </p-panel>

    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div>
                <strong><i class="fa fa-desktop"></i> IT</strong>
            </div>
        </ng-template>

        <account-it #itComponent [translations]="translations" [it]="_account?.it" [disabled]="form.disabled"></account-it>
    </p-panel>
</div>
