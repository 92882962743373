import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  FileParameter,
  FileResponse,
  PersonClient,
  PhotoClient,
} from '../../api.client';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  private _shouldCropperReload$: Subject<null> = new Subject<null>();

  constructor(private readonly _personClient: PersonClient, private readonly _photoClient: PhotoClient) {}

  get shouldCropperReload$(): Observable<null> {
    return this._shouldCropperReload$.asObservable();
  }

  reloadCropper(): void {
    this._shouldCropperReload$.next();
  }

  public createAwaitingPdfs(emails: any[]): Observable<any> {
    return this._personClient.generate(emails);
  }

  public removeBackgroundFromImage(imageFile: FileParameter): Observable<FileResponse> {
    return this._photoClient.removeBadgeBackgroundFromImage(imageFile);
  }
}
