import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import { ActivityTaskSummaryModel, LimitedResultOfActivityModel, UserInformation } from "@volt/api";
import { SharedTableState } from "@volt/shared/components/table/models/shared-table-state";
import { PC, PermissionCollection } from "@volt/shared/services/permissions.service";
import { CommonUtils, isEquivalent } from "@volt/shared/utils/common.utils";
import * as _ from "lodash";
import { EMPTY, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, shareReplay, switchMap, tap } from "rxjs/operators";
import { AuthService } from "../../../auth";
import { RoleConstant } from "../../../auth/shared/roles.constants";
import { ActivityAggregateFilterParams } from "../../models/activity-aggregate-filter-params";
import { ActivityFilterParams } from "../../models/activity-filter-params";
import { ActivityService } from "../../services/activity.service";

@Component({
  selector: 'activity',
  templateUrl: './activity.component.html',
})
export class ActivityComponent implements OnInit {
  activityFilters = new ActivityFilterParams();
  aggregateFilters = new ActivityAggregateFilterParams();
  currentUser: UserInformation;
  canAssignActivities: boolean = false;
  currentTab = 0;

  activityData$: Observable<LimitedResultOfActivityModel>;
  aggregateData$: Observable<ActivityTaskSummaryModel[]>;

  isTableLoading$: Observable<boolean>;
  isAggregateLoading$: Observable<boolean>;

  private _tableState = new SharedTableState('activityName', true, 0, 20, null);

  constructor(
    private _activityService: ActivityService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    @Inject(PC) public pc: PermissionCollection,
  ) {}

  ngOnInit() {
    this.isTableLoading$ = this._activityService.isLoading$;
    this.isAggregateLoading$ = this._activityService.isAggregateLoading$;

    this._activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.currentTab = parseInt(paramMap.get('tabIndex') || '0', 10);
    });

    this.activityData$ = this._activatedRoute.queryParams.pipe(
      debounceTime(750),
      filter(() => (this._activatedRoute.snapshot.paramMap.get('tabIndex') || '0') === '0'),
      distinctUntilChanged(isEquivalent),
      tap((queryParams: Params) => {
        this.activityFilters = ActivityFilterParams.fromParams(queryParams);
        this._tableState = SharedTableState.fromParams(queryParams);
      }),
      switchMap(() => {
        if (_.isEmpty(this._activatedRoute.snapshot.queryParams)) {
          this._updateQueryParams();
          return EMPTY;
        }
        return this._activityService.getActivities(this._tableState, this.activityFilters).pipe(
          tap(limitedResult => {
            const newTableState = SharedTableState.fromParams(limitedResult);
            newTableState.sortAscending = limitedResult.sortAscending === true;
            this._tableState = newTableState;
          }),
        );
      }),
    );
    
    this.aggregateData$ = this._activatedRoute.queryParams.pipe(
      debounceTime(750),
      filter(() => (['1', '2'].indexOf(this._activatedRoute.snapshot.paramMap.get('tabIndex'))) > -1),
      distinctUntilChanged(isEquivalent),
      switchMap(() => this._activityService.getAggregateSummary(this.aggregateFilters)),
      shareReplay(1)
    )

    this.canAssignActivities = this._authService
      .getCurrentUser()
      .isInRole(RoleConstant.SystemAdministrator, RoleConstant.RetailerAdmin, RoleConstant.RetailerCalendarAdmin);
  }

  onTabChanged(tabIndex: number) {
    const queryParams = this._currentTabParams(tabIndex);
    this._router.navigate([{ tabIndex }], { relativeTo: this._activatedRoute, queryParams });
  }

  onTableStateChange(state: SharedTableState) {
    this._tableState = state;
    this._updateQueryParams();
  }

  onActivityFiltersChanged(filters: ActivityFilterParams) {
    this.activityFilters = filters;
    this._updateQueryParams();
  }

  onAggregateFiltersChanged(filters: ActivityAggregateFilterParams) {
    this.aggregateFilters = ActivityAggregateFilterParams.fromParams(filters);
    this._updateQueryParams();
  }

  private _updateQueryParams() {
    const tabIndex = parseInt(this._activatedRoute.snapshot.paramMap.get('tabIndex') || '0', 10);
    this._router.navigate(['./'], {
      queryParams: this._currentTabParams(tabIndex),
      relativeTo: this._activatedRoute,
    });
  }
  
  private _currentTabParams(tabIndex: number): Params {
    switch (tabIndex) {
      case 1:
        return CommonUtils.toQueryParams({ ...this.aggregateFilters });
      case 2:
        return CommonUtils.toQueryParams({ ...this.aggregateFilters });
      default:
        const params = CommonUtils.toQueryParams({
          ...this._tableState,
          ...this.activityFilters,
        });
        delete params.totalCount;
        return params;
    }
  }
}
