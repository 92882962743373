import { Routes } from '@angular/router';
import { ActivityComponent } from './components/activity/activity.component';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';

export const activityRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'data',
        component: ActivityComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Activities',
          titleKey: translationKey('activities'),
          searchKey: translationKey('search.activities'),
          requiredPermission: [PermissionNames.ActivitiesManage, Privilege.Read],
        },
      },
      {
        path: 'associate-activities',
        data: {
          feature: 'associateactivities',
          title: 'Associate Activities',
          titleKey: translationKey('associateActivities'),
        },
        loadChildren: () =>
          import('../associate-activities/associate-activities.module').then(m => m.AssociateActivitiesModule),
      },
    ],
  },
];
