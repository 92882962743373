import { TaskLocationInput } from '../../api.client';

export class AddTaskLocation extends TaskLocationInput {
  public accountId: number;
  public add: boolean;
  public edit: boolean;
  public id: string;
  constructor() {
    super();
    this.accountId = null;
    this.add = true;
    this.edit = false;
    this.referenceNumber = null;
    this.number = null;
    this.start = null;
    this.end = null;
    this.status = null;
    this.employeeNumbers = null;
    this.id = null;
  }
}
