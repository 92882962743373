import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { isEqual, zip } from 'lodash-es';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiCancelService {
  private _currentUrl: ActivatedRouteSnapshot | null;

  private $apiCancel = new Subject();
  apiCancel$ = this.$apiCancel.asObservable();

  cancelPendingRequests() {
    this._currentUrl = null;
    this.$apiCancel.next();
  }

  setCurrentUrl(snapshot: ActivatedRouteSnapshot) {
    this._currentUrl = snapshot;
  }

  hasCurrentUrl() {
    return !!this._currentUrl;
  }

  isOnCurrentUrl(snapshot: ActivatedRouteSnapshot): boolean {
    // Are all of the URLs segments deeply equal on every snapshot from the root to the leaf?
    return zip(snapshot.pathFromRoot, this._currentUrl?.pathFromRoot).every(([s, c]) => isEqual(s?.url, c?.url));
  }
}
