import { debounce, delay, distinctUntilChanged, map, scan, share, takeUntil } from 'rxjs/operators';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Constructable } from '../types';

const request$ = new ReplaySubject<{ request: XMLHttpRequest; done: boolean; url: string }>(1);

/**
 * Wrap XMLHttpRequest.send to create an application wide request stream.
 */
// tslint:disable-next-line:no-shadowed-variable
(function(open, send, request$) {
  XMLHttpRequest.prototype.open = function(...args: any[]) {
    this.___url = args.length >= 2 ? args[1] : null;
    open.apply(this, args);
  };

  XMLHttpRequest.prototype.send = function(data) {
    request$.next({ request: this, done: false, url: <string>(<any>this.___url) });
    const listener = this.addEventListener('readystatechange', (ev: ProgressEvent) => {
      if (this.readyState === 4) {
        request$.next({ request: this, done: true, url: <string>(<any>this.___url) });
        this.removeEventListener('readystatechange', listener);
      }
    });
    send.call(this, data);
  };
})(XMLHttpRequest.prototype.open, XMLHttpRequest.prototype.send, request$);

/**
 *  Loading stream
 *
 *  Emits true when there's a request pending, false otherwise.
 */
const loading$: Observable<boolean> = request$.pipe(
  // tap(request => console.log(request.url)),
  map(xhr => !xhr.done),
  scan((pendingCount: number, isNewRequest: boolean) => (isNewRequest ? ++pendingCount : --pendingCount), 0),
  map(pending => pending > 0),
  distinctUntilChanged(),
  debounce(pending => {
    if (pending) return of(pending);

    return of(pending).pipe(delay(300));
  }),
  share(),
);

// start tracking when file is loaded
loading$.subscribe();

type FunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? K : never }[keyof T];

@Injectable()
export class RequestService implements OnDestroy {
  disposed$ = new Subject();

  get isLoading$(): Observable<boolean> {
    return loading$.pipe(
      takeUntil(this.disposed$),
      delay(0), // fix detection change
    );
  }

  isFetching<T>(client: Constructable<T>, methodName: FunctionPropertyNames<T>): Observable<boolean> {
    const proto = <any>client.prototype;
    return of(false);
  }

  ngOnDestroy(): void {
    this.disposed$.next();
  }
}
