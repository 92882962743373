import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { BlobService } from '@volt/shared/services/blob.service';
import {map, tap} from 'rxjs/operators';
import {APPLICATION_CONFIGURATION, ApplicationConfiguration} from "../../../../configuration";

@Pipe({
  name: 'secureUrl',
})
export class SecureUrlPipe implements PipeTransform {
  constructor(
    private readonly _blobService: BlobService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration
  ) {}

  transform(url: string): Observable<string> {
    if (!this._blobService.referencesVoltBlob(url)) {
      return of(url);
    }

    const blobPath = `${this._appConfig.blobUrl}/`
    if(url.startsWith(blobPath)) {
      url = url.replace(blobPath, "")
    }

    if (this._blobService.isSecured(url)) {
      const container = url.match(/[a-zA-Z1-9]*/)[0]
      const regex = new RegExp(container + "/\\$path")
      return this._blobService.getContainerSasTokenWithRefresh(container)
      .pipe(
        map((result) => result.replace(regex, url)),
        tap(finalResult => console.log('SecureUrl.transform end',finalResult))
      )
    }

    url = this._blobService.getFullUrl(url);
    console.log('SecureUrl insecure url', url);
    return of(url);
  }
}
