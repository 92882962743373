import { InjectionToken } from '@angular/core';

export interface ApplicationFeature {
  name: string;
  enabled: boolean;
  features?: ApplicationFeature[];
}

export interface IdentityConfiguration {
  issuer: string;
  resourceServer: string;
  clientId: string;
}

export interface ApplicationConfiguration {
  apiUrl: string;
  chatBotUrl: string;
  originUrl: string;
  blobUrl: string;
  legacyDashboard: string;
  ssoUrl: string;
  appIosUrl: string;
  appAndroidUrl: string;
  features: ApplicationFeature[];
  appInsights: {
    instrumentationKey: string | null;
  };
  recaptchaSiteKey: string;
  importLogApiUrl: string;
  featureAvailabilityApiUrl: string;
  blobApiUrl: string;
  accountsApiUrl: string;
  featureApiBaseUrl: string;
  locationApiBaseUrl: string;
  lmsApiUrl: string;
  alertNotificationUrl: string;
  privacyPolicyUrl: string;
  tenantId: string;
}

export const APPLICATION_CONFIGURATION = new InjectionToken<ApplicationConfiguration>('@@voltApplicationConfiguration');
