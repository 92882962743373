<div class="container">
    <v-container-wrapper [headerKey]="'voltRegistration'" [withToast]="true">
        <ng-template vContainerTemplate="content" let-translated="translated">
            <div *ngIf="invitation$ | async as invitation">
                <div *ngIf="!(submitted$ | async)">
                    <div>
                        <p-steps
                          #stepper
                          [model]="steps"
                          [readonly]="true"
                          [activeIndex]="currentStep"
                          styleClass="mb-4"
                        ></p-steps>

                        <div [class.d-none]="stepper.activeIndex != 0">
                            <personal-information
                              #personalInformationComponent
                              [translations]="translated"
                              [contact]="invitation.contact"
                              [personalizedMessage]="invitation.personalizedMessage"
                            ></personal-information>

                            <div style="margin-top: 1rem">
                                <stepper-controls
                                        [translations]="translated"
                                        [canGoBack]="false"
                                        [canGoNext]="true"
                                        [nextDisabled]="personalInformationComponent.invalid"
                                        (next)="nextStep()"
                                ></stepper-controls>
                            </div>
                        </div>

                        <div [class.d-none]="stepper.activeIndex != 1">
                            <volt-account-info
                              #voltAccountInfoComponent
                              [disabled]="false"
                              [account]="invitation.voltAccount"
                              [personalContact]="personalInformationComponent.value"
                              [translations]="translated"
                              (inited)="setupValidators(voltAccountInfoComponent)"
                            ></volt-account-info>

                            <div style="margin-top: 1rem">
                                <stepper-controls
                                        [translations]="translated"
                                        [canGoBack]="true"
                                        [canGoNext]="true"
                                        [nextDisabled]="voltAccountInfoComponent.invalid"
                                        (previous)="previousStep()"
                                        (next)="nextStep()"
                                ></stepper-controls>
                            </div>
                        </div>
                        <div [class.d-none]="stepper.activeIndex != 2">
                            <registration-retailer-account-info
                                    #registrationRetailerAccountInfoComponent
                                [translations]="translated"
                                [account]="invitation.retailerAccount"
                            ></registration-retailer-account-info>

                            <div style="margin-top: 1rem">
                                <stepper-controls
                                        [translations]="translated"
                                        [canGoBack]="true"
                                        [canGoNext]="true"
                                        [nextDisabled]="registrationRetailerAccountInfoComponent.invalid"
                                        (previous)="previousStep()"
                                        (next)="nextStep()"
                                ></stepper-controls>
                            </div>
                        </div>

                        <div *ngIf="stepper.activeIndex == 3">

                            <div style="max-width: 800px; margin: auto">
                                <div [class.d-none]="submitting$ | async">
                                    <license-agreement
                                      #licenseAgreementComponent
                                      [translations]="translated"
                                    ></license-agreement>
                                </div>

                                <div *ngIf="submitting$ | async">
                                    <account-submitting></account-submitting>
                                </div>
                            </div>

                            <div style="margin-top: 1rem" *ngIf="!(submitting$ | async)">
                                <stepper-controls
                                        [translations]="translated"
                                        [canGoBack]="true"
                                        [canGoNext]="false"
                                        [nextDisabled]="licenseAgreementComponent.invalid"
                                        (previous)="previousStep()"
                                        (next)="submit(voltAccountInfoComponent.value, registrationRetailerAccountInfoComponent.value, personalInformationComponent.value, invitation.invitedAccount)"
                                        [nextLabel]="translated.submit"
                                        [nextButtonIcon]="'pi pi-check'"
                                        [nextButtonStyleClass]="'p-button-success'"
                                ></stepper-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="submitted$ | async">
                <account-submitted [translations]="translated"></account-submitted>
            </div>
        </ng-template>
    </v-container-wrapper>
</div>
