import { Component, OnInit, Input } from '@angular/core';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: [],
})
export class RegistrationModalComponent extends DynamicDialogBaseComponent implements OnInit {
  message: string;

  constructor(
    public readonly _dialogReference: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    private _router: Router,
  ) {
    super(_dialogReference, _dialogConfig);
  }

  ngOnInit() {
    this._router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => this._dialogReference.close(false));

    this.message = this._dialogConfig.data.message;
  }

  onClose(): void {
    this._dialogReference.close();
  }
}
