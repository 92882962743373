import { of as observableOf, Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, NgRedux } from '@angular-redux/store';
import { DropDownModel, UserInformation, Log } from '../../../api.client';
import { ServiceDeploymentsService } from '../servicedeployments/services/service-deployments.service';
import { IAppState } from '../../../store/appstate';
import { AddServiceDeploymentStateConstant } from '../../actions/servicedeployment.constants';
import { RoleConstant } from '../../../auth/shared/roles.constants';
import { AddServiceDeployment } from '../../models/addServiceDeployment';
import { catchError } from 'rxjs/operators';
import { DataPassingService } from '../../services/data-passing.service';
import { Status } from '../../../shared/constants/status.constant';
import {
  IServiceDeploymentLogStatus,
  ViewLogStatusModalComponent,
} from '../view-log-status-modal/view-log-status-modal.component';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { AuthService } from '../../../auth';
import { RetailerConfigType, RETAILER_CONFIG } from 'src/retailer-config';
import { FieldsInfoModalComponent } from '../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'add-service-deployment',
  templateUrl: './addservicedeployments.component.html',
  styleUrls: ['./addservicedeployments.component.scss'],
})
export class AddServiceDeploymentsComponent implements OnInit, OnDestroy {
  canCreate = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);
  canEdit = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Update);
  public addServiceDeploymentsGroup: UntypedFormGroup;
  public errorMessage: string;
  public error: string = null;
  public fieldTypeDropModel: DropDownModel[];
  public statusDropDownModel: DropDownModel[];
  public serviceDepRefNumber: string;
  public accountNumber: string;
  public loading = false;
  public role: string;
  public currentUser: UserInformation;
  public currentAddedServiceDeployment: AddServiceDeployment;
  public accountId: any;
  public submitted = false;
  public serviceDepRefNumberExist = false;
  public invalidControl: string[] = [];
  public minDate: Date;
  public showEndDate: boolean;
  public checkFieldGroupRole: boolean;
  public selectedStatus: string;
  public isProvider: boolean;
  public serviceDeploymentDateMsg = false;

  private addServiceDeployemntSubscription: ISubscription;

  @select([AddServiceDeploymentStateConstant.FilterStateName])
  private readonly addServiceDeploymentData: Observable<AddServiceDeployment>;
  isEdit: boolean;

  constructor(
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _appState: NgRedux<IAppState>,
    private _serviceDeploymentService: ServiceDeploymentsService,
    private _dataPassingService: DataPassingService,
    private readonly permissionsService: PermissionsService,
    private readonly _authService: AuthService,
    private _dynamicDialogService: DynamicDialogService,
    @Inject(RETAILER_CONFIG) public readonly retailerConfig: RetailerConfigType,
  ) {}

  ngOnDestroy(): void {
    this.addServiceDeployemntSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currentUser = this._authService.getCurrentUser();
    this.role = this.currentUser.roles;

    this.addServiceDeployemntSubscription = this.addServiceDeploymentData.subscribe(
      (data: AddServiceDeployment) => {
        this.isEdit = data.edit === true;
        this.currentAddedServiceDeployment = data;

        if (!this.currentAddedServiceDeployment.banner) {
          this.currentAddedServiceDeployment.banner = this._authService.getCurrentUser().banners[0];
        }

        if (this.currentAddedServiceDeployment.status == null) {
          this.selectedStatus = Status.Active.toString();
        } else {
          this.selectedStatus = Status[this.capitalizeFirstLetter(this.currentAddedServiceDeployment.status)];
        }
      },
      (err: any) => {
        console.error(err);
      },
    );

    this.initForm();
    this.getAccountDropDownList();
    this.getStatusDropDownList();
  }

  public capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public submitServiceDeployments() {
    const checkResponse = this.checkControlValidation();

    if (!checkResponse) {
      this.submitted = true;
      return;
    }

    this.serviceDepRefNumber = this.addServiceDeploymentsGroup.get('serviceDepRefNumber').value.trim();

    const serviceDeployment = Object.assign({}, this.currentAddedServiceDeployment, {
      accountId: this.addServiceDeploymentsGroup.get('fieldGroupType').value,

      name: this.addServiceDeploymentsGroup.get('serviceDeploymentName').value.trim(),

      referenceNumber: this.addServiceDeploymentsGroup.get('serviceDepRefNumber').value.trim(),

      serviceStart: this.addServiceDeploymentsGroup.get('serviceStartDate').value,

      serviceEnd: this.addServiceDeploymentsGroup.get('serviceEndDate').value,

      note:
        this.addServiceDeploymentsGroup.get('serviceNote').value == null
          ? null
          : this.addServiceDeploymentsGroup.get('serviceNote').value.trim(),

      visitOverview:
        this.addServiceDeploymentsGroup.get('serviceVistOverview').value == null
          ? null
          : this.addServiceDeploymentsGroup.get('serviceVistOverview').value.trim(),

      status: this.addServiceDeploymentsGroup.get('serviceStatus').value,

      retailer: this.retailerConfig.retailer,

      serviceDeploymentTaskCount: 0,
    });

    if (
      new Date(this.addServiceDeploymentsGroup.get('serviceEndDate').value) <
      new Date(this.addServiceDeploymentsGroup.get('serviceStartDate').value)
    ) {
      this.serviceDeploymentDateMsg = true;
      const checkDate = this.checkControlValidation();
      if (!checkDate) {
        return;
      }
    }

    this.loading = true;

    const request = this.isEdit
      ? this._serviceDeploymentService.updateServiceDeployment(serviceDeployment, serviceDeployment.accountId)
      : this._serviceDeploymentService.createServiceDeployment(serviceDeployment, serviceDeployment.accountId);
    request
      .pipe(
        catchError(err => {
          return observableOf(err);
        }),
      )
      .subscribe((response: Log) => {
        this.loading = false;
        this._dynamicDialogService.open(ViewLogStatusModalComponent, {
          data: {
            status: 'Pending',
            serviceDeploymentStatusName: 'Service Deployment Status',
            serviceDeploymentReferenceNumber: 'Service Deployment Reference #',
            transactionId: response.transactionId,
            accountId: this.currentAddedServiceDeployment.accountId,
            referenceNumber: this.serviceDepRefNumber,
            showImportTaskLocation: false,
          } as IServiceDeploymentLogStatus,
        });
      });
  }

  public getAccountDropDownList() {
    this._serviceDeploymentService.getAccountDropList().subscribe(
      (data: any) => {
        this.fieldTypeDropModel = data;
        if (this.currentUser.roles === RoleConstant.ProviderAdmin) {
          this.currentAddedServiceDeployment.accountId = this.currentUser.accountId;
          this.isProvider = true;
        }
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public getStatusDropDownList() {
    this._serviceDeploymentService.getStatusList().subscribe(
      (data: any) => {
        this.statusDropDownModel = data;

        if (this.currentAddedServiceDeployment.status == null) {
          this.selectedStatus = Status.Active.toString();
        } else {
          this.selectedStatus = '' + Status[this.capitalizeFirstLetter(this.currentAddedServiceDeployment.status)];
        }
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public checkServiceRefNumberAlreadyExist(flag: boolean) {
    this.serviceDepRefNumberExist = flag;
  }

  public setEndDate(minDate: Date) {
    this.minDate = minDate;
    this.showEndDate = false;
    this._dataPassingService.passServiceDeploymentEndDate(this.showEndDate);
  }

  public setEndDateMsg(event: any) {
    this.serviceDeploymentDateMsg = false;
  }

  public checkControlValidation() {
    this.invalidControl = [];
    this.checkValidationForAddServiceDeployment();

    if (this.invalidControl.length > 0) {
      return false;
    }

    return true;
  }

  public checkValidationForAddServiceDeployment() {
    if (this.addServiceDeploymentsGroup.invalid) {
      this.invalidControl.push('Invalid');
    } else if (this.serviceDepRefNumberExist === true) {
      this.invalidControl.push('Service Deployment Reference Number Already Exist');
    } else if (this.serviceDeploymentDateMsg === true) {
      this.invalidControl.push('End date cannot be less than start date');
    }
  }

  public formReset() {
    this.addServiceDeploymentsGroup.reset();
    this._router.navigate(['/servicedeployments/data']);
  }

  public showBannerInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: { messages: ['The banner the Service Deployment will be associated to.'] },
    });
  }

  private initForm() {
    this.addServiceDeploymentsGroup = this._fb.group({
      fieldGroupType: this._fb.control(this.currentAddedServiceDeployment.accountId, Validators.required),
      serviceDeploymentName: this._fb.control(this.currentAddedServiceDeployment.name, Validators.required),
      serviceDepRefNumber: this._fb.control(
        this.currentAddedServiceDeployment.referenceNumber,
        Validators.compose([Validators.required, Validators.pattern(/^[\w\.\-\:]*$/)]),
      ),
      serviceStartDate: this._fb.control(
        this.currentAddedServiceDeployment.serviceStart
          ? new Date(this.currentAddedServiceDeployment.serviceStart.toDate())
          : null,
        Validators.required,
      ),
      serviceEndDate: this._fb.control(
        this.currentAddedServiceDeployment.serviceEnd
          ? new Date(this.currentAddedServiceDeployment.serviceEnd.toDate())
          : null,
        Validators.required,
      ),
      serviceNote: this._fb.control(this.currentAddedServiceDeployment.note),
      serviceVistOverview: this._fb.control(this.currentAddedServiceDeployment.visitOverview),
      serviceStatus: this._fb.control('3'),
    });

    if (this.currentAddedServiceDeployment) {
      if (this.currentAddedServiceDeployment.edit && !this.canEdit) {
        CommonUtils.disableControls(this.addServiceDeploymentsGroup.controls);
      } else if (this.currentAddedServiceDeployment.add && !this.canCreate) {
        this._router.navigate(['../data'], {
          relativeTo: this._route,
        });
      }
    }
  }
}
