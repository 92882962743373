import { Component, OnInit } from '@angular/core';
import { AccountViewService } from '../../../services/accountview.service';
import { PersonListViewModel } from '../../../../api.client';

@Component({
  selector: 'non-field-roles-list',
  templateUrl: './non-field-roles-list.component.html',
})
export class FieldRolesListComponent implements OnInit {
  public data: PersonListViewModel[] = [];
  cols: any[];
  public totalRecords: number;

  constructor(private _accountViewService: AccountViewService) {}

  ngOnInit(): void {}

  getNonFieldRolesListById(id: any) {
    this.getColumns();

    this._accountViewService.getNonFieldRolesListById(id).subscribe(response => {
      if (response) {
        this.data = response;
        this.totalRecords = this.data.length;
      }
    });
  }

  getColumns() {
    this.cols = [];

    this.cols = [
      { field: 'number', header: 'Emp#', width: '150px' },
      { field: 'name', header: 'User Name', width: '200px' },
      { field: 'email', header: 'Email', width: '350px' },
      { field: 'roleName', header: 'Role', width: '200px' },
      { field: 'badge', header: 'Badge' , width: '150px'},
      { field: 'trainingStatus', header: 'Training', width: '100px' },
    ];
  }
}
