import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['../modal.scss'],
})
export class ConfirmModalComponent extends DynamicDialogBaseComponent implements OnInit {
  message: string;
  header: string;
  acceptLabel: string;
  rejectLabel: string;
  accept: any;
  reject: any;
  showFooter: boolean;

  constructor(
    public readonly _dialogReference: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    private _router: Router,
  ) {
    super(_dialogReference, _dialogConfig);

    this.message = this._dialogConfig.data.message;
    this.header = this._dialogConfig.data.header;
    this.acceptLabel = this._dialogConfig.data.acceptLabel ? this._dialogConfig.data.acceptLabel : 'Yes';
    this.rejectLabel = this._dialogConfig.data.rejectLabel ? this._dialogConfig.data.rejectLabel : 'No';
    this.accept = this._dialogConfig.data.accept;
    this.reject = this._dialogConfig.data.reject;
    this.showFooter = this._dialogConfig.data.showFooter ? this._dialogConfig.data.showFooter : true;
  }

  ngOnInit() {
    this._router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => this._dialogReference.close(false));
  }

  public onSubmit() {
    if (typeof this.accept === 'function') {
      this.accept();
    }
    this._dialogReference.close();
  }

  public onClose() {
    if (typeof this.reject === 'function') {
      this.reject();
    }
    this._dialogReference.close();
  }
}
