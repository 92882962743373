import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  ServicedeploymentTaskLocationSearchParameters
} from '../../../models/servicedeployment-task-location-search-parameters';
import { ServiceDeploymentsService } from '../../servicedeployments/services/service-deployments.service';
import { TableDisplay } from '../../table-display/table-display.component';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { FileResponse, GenericTableReportOfServiceDeploymentTaskLocationViewModel } from '../../../../api.client';
import { ServicedeploymentTaskLocationService } from '../services/servicedeployment-task-location.service';
import { LazyLoadEvent } from 'primeng/api';
import { SubscriptionLike as ISubscription, Subject, combineLatest } from 'rxjs';
import { DataPassingService } from '../../../services/data-passing.service';
import { CommonUtils } from '../../../../shared/utils/common.utils';

import { ServiceTaskLocationRetrievalClient } from '../../../../api.client';
import { AddTaskLocation } from '../../../models/addTaskLocation';
import { AddServicedeploymentTaskLocationReduxService } from '../../addservicedeployments-task-location/services/add-servicedeploymenttasklocation.reudx.service';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import * as moment from 'moment';

@Component({
  selector: 'servicedeployment-task-location',
  templateUrl: 'servicedeployment-task-location.component.html',
  styleUrls: ['./servicedeployment-task-location.component.scss'],
})
export class ServicedeploymentTaskLocationComponent extends TableDisplay implements OnInit, OnDestroy {
  public serviceDeploymentRefId: string;
  public taskRefId: string;
  public isLocationComponent: true;
  public serviceDeploymentRefNumber: string;
  public serviceDeploymentTaskRefNumber: string;
  public fieldGroupName: string;
  public serviceDeploymentName: string;
  public serviceTaskName: string;
  public statusType: string;
  public accountId: number;
  public showDateFilter: boolean;
  private routeSubscription: ISubscription;
  private dataSubscription: ISubscription;
  private serviceDeploymentTaskRefNumberParamsSubscription: ISubscription;
  public currentaddedServiceDeploymentTaskLocation: AddTaskLocation;
  public status;
  public minDate: Date;
  public showEndDate: boolean;

  canCreate = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);
  canEdit = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Update);

  private readonly _serviceDeploymentFilterParams$ = new Subject<ServiceDeploymentReportParams>();

  private readonly _onDestroy$ = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _serviceDeploymentTaskLocationService: ServicedeploymentTaskLocationService,
    private _dataPassingService: DataPassingService,
    private _router: Router,
    private _serviceDeploymentTaskLocationClient: ServiceTaskLocationRetrievalClient,
    private _taskLocationReduxService: AddServicedeploymentTaskLocationReduxService,
    private _serviceDeploymentsService: ServiceDeploymentsService,
    private readonly permissionsService: PermissionsService,
  ) {
    super(_serviceDeploymentsService);
  }

  ngOnInit() {
    this.searchBy = 'Search By Store Number';
    this.showDateFilter = true;
    this.isLocationComponent = true;

    this._serviceDeploymentFilterParams$.pipe(
      takeUntil(this._onDestroy$),
      tap(() => this.loading = true),
      switchMap((filterParams) => this._serviceDeploymentTaskLocationService.getServiceDeploymentTaskLocation(filterParams)),
      finalize(() => this.loading = false)
    ).subscribe((taskLocations) => {
      this.cols = taskLocations.columnList;
      this.data = taskLocations.results;
      this.totalRecords = taskLocations.totalCount;
      this.loading = false;
    });

    combineLatest([
      this._route.queryParamMap,
      this._route.paramMap.pipe(filter(pm => pm.has('refid') && pm.has('taskId')))
    ]).subscribe(([queryParams, params]: [ParamMap, ParamMap]) => {
      // init page data
      this.serviceDeploymentRefNumber = queryParams.get('serviceDeploymentRefNumber');
      this.serviceDeploymentTaskRefNumber = queryParams.get('taskRefNumber');
      this.fieldGroupName = queryParams.get('fieldGroupName');
      this.serviceDeploymentName = queryParams.get('serviceDeploymentName');
      this.serviceTaskName = queryParams.get('taskName');
      this.statusType = queryParams.get('status');
      this.accountId = +queryParams.get('accountId');
      this.serviceDeploymentRefId = params.get('refid');
      this.taskRefId = params.get('taskId');

      // init filtering based on params and queryParams
      const filters = new ServiceDeploymentReportParams();
      filters.serviceDeploymentRefId = this.serviceDeploymentRefId;
      filters.serviceDeploymentTaskRefId = this.taskRefId;

      // trigger data fetch
      this.serviceDeploymentFilterParams = filters;
      this._serviceDeploymentFilterParams$.next(filters);
    });

  }

  public refresh() {
    this.serviceDeploymentFilterParams.sortColumn = '-start';
    this.pullData(this.serviceDeploymentFilterParams);
  }

  pullData($event: ServiceDeploymentReportParams) {
    this.loading = true;
    this.dataSubscription = this._serviceDeploymentTaskLocationService
      .getServiceDeploymentTaskLocation($event)
      .subscribe((data: GenericTableReportOfServiceDeploymentTaskLocationViewModel) => {
        this.cols = data.columnList;
        this.data = data.results;
        this.totalRecords = data.totalCount;
        this.loading = false;
      });
  }

  sortdata(event: LazyLoadEvent) {
    this.serviceDeploymentFilterParams.sortColumn = `${event.sortOrder === -1 ? '-' : ''}${event.sortField ?? 'start'}`;
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.dataSubscription && this.dataSubscription.unsubscribe();
    this.serviceDeploymentTaskRefNumberParamsSubscription &&
      this.serviceDeploymentTaskRefNumberParamsSubscription.unsubscribe();
  }

  public searchStartDate(startDate: Date) {
    this.minDate = moment(startDate).startOf('day').toDate();
    this.showEndDate = true;

    this._dataPassingService.passServiceDeploymentEndDate(this.showEndDate);

    this.serviceDeploymentFilterParams.serviceDeploymnetTaskLocationSearchParameters.serviceStartDate = moment(startDate).startOf('day');
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  public searchEndDate(endDate: Date) {
    this.serviceDeploymentFilterParams.serviceDeploymnetTaskLocationSearchParameters.serviceEndDate = moment(endDate).endOf('day');
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  public searchByNumber(storeNumber: string) {
    storeNumber = storeNumber.trim();

    this.serviceDeploymentFilterParams.serviceDeploymnetTaskLocationSearchParameters.numberSearch = storeNumber;
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  clearFilter() {
    this.serviceDeploymentFilterParams.serviceDeploymnetTaskLocationSearchParameters = new ServicedeploymentTaskLocationSearchParameters();
    this.serviceDeploymentFilterParams.sortColumn = '-start';
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  onDownLoad() {
    this.loading = true;
    this._serviceDeploymentTaskLocationClient
      .getFile(this.serviceDeploymentRefId, this.taskRefId)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((file: FileResponse) => {
        const date = moment().utc().format('YYYY_MM_DD_HH_mm');
        const fileName = `TaskLocations_${date}_UTC.csv`;
        CommonUtils.download(file.data, fileName);
        this.loading = false;
      });
  }

  public addServiceDeploymentTaskLocation() {
    this.currentaddedServiceDeploymentTaskLocation = new AddTaskLocation();
    this.currentaddedServiceDeploymentTaskLocation.edit = false;
    this._taskLocationReduxService.setFilter(this.currentaddedServiceDeploymentTaskLocation);
    this._router.navigate([
      '/servicedeployments',
      'addservicedeploymenttasklocation',
      this.serviceDeploymentRefId,
      this.taskRefId,
    ], {queryParamsHandling: 'preserve'});
  }

  public editTaskLocation(data: AddTaskLocation) {
    data.edit = true;
    this._taskLocationReduxService.setFilter(data);
    this._router.navigate([
      '/servicedeployments',
      'addservicedeploymenttasklocation',
      this.serviceDeploymentRefId,
      this.taskRefId,
    ], {queryParamsHandling: 'preserve'});
  }

  public importTaskLocation() {
    this._router.navigate(['/servicedeployments', 'importtasklocation', this.serviceDeploymentRefId, this.taskRefId], {queryParamsHandling: 'preserve'});
  }
}
