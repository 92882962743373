export interface IDateDropDown {
  label: string;
  value: number;
}

export class DateDropDown implements IDateDropDown {
  public static defaultRange: DateDropDown[] = [{ label: 'Last 7d', value: 7 }, { label: 'Last 30d', value: 30 }];

  label: string;
  value: number;

  constructor() {
    this.label = '';
    this.value = 0;
  }
}
