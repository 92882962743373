export class ArrayUtils {
  static isConsecutive(items: number[]): { min: number; max: number; isConsecutive: boolean } {
    const min = Math.min(...items);
    const max = Math.max(...items);
    return { min, max, isConsecutive: max - min + 1 === items.length };
  }

  static ascending<T = any>(first: T, second: T): number {
    if (typeof first === 'number' || typeof second === 'number') {
      return ((first as unknown) as number) - ((second as unknown) as number);
    }

    return 1;
  }

  static distinct<T = any>(data: any[], selector: (a: T) => any): any[] {
    const existing: any = {};

    if (data.length <= 1) return data;

    for (const d of data) {
      const key = selector(d);
      if (!existing[key]) {
        existing[key] = d;
      }
    }
    return Object.values(existing);
  }
}
