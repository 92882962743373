import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FileParameter, ProductInput, ProductModel } from '../../../api.client';
import { ICustomFile } from 'file-input-accessor';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataPassingService } from '../services/data-passing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductClient } from '../../../api.client';
import { MonitoringService } from '../../../shared/services/monitoring.service';

@Component({
  selector: 'products-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss'],
})
export class ProductAddComponent implements OnInit, OnDestroy {
  @Input() public products: ProductModel[] = [];
  public file: ICustomFile[] = [];
  public product: ProductModel;
  public fileCtrl = this._fb.control(null);
  public _addProductFormGroup: UntypedFormGroup;
  public productId: string = null;
  public partNumberAlreadyExist: boolean;
  public loading = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _dataPassingService: DataPassingService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _productClient: ProductClient,
    private readonly monitoringService: MonitoringService,
  ) {}

  ngOnInit() {
    this._addProductFormGroup = this._fb.group({
      partNumber: this._fb.control('', Validators.required),
      partName: this._fb.control('', Validators.required),
      category: this._fb.control('', Validators.required),
      status: this._fb.control(false),
      image: this.fileCtrl,
    });

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.productId = this.activatedRoute.snapshot.params.id;
      this.loading = true;
      this._productClient.getProduct(this.productId).subscribe(
        (data: ProductModel) => {
          this.product = data;
          this.populateForm(this.product);
        },
        (err: any) => {
          console.error(err);
          this.monitoringService.logApiException(err);
        },
      );
    }

    this.fileCtrl.valueChanges.subscribe(val => {
      this.file = val ? val : [];
    });
  }

  private populateForm(data: ProductModel) {
    this._addProductFormGroup.patchValue({
      partNumber: data.itemNumber,
      partName: data.name,
      category: data.category,
      status: true,
    });
    this.loading = false;
  }

  public cancel() {
    if (this.productId == null) {
      this._router.navigate(['../management'], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this._router.navigate(['../../management'], {
        relativeTo: this.activatedRoute,
      });
    }
  }
  public processProduct(): void {
    this.loading = true;
    const product = new ProductInput();
    product.itemNumber = this._addProductFormGroup.get('partNumber').value.trim();
    product.name = this._addProductFormGroup.get('partName').value.trim();
    product.category = this._addProductFormGroup.get('category').value.trim();
    product.isActive = this._addProductFormGroup.get('status').value;

    this._productClient.addProduct(product).subscribe(
      (data: string) => {
        const productId = data;
        if (this.file.length) {
          const file: FileParameter = {
            data: this.file[0],
            fileName: this.file[0].name,
          };
          this._productClient.addProductImage(productId, file).subscribe(
            () => {
              this.loading = false;
              this.navigateToProductList();
            },
            (err: any) => {
              this.loading = false;
              console.error(err);
              this.monitoringService.logApiException(err);
            },
          );
        } else {
          this.loading = false;
          this.navigateToProductList();
        }
      },
      (err: any) => {
        this.loading = false;
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  private navigateToProductList() {
    if (this.productId == null) {
      this._router.navigate(['../management'], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this._router.navigate(['../../management'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  public checkForExistingPartNumber(partNumber: string) {
    if (partNumber === '') return;
    partNumber = partNumber.trim();
    this.loading = true;
    this._productClient.validatePartNumber(partNumber).subscribe(
      data => {
        this.loading = false;
        this.partNumberAlreadyExist = data;
        if (this.partNumberAlreadyExist) {
          this._addProductFormGroup.setErrors({ invalid: true });
        }
      },
      (err: any) => {
        this.loading = false;
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  ngOnDestroy(): void {}
}
