import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxPowerBiService } from 'ngx-powerbi';
import { IEmbedConfiguration } from 'embed';
import { IFilterCriteria } from './filter-criteria.interface';
import { FilterType, Permissions, ViewMode, LayoutType } from 'powerbi-models';
import { models, Report } from 'powerbi-client';
import { PowerBiToken } from '@volt/api';

@Component({
  selector: 'powerbi-report',
  templateUrl: './powerbi-report.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PowerBiReportComponent implements OnInit, AfterViewInit {
  @Input()
  public reportId: string = null;

  @Input()
  public groupId: string = null;

  @Input()
  public title: string = null;

  @Input()
  public iconClass: string = null;

  @Input()
  public filterCriteria: IFilterCriteria = null;

  @Input()
  public filterPaneEnabled = true;

  private accessToken: string = null;
  private readonly powerBiService = new NgxPowerBiService();

  constructor(private readonly route: ActivatedRoute) {}

  public get isValidConfiguration(): boolean {
    return !!(this.reportId && this.accessToken);
  }

  public ngOnInit() {
    this.getRouteData();
  }

  public ngAfterViewInit() {
    if (this.isValidConfiguration) {
      const elem = <HTMLElement>document.getElementById('pbi-container');
      const embedConfig = this.getEmbedConfig();
      this.powerBiService.embed(elem, embedConfig);
    }
  }

  private getRouteData() {
    const routeData = this.route.snapshot.data;

    // allow values to be passed in via input parameters or as route data
    this.title = this.title || routeData.title;
    this.iconClass = this.iconClass || routeData.iconClass;
    this.reportId = this.reportId || routeData.reportId;
    this.groupId = this.groupId || routeData.groupId;
    this.filterCriteria = this.filterCriteria || routeData.filterCriteria;

    const powerBiCredentials = routeData.token as PowerBiToken;

    this.accessToken = powerBiCredentials ? powerBiCredentials.access_token : null;
  }

  private getEmbedConfig(): IEmbedConfiguration {
    const embedUrl = this.getEmbedUrl();
    const filters = this.buildFilter(this.filterCriteria);
    const settings = {
      navContentPaneEnabled: true,
      filterPaneEnabled: this.filterPaneEnabled,
    };

    return {
      embedUrl: embedUrl,
      uniqueId: undefined,
      type: 'report',
      accessToken: this.accessToken,
      tokenType: undefined,
      groupId: undefined,
      id: undefined,
      settings: settings,
      pageName: undefined,
      filters: filters,
      pageView: 'fitToWidth',
      datasetId: undefined,
      permissions: Permissions.Read,
      viewMode: ViewMode.View,
      action: undefined,
      dashboardId: undefined,
      height: undefined,
      width: undefined,
    };
  }

  private getEmbedUrl(): string {
    let url = null;

    if (this.reportId) {
      url = 'https://app.powerbi.com/reportEmbed?reportId=' + this.reportId;

      if (this.groupId) {
        url += '&groupId=' + this.groupId;
      }
    }

    return url;
  }

  private buildFilter(criteria: IFilterCriteria): models.IFilter[] {
    let filters: models.IFilter[];

    if (criteria && criteria.table && criteria.column && criteria.values) {
      filters = [
        {
          filterType: FilterType.Basic,
          $schema: 'http://powerbi.com/product/schema#basic',
          operator: 'In',
          values: criteria.values,
          target: { table: criteria.table, column: criteria.column },
        } as models.IBasicFilter,
      ];
    }

    return filters;
  }
}
