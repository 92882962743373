<div>
  <button (click)="close()" type="button" class="btn-close closer text-end" aria-label="Close"></button>
  <p class="title">Alerts</p>
  <hr />

  <div class="row">
    <div class="col-12">
      <ul class="list-unstyled">
        <li>
          <div class="alert alert-success" role="alert">
            This is a success alert with
            <div class="text-end small">a moment ago</div>
          </div>
        </li>
        <li>
          <div class="alert alert-danger" role="alert">
            This is a danger alert with
            <div class="text-end small">10 minutes ago</div>
          </div>
        </li>
        <li>
          <div class="alert alert-warning" role="alert">
            This is a warning alert with
            <div class="text-end small">12/11/2017 2:34PM</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
