import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'task-reference-number',
  templateUrl: './task-reference-number.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})

// tslint:disable-next-line:component-class-suffix
export class TaskReferenceNumber implements OnInit {
  @Output() private referenceNumberEntered: EventEmitter<string> = new EventEmitter<string>();
  @Input() public group: UntypedFormGroup;
  @Input() public serviceDepRefNumberExist: boolean;
  @Input() public submitted;
  @Input() public isEdit;

  constructor(private _dynamicDialogService: DynamicDialogService) {}
  ngOnInit(): void {}

  public get taskReferenceNumberControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('taskReferenceNumber');
  }

  public checkServiceRefNumberAlreadyExist(event: string) {
    this.referenceNumberEntered.emit(event);
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          "Technical Reference Number that the field group uses to identify this Task in the field group's system.",
          'Must be unique for each new Task. No spaces.',
        ],
      },
    });
  }
}
