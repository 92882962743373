import { Injectable } from '@angular/core';
import {
  AccountsClient,
  ProductClient,
  AppLicensingClient,
  AccountAppLinkModel,
  AppClient,
  TagClient,
  AppModel,
} from "@volt/api";
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountViewService {
  activityList: string[];
  private getAppLinkAccountId = new Subject<any>();
  public getAppLinksAccountId = this.getAppLinkAccountId.asObservable();
  private getInvitedANote = new Subject<any>();
  public getInvitedAccountANote = this.getInvitedANote.asObservable();

  constructor(
    private _accountsClient: AccountsClient,
    private _productClient: ProductClient,
    private _appLicensingClient: AppLicensingClient,
    private _appClient: AppClient,
    private _tagClient: TagClient,
  ) {}

  public getAccountsListByStatusId(id: number, sortField: string, page: number, pageSize: number): Observable<any> {
    return this._accountsClient.getAccountsListByStatusId(id, sortField, page, pageSize);
  }

  public getAccountsListBySearchFilter(
    statusId: number,
    filterText: string,
    sortField: string,
    page: number,
    pageSize: number,
  ): Observable<any> {
    return this._accountsClient.getAccountsListBySearchFilter(statusId, filterText, sortField, page, pageSize);
  }

  public getAccountById(id: string): Observable<any> {
    return this._accountsClient.getAccountById(id);
  }

  public getProductList(): Observable<any> {
    return this._productClient.getProductList();
  }

  public getAppList(): Observable<AppModel[]> {
    return this._appClient.getAppList().pipe(
      map(apps => {
        let dependentApps: AppModel[] = [];

        // Grab all dependent apps
        // Some apps rely on other apps to be selected
        for (const app of apps) {
          if (app.parentAppIds?.length > 0 && !dependentApps.includes(app)) {
            dependentApps.push(app);

            for (const parentAppId of app.parentAppIds) {
              const parentApp = apps.find(app => app.appId == parentAppId);
              if (!dependentApps.includes(parentApp)) {
                dependentApps.push(parentApp);
              }
            }
          }
        }

        // Rudimentary sort the dependent apps so least dependent or parent apps are on top and most dependent apps are on bottom
        dependentApps = dependentApps.sort((l, r) => l.parentAppIds?.length || 0 - r.parentAppIds?.length || 0);

        const independentApps = apps.filter(app => !dependentApps.includes(app));

        return [...independentApps, ...dependentApps];
      }),
    );
  }

  public getTagList() {
    return this._tagClient.getTagList();
  }

  public getNonFieldRolesListById(id: string): Observable<any> {
    return this._accountsClient.getNonFieldRolesList(id);
  }

  public getInvitedAccountsANote(data: any) {
    this.getInvitedANote.next(data);
  }

  public getAccountAppLinkListByAccountId(id: string): Observable<any> {
    return this._accountsClient.getAccountAppLinkListByAccountId(id);
  }

  public getAccountAppLinkListById(id: number): Observable<any> {
    return this._accountsClient.getAccountAppLinkListById(id);
  }

  public getLinkTypeDropDownList(): Observable<any> {
    return this._accountsClient.getLinkTypeList();
  }

  public saveAccountAppLink(params: AccountAppLinkModel): Observable<any> {
    return this._accountsClient.saveAccountAppLink(params);
  }

  public getAppsLinkAccountId(data: any) {
    this.getAppLinkAccountId.next(data);
  }

  public deleteAccountAppLinkById(id: number): Observable<any> {
    return this._accountsClient.deleteAccountAppLinkById(id);
  }
}
