import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['../../../assets/auth/auth.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpiredLinkComponent {
  emailOrMobileValue: string;

  constructor(route: ActivatedRoute) {
    route.queryParams.subscribe(params => {
      this.emailOrMobileValue = params['emailOrMobile'];
    });
  }
}
