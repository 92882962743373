import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';

@Component({
  selector: 'show-required-modal',
  templateUrl: './show-required-modal.component.html',
  styleUrls: ['./show-required-modal.component.scss'],
})
export class RequiredModalComponent extends DynamicDialogBaseComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public requiredfield: any;

  constructor(
    public _dialogReference: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _router: Router) {
      super(_dialogReference, _dialogConfig)
      this.requiredfield = this._dialogConfig.data.requiredfield
    }

  ngOnInit() {
    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this._dialogReference.close(false));
  }
  onClose(): void {
      return this._dialogReference.close(false);
  }
}

