<div id="container">
    <div>
        <input-label [label]="translations.address"></input-label>
        <input autocomplete="address-line1" pInputText [formControl]="controls.address1" class="w-100">
    </div>

    <div>
        <label>{{translations.address2}}</label>
        <input autocomplete="address-line2" pInputText [formControl]="controls.address2" class="w-100">
    </div>

    <account-country [translations]="translations" [control]="controls.country"></account-country>

    <div>
        <input-label [label]="translations.city"></input-label>
        <input pInputText [formControl]="controls.city" class="w-100">
    </div>

    <account-state [translations]="translations" [control]="controls.state" [selectedCountry]="controls.country.valueChanges | async"></account-state>
    <account-zip [translations]="translations" [control]="controls.zip" [selectedCountry]="controls.country.valueChanges | async"></account-zip>
</div>
