import { ActiveFieldEmployeeCount } from '@volt/api';
import { SwaggerTypeKey } from '../shared/types';

export class UserFilterParameters {
  constructor(public filterText: string = '', public page: number = 1, public pageSize: number = 20) {
    this.accountId = 0;
    this.rolesId = 0;
    this.includeInactive = false;
    this.certificationStatus = '';
    this.approvalStatusId = undefined;
    this.quickFilter = undefined;
    this.isAwaitingBadge = undefined;
    this.personTags = [];
    this.isDormant = false;
    this.isNotCertified = false;
    this.fieldGroupType = [];
  }

  active?: boolean;
  accountId: number;
  rolesId: number;
  includeInactive: boolean;
  sortCol: string;
  isAscending: boolean;
  approvalStatusId?: number;
  certificationStatus?: string;
  quickFilter?: SwaggerTypeKey<ActiveFieldEmployeeCount>;
  isAwaitingBadge?: boolean;
  personTags?: string[];
  isDormant?: boolean;
  isNotCertified?: boolean;
  fieldGroupType: string[];
}
