export const environment = {
  envName: 'qa',
  production: true,
  mapsApiKey: 'AIzaSyC21CfGGdO5O-YgfjKiOSBLIr3vQPLVTug',
  uploadsSecureContainer: 'uploadssecure',
  uploadsContainer: 'uploads',
  isDevelopment: false,
  eoc: {
    taskLocationTemplate: 'templates/import_task_location_template.csv',
    emergencyTemplate: 'templates/emergency-upload-template.csv',
    productTemplate: 'templates/products-upload-template.csv',
  },
  activityResponsibility: {
    activityResponsibilityTemplate: 'templates/activity-responsibilities.csv',
  },
  assembler: {
    assemblerTemplate: 'templates/assembler.csv',
  },
  inventoryNotifications: {
    inventoryNotificationTemplate: 'templates/inventory-notifications.csv',
  },
  locations: {
    allowedFeatures: 'templates/location-allowed-features.csv',
  },
};
