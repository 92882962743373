import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'v-dynamic-dialog-base',
  template: `
    <p-panel [styleClass]="dialogStyleClass" [toggleable]="false">
      <ng-template pTemplate='header'>
        <div class="d-flex align-items-center justify-content-between">
          <span>{{ headerText || ' ' }}</span>
          <i class="fa fa-close clickable" (click)="onClose()" *ngIf="closable"></i>
        </div>
      </ng-template>
      <ng-content select="dynamicDialogContent"></ng-content>
      <ng-template pTemplate='footer'>
        <ng-content select="dynamicDialogFooter"></ng-content>
      </ng-template>
    </p-panel>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDialogBaseComponent {
  /**
   * Style class of the Dialog
   */
  @Input() dialogStyleClass: string;
  /**
   * Header Text
   */
  @Input() headerText: string;

  /**
   * Dialog Close event
   */
  @Output() dialogClose: EventEmitter<null> = new EventEmitter<null>();
  public closable: boolean;

  constructor(private _dynamicDialogBaseRef: DynamicDialogRef, private _dynamicDialogBaseConfig: DynamicDialogConfig) {
    this.closable = _dynamicDialogBaseConfig.closable;
  }

  onClose() {
    this._dynamicDialogBaseRef.close();
    this.dialogClose && this.dialogClose.emit();
  }
}
