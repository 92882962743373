import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ServiceDeploymentsService } from '../servicedeployments/services/service-deployments.service';
import { ServicedeploymentTaskLocationService } from '../locations/services/servicedeployment-task-location.service';
import { filter, takeWhile, switchMap } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';

export interface IServiceDeploymentLogStatus {
  status: string;
  transactionId: string;
  accountId: number;
  referenceNumber: string;
  serviceDeploymentRefId: string;
  taskRefId: string;
  serviceDeploymentStatusName: string;
  serviceDeploymentReferenceNumber: string;
  showImportTaskLocation: boolean;
}

@Component({
  selector: 'view-log-status-modal',
  templateUrl: './view-log-status-modal.component.html',
  styleUrls: ['../../../shared/components/modal/modal.scss'],
})
export class ViewLogStatusModalComponent extends DynamicDialogBaseComponent implements OnInit, OnDestroy {
  logData: IServiceDeploymentLogStatus = {} as IServiceDeploymentLogStatus;

  private stop: boolean;
  private interval = 5000;
  private transactionSubscription: Subscription;
  public showReferenceNumber = true;
  public loading = false;
  public data: any;
  public rejectReason: string;

  constructor(
    public readonly _dialogReference: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    private _serviceDeploymentService: ServiceDeploymentsService,
    private _serviceTaskLocationService: ServicedeploymentTaskLocationService,
    private _router: Router,
  ) {
    super(_dialogReference, _dialogConfig);
  }

  ngOnInit() {
    this.logData = this._dialogConfig.data as IServiceDeploymentLogStatus;

    this._router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => this._dialogReference.close(false));

    this.stop = true;

    this.transactionSubscription = timer(0, this.interval)
      .pipe(
        takeWhile(() => this.stop),
        switchMap(() =>
          this._serviceDeploymentService.getServiceDeploymentLog(this.logData.accountId, this.logData.transactionId),
        ),
      )
      .subscribe(
        response => {
          if (response) {
            this.data = response;

            if (response.statusSummary.success > 0) {
              this.loading = false;
              this.logData.status = 'Success';
              if (this.logData.showImportTaskLocation) {
                this.showReferenceNumber = false;
              }
              this.stop = false;
            } else if (response.statusSummary.failed > 0) {
              this.loading = false;
              this.logData.status = 'Failed';
              this.retrieveServiceDeploymentDetails();
              this.stop = false;
            } else this.loading = response.statusSummary.pending > 0;
          } else {
            this.loading = false;
            this.stop = false;
          }
        },
        err => {
          this.loading = false;
          this.stop = false;
          console.error(err);
        },
      );
  }

  retrieveServiceDeploymentDetails() {
    this.data = null;
    this.loading = true;

    this._serviceDeploymentService
      .getServiceDeploymentDetails(this.logData.transactionId, this.logData.accountId, this.logData.referenceNumber)
      .subscribe(
        response => {
          if (response) {
            this.data = response;
            this.loading = false;
            this.logData.status = 'Failed';
            const rejectResult = response.results;
            if (rejectResult.length > 0) {
              let rejectMessage = '';

              for (let i = 0; i < rejectResult.length; i++) {
                if (rejectMessage === '') {
                  rejectMessage = rejectResult[i].referenceNumber + '-' + rejectResult[i].message;
                } else {
                  rejectMessage = rejectMessage + '|' + rejectResult[i].referenceNumber + '-' + rejectResult[i].message;
                }
              }
              if (!this.logData.showImportTaskLocation) {
                this.rejectReason = rejectMessage;
              } else {
                this.showReferenceNumber = false;
                this.rejectReason = 'Some records failed to update. Please check your mail for further details';
                this.sendEmailFailedImportTaskLocation(rejectMessage);
              }
            }
          } else {
            this.loading = false;
          }
        },
        err => {
          console.error(err);
        },
      );
  }

  public sendEmailFailedImportTaskLocation(rejectReason: string) {
    this._serviceTaskLocationService.sendEmailFailedImportTaskLocation(rejectReason).subscribe(
      response => {
        if (response) {
          console.log(response);
        }
      },
      err => {
        console.error(err);
      },
    );
  }

  public formReset() {
    if (
      this.logData.serviceDeploymentRefId !== '' &&
      this.logData.serviceDeploymentRefId !== null &&
      this.logData.serviceDeploymentRefId !== undefined &&
      this.logData.taskRefId !== '' &&
      this.logData.taskRefId !== null &&
      this.logData.taskRefId !== undefined
    ) {
      this._router.navigate([
        '/servicedeployments',
        this.logData.serviceDeploymentRefId,
        'task',
        this.logData.taskRefId,
      ], {queryParamsHandling: 'preserve'});
    } else if (
      this.logData.serviceDeploymentRefId !== '' &&
      this.logData.serviceDeploymentRefId !== null &&
      this.logData.serviceDeploymentRefId !== undefined
    ) {
      this._router.navigate(['/servicedeployments', this.logData.serviceDeploymentRefId], {queryParamsHandling: 'preserve'});
    } else {
      this._router.navigate(['/servicedeployments/data']);
    }
  }

  ngOnDestroy(): void {
    this.transactionSubscription.unsubscribe();
  }
}
