import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { AccountContactComponent } from '../../../components/volt-account-info/components/account-contact/account-contact.component';
import { Contact } from '../../../../models/volt-account';

@Component({
  selector: 'personal-information',
  templateUrl: './personal-information.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalInformationComponent implements OnInit {
  @Input() translations: Translations;
  @Input() contact: Contact;
  @Input() personalizedMessage: string;

  @ViewChild(AccountContactComponent) accountContactComponent: AccountContactComponent;

  constructor() { }

  ngOnInit(): void {
  }

  get invalid() {
    return this.accountContactComponent == null || this.accountContactComponent.invalid;
  }

  get value() {
    return this.accountContactComponent?.value;
  }
}
