export enum Status {
  Active = 1,
  Pending = 2,
  Inactive = 3,
  Invited = 4,
}

export enum ClientCertificationStatus {
  Complete = 'Complete',
  NotStarted = 'NotStarted',
  NotInvited = 'NotInvited',
  NotCertified = 'NotCertified',
  Incomplete = 'Incomplete',
}

export enum ClientCourseStatus {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
  NotStarted = 'NotStarted',
}

export enum ClientBadgeApprovalStatus {
  NotUploaded,
  Approved,
  Pending,
  Denied,
}
