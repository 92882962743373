export class SharedTableState {
  sortBy?: string;
  sortAscending?: boolean;
  offset: number;
  limit: number;
  totalCount: number;

  constructor(sortBy?: string, sortAscending?: boolean, offset?: number, limit?: number, totalCount?: number) {
    this.sortBy = sortBy;
    this.sortAscending = sortAscending;
    this.offset = offset;
    this.limit = limit;
    this.totalCount = totalCount;
  }

  static fromParams(params: any): SharedTableState {
    const tableState = new SharedTableState();

    tableState.sortBy = params['sortBy'];
    tableState.sortAscending = params['sortAscending'] === 'true';
    tableState.offset = Number(params['offset'] ?? '0');
    tableState.limit = Number(params['limit'] ?? '20');
    tableState.totalCount = Number(params['totalCount']);

    return tableState;
  }
}
