<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-loc-end-label">
      End Date Time
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-loc-end-tooltip"></i>
      </span>
    </label>
  </div>

  <div class="col-sm-8" [formGroup]="group">
    <p-calendar
      [(ngModel)]="endDate"
      showTime="true"
      (onSelect)="onSelectEndDate(ref.value)"
      hourFormat="12"
      [disabled]="showEndDate"
      [showIcon]="true"
      name="endDate"
      dateFormat="mm/dd/yy"
      [formControlName]="'endDate'"
      [baseZIndex]="1030"
      #ref
      required
      data-cy="task-loc-end"
    ></p-calendar>

    <div *ngIf="submitted">
      <span *ngIf="submitted && endDateControl.hasError('required')" class="text-danger" data-cy="task-loc-end-error">
        A valid End Date Time is required.
      </span>
    </div>

    <span
      *ngIf="group.get('endDate').touched && group.get('endDate').hasError('invalidEndDate')"
      class="text-danger"
      data-cy="task-loc-end-error"
    >
      End date cannot be less than start date
    </span>
  </div>
</div>
