import { select } from '@angular-redux/store';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../../configuration';
import { FileParameter, TaskLocationClient, TaskLocationImportViewModel } from '../../../api.client';
import { OperatorUtils } from '../../../shared/utils/operator.utils';
import { ServiceDeploymentStateConstant } from '../../actions/servicedeployment.constants';
import { ServiceDeploymentAndServiceTaskParams } from '../../models/servicedeployment-and-servicetaskparams';
import { ViewErrorStatusModalComponent } from '../view-error-status-modal/view-error-status-modal.component';
import {
  IServiceDeploymentLogStatus,
  ViewLogStatusModalComponent,
} from '../view-log-status-modal/view-log-status-modal.component';
import { environment } from '../../../../environments/environment';
import { BlobService } from '@volt/shared/services/blob.service';
import { ContainerWrapperToastService } from '@volt/shared/components/containers/container-wrapper/container-wrapper-toast.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'import-task-location.component',
  templateUrl: './import-task-location.component.html',
  providers: [MessageService],
})
export class ImportTaskLocationComponent implements OnInit {
  private serviceDeploymentRefId;
  private taskRefId;
  private routeSubscription: ISubscription;
  public working = false;
  public serviceDeploymentRefNumber;
  public taskRefNumber;
  public fieldGroupName: string;
  public accountId;
  private serviceDeploymentRefNumberAndTaskRefNumberSubscription: ISubscription;
  public fileSize = 10000000;

  @select([ServiceDeploymentStateConstant.FilterStateName])
  private readonly serviceDeploymentTask: Observable<ServiceDeploymentAndServiceTaskParams>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _taskLocationClient: TaskLocationClient,
    private readonly _dynamicDialogService: DynamicDialogService,
    private readonly _blobService: BlobService,
    private readonly _messageService: MessageService,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this._route.params.subscribe((params: Params) => {
      this.serviceDeploymentRefId = params['serviceDeploymentRefId'];
      this.taskRefId = params['taskRefId'];
    });

    this.serviceDeploymentRefNumberAndTaskRefNumberSubscription = this.serviceDeploymentTask.subscribe(
      (data: ServiceDeploymentAndServiceTaskParams) => {
        this.serviceDeploymentRefNumber = data.serviceDeploymentRefNumber;
        this.taskRefNumber = data.serviceDeploymentTaskRefNumber;
        this.fieldGroupName = data.fieldGroupName;
        this.accountId = data.accountId;
      },
    );
  }

  public uploadFile(files: File[]) {
    this.working = true;
    const file: FileParameter = {
      data: files[0],
      fileName: files[0].name,
    };

    this._taskLocationClient
      .uploadTaskLocation(this.serviceDeploymentRefNumber, this.accountId, this.taskRefNumber, file)
      .pipe(
        OperatorUtils.logAndRethrowError(() => {
          this.working = false;
        }),
      )
      .subscribe((taskLocationImport: TaskLocationImportViewModel) => {
        if (taskLocationImport.errorList && taskLocationImport.errorList.length > 0) {
          this._dynamicDialogService.open(ViewErrorStatusModalComponent, {
            data: { errorList: taskLocationImport.errorList },
            closeOnEscape: true,
          });
        } else {
          this._dynamicDialogService.open(ViewLogStatusModalComponent, {
            data: {
              status: 'Pending',
              serviceDeploymentStatusName: 'Task Location Status',
              serviceDeploymentReferenceNumber: 'Task Location Reference #',
              transactionId: taskLocationImport.transactionId,
              accountId: this.accountId,
              showImportTaskLocation: true,
              serviceDeploymentRefId: this.serviceDeploymentRefId,
              taskRefId: this.taskRefId,
            } as IServiceDeploymentLogStatus,
          });
        }
        this.working = false;
      });
  }

  downloadTemplate() {
    this._blobService
      .downloadBlobFromContainer(environment.uploadsSecureContainer, environment.eoc.taskLocationTemplate)
      .subscribe(
        _ => {},
        err => {
          console.error(err);

          this._messageService.add({
            severity: 'error',
            detail: 'Could not download file',
          });
        },
      );
  }
}
