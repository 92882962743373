import { Routes } from '@angular/router';
import { AllowedView } from '../shared/allowed-view';
import { ProductManagementComponent } from './products/product-management/product-management.component';
import { ProductAddComponent } from './products/product-add/product-add.component';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { Privilege } from '@volt/shared/services/permissions.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { translationKey } from '@volt/shared/utils/common.utils';

export const productsRoutes: Routes = [
  {
    path: 'management',
    component: ProductManagementComponent,
    canActivate: [PermissionsGuard],
    data: {
      title: 'Product Management',
      titleKey: translationKey('productManagement'),
      requiredPermission: [PermissionNames.EmergenciesManageProducts, Privilege.Read],
      searchKey: translationKey('search.emergencies.products'),
    },
  },
  {
    path: 'addproduct',
    component: ProductAddComponent,
    canActivate: [PermissionsGuard],
    data: {
      title: 'Add Product',
      requiredPermission: [PermissionNames.EmergenciesManageProducts, Privilege.Read],
    },
  },
  {
    path: 'editproduct/:id',
    component: ProductAddComponent,
    canActivate: [PermissionsGuard],
    data: {
      title: 'Edit Product',
      requiredPermission: [PermissionNames.EmergenciesManageProducts, Privilege.Read],
    },
  },
];
