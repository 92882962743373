import { Routes } from '@angular/router';
import { RetailLinkRegistrationComponent } from './components/retaillink-registration.component';
import { translationKey } from '@volt/shared/utils/common.utils';

export const retailLinkRegistrationsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RetailLinkRegistrationComponent,
        data: {
          title: 'Supplier Registration',
          titleKey: translationKey('supplierRegistration'),
          searchKey: translationKey('search.supplierRegistration'),
        },
      },
    ],
  },
];
