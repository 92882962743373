import { Reducer } from 'redux';
import { IAction } from '../../store/actions/tile.actions';
import { ActionLogFilterParams } from '../action-log-filter-parameters';
import { ActionLogFilterActions } from '../actions/action-log.constants';

export const ActionLogReducer: Reducer<ActionLogFilterParams> = (
  state: ActionLogFilterParams = new ActionLogFilterParams(),
  action: IAction<ActionLogFilterParams>,
) => {
  const params = <ActionLogFilterParams>action.payload;
  switch (action.type) {
    case ActionLogFilterActions.SetFilter: {
      const newstate = Object.assign({}, state, params);
      return newstate;
    }
    default: {
      return state;
    }
  }
};
