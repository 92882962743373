<div class="row align-items-center mt-3">
  <div class="col-sm-4  " style="text-align: right;">
    <label class="mb-0" data-cy="ser-dep-field-group-label">
      Field Group
      <span class="required" aria-required="true">*</span>
      <span data-cy="ser-dep-field-group-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>

  <div class="col-sm-8" [formGroup]="group">
    <p-dropdown
      styleClass="w-100"
      [options]="fieldTypeDropModel"
      (onChange)="onAccountChange($event)"
      [disabled]="isEdit"
      [(ngModel)]="providerAccountName"
      [formControlName]="'fieldGroupType'"
      [filter]="true"
      required
      data-cy="ser-dep-field-group"
      [virtualScroll]="true"
      itemSize="30"
    ></p-dropdown>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && fieldGroupTypeControl.hasError('required')"
        class="text-danger"
        data-cy="ser-dep-field-group-error"
      >
        A valid Field Group is required.
      </span>
    </div>
  </div>
</div>
