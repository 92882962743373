import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SharedTableColumn } from '@volt/shared/components/table/models/shared-table-column';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'shared-table-header-cell',
  templateUrl: './shared-table-header-cell.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedTableHeaderCellComponent {
  @Input() column: SharedTableColumn<any>;
  @Input() data: any;
  @Input() translations: Translations;
}
