import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureService } from '@volt/shared/directives/features/feature.service';
import { PermissionsService } from '@volt/shared/services/permissions.service';
import isNil from 'lodash-es/isNil';
import { combineLatest, from, Observable, of, pipe } from 'rxjs';
import { concatMap, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { UserInformation } from '../../../api.client';
import { AuthService } from '../../../auth';
import { RoleConstant } from '../../../auth/shared/roles.constants';
import { AccountTypeEnum } from '../models/AccoountTypeEnum';
import { FieldGroupTypeEnum } from '../models/FieldGroupTypeEnum';
import { NavPath } from '../models/navpath';
import { navPaths } from '../sidenav/nav-paths';
import { AppListGuard } from '@volt/shared/guards/app-list.guard';

let _navInstanceId = 0;

function compareSortOrder(a: NavPath, b: NavPath): number {
  if (a.sortOrder && !b.sortOrder) return -1;
  if (b.sortOrder && !a.sortOrder) return 1;
  if (a.sortOrder && b.sortOrder) {
    if (a.sortOrder.valueOf() < b.sortOrder.valueOf()) return -1;
    if (a.sortOrder.valueOf() > b.sortOrder.valueOf()) return 1;
  }

  return 0;
}

function compareTitle(a: NavPath, b: NavPath): number {
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;

  return 0;
}

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
  @Input() public listClass = '';
  @Input() public itemClass = '';
  @Input() public linkClass = '';
  @Input() public ddLinkClass = '';
  @Input() public ddMenuClass = '';
  @Input() public chevronClass = '';

  private _navPaths: NavPath[] = navPaths;
  public navPaths$: Observable<NavPath[]>;
  public navId = `nav-${_navInstanceId++}`;
  public currentUser: UserInformation;
  public fieldGroupType: string;
  public accountTypeId: number;
  public role: string;
  public collapsedMap: { [key: string]: boolean } = {};
  public checkAccountHideTransaction: boolean;

  constructor(
    private _authService: AuthService,
    private readonly route: ActivatedRoute,
    private _featureService: FeatureService,
    private _appListGuard: AppListGuard,
    private readonly permissionsService: PermissionsService,
  ) {
    this.navId = `nav-${_navInstanceId++}`;
  }

  ngOnInit(): void {
    const navdetails = this.route.snapshot.data.sidenav;

    this.role = navdetails?.role;
    this.fieldGroupType = navdetails?.fieldGroupType;
    this.accountTypeId = navdetails?.accountTypeId;
    this.checkAccountHideTransaction = navdetails?.checkAccountHideTransaction;

    this.navPaths$ = from(this._navPaths).pipe(
      this.filterNavPath(),
      concatMap(navPath => {
        if (navPath.children) {
          this.collapsedMap[navPath.feature] = true;
          return from(navPath.children).pipe(
            this.filterNavPath(),
            toArray(),
            this.sortNavPaths(),
            map((childNavs: (NavPath & { show: boolean })[]) => {
              return {
                ...navPath,
                children: childNavs,
                visible: navPath.route || childNavs.filter(s => s.show).length > 0,
              };
            }),
          );
        }
        return of(navPath);
      }),
      toArray(),
      this.sortNavPaths(),
    );
  }

  private filterNavPath() {
    return pipe(
      filter<NavPath>(navPath => isNil(navPath.visible) || navPath.visible === true),
      mergeMap<NavPath, Observable<NavPath & { show: boolean }>>(navPath => {
        if (
          navPath.title === 'Suppliers' &&
          this.accountTypeId === AccountTypeEnum.Provider &&
          (this.fieldGroupType === FieldGroupTypeEnum.DSD ||
            this.fieldGroupType === FieldGroupTypeEnum.Specialty ||
            this.fieldGroupType === FieldGroupTypeEnum.SRT)
        ) {
          return of({ ...navPath, show: false });
        }

        if (
          (navPath.title === 'Locations' || navPath.title === 'Service Deployments') &&
          this.checkAccountHideTransaction === true &&
          this.role === RoleConstant.ProviderAdmin
        ) {
          return of({ ...navPath, show: false });
        }

        return combineLatest([
          this._handleFeature(navPath),
          this._handleNavigationPermission(navPath),
          this._handleAppId(navPath),
        ]).pipe(
          map(showResults => {
            return {
              ...navPath,
              show: !showResults.includes(false),
            };
          }),
        );
      }),
      filter(navPath => navPath.show === true),
    );
  }

  private _handleFeature(navPath: NavPath) {
    if (isNil(navPath.feature)) {
      return of(true);
    }

    return this._featureService.isFeatureEnabled$(navPath.feature);
  }

  private _handleNavigationPermission(navPath: NavPath) {
    if (isNil(navPath.permission)) {
      return of(isNil(navPath.roleViews) || this._authService.isUserAllowedView(navPath.roleViews));
    }

    const [permission, privilege] = navPath.permission;

    return this.permissionsService.hasPermission$(permission, privilege);
  }

  private _handleAppId(navPath: NavPath) {
    if (isNil(navPath.appId)) {
      return of(true);
    }

    return this._appListGuard.hasAppAccess$(navPath.appId);
  }

  private sortNavPaths() {
    return map<NavPath[], NavPath[]>(paths => paths.slice().sort(compareTitle).sort(compareSortOrder));
  }
}
