<modal-header [title]="'Advanced Search'" (onClose)="onClose()"></modal-header>
<div class="modal-body expand-width">
  <ng-container *ngIf="advancedSearchFilters && advancedSearchFilters?.length !== 0">
    <form [formGroup]="form">
      <div *ngFor="let type of advancedSearchFilters">
        <ng-container [ngSwitch]="type.key">
          <ng-container *ngSwitchCase="'status'">
            <p-dropdown
              styleClass="w-100 mt-2"
              [options]="status$ | async"
              [formControlName]="type.key"
              appendTo="body"
              [attr.data-cy]="'adv-search-' + type.key"
            ></p-dropdown>
          </ng-container>
          <ng-container *ngSwitchCase="'serviceModel'">
            <p-dropdown
              styleClass="w-100 mt-2"
              [options]="serviceModels$ | async"
              [formControlName]="type.key"
              appendTo="body"
              [attr.data-cy]="'adv-search-' + type.key"
            ></p-dropdown>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <input
              pInputText
              class="w-100 mt-2"
              type="text"
              [formControlName]="type.key"
              placeholder="{{ type.header }}"
              [attr.data-cy]="'adv-search-' + type.key"
            />
          </ng-container>
        </ng-container>
      </div>
    </form>
  </ng-container>
</div>
<modal-footer (onSubmit)="onSubmit()" [saveLabel]="'Search'" (onClose)="onClose()"></modal-footer>
