<v-loading-container [working]="loading">
  <div class="container-fluid dashboard-page">
    <div class="row flex-xl-nowrap">
      <div class="col-xl-12">
        <div class="">
          <form [formGroup]="addServiceDeploymentTaskGroup" (submit)="submitServiceDeploymentTasks()">
            <div class="sep-form">
              <h2 data-cy="task-page-title">Add Service Task</h2>

              <div class="form-fields">
                <div class="row">
                  <div
                    class="col-xl-12 col-sm-12 top-head"
                    *ngIf="
                      fieldGroupName != null &&
                      fieldGroupName != '' &&
                      serviceDeploymentReferenceNumber != null &&
                      serviceDeploymentReferenceNumber != ''
                    "
                  >
                    <p>
                      Field Group:
                      <span style="font-style: italic; color: #b3b3b3; font-weight: normal" data-cy="task-field-group">
                        {{ fieldGroupName }}
                      </span>
                      <span style="padding-left: 30px" data-cy="task-ser-dep-label">
                        Service Deployment:&nbsp;&nbsp;
                      </span>
                      <span style="font-style: italic; color: #b3b3b3; font-weight: normal" data-cy="task-ser-dep">
                        {{ serviceDeploymentName }}&nbsp;({{ serviceDeploymentReferenceNumber }})
                      </span>
                    </p>

                    <p></p>
                  </div>
                </div>

                <div class="row"></div>

                <div>
                  <task-name [group]="addServiceDeploymentTaskGroup" [submitted]="submitted"></task-name>

                  <task-reference-number
                    [group]="addServiceDeploymentTaskGroup"
                    [isEdit]="currentaddedServiceDeploymentTask.edit"
                    [submitted]="submitted"
                    [serviceDepRefNumberExist]="serviceDepRefNumberMsg"
                    (referenceNumberEntered)="checkServiceRefNumberAlreadyExist($event)"
                  ></task-reference-number>

                  <service-model
                    [group]="addServiceDeploymentTaskGroup"
                    [submitted]="submitted"
                    [serviceModelList]="serviceModel"
                    [setServiceModel]="currentaddedServiceDeploymentTask"
                  ></service-model>

                  <activity-type
                    [group]="addServiceDeploymentTaskGroup"
                    [submitted]="submitted"
                    [activeModel]="activeModel"
                    [setActivityName]="currentaddedServiceDeploymentTask"
                  ></activity-type>

                  <supplier-list
                    [group]="addServiceDeploymentTaskGroup"
                    [serviceTaskSupplierMsg]="serviceTaskSupplierMsg"
                    [submitted]="submitted"
                    (selectSupplier)="getSupplierListByFilter($event)"
                    [filteredSupplierList]="filteredSupplierList"
                    (supplierExist)="supplierExist($event)"
                    [initialSupplierList]="initialSupplierList"
                  ></supplier-list>

                  <department-list
                    [group]="addServiceDeploymentTaskGroup"
                    [submitted]="submitted"
                    (selectDepartment)="getDepartmentListByFilter($event)"
                    [serviceTaskDepartmentMsg]="serviceTaskDepartmentMsg"
                    (departmentExist)="departmentExist($event)"
                    [filteredDepartmentList]="filteredDepartmentList"
                    [initialDepartmentList]="initialDepartmentList"
                  ></department-list>

                  <instruction
                    [canEdit]="isEdit ? canEdit : canCreate"
                    [group]="addServiceDeploymentTaskGroup"
                    (instructionName)="getInstruction($event)"
                    [serviceTaskInstructionMsg]="serviceTaskInstructionMsg"
                    [selectedInstructions]="selectedInstructions"
                    [serviceTaskReferenceNumber]="this.currentaddedServiceDeploymentTask.referenceNumber"
                  ></instruction>

                  <time-estimate [group]="addServiceDeploymentTaskGroup" [submitted]="submitted"></time-estimate>

                  <note [group]="addServiceDeploymentTaskGroup"></note>

                  <status-list
                    [group]="addServiceDeploymentTaskGroup"
                    [statusDropDownModel]="statusDropDownModel"
                    [selectedStatus]="selectedStatus"
                  ></status-list>

                  <div class="row mt-3">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-cy="task-submit-button"
                        [disabled]="(isEdit && !canEdit) || (!isEdit && !canCreate)"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button type="button" (click)="formReset()" class="btn btn-cancel" data-cy="task-cancel-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
