import { Reducer } from 'redux';
import { IAction } from '../../store/actions/tile.actions';
import { AddServiceDeployment } from '../models/addServiceDeployment';
import { AddServiceDeploymentFilterActions } from '../actions/servicedeployment.constants';

// tslint:disable-next-line:variable-name
export const AddServiceDeploymentReducer: Reducer<AddServiceDeployment> = (
  state: AddServiceDeployment = new AddServiceDeployment(),
  action: IAction<AddServiceDeployment>,
) => {
  const params = <AddServiceDeployment>action.payload;
  switch (action.type) {
    case AddServiceDeploymentFilterActions.SetFilter: {
      const newstate = Object.assign({}, state, params);
      return newstate;
    }
    default: {
      return state;
    }
  }
};
