import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BaseFormField } from '@volt/shared/components/form-fields/base-form-field.component';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import { debounceTime } from 'rxjs/operators';
import { TranslationService } from '@volt/shared/services/translation.service';

@Component({
  selector: 'v-search-field',
  templateUrl: './search-field.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent extends BaseFormField implements OnInit, OnChanges {
  @Input() debounce = 500;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() tooltipText: string = '';
  @Input() disableTooltip: boolean = true;

  constructor(private readonly _translationService: TranslationService, private readonly _cd: ChangeDetectorRef) {
    super(_translationService);
  }

  ngOnInit(): void {
    this._translationService
      .getTranslatedObject()
      .pipe(OperatorUtils.takeUntilDisposed(this))
      .subscribe(translated => {
        this.translatedData = translated;
        this.updateTextFilterDisplay();
        this._cd.markForCheck();
      });
  }

  protected get filterDisplayLabel(): string {
    return this.translatedData?.search;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.firstChange) {
      this.control.valueChanges
        .pipe(debounceTime(this.debounce), OperatorUtils.takeUntilDisposed(this))
        .subscribe(() => {
          this.updateTextFilterDisplay();
          this._cd.markForCheck();
        });
    }
  }
}
