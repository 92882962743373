import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import isNil from 'lodash-es/isNil';
import { select } from '@angular-redux/store';
import { AddServiceDeploymentStateConstant } from '../../../../actions/servicedeployment.constants';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { ServiceDeploymentInput } from '../../../../../api.client';
import { ServiceDeploymentsService } from '../../../servicedeployments/services/service-deployments.service';
import { AddServiceDeployment } from '../../../../models/addServiceDeployment';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'service-deployment-reference-number',
  templateUrl: './service-deployment-reference-number.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class ServiceDeploymentReferenceNumberComponent implements OnInit {
  @Output() private referenceNumberEntered: EventEmitter<string> = new EventEmitter<string>();
  @Input() public group: UntypedFormGroup;
  @Output() public serviceDepReferNumberExist: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public submitted;
  @Input() public isEdit;
  public serviceDepRefNumberExist = false;
  public currentaddedServiceDeployment: AddServiceDeployment;
  private addServiceDeployemntSubscription: ISubscription;
  public serviceDepRefNumber: string;

  @select([AddServiceDeploymentStateConstant.FilterStateName])
  private readonly addServiceDeployment: Observable<ServiceDeploymentInput>;

  constructor(
    private _serviceDeploymentService: ServiceDeploymentsService,
    private _dynamicDialogService: DynamicDialogService,
  ) {}

  ngOnInit(): void {
    this.addServiceDeployemntSubscription = this.addServiceDeployment.subscribe(
      (data: AddServiceDeployment) => {
        this.currentaddedServiceDeployment = data;
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          "Technical Reference Number that the field group uses to identify this Service in the field group's system. Must be unique for each new Service Deployment. No spaces.",
        ],
      },
    });
  }

  public get serviceDepRefNumberControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('serviceDepRefNumber');
  }

  public checkServiceRefNumberAlreadyExist(refNumber: string) {
    this.serviceDepRefNumber = refNumber;

    refNumber = refNumber.trim();

    if (!isNil(refNumber) && refNumber !== '' && !isNil(this.currentaddedServiceDeployment.accountId)) {
      this._serviceDeploymentService
        .checkServiceRefNumberAlreadyExist(refNumber, this.currentaddedServiceDeployment.accountId)
        .subscribe(
          (data: any) => {
            this.serviceDepRefNumberExist = data;
            this.serviceDepReferNumberExist.emit(this.serviceDepRefNumberExist);
          },
          (err: any) => {
            console.error(err);
          },
        );
    }
  }
}
