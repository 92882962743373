import { Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AvailableLangs } from '../types';
import { LocalStorageService } from './local-storage.service';
import { Translations } from './translations.types';

interface TranslationParams<TKey extends keyof Translations> {
  key: TKey;
  params?: HashMap;
}

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private readonly ACTIVE_LANG_KEY = '@@voltDashboardActiveLang';

  constructor(private _localStorageService: LocalStorageService, private _translocoService: TranslocoService) {}

  setupTranslation(): void {
    if (this._localStorageService.isEnabled) {
      const activeLang = this._localStorageService.get(this.ACTIVE_LANG_KEY);

      if (activeLang) {
        this._translocoService.setActiveLang(activeLang);
      } else {
        this.getBrowserLang();
      }
    } else {
      this.getBrowserLang();
    }
  }

  get currentTranslations() {
    return this._translocoService.getTranslation(this._translocoService.getActiveLang()) as Translations;
  }

  getTranslatedObject(...params: Array<TranslationParams<keyof Translations>>): Observable<Translations> {
    return this._translocoService.langChanges$.pipe(
      switchMap(() =>
        this._translocoService.selectTranslation().pipe(
          map(Object.create),
          map<HashMap<any>, Translations>((translationObject: HashMap<any>) => {
            translationObject = params
              .filter(s => s.params != null)
              .reduce((acc, cur) => {
                acc[cur.key] = this._translocoService.translate(cur.key, cur.params);
                return acc;
              }, translationObject);
            return translationObject as Translations;
          }),
        ),
      ),
    );
  }

  translate(key: keyof Translations, params?: HashMap): string {
    return this._translocoService.translate(key, params);
  }

  selectTranslate(key: keyof Translations, params?: HashMap): Observable<string> {
    return this._translocoService.selectTranslate(key, params);
  }

  get activeLang$(): Observable<string> {
    return this._translocoService.langChanges$.pipe(
      distinctUntilChanged(),
      tap(this.setActiveLangInLocalStorage.bind(this)),
    );
  }

  get langOptions$(): Observable<SelectItem[]> {
    return of([
      { label: 'EN', value: 'en' },
      { label: 'ES', value: 'es' },
    ]);
  }

  get langChanged$(): Observable<AvailableLangs> {
    return this._translocoService.langChanges$ as Observable<AvailableLangs>;
  }

  private setActiveLangInLocalStorage(lang: string): void {
    this._localStorageService.set(this.ACTIVE_LANG_KEY, lang);
  }

  private getBrowserLang(): void {
    const preferredLang = navigator.languages.map(l => {
      const trySplit = l.split('-');
      if (trySplit.length && trySplit.length > 1) {
        return trySplit[0];
      }

      return l;
    })[0];

    if (preferredLang && (preferredLang === 'en' || preferredLang === 'es')) {
      this._translocoService.setActiveLang(preferredLang);
    }
  }
}
