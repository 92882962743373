import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';

@Component({
  selector: 'retaillink-control-modal',
  templateUrl: './retaillink-control-modal.component.html',
  styleUrls: ['./retaillink-control-modal.component.scss'],
})
export class RetailLinkControlModalComponent extends DynamicDialogBaseComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public requiredfield: any;

  constructor(
    private _dialogReference: DynamicDialogRef, 
    private _dialogConfig: DynamicDialogConfig, 
    private _router: Router) {
      super(_dialogReference, _dialogConfig)
      this.requiredfield = this._dialogConfig.data.requiredfield
    }

  ngOnInit() {
    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this._dialogReference.close(false));
  }
  onClose(){
    return this._dialogReference.close(false);
  }
}
