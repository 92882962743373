<div class="card">
  <div class="card-header">
    <div class="d-flex flex-row justify-content-between">
      <h2 class="card-title">
        <i class="fa fas fa-clock tile-icon"></i>
        {{ translations.taskHoursByActivity }}
      </h2>
    </div>
  </div>
  <div class="card-body">
    <v-loading-container [working]="isLoading">
      <p *ngIf="!aggregateData?.length && !isLoading">{{translations.noDataToDisplay}}</p>
      <div [chart]="chart" style="width: 100%; margin: 0 auto"></div>
    </v-loading-container>
  </div>
</div>

