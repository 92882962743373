import { OnInit, Input, Component, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropDownModel } from '@volt/api';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'supplier-list',
  templateUrl: './supplier.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})
export class SupplierComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public serviceTaskSupplierMsg: boolean;
  @Input() public filteredSupplierList: DropDownModel[];
  @Input() public initialSupplierList: DropDownModel[];
  @Output() private selectSupplier: EventEmitter<any> = new EventEmitter<any>();
  @Output() private supplierExist: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: ['Supplier number OR Supplier Name of the company that is being serviced.'],
      },
    });
  }

  public get supplierControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('supplier');
  }

  public getSupplierListByFilter(event) {
    this.selectSupplier.emit(event);
  }

  public onClearEvent(event: any) {
    this.serviceTaskSupplierMsg = false;
    this.supplierExist.emit(event);
  }
}
