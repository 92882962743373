import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { UntypedFormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Country } from '../../models/models';

@Component({
  selector: 'account-state',
  templateUrl: './account-state.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountStateComponent {
  @Input() translations: Translations;
  @Input() control: UntypedFormControl;
  @Input() set selectedCountry(c: Country | null) {
    const allStates = {
      [Country.USA]: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
        "Minnesota",
        "Puerto Rico"
      ],
      [Country.Canada]: [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Nova Scotia",
        "Northwest Territories",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon",
        "District of Columbia",
      ]
    }

    const states = allStates[c || Country.USA].sort();

    this.options = states.map(state => {
      return {
        label: state,
        value: state
      } as SelectItem
    });

    if(this.control.value == null) {
      this.control.setValue(this.options[0].value);
    }

    this.label = c === Country.Canada ? this.translations.province : this.translations.state;
  }

  options: SelectItem[];
  label: string;

  constructor() { }
}
