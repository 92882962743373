export class LocationFilterParameters {
  constructor() {
    this.filterText = '';
    this.onlyRepsInStore = false;
    this.storesInEmergencyState = false;
    this.showInactiveStores = false;
    this.page = 1;
    this.pageSize = 24;
  }
  public filterText: string;
  public storesInEmergencyState: boolean;
  public showInactiveStores: boolean;
  public onlyRepsInStore: boolean;
  public page: number;
  public pageSize: number;
}
