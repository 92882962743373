import { trigger, animate, transition, style, query } from '@angular/animations';

export const routeFade = trigger('routeFade', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({
          opacity: 0,
          position: 'absolute',
          top: '8px',
          left: 0,
          right: 0,
          bottom: 0,
        }),
      ],
      { optional: true },
    ),
    query(
      ':leave',
      [
        style({
          opacity: 1,
          position: 'absolute',
          top: '8px',
          left: 0,
          right: 0,
          bottom: 0,
        }),
        animate('0.2s', style({ opacity: 0 })),
      ],
      { optional: true },
    ),
    query(
      ':enter',
      [
        style({
          opacity: 0,
          top: '8px',
          left: 0,
          right: 0,
          bottom: 0,
        }),
        animate('0.2s', style({ opacity: 1 })),
      ],
      { optional: true },
    ),
  ]),
]);

export const fade = trigger('fade', [
  transition(':enter', [
    // :enter is alias to 'void => *'
    style({ opacity: 0 }),
    animate('0.2s', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    // :leave is alias to '* => void'
    animate('0.2s', style({ opacity: 0 })),
  ]),
]);
