import { Routes } from '@angular/router';
import { ActionLogComponent } from './components/actionlog/action-log.component';
import { translationKey } from '@volt/shared/utils/common.utils';

// tslint:disable-next-line:variable-name
export const ActionLogsRoutes: Routes = [
  {
    path: 'logs',
    component: ActionLogComponent,
    data: {
      title: 'Service Logs',
      titleKey: translationKey('serviceLogs'),
      searchKey: translationKey('search.serviceLogs'),
    },
  },
];
