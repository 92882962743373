import { Directive, ElementRef, Input } from '@angular/core';
import { ColBreakpoint, ColSpanOption } from '../../types';

@Directive({
  selector: '[colSpan]',
})
export class ColSpanDirective {
  @Input() set colSpan(option: ColSpanOption) {
    const cols = Object.keys(option).map((key: ColBreakpoint) => {
      if (key === 'xs') {
        return `col-${option[key]}`;
      }

      return `col-${key}-${option[key]}`;
    });

    this.el.nativeElement.classList.add(...cols);
  }

  constructor(private readonly el: ElementRef<HTMLElement>) {}
}
