<ul [id]="navId" [class]="'clearfix ' + listClass">
  <ng-container *ngIf="navPaths$ | async as navPaths; else loading">
    <ng-container *ngFor="let path of navPaths; index as i">
      <ng-container *ngIf="!path.children?.length; else hasChildren">
        <li *ngIf="path.visible === undefined || path.visible" [class]="itemClass">
          <a [class]="linkClass" [routerLink]="path.route" [queryParams]="path.queryParams" routerLinkActive="active">
            <ng-container *ngTemplateOutlet="childDisplay"></ng-container>
          </a>
        </li>
      </ng-container>

      <ng-template #hasChildren>
        <li [class]="itemClass">
          <ng-container *ngIf="!path.route; else parentWithRoute">
            <ng-container *ngTemplateOutlet="parentWithoutRoute"></ng-container>
          </ng-container>
          <ul [class]="ddMenuClass" routerLinkActive="show" [ngbCollapse]="collapsedMap[path.feature]">
            <li [class]="itemClass" *ngFor="let child of path.children">
              <a
                [class]="linkClass"
                *ngIf="child.visible"
                [routerLink]="child.route"
                [queryParams]="child.queryParams"
                routerLinkActive="active"
              >
                <i [class]="'fa ' + child.iconClass"></i>
                <span *ngIf="!!child.titleKey">
                  {{ child.titleKey | transloco: child.translateParams }}
                </span>
                <span *ngIf="!child.titleKey">{{ child.title }}</span>
                <span *ngIf="child.showAlert" class="badge rounded-pill badge-danger">3</span>
              </a>
            </li>
          </ul>
        </li>
      </ng-template>

      <ng-template #parentWithRoute>
        <a
          [ngClass]="[linkClass, ddLinkClass]"
          [class.collapsed]="collapsedMap[path.feature]"
          [attr.data-parent]="'#' + navId"
          routerLinkActive="active"
          (click)="collapsedMap[path.feature] = !collapsedMap[path.feature]"
          [routerLink]="path.route"
        >
          <ng-container *ngTemplateOutlet="parentDisplay"></ng-container>
        </a>
      </ng-template>

      <ng-template #parentWithoutRoute>
        <a
          *ngIf="!path.route; else parentWithRoute"
          [ngClass]="[linkClass, ddLinkClass]"
          [class.collapsed]="collapsedMap[path.feature]"
          [attr.data-parent]="'#' + navId"
          routerLinkActive="active"
          (click)="collapsedMap[path.feature] = !collapsedMap[path.feature]"
        >
          <ng-container *ngTemplateOutlet="parentDisplay"></ng-container>
        </a>
      </ng-template>

      <ng-template #parentDisplay>
        <ng-container *ngTemplateOutlet="childDisplay"></ng-container>
        <span [class]="chevronClass ? chevronClass : 'chevron'"></span>
      </ng-template>

      <ng-template #childDisplay>
        <i [class]="'fa ' + path.iconClass"></i>
        <span *ngIf="!!path.titleKey">
          {{ path.titleKey | transloco: path.translateParams }}
        </span>
        <span *ngIf="!path.titleKey">{{ path.title }}</span>
        <span *ngIf="path.showAlert" class="alert alert-danger"></span>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <v-loading-spinner></v-loading-spinner>
  </ng-template>
</ul>
