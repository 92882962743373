import { OnDestroy, OnInit, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Form } from '../../types';
import { OperatorUtils } from '../../utils/operator.utils';

@Directive()
export abstract class BaseFilterForm<T extends {} = any, TForm = Form<T>> implements OnDestroy, OnInit {
  form: UntypedFormGroup;
  private $submitClick: Subject<null> = new Subject<null>();

  abstract initForm(): void;

  ngOnDestroy(): void {}
  ngOnInit(): void {}

  public get controls(): TForm {
    return (<unknown>this.form.controls) as TForm;
  }

  public onSubmit() {
    this.$submitClick.next();
  }

  protected initFormSharedValues(cb: (formValues: { previous: T; current: T }) => void) {
    this.form.sharedValueChanges = this.form.valueChanges.pipe(
      OperatorUtils.mapToFormSharedValue(this.form),
      OperatorUtils.takeUntilDisposed(this),
    );

    this.form.sharedValueChanges.subscribe(cb);
  }

  protected initSubmitClickSubscription(cb: () => void) {
    this.$submitClick.asObservable().pipe(OperatorUtils.takeUntilDisposed(this)).subscribe(cb);
  }
}
