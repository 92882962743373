import { InjectionToken } from '@angular/core';
import UserConstraints from './assets/data/user-constraints.json';

export interface IUserFieldConstraint {
  maxLength: number;
}

export interface UserConstraintsConfigType {
  employeeNumber: IUserFieldConstraint;
  nickName: IUserFieldConstraint;
  firstName: IUserFieldConstraint;
  lastName: IUserFieldConstraint;
  email: IUserFieldConstraint;
  address1: IUserFieldConstraint;
  address2: IUserFieldConstraint;
  city: IUserFieldConstraint;
  zip: IUserFieldConstraint;
  phone: IUserFieldConstraint;
  mobile: IUserFieldConstraint;
  userName: IUserFieldConstraint;
  title: IUserFieldConstraint;
  department: IUserFieldConstraint;
}

export const USER_CONSTRAINTS_CONFIG = new InjectionToken<UserConstraintsConfigType>('UserConstraints');
export const userConstraintsConfig: UserConstraintsConfigType = { ...UserConstraints };
