import { ActionLogFilterParams } from '../action-log/action-log-filter-parameters';
import { DashboardState } from '../dashboard/store/dashboard.state';
import { LocationFilterParameters } from '../locations/location-filter-parameters';
import { FeedbackFilterParameters } from '../reports/feedback/feedback-filter-parameter';
import { AddServiceDeployment } from '../servicedeployments-tasks-locations/models/addServiceDeployment';
import { AddServiceTask } from '../servicedeployments-tasks-locations/models/addServiceTask';
import { AddTaskLocation } from '../servicedeployments-tasks-locations/models/addTaskLocation';
import { ServiceDeploymentAndServiceTaskParams } from '../servicedeployments-tasks-locations/models/servicedeployment-and-servicetaskparams';
import { ServiceDeploymentReportParams } from '../servicedeployments-tasks-locations/models/servicedeployment-report-parameters';
import { InitialUserFilter } from '../users/store/UserFilterState';
import { InitialVisitFilter } from '../users/store/UserVisitState';
import { rootReducer } from './reducers/root.reducer';
import { SettingsState } from './states/settings.state';

export type IAppState = ReturnType<typeof rootReducer>;

export const initialState: IAppState = {
  dashboardState: new DashboardState(),
  locationFilter: new LocationFilterParameters(),
  userFilter: InitialUserFilter,
  userVisits: [],
  userVisitFilter: InitialVisitFilter,
  settingsState: new SettingsState(),
  actionLogFilter: new ActionLogFilterParams(),
  addservicedeploymentFilter: new AddServiceDeployment(),
  addservicedeploymentTaskFilter: new AddServiceTask(),
  addservicedeploymentTaskLocationFilter: new AddTaskLocation(),
  feedBackFilter: new FeedbackFilterParameters(),
  servicedeploymentFilter: new ServiceDeploymentAndServiceTaskParams(),
  serviceDeploymentsReportFilters: new ServiceDeploymentReportParams(),
};
