<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-ref-num-label">
      Task Reference Number
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-ref-num-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <div *ngIf="!isEdit">
      <input
        class="form-control rounded-0"
        [formControlName]="'taskReferenceNumber'"
        id="taskReferenceNumber"
        (blur)="checkServiceRefNumberAlreadyExist(ref.value)"
        required
        data-cy="task-ref-num"
        #ref
      />
    </div>

    <div *ngIf="isEdit">
      <input
        class="form-control rounded-0"
        [formControlName]="'taskReferenceNumber'"
        id="taskReferenceNumber"
        readonly
        data-cy="task-ref-num"
        #ref
      />
    </div>

    <div *ngIf="submitted" data-cy="task-ref-num-error">
      <span *ngIf="taskReferenceNumberControl.hasError('required')" class="text-danger">
        Task Reference Number is required.
      </span>
      <span *ngIf="taskReferenceNumberControl.hasError('pattern')" class="text-danger">
        Invalid Reference Number. Allowed characters: A-Z a-z 0-9 . - _
      </span>
    </div>

    <div
      class="text-danger"
      *ngIf="
        serviceDepRefNumberExist &&
        !taskReferenceNumberControl.hasError('required') &&
        !taskReferenceNumberControl.hasError('pattern')
      "
      data-cy="task-ref-num-error"
    >
      Service Task Reference Number already exists
    </div>
  </div>
</div>
