import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'account-logo',
  templateUrl: './account-logo.component.html',
  styles: [
    `
        :host ::ng-deep .p-fileupload-buttonbar {
            display: none;
        }
        
        :host ::ng-deep .p-fileupload-content {
            text-align: center;
            background-color: transparent;
            padding: 0 !important;
            border: none !important;
        }
        
        :host ::ng-deep .p-fileupload-content:hover {
            cursor: pointer;
        }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountLogoComponent implements OnInit {
  @Input() translations: Translations;
  @Input() control: UntypedFormControl;
  @Input() disabled = false;

  constructor(private readonly _domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  setControlValueAndGetFileUrl(file) {
    this.control.setValue(file);
    this.control.markAsDirty()
    return this._domSanitizer.bypassSecurityTrustResourceUrl(file.objectURL.changingThisBreaksApplicationSecurity)
  }
}
