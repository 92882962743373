import { InjectionToken } from '@angular/core';
import { RoleConstant } from '../../auth/shared/roles.constants';
import { ApprovalStatus2, ServiceTaskEventStatus, StatusTypes } from '../../api.client';

export type InjectedStatusTypes = typeof StatusTypes;
export type InjectedApprovalStatusTypes = typeof ApprovalStatus2 | typeof ServiceTaskEventStatus;
export type InjectedRoleTypes = typeof RoleConstant;

export const STATUS_TYPES = new InjectionToken<InjectedStatusTypes>('STATUS_TYPES');
export const APPROVAL_STATUS_TYPES = new InjectionToken<InjectedApprovalStatusTypes>('APPROVAL_STATUS_TYPES');
export const ROLE_TYPES = new InjectionToken<InjectedRoleTypes>('ROLE_TYPES');
