import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';

@Directive({
  selector: '[disableControl],[disableControlPermission]',
})
export class DisableControlDirective {
  private disableControlCounter: number = 0;

  @Input() set disableControl(disableCondition: boolean) {
    const disableControlCounter = ++this.disableControlCounter;

    if (this._ngControl.control) {
      this.changeControlState(disableCondition);
    } else {
      setTimeout(() => {
        if (disableControlCounter === this.disableControlCounter) {
          this.disableControl = disableCondition;
        }
      }, 0);
    }
  }

  @Input() set disableControlPermission(permissions: [PermissionNames, Privilege, boolean?]) {
    if (permissions == null) {
      return;
    }

    const disableControlCounter = ++this.disableControlCounter;
    const [permission, privilege, condition = false] = permissions;
    const shouldDisabled = !this._permissionsService.hasPermission(permission, privilege) || condition;
    if (this._ngControl.control) {
      this.changeControlState(shouldDisabled);
    } else {
      setTimeout(() => {
        if (disableControlCounter === this.disableControlCounter) {
          this.disableControlPermission = permissions;
        }
      }, 0);
    }
  }

  constructor(private readonly _ngControl: NgControl, private readonly _permissionsService: PermissionsService) {}

  private changeControlState(disableCondition: boolean) {
    if (this._ngControl.control.disabled !== disableCondition) {
      if (disableCondition) {
        this._ngControl.control.disable();
      } else {
        this._ngControl.control.enable();
      }
    }
  }
}
