import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'account-submitting',
  templateUrl: './account-submitting.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSubmittingComponent implements OnInit {
  randomFact$: Observable<string>;

  private httpClient: HttpClient;
  constructor(
    private handler: HttpBackend, // Bypass interceptor so we don't pass auth token
  ) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.randomFact$ = this.httpClient.get('https://uselessfacts.jsph.pl/random.json?language=en').pipe(
      map((res: any) => res.text)
    );
  }

}
