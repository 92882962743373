import { UntypedFormGroup } from '@angular/forms';
import { ApiError, FileResponse, UserInformation } from '../../api.client';
import uniqBy from 'lodash-es/uniqBy';
import {
  combineLatest,
  isObservable,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  pipe,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  takeUntil, tap
} from "rxjs/operators";
import { RoleConstant } from '../../auth/shared/roles.constants';
import { RootInjector } from '../../root-injector';
import { ApiResponse } from '../models/api-response';
import { MonitoringService } from '../services/monitoring.service';
import { CommonUtils, isEquivalent, isTimeoutError } from './common.utils';
import * as moment from 'moment';
import { ContainerWrapperToastService } from '@volt/shared/components/containers/container-wrapper/container-wrapper-toast.service';
import { TranslationService } from '@volt/shared/services/translation.service';

export interface DisposableDirective {
  ngOnInit: () => void;
  ngOnDestroy: () => void;
}

export class OperatorUtils {
  public static getApiResponse<T = any>(apiCall: Observable<T>, initialValue: T = null): Observable<ApiResponse<T>> {
    return apiCall.pipe(
      map(data => ({ isLoading: false, data, error: '' })),
      startWith({ data: initialValue, isLoading: true, error: '' }),
      this.logErrorAndReturn((err: ApiError) =>
        of<ApiResponse<T>>({
          isLoading: false,
          data: initialValue,
          error: err.error || 'Unexpected error',
        }),
      ),
    );
  }

  public static logAndRethrowError<TInput = any>(cb?: (err?: any) => void): MonoTypeOperatorFunction<TInput> {
    const _service = RootInjector.get(MonitoringService);
    return catchError((err: any) => {
      console.error(err);
      _service.logApiException(err);
      cb && cb(err);
      return throwError(err);
    });
  }

  public static logAndToastError<TInput = any>(_toastService: ContainerWrapperToastService,cb?: (err?: any) => void): MonoTypeOperatorFunction<TInput> {
    const _monitoringService = RootInjector.get(MonitoringService);
    const _translationService = RootInjector.get(TranslationService);
    return catchError((err: ApiError) => {
      console.error(err);
      _monitoringService.logApiException(err);

      if(err.status === 500) {
        if(err.detail.startsWith('System.TimeoutException') || isTimeoutError(err)){
          setTimeout(() => {
            _toastService.addMessage({
              severity: 'error',
              detail: _translationService.translate('yourRequestTimeoutOut')
            })
          })
        } else {
          setTimeout(() => {
            _toastService.addMessage({
              severity: 'error',
              detail: _translationService.translate('anErrorHasOccurred')
            })
          })
        }
      }

      cb && cb(err);
      return throwError(err);
    });
  }

  public static logErrorAndReturn<TReturn = any>(
    obsFactory: (err?: ApiError) => Observable<TReturn>,
  ): MonoTypeOperatorFunction<TReturn> {
    return pipe(
      this.logAndRethrowError(),
      catchError((err: ApiError) => obsFactory(err)),
    );
  }

  public static takeUntilDisposed<TInput>(componentOrService: DisposableDirective): MonoTypeOperatorFunction<TInput> {
    const ngOnDestroy = componentOrService.ngOnDestroy;
    const ngOnInit = componentOrService.ngOnInit;
    const key = '__disposed$';

    if (ngOnDestroy && ngOnDestroy[key]) {
      return takeUntil(ngOnDestroy[key]);
    }

    const originalInit = ngOnInit || noop;
    const originalDestroy = ngOnDestroy || noop;

    function onInit() {
      if (onDestroy[key].isStopped) {
        // to support components that recycle
        onDestroy[key] = new Subject();
      }
      originalInit.call(this);
    }

    function onDestroy() {
      originalDestroy.call(this);
      const disposed$ = onDestroy[key];
      disposed$.next();

      disposed$.complete();
    }

    componentOrService.ngOnDestroy = onDestroy;
    componentOrService.ngOnInit = onInit;

    return takeUntil((onDestroy[key] = new Subject()));
  }

  public static mapToFormSharedValue<TFormValue = any>(
    formGroup: UntypedFormGroup,
  ): OperatorFunction<TFormValue, { previous: TFormValue; current: TFormValue }> {
    return pipe(
      startWith(formGroup.getRawValue() as TFormValue),
      debounceTime(0),
      map(() => formGroup.getRawValue()),
      distinctUntilChanged(isEquivalent),
      scan<TFormValue, { previous: TFormValue; current: TFormValue }>(
        (acc, current) => {
          acc.previous = acc.current;
          acc.current = current;
          return acc;
        },
        { previous: {} as TFormValue, current: formGroup.getRawValue() },
      ),
      shareReplay(1),
    );
  }

  public static isCurrentUserInRole(...roles: RoleConstant[]): OperatorFunction<UserInformation, boolean> {
    return pipe(map(user => Boolean(user?.isInRole(...roles))));
  }

  public static vmFromLatest<TVm extends {}, TComputedVm extends {} = never>(
    vmBase: { [K in keyof TVm]: Observable<TVm[K]> },
    computeFunction?: (vmBaseReturn: TVm) => TComputedVm | Observable<TComputedVm>,
  ): Observable<TVm & TComputedVm> {
    const vmBaseKeys = Object.keys(vmBase);
    const vmBaseValues = Object.values(vmBase);
    return combineLatest(vmBaseValues).pipe(
      switchMap(responses => {
        const returnVm = vmBaseKeys.reduce((vm, key, index) => {
          vm[key] = responses[index];
          return vm;
        }, {} as TVm);

        if (computeFunction) {
          const computedVm = computeFunction(returnVm);

          return isObservable(computedVm)
            ? computedVm.pipe(map(computed => Object.assign(returnVm, computed) as TVm & TComputedVm))
            : of(Object.assign(returnVm, computedVm) as TVm & TComputedVm);
        }

        return of(returnVm as TVm & TComputedVm);
      }),
    );
  }

  public static uniqueBy<TItem extends {} = unknown>(identity: keyof TItem): OperatorFunction<TItem[], TItem[]> {
    return map<TItem[], TItem[]>(items => uniqBy(items, identity));
  }

  public static downloadBlob = (name: string) => (source: Observable<FileResponse>) =>
    new Observable<FileResponse>(observer => {
      return source.subscribe({
        next(file) {
          const fileName = `${name}_${moment.utc().format('YYYY_MM_DD_hh_mm')}_UTC.csv`;
          CommonUtils.download(file.data, fileName);
          observer.next(file);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
    });

  /**
   * Like finalize, except we also call fn when obs emits
   * @param fn
   */
  public static tapFinalize = <T>(fn: () => void) => (source: Observable<T>) =>
    new Observable<T>(observer => {
      return source.pipe(tap({
        next: fn,
        error: fn,
        complete: fn
      })).subscribe({
        next(args: T) {
          observer.next(args);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      })
    })
}

function noop() {}
