import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class NoteComponent implements OnInit {
  @Input() public group: UntypedFormGroup;

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: { messages: ['An optional field to maintain notes.'] },
    });
  }
}
