import { Component, OnInit } from '@angular/core';
import { NavPath } from '../models/navpath';
import { navPaths } from './nav-paths';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
})
export class SideNavComponent implements OnInit {
  public navPaths: NavPath[] = navPaths;
  public inMobile = false;

  constructor() {}

  ngOnInit(): void {}
}
