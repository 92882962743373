import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'store-number',
  templateUrl: './storenumber.component.html',
  styleUrls: ['../../addservicedeployment-task-location.component.scss'],
})
export class StoreNumberComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public storeNumberExist: boolean;
  @Input() public storeNumberLocationTimeZoneExist: boolean;
  @Output() private storeNumberEntered: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Each task location within a service task should be assigned to a unique store number.',
          'Task location with duplicate store number will be rejected.',
        ],
      },
    });
  }

  public get storeNumberControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('storeNumber');
  }

  public checkStoreNumberAlreadyExist(event: string) {
    this.storeNumberEntered.emit(event);
  }
}
