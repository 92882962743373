<div class="site-wrapper">
  <p-toast></p-toast>
  <header>
    <navbar [showMainNav]="showMainNav"></navbar>
  </header>
  <div class="site-nav d-none d-lg-block vertical-align-top m-0 p-0" *ngIf="showSideNav">
    <div class="card margin-0 padding-0 vertical-align-top sticky-top">
      <div class="text-center m-auto">
        <div class="d-flex align-items-center">
          <img
            (click)="goToMyAccount()"
            routerLinkActive="router-link-active"
            src="{{ userAccountLogo | imageFlow: 'w=150' | async }}"
            alt="Account-Logo"
            class="img-fluid"
            style="cursor: pointer"
          />
        </div>
        <div class="text-muted">
          {{ userAccount }}
        </div>
      </div>
    </div>
    <sidenav></sidenav>
    <div *ngIf="privacyPolicyUrl" class="card text-center p-1 sticky-bottom">
      <a [href]="privacyPolicyUrl" target="_blank">Privacy Policy</a>
    </div>
  </div>
  <div [ngClass]="{ 'ms-lg-250': showMainNav }" class="site-content">
    <div [@routeFade]="getRouterOutletState(o)" class="w-100 h-100">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
  <div #flyouts>
    <div [ngClass]="{ open: panelShow === panelType.Settings }" class="flyout">
      <site-settings></site-settings>
    </div>
    <div [ngClass]="{ open: panelShow === panelType.Alert }" class="flyout">
      <alerts></alerts>
    </div>
    <div [ngClass]="{ open: panelShow === panelType.MainNav }" class="flyout bg-primary d-lg-none overflow-scroll">
      <button (click)="closeNav()" type="button" class="close closer text-light text-end" aria-label="Close"></button>
      <p class="title text-light">Menu</p>
      <hr />
      <navigation
        [listClass]="'list-unstyled'"
        [itemClass]="'d-block text-light'"
        [linkClass]="'d-block pt-3 pb-3 text-light'"
        [chevronClass]="'fa fa-angle-down'"
      ></navigation>
    </div>
  </div>
  <div *ngIf="canViewChatBot$ | async" class="chatbot-container">
    <support-bot></support-bot>
  </div>
</div>
