import { RangeSetting } from '../../shared/components/misc/date-range-selector/range-setting';
import { TileSettings } from '../../dashboard/components/dashboard-tile/tile-settings';
import { TileFeedBackEnum } from './models/TileFeedbackEnum';

export class FeedbackFilterParameters {
  constructor() {
    this.filterText = '';
    this.page = 1;
    this.pageSize = 20;
    this.sortColumn = '-updatedDate';
    this.ascending = true;
    this.statBox = TileFeedBackEnum.NegativeTaskFeedback;
    this.rangeSetting = {
      id: 7,
      name: 'Last 7d',
      days: 7,
      isDefault: true,
    };
  }
  public filterText: string;
  public page: number;
  public pageSize: number;
  public sortColumn: string;
  public ascending: boolean;
  public fromDate: any;
  public toDate: any;
  public rangeSetting: RangeSetting;
  public tileSettings: TileSettings;
  public statBox: TileFeedBackEnum;
}
