import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'v-question-mark-tooltip',
  template: `
    <i class="fa fa-question" [ngClass]="sizeClass" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition"></i>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionMarkTooltipComponent {
  @Input() tooltip: string;
  @Input() sizeClass: 'fa-xs' | 'fa-sm' | 'fa-lg' | 'fa-1x' | 'fa-2x' | 'fa-3x' | 'fa-5x' | 'fa-7x' | 'fa-10x' =
    'fa-1x';
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'top';
}
