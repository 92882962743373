import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../configuration';
import { RedirectClient } from '@volt/api';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RedirectComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _redirectClient: RedirectClient,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  ngOnInit() {
    const queryParams = this._route.queryParams.pipe(
      map(params => {
        const path = params['path'];
        return path;
      }),
    );
    const dataPrams = this._route.data.pipe(
      map(params => {
        const path = decodeURI(params['path']);
        return path;
      }),
    );

    combineLatest(queryParams, dataPrams)
      .pipe(
        mergeMap(([qp, dp]) => {
          const path = qp === undefined ? dp : qp;
          return this._redirectClient.triggerRemoteRedirectRequest(path);
        }),
      )
      .subscribe((token: string) => {
        if (token) {
          const url = `${this._appConfig.legacyDashboard}/RemoteRedirect?token=${token}`;
          try {
            window.location.replace(url);
          } catch (e) {
            window.location.href = url;
          }
          return;
        }
        this._router.navigate(['login']);
      });
  }
}
