export const ServiceDeploymentStateConstant = {
  FilterStateName: 'servicedeploymentFilter',
};

export const ServiceDeploymentFilterActions = {
  SetFilter: 'SET_SERVICEDEPLOYMENT_FILTER',
};

export const AddServiceDeploymentStateConstant = {
  FilterStateName: 'addservicedeploymentFilter',
};

export const AddServiceDeploymentFilterActions = {
  SetFilter: 'SET_ADDSERVICEDEPLOYMENT_FILTER',
};

export const AddServiceDeploymentTaskStateConstant = {
  FilterStateName: 'addservicedeploymentTaskFilter',
};

export const AddServiceDeploymentTaskFilterActions = {
  SetFilter: 'SET_ADDSERVICEDEPLOYMENT_TASK_FILTER',
};

export const AddServiceDeploymentTaskLocationStateConstant = {
  FilterStateName: 'addservicedeploymentTaskLocationFilter',
};

export const AddServiceDeploymentTaskLocationFilterActions = {
  SetFilter: 'SET_ADDSERVICEDEPLOYMENT_TASK_LOCATION_FILTER',
};
