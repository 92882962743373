<p-toast [baseZIndex]="100"></p-toast>
<v-loading-container [working]="loading$ | async">
  <v-info-box [infoTitle]="'Info'">
    <ul>
      <li>
        <b>Child:</b>
        The company will be able to see some data related to the child account
      </li>
      <li>
        <b>Related:</b>
        Data on Related companies will be aggregated on specific reports
      </li>
    </ul>
  </v-info-box>
  <p-toolbar [styleClass]="'mb-5'">
    <form [formGroup]="form" (submit)="addRelationship()">
      <div class="d-flex flex-sm-row flex-column">
        <p-dropdown
          [options]="relationshipList"
          [style]="{ width: '100%' }"
          class="ms-md-2 mb-2 mb-md-0"
          formControlName="relationship"
        ></p-dropdown>

        <p-dropdown
          [filter]="true"
          [options]="accountList"
          [virtualScroll]="true"
          [itemSize]="30"
          [style]="{ width: '100%' }"
          formControlName="account"
          class="ms-md-2 mb-2 mb-md-0"
        ></p-dropdown>

        <ng-container *permissions="[pc.names.AccountsManageHierarchy, pc.privilege.Create]">
          <button type="submit" class="btn btn-success btn-sm rounded-0 ms-md-2 mb-2 mb-md-0" [disabled]="!form.valid">
            Submit
          </button>
        </ng-container>
      </div>
    </form>
  </p-toolbar>

  <shared-table [columns]="columns" [data]="data" [state]="tableState" (stateChanged)="onTableStateChanged($event)">
    <ng-template sharedTable="relationship" let-row>
      <relationship [data]="row"></relationship>
    </ng-template>

    <ng-template sharedTable="deleteRecord" let-row>
      <delete-relationship [data]="row" [pc]="pc" (deleteClicked)="onDeleteRecord($event)"></delete-relationship>
    </ng-template>
  </shared-table>
</v-loading-container>
