import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ContentChildren,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslationService } from '@volt/shared/services/translation.service';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Translations } from '../../../services/translations.types';
import { ContainerWrapperTemplateDirective } from './container-wrapper-template.directive';
import { ContainerWrapperToastService } from './container-wrapper-toast.service';

interface ContainerTemplate {
  translated: Translations;
}

@Component({
  selector: 'v-container-wrapper',
  template: `
    <ng-container *ngIf="withToast" #toastTemplate></ng-container>
    <div class="container-fluid" *ngIf="translatedDataObservable | async as translatedData; else loading">
      <header *ngIf="showHeader" class="page-header d-flex align-items-center justify-content-between mb-0">
        <div class="d-flex align-items-center">
          <ng-container *ngIf="headerLeftTemplate; else noLeftHeaderTemplate">
            <ng-container
              *ngTemplateOutlet="headerLeftTemplate; context: { translated: translatedData }"
            ></ng-container>
          </ng-container>
          <ng-template #noLeftHeaderTemplate>
            <h2 class="page-title" data-cy="container-title">{{ translatedData[headerKey] || header }}</h2>
          </ng-template>
          <ng-container *ngIf="backToTemplate; else noBackToTemplate">
            <div class="page-title-bar">
              <ng-container *ngTemplateOutlet="backToTemplate; context: { translated: translatedData }"></ng-container>
            </div>
          </ng-container>
          <ng-template #noBackToTemplate>
            <div *ngIf="backPageLink?.length" class="page-title-bar">
              <a [routerLink]="backPageLink">
                <i class="fa fa-chevron-left"></i>
                {{ translatedData.back }} {{ translatedData.to }} {{ translatedData[backPageKey] }}
              </a>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="headerRightTemplate">
          <ng-container *ngTemplateOutlet="headerRightTemplate; context: { translated: translatedData }"></ng-container>
        </ng-container>
      </header>

      <ng-container *ngTemplateOutlet="contentTemplate; context: { translated: translatedData }"></ng-container>

      <ng-content></ng-content>
    </div>
    <ng-template #loading>
      <v-loading-spinner></v-loading-spinner>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerWrapperComponent<TContainerVm = any>
  implements AfterContentInit, AfterViewInit, OnDestroy, OnInit {
  @Input() translatedDataObservable: Observable<Translations>;
  @Input() headerKey: keyof Translations;
  @Input() backPageLink: string[];
  @Input() backPageKey: keyof Translations;
  @Input() header: string;
  @Input() withToast = false;
  @Input() toastPosition:
    | 'top-right'
    | 'top-left'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'bottom-center'
    | 'center' = 'top-right';
  @Input() toastBaseZIndex = 10000;
  @Input() toastStyleClass: string;
  @Input() showHeader = true;

  @ViewChild('toastTemplate', { read: ViewContainerRef }) toastTemplate: ViewContainerRef;

  @ContentChildren(ContainerWrapperTemplateDirective) wrapperTemplates: QueryList<ContainerWrapperTemplateDirective>;

  headerRightTemplate: TemplateRef<ContainerTemplate>;
  headerLeftTemplate: TemplateRef<ContainerTemplate>;
  contentTemplate: TemplateRef<ContainerTemplate>;
  backToTemplate: TemplateRef<ContainerTemplate>;

  constructor(
    private readonly _injector: Injector,
    private readonly _containerWrapperToastService: ContainerWrapperToastService,
    private readonly _cd: ChangeDetectorRef,
    private readonly _translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    if (this.translatedDataObservable == null) {
      this.translatedDataObservable = this._translationService.getTranslatedObject();
    }
  }

  ngAfterContentInit(): void {
    this.wrapperTemplates.changes.pipe(startWith(this.wrapperTemplates)).subscribe(queryList => {
      queryList.forEach(template => {
        switch (template.templateString) {
          case 'headerRight':
            this.headerRightTemplate = template.templateRef;
            break;
          case 'headerLeft':
            this.headerLeftTemplate = template.templateRef;
            break;
          case 'content':
            this.contentTemplate = template.templateRef;
            break;
          case 'backTo':
            this.backToTemplate = template.templateRef;
            break;
        }
      });
      this._cd.markForCheck();
    });
  }

  async ngAfterViewInit() {
    if (!this.withToast || !this.toastTemplate) {
      return;
    }

    const { ContainerWrapperToastComponent } = await import('./container-wrapper-toast.component');
    const { instance: componentInstance } = this.toastTemplate.createComponent(ContainerWrapperToastComponent);
    componentInstance.position = this.toastPosition;
    componentInstance.baseZIndex = this.toastBaseZIndex;
    componentInstance.toastProxy = this._containerWrapperToastService;
    componentInstance.styleClass = this.toastStyleClass;
  }

  ngOnDestroy(): void {
    if (!this.withToast || !this.toastTemplate) {
      return;
    }

    this.toastTemplate?.clear();
  }
}
