import { combineReducers } from 'redux';
import { ActionLogReducer } from '../../action-log/reducer/action-log.reducer';
import { DashboardReducer } from '../../dashboard/reducers/dashboard.reducer';
import { LocationFilterReducer } from '../../locations/reducers/location.reducer';
import { FeedBackReducer } from '../../reports/feedback/reducers/feedback.reducers';
import { AddServiceDeploymentReducer } from '../../servicedeployments-tasks-locations/reducers/add-servicedeployment.reducer';
import { AddServiceDeploymentTaskReducer } from '../../servicedeployments-tasks-locations/reducers/add-servicedeploymenttask.reducer';
import { AddServiceDeploymentTaskLocationReducer } from '../../servicedeployments-tasks-locations/reducers/add-servicedeploymenttasklocation.reducer';
import { serviceDeploymentsReportReducer } from '../../servicedeployments-tasks-locations/reducers/service-deployments-report.reducer';
import { ServiceDeploymentReducer } from '../../servicedeployments-tasks-locations/reducers/servicedeployment.reducer';
import { UserFilterReducer } from '../../users/reducers/user.reducer';
import { UserVisitsFilterReducer, UserVisitsReducer } from '../../users/reducers/visit.reducer';
import { SettingsReducer } from './settings.reducer';

// Define the global store shape by combining our application's
// reducers together into a given structure.
export const rootReducer = combineReducers({
  dashboardState: DashboardReducer,
  locationFilter: LocationFilterReducer,
  userFilter: UserFilterReducer,
  userVisits: UserVisitsReducer,
  userVisitFilter: UserVisitsFilterReducer,
  feedBackFilter: FeedBackReducer,
  servicedeploymentFilter: ServiceDeploymentReducer,
  addservicedeploymentFilter: AddServiceDeploymentReducer,
  addservicedeploymentTaskFilter: AddServiceDeploymentTaskReducer,
  addservicedeploymentTaskLocationFilter: AddServiceDeploymentTaskLocationReducer,
  actionLogFilter: ActionLogReducer,
  settingsState: SettingsReducer,
  serviceDeploymentsReportFilters: serviceDeploymentsReportReducer,
});
