import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'v-info-box',
  template: `
    <div class="card my-3 border-primary">
      <div class="card-body d-flex flex-row">
        <div><i class="fa fa-info-circle fa-3x me-3 text-primary"></i></div>
        <div>
          <h6 class="mb-3">
            <b>{{ infoTitle }}</b>
          </h6>
          <h6>
            {{ infoMessage }}
          </h6>

          <div>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxComponent {
  @Input() infoTitle: string;
  @Input() infoMessage: string;
}
