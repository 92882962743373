import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { UntypedFormControl, Validators } from '@angular/forms';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';
import { AlternativeNumbersClient } from '@volt/api';

@Component({
  selector: 'supplier-number',
  templateUrl: './supplier-number.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierNumberComponent implements OnInit {
  @Input() translations: Translations;
  @Input() control: UntypedFormControl;
  @Input() accountId: number;
  @Input() required = true;
  @Input() disabled = false;

  constructor(private readonly _numbersClient: AlternativeNumbersClient) { }

  ngOnInit(): void {
    if(this.accountId != null) {
      this.control.addAsyncValidators((control) => {
        if(control.value == null) return of(null);

        return this._numbersClient.getSupplierNumber(control.value, this.accountId).pipe(
          map(res => res ? { taken: 'true' } : null),
        );
      });
    }

    if(this.required) {
      this.control.addValidators(Validators.required);
    }

    this.control.updateValueAndValidity();
  }
}
