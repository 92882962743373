import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DropDownModel } from '../../../../../api.client';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AddServicedeploymentReduxService } from '../../services/add-servicedeployment.redux.service';
import { AddServiceDeployment } from '../../../../models/addServiceDeployment';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'field-group-dropdown',
  templateUrl: './field-group-dropdown.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class FieldGroupDropdownComponent implements OnInit, OnChanges {
  @Input() public fieldTypeDropModel: DropDownModel[];
  @Input() public submitted;
  @Input() public checkFieldGroupRole: boolean;
  @Input() public providerAccount: string;
  public providerAccountName: string;
  @Input() public group: UntypedFormGroup;
  @Input() public isEdit;
  @Output() private selectedAccount: EventEmitter<number> = new EventEmitter<number>();

  @Input() set fieldGroupName(currentServiceDeployment: AddServiceDeployment) {
    this.providerAccountName =
      currentServiceDeployment.accountId !== null ? currentServiceDeployment.accountId.toString() : null;
  }

  @Input() isProvider;

  constructor(
    private _addservicedeploymentReduxService: AddServicedeploymentReduxService,
    private _dynamicDialogService: DynamicDialogService,
  ) {}

  ngOnInit(): void {}

  onAccountChange(event: any) {
    const updateAccountId = { accountId: event.value };
    this._addservicedeploymentReduxService.setFilter(updateAccountId);
  }

  public get fieldGroupTypeControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('fieldGroupType');
  }

  ngOnChanges(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: { messages: ['Field Groups will only be able to create Service Deployments for their organization.'] },
    });
  }
}
