import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ColSpanOption } from '../../../types';
import { BaseControlComponent } from '../base-control.component';
import { BaseControlService } from '../base-control.service';

export const checkboxDefaultLabelColSpan: ColSpanOption = {
  xs: 10,
  md: 10,
};

export const checkboxDefaultControlColSpan: ColSpanOption = {
  xs: 2,
  md: 2,
};

@Component({
  selector: 'v-checkbox-control',
  template: `
    <ng-container *ngIf="translatedFormData$ | async as translated">
      <div class="d-flex">
        <p-checkbox
          class="me-2"
          [id]="controlId"
          [formControl]="control"
          binary="true"
          [disableControl]="disabled"
        ></p-checkbox>
        <label class="mb-0 flex-shrink-1" [for]="controlId">
          <span [ngClass]="{ 'fw-bold': boldLabel }">{{ labelPart1 }}</span>
          <span style="white-space: nowrap" [ngClass]="{ 'fw-bold': boldLabel }">
            {{ labelPart2 }}
            <span class="text-danger" *ngIf="isRequired">*</span>
            <v-question-mark-tooltip
              *ngIf="tooltipText"
              class="d-inline-block"
              sizeClass="fa-lg"
              [tooltip]="tooltipText"
            ></v-question-mark-tooltip>
          </span>
        </label>
      </div>
      <div class="d-flex" *ngIf="displayError">
        <div class="flex-grow-1" *ngIf="control && control.errors" style="padding-left: 28px;">
          <div *ngFor="let error of control.errors | keyvalue">
            <span class="text-danger">
              <v-control-error
                [controlLabel]="labelPart1 + labelPart2"
                [error]="error"
                [translations]="translated"
              ></v-control-error>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      :host ::ng-deep p-checkbox {
        display: inline-block;
        height: 20px;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxControlComponent extends BaseControlComponent implements OnInit, OnChanges {
  @Input() displayError = true;
  labelPart1: string;
  labelPart2: string;

  @Input() label: string;

  @HostBinding('class.d-inline-block')
  @Input()
  inline = false;

  @Input() disabled = false;
  @Input() overrideColSpan = false;

  constructor(private readonly _baseControlService: BaseControlService, private readonly _cdr: ChangeDetectorRef) {
    super(_baseControlService);
  }

  ngOnInit(): void {
    this.labelColSpan = this.overrideColSpan ? this.labelColSpan : checkboxDefaultLabelColSpan;
    this.controlColSpan = this.overrideColSpan ? this.controlColSpan : checkboxDefaultControlColSpan;
    this._baseControlService.formReset$.pipe(takeUntil(this._baseControlDestroy$)).subscribe(() => {
      if (this.control) {
        this.control.markAsUntouched({ onlySelf: true });
        this.control.markAsPristine({ onlySelf: true });
        this.control.updateValueAndValidity({ onlySelf: true });
        this._cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label) {
      const words = changes.label.currentValue.split(' ');
      if (words.length === 0) {
        this.labelPart1 = '';
        this.labelPart2 = '';
      } else if (words.length === 1) {
        this.labelPart1 = '';
        this.labelPart2 = words[0];
      } else {
        this.labelPart1 = words.filter((_, i) => i !== words.length - 1).join(' ');
        this.labelPart2 = ' ' + words[words.length - 1];
      }
    }
  }
}
