import { Router } from '@angular/router';
import { Translations } from '@volt/shared/services/translations.types';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import { Observable } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { GlobalSearchService, GlobalSearchResults } from '../services/global-search.service';
import { tap } from 'rxjs/operators';
import { SearchableRoute } from '../models/searchable-route.types';
import { LayoutService } from '../../services/layout.service';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { UntypedFormBuilder } from '@angular/forms';

export interface GlobalSearchVm {
  search: GlobalSearchResults;
  translated: Translations;
}

@Component({
  selector: 'global-search',
  template: `
    <div (keydown)="onKeyPress($event.key)" #globalSearch>
      <ng-container *ngIf="!isMinimized; else iconOnly">
        <div class="d-flex align-items-center">
          <a (click)="minimizeSearch()" class="back-button" *ngIf="isMobileView">
            <i class="fa fa-chevron-left"></i>
          </a>
          <form [formGroup]="form">
            <div class="search-input" [ngClass]="{ needsMargin: !isMobileView }">
              <i class="fa fa-search"></i>
              <input
                class="form-control"
                (focus)="openResultsWindow()"
                #searchInput
                formControlName="search"
                type="search"
              />
            </div>
          </form>
        </div>
        <div class="search-result-container" [ngClass]="{ open: searchActive }" *ngIf="vm$ | async as vm">
          <ng-container *ngIf="searchSectionKeys.length">
            <ng-container
              *ngIf="vm.search?.history?.items?.length > 0 || vm.search?.results?.items?.length > 0; else noResults"
            >
              <ng-container *ngFor="let key of searchSectionKeys">
                <div *ngIf="vm.search[key]?.items?.length" class="search-result-section">
                  <div class="search-group-title">
                    <small>
                      <b>{{ vm.translated[vm.search[key].titleKey] }}</b>
                    </small>
                  </div>
                  <ng-container *ngFor="let link of vm.search[key]?.items">
                    <div class="search-link clickable" (click)="onItemSelect(link)">
                      <span class="text-primary">{{ link.title }}</span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noResults>
              <div class="no-results">
                <small>
                  <b>{{ vm.translated.searchReturnedNoReuslts }}</b>
                </small>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <ng-template #iconOnly>
      <a (click)="maximizeSearch()" class="search-icon-only">
        <i class="fa fa-search" [ngClass]="{ mobile: isMobileView }"></i>
      </a>
    </ng-template>
  `,
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent extends BaseFilterForm implements OnInit, OnDestroy {
  @ViewChild('searchInput') private _searchInput: ElementRef;
  @ViewChild('globalSearch') private _globalSearch: ElementRef;

  @Input() isMobileView: boolean = false;
  @Input() isMinimized: boolean = false;
  @Output() searchMinimized: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchTermModel: string = '';
  vm$: Observable<GlobalSearchVm>;
  searchActive: boolean = false;
  searchSectionKeys: string[] = [];

  @HostListener('document:click', ['$event'])
  private _clickWatch(event: any) {
    if (this._layoutService.isClickInsideElement(event, this._globalSearch)) {
      return;
    }
    if (!this._layoutService.isClickInsideElement(event, this._globalSearch)) {
      this.searchActive = false;
    }
  }

  constructor(
    private readonly _globalSearchService: GlobalSearchService,
    private readonly _router: Router,
    private readonly _layoutService: LayoutService,
    private readonly _fb: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initFormSharedValues(({ previous, current }) => {
      if (current.search !== previous.search) {
        this.onSearch(current.search);
      }
    });
    this.vm$ = OperatorUtils.vmFromLatest<GlobalSearchVm>({
      search: this._globalSearchService.searchResults$,
      translated: this._globalSearchService.translatedData$,
    }).pipe(
      tap(vm => {
        this.searchSectionKeys = Object.keys(vm.search);
      }),
    );
  }

  onSearch(term: string) {
    console.log('SET SEARCH TERM', { term })
    this._globalSearchService.setSearchTerm(term);
  }

  onItemSelect(item: SearchableRoute) {
    this._globalSearchService.saveSearchHistory(item.title, item.route).subscribe(() => {
      this.closeResultsWindow();
      this._router.navigate([item.route]);
    });
  }

  openResultsWindow() {
    this.searchActive = true;
  }

  closeResultsWindow() {
    this.searchActive = false;
    this.form.get('search').setValue('');
    this._searchInput.nativeElement.blur();
  }

  maximizeSearch() {
    this.searchMinimized.next(false);
  }

  minimizeSearch() {
    this.searchMinimized.next(true);
  }

  onKeyPress(key: string) {
    switch (key) {
      case 'Escape':
        this.closeResultsWindow();
        break;

      default:
        break;
    }
  }

  initForm(): void {
    this.form = this._fb.group({ search: '' });
  }
}
