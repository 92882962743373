<v-loading-container [working]="loading">
  <ng-container>
    <modal-header [title]="'Do you want delete this file?'" (onClose)="onClose()"></modal-header>

    <div class="modal-body">
      <p>
        By clicking "Delete" you are agreeing to delete the instruction {{ this.name }} from the current service task
      </p>
    </div>

    <modal-footer
      [footerStyle]="'delete_btn'"
      (onSubmit)="deleteInstruction(name, serviceTaskReferenceNumber)"
      (onClose)="closeModal()"
    ></modal-footer>
  </ng-container>
</v-loading-container>
