<div class="container-fluid">
  <header class="page-header d-flex align-items-center">
    <h1 class="page-title">{{ title || 'Report' }}</h1>
  </header>
  <div class="row">
    <div class="col card ms-2 me-2 mt-2 mb-1 p-2">
      <div *ngIf="isValidConfiguration; else reportUnavailable" id="pbi-container" style="height: 85vh;"></div>
      <ng-template #reportUnavailable>
        <div class="col"><h3 class="text-center">Report Unavailable</h3></div>
      </ng-template>
    </div>
  </div>
</div>
