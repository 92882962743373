import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'task-location-ref-number',
  templateUrl: './task-location-reference-number.component.html',
  styleUrls: ['../../addservicedeployment-task-location.component.scss'],
})
export class TaskLocationRefNumberComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public taskLocationRefNumberExist: boolean;
  @Input() public isEdit;
  @Output() private taskLocationRefNumberEntered: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Task Location Reference Number should be unique for a service task.',
          'Duplicate task location reference number will be rejected by the system.',
        ],
      },
    });
  }

  public get LocationRefNoControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('locationRefNumber');
  }

  public checkTaskLocationRefNumberAlreadyExist(event: string) {
    this.taskLocationRefNumberEntered.emit(event);
  }
}
