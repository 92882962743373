<v-loading-container>
  <p-table [columns]="cols" *ngIf="selectedTag" [value]="data$ | async" [rows]="20" [responsive]="true" [lazy]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div *ngIf="col.field == 'checkBoxes'">
            <div class="p-g-12">
              <p-checkbox
                value="{{ rowData['id'] }}"
                (onChange)="getTagAssignedId($event, rowData['id'])"
                [(ngModel)]="selectedTag"
              ></p-checkbox>
            </div>
          </div>

          <div>
            {{ rowData[col.field] }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</v-loading-container>
