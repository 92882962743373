import './app/shared/services/request.service';
import './monkey-patches';

import { ApplicationRef, enableProdMode, NgZone } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NavigationExtras, Router } from '@angular/router';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PC, Privilege } from '@volt/shared/services/permissions.service';
import { ImportLogApiBaseUrl } from './app/api-import-log.client';
import { ApiBaseUrl } from '@volt/api';
import { AppModule } from './app/app.module';
import { FeatureAvailabilityBaseApiUrl } from './app/feature-availability.client';
import { RootInjector } from './app/root-injector';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration, IdentityConfiguration } from './configuration';
import { RETAILER_CONFIG, RetailerConfigType } from './retailer-config';
import { environment } from './environments/environment';
import './monkey-patches';
import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { authConfig, authModuleConfig } from './app/auth/authConfig';
import { BlobApiBaseUrl } from '@volt/api-blob';
import { AccountsApiBaseUrl } from "@volt/api-accounts";
import {FeatureApiBaseUrl} from "./app/api-feature.client";
import {LocationApiBaseUrl} from "./app/api-location.client";
import {FEATURE_FLAGS, FeatureFlags} from "./feature-flags";
import { TenantIdValue } from '@volt/shared/services/tenant-id.service';

(async () => {
  try {
    var [config, identityConfig, retailerConfig]: [ApplicationConfiguration, IdentityConfiguration, RetailerConfigType] = await
        Promise.all([
      fetch('assets/data/configuration.json').then(res => res.json()),
      fetch('assets/data/identity.json').then(res => res.json()),
      fetch('assets/data/retailer.json').then(res => res.json())
    ])
    const appFeatureFlags: FeatureFlags = await fetch(config.apiUrl + '/dashboard/v1/feature-flags').then(res => res.json());
    authConfig.issuer = identityConfig.issuer;
    authConfig.clientId = identityConfig.clientId;
    authModuleConfig.resourceServer.allowedUrls.push(identityConfig.resourceServer);

    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: AuthConfig, useValue: authConfig },
      { provide: OAuthModuleConfig, useValue: authModuleConfig },
      { provide: APPLICATION_CONFIGURATION, useValue: config },
      { provide: RETAILER_CONFIG, useValue: retailerConfig },
      { provide: FEATURE_FLAGS, useValue: appFeatureFlags },
      { provide: ApiBaseUrl, useValue: config.apiUrl },
      { provide: ImportLogApiBaseUrl, useValue: config.importLogApiUrl },
      { provide: FeatureAvailabilityBaseApiUrl, useValue: config.featureAvailabilityApiUrl },
      { provide: BlobApiBaseUrl, useValue: config.blobApiUrl },
      { provide: AccountsApiBaseUrl, useValue: config.accountsApiUrl },
      { provide: FeatureApiBaseUrl, useValue: config.featureApiBaseUrl },
      { provide: LocationApiBaseUrl, useValue: config.locationApiBaseUrl },
      { provide: PC, useValue: { names: PermissionNames, privilege: Privilege } },
      { provide: TenantIdValue, useValue: config.tenantId }
    ])
      .bootstrapModule(AppModule)
      .then(ngModuleRef => {
        RootInjector.setInjector(ngModuleRef.injector);
        if (!environment.production) {
          const ref = ngModuleRef.injector.get(ApplicationRef);
          enableDebugTools(ref.components[0]);
        }

        if ((window as any).Cypress) {
          // Run in Cypress
          (window as any).ngNavigate = (commands: any[], extra?: NavigationExtras) => {
            ngModuleRef.injector.get(NgZone)?.run(() => {
              ngModuleRef.injector.get(Router).navigate(commands, extra);
            });
          };
        }
      })
      .catch(err => console.error(err));
  } catch (e) {
    console.error(`Load pre-initialize configuration error: ${e}`);
  }
})();
