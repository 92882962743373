import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { Support } from '../../../../../models/volt-account';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'account-support',
  templateUrl: './account-support.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSupportComponent extends BaseFilterForm<Support> {
  @Input() translations: Translations;

  _support: Support;
  @Input() set support(s: Support) {
    this._support = s;
    this.controls.email.setValue(s?.email);
    this.controls.phone.setValue(s?.phone);
    this.controls.note.setValue(s?.note);
  }

  @Input() set disabled(d: boolean) {
    if(d) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  constructor(private readonly _fb: UntypedFormBuilder) {
    super();
    this.initForm();
  }

  initForm(): void {
    this.form = this._fb.group({
      email: ['', [Validators.email, Validators.required]],
      phone: ['', Validators.required],
      note: []
    })
  }

  get invalid() {
    return this.form.invalid;
  }

  get value() {
    return {
      email: this.controls.email.value,
      phone: this.controls.phone.value,
      note: this.controls.note.value
    } as Support;
  }
}
