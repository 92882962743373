<p-table [value]="products" [responsive]="true" responsiveLayout="scroll">
  <ng-template pTemplate="caption">
    <div style="text-align: right">
      <span class="p-input-icon-left">
        <i class="fa fa-search"></i>
        <input type="text" pInputText placeholder="Search" #input />
      </span>
      <button
        type="button"
        label="Search"
        class="btn btn-primary btn-sm"
        style="cursor: pointer; margin: 0 4px 0 0"
        (click)="search(input.value)"
        data-cy="search-button"
      >
        Search
      </button>
      <button
        type="button"
        label="Clear"
        class="btn btn-primary btn-sm"
        style="cursor: pointer; margin: 0 4px 0 0"
        icon="fa-file"
        (click)="clearSearch(); input.value = ''"
        data-cy="clear-button"
      >
        Clear
      </button>
      <button
        type="button"
        label="Add"
        class="btn btn-primary btn-sm"
        style="cursor: pointer"
        icon="fa-file"
        (click)="addProduct()"
        data-cy="add-product-button"
      >
        Add Product
      </button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr data-cy="products-headers" >
      <th>WIC/Part#</th>
      <th>Image Url</th>
      <th>Part Name</th>
      <th>Category</th>
      <th>Updated By</th>
      <th>Updated</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr [id]="product.id">
      <td (click)="editProduct(product)" style="color: cornflowerblue; text-decoration: underline">
        {{ product.itemNumber }}
      </td>
      <td>
        <a *ngIf="product.imageUrl | secureUrl | async as url" [href]="url" target="_blank">{{ url }}</a>
      </td>
      <td>{{ product.name }}</td>
      <td>{{ product.category }}</td>
      <td>{{ product.createdByName }} ({{ product.createdByEmail }})</td>
      <td>{{ product.updatedDate.format('lll') }}</td>
    </tr>
  </ng-template>
</p-table>
