<p-toolbar styleClass="mb-2">
  <div class="v-filter-grid">
    <div *authorize="[allowedView.RetailerAdmin, allowedView.Admin]">
      <div *ngIf="pspAccountList !== undefined">
        <label>{{ translations.account }}</label>
        <p-dropdown
          [options]="pspAccountList"
          (onChange)="FilterByPSP()"
          [(ngModel)]="selectedPSP"
          filter="true"
          styleClass="w-100"
        >
          <ng-template let-item pTemplate="selectedPSP">
            <span style="vertical-align: middle;">{{ item.label }}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div>
      <label>{{ translations.searchByType }}</label>
      <p-dropdown
        [options]="searchTypes"
        [(ngModel)]="selectedOption"
        [disabled]="!isAccountSelected"
        (onChange)="getSelectedOption($event)"
        styleClass="w-100"
      ></p-dropdown>
    </div>

    <div>
      <label>{{ translations.timeFrame }}</label>
      <p-dropdown
        [options]="datesDropDownList"
        [(ngModel)]="days"
        [disabled]="!isAccountSelected"
        (onChange)="setDays($event)"
        styleClass="w-100"
      ></p-dropdown>
    </div>

    <!-- Search by transaction -->
    <ng-container [ngSwitch]="selectedOption">
      <div>
        <label *ngIf="!!selectedOption">{{ translations.search }}</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="transactionId"
          placeholder="Transaction ID"
          [disabled]="!isAccountSelected"
          *ngSwitchCase="'transaction'" />
        <!-- Search by reference -->
        <input
          type="text"
          class="form-control"
          [(ngModel)]="referenceNumber"
          placeholder="Reference #"
          [disabled]="!isAccountSelected"
          *ngSwitchCase="'reference'" />

        <input
          type="text"
          class="form-control"
          [defaultValue]="status"
          [disabled]="true"
          *ngSwitchCase="'statusAndType'" />
      </div>

      <div *ngSwitchCase="'statusAndType'">
        <label>{{ translations.type }}</label>
        <p-dropdown
          [options]="typeDropModel"
          (onChange)="setType($event)"
          styleClass="w-100"
        ></p-dropdown>
      </div>
    </ng-container>


    <div class="grid-row text-right">
      <button
        class="btn btn-primary btn-sm"
        style="cursor: pointer;"
        title="Search"
        (click)="search()"
        [disabled]="!canSearch()">
        {{ translations.search }}
        <i class="fa fa-search"></i>
      </button>
      <button class="btn btn-primary btn-sm clear" style="cursor: pointer;" title="Clear" (click)="clearSearch()">
        {{ translations.clear }}
      </button>
    </div>
  </div>
</p-toolbar>
