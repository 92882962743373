import { IAction } from '../../store/actions/tile.actions';
import { Reducer } from 'redux';
import { AddServiceDeploymentTaskLocationFilterActions } from '../actions/servicedeployment.constants';
import { AddTaskLocation } from '../models/addTaskLocation';

export const AddServiceDeploymentTaskLocationReducer: Reducer<AddTaskLocation> = (
  state: AddTaskLocation = new AddTaskLocation(),
  action: IAction<AddTaskLocation>,
) => {
  const params = <AddTaskLocation>action.payload;
  switch (action.type) {
    case AddServiceDeploymentTaskLocationFilterActions.SetFilter: {
      const newstate = Object.assign({}, state, params);
      return newstate;
    }
    default: {
      return state;
    }
  }
};
