import { Directive, Input, TemplateRef } from '@angular/core';

export type ContainerTemplateType = 'headerRight' | 'headerLeft' | 'content' | 'backTo';

@Directive({
  selector: '[vContainerTemplate]',
})
export class ContainerWrapperTemplateDirective {
  @Input() set vContainerTemplate(value: ContainerTemplateType) {
    if (!value) {
      throw new Error('vContainerTemplate cannot be empty');
    }

    this.template = value;
  }

  private template: ContainerTemplateType;

  get templateString(): ContainerTemplateType {
    return this.template;
  }

  constructor(public templateRef: TemplateRef<any>) {}
}
