import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from 'src/configuration';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FeatureService } from '@volt/shared/directives/features/feature.service';
import { SupportBotService } from '@volt/shared/services/supportbot.service';
import { finalize, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'support-bot',
  template: `
      <p-button value="Primary" styleClass="rounded-button" (click)="openChatWindow = !openChatWindow">
        <div *ngIf="openChatWindow">
          <div class="d-flex flex-column justify-content-center flex-wrap">
            <h5>24/7 Support Bot</h5>
          </div>
          <v-loading-container [working]="loading$ | async">
            <iframe
              style="styleSet"
              [src]="chatbotIFrameSource$ | async"
              loading="lazy"
              style="min-width: 400px; width: 100%; min-height: 500px;"
            ></iframe>
          </v-loading-container>
        </div>
        <div *ngIf="!openChatWindow">
          <i class="pi pi-comments"></i>
        </div>
      </p-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportBotComponent implements OnInit {
  public openChatWindow = false;
  loading$ = new BehaviorSubject(true)
  chatbotIFrameSource$: Observable<SafeResourceUrl>;
  chatbotExternalSource: string;

  constructor(
    private readonly _supportBotService: SupportBotService,
    private readonly _featureService: FeatureService,
    @Inject(APPLICATION_CONFIGURATION) private readonly appConfig: ApplicationConfiguration,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (this._featureService.isFeatureEnabled('SupportBot')) {
      this.chatbotIFrameSource$ = this._supportBotService.buildChatBotUrl().pipe(
        switchMap((url: string) => {
            return of(this.sanitizer.bypassSecurityTrustResourceUrl(url));
        }),
        finalize(() => this.loading$.next(false))
      );
    }
  }
}
