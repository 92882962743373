<div class="login-container">
  <div class="login-box">
    <div class="login-body">
      <h6>Authenticating</h6>
      <p>Please wait while we authenticate your account</p>
      <div>
        <v-loading-spinner></v-loading-spinner>
      </div>
    </div>
  </div>
</div>
