import { Component, OnInit, ChangeDetectionStrategy, Input } from "@angular/core";
import { ActivityTaskSummaryModel } from "@volt/api";
import { pieChartColors } from "@volt/shared/constants/charts.constant";
import { Translations } from "@volt/shared/services/translations.types";
import { Chart } from "angular-highcharts";
import * as Highcharts from "highcharts";

@Component({
  selector: 'activity-count-chart',
  templateUrl: './activity-count-chart.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityCountChartComponent implements OnInit {
  @Input() aggregateData: ActivityTaskSummaryModel[];

  @Input() translations: Translations;
  
  @Input() isLoading: boolean;
  
  constructor() {}

  ngOnInit(): void {}
  
  get chart() {
    return this._initChart();
  }
  
  private get _chartData() {
    return (this.aggregateData || []).map((value) => ({
      name: value.activity,
      y: value.count
    }));
  }
  
  private _initChart(): Chart {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      },
      colors: pieChartColors
    });
    
    return new Chart({
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: 500,
      },
      loading: {},
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}',
          },
        },
      },
      tooltip: {
        pointFormat: `${this.translations.taskCount}: <b>{point.y}</b>`,
        enabled: true,
      },
      series: [
        {
          name: 'Activity',
          data: this._chartData,
          type: undefined,
        },
      ],
    });
  }
}
