import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Disposable } from '../../extensions/disposable';
import { TranslationService } from '../../services/translation.service';
import { Translations } from '../../services/translations.types';

@Directive()
export abstract class BaseFormField extends Disposable {
  @Input() displayLength = 3;
  @Input() control: UntypedFormControl;
  @Input() hideLabel = false;

  @Output() valueChanged = new EventEmitter<any>();

  items: SelectItem[] = [];
  selectedItems: SelectItem[] = [];
  translatedData: Translations;
  protected translatedData$: Observable<Translations>;

  protected constructor(private readonly baseTranslationService?: TranslationService) {
    super();
    this.translatedData$ = baseTranslationService
      ?.getTranslatedObject()
      .pipe(tap(translated => (this.translatedData = translated)));
  }

  protected abstract get filterDisplayLabel(): string;

  protected updatedAutoCompletedItems<TAutoCompleteItem extends {}>(
    mapToSelector: (item: TAutoCompleteItem) => TAutoCompleteItem[keyof TAutoCompleteItem],
  ) {
    const _selectedItems: TAutoCompleteItem[] = this.control.value;
    if (!this.translatedData || !this.control || !_selectedItems) return;
    const uniqueValues = [...new Set(_selectedItems.map(mapToSelector))];
    const filterDisplay = {
      label: this.filterDisplayLabel,
      value:
        uniqueValues.length <= this.displayLength
          ? uniqueValues.join(',\n')
          : `${uniqueValues.length} ${this.translatedData.itemsSelected}`,
    };
    this.control.filterDisplay$.next(filterDisplay);
  }

  protected updateSelectedItems() {
    const _selectedItems = this.control.value;
    if (!this.translatedData || !this.control || !_selectedItems) return;

    this.selectedItems = this.items.filter(
      item => _selectedItems.find(selectedItem => selectedItem === item.value) != null,
    );

    const uniqueValues = [...new Set(this.selectedItems.map(selectedItem => selectedItem.value))];
    this.selectedItems = uniqueValues.map(uniqueValue =>
      this.selectedItems.find(selectedItem => selectedItem.value === uniqueValue),
    );

    const filterDisplay = {
      label: this.filterDisplayLabel,
      value:
        this.selectedItems.length <= this.displayLength
          ? this.selectedItems.map(si => si.label).join(',\n')
          : `${this.selectedItems.length} ${this.translatedData.itemsSelected}`,
    };

    this.control.filterDisplay$.next(filterDisplay);
  }

  protected updateSelectedItem() {
    if (!this.translatedData || !this.control) return;
    const value = this.control.value;
    const selectItem = this.items.find(t => t.value === value);
    const filterDisplay = {
      label: this.filterDisplayLabel,
      value: selectItem ? selectItem.label : undefined,
    };
    this.control.filterDisplay$.next(filterDisplay);
    this.valueChanged.emit(value);
  }

  protected updateDateFilterDisplay() {
    if (!this.control) return;
    const date = this.control.value;
    const filterDisplay = {
      label: this.filterDisplayLabel,
      value: moment(date).format('MM/DD/YYYY'),
    };
    this.control.filterDisplay$.next(filterDisplay);
  }

  protected updateTextFilterDisplay() {
    if (!this.control) return;
    const filterDisplay = {
      label: this.filterDisplayLabel,
      value: this.control.value,
    };
    this.control.filterDisplay$.next(filterDisplay);
  }

  protected updateSliderFilterDisplay() {
    if (!this.control) return;
    const filterDisplay = {
      label: this.filterDisplayLabel,
      value: this.control.value,
    };
    this.control.filterDisplay$.next(filterDisplay);
  }
}
