<v-loading-container [working]="areActivitiesLoading">
  <shared-table
    [columns]="columns"
    [data]="activityData.results"
    [state]="{
      offset: activityData.offset,
      limit: activityData.limit,
      totalCount: activityData.totalCount,
      sortBy: activityData.sortBy,
      sortAscending: activityData.sortAscending
    }"
    (stateChanged)="tableStateChanged($event)"
  ></shared-table>
</v-loading-container>
