import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageFlowPipe } from '@volt/shared/pipes/image-flow/image-flow.pipe';

@NgModule({
  declarations: [ImageFlowPipe],
  exports: [ImageFlowPipe],
  imports: [CommonModule],
})
export class ImageFlowModule {}
