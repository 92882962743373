import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Address } from '../../../../../models/volt-account';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';

@Component({
  selector: 'account-address',
  templateUrl: './account-address.component.html',
  styleUrls: [ './account-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountAddressComponent extends BaseFilterForm<Address> {
  @Input() translations: Translations;
  @Input() set address(address: Address) {
    this.controls.address1.setValue(address?.address1);
    this.controls.address2.setValue(address?.address2);
    this.controls.city.setValue(address?.city);
    this.controls.state.setValue(address?.state);
    this.controls.zip.setValue(address?.zip);
    this.controls.country.setValue(address?.country);
  }

  @Input() set disabled(d: boolean) {
    if(d) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  constructor(private readonly _fb: UntypedFormBuilder) {
    super();
    this.initForm();
  }

  initForm(): void {
    this.form = this._fb.group({
      address1: ['', Validators.required],
      address2: [],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zip: ['', Validators.required]
    });
  }

  get invalid() {
    return this.form.invalid;
  }

  get value() {
    return {
      address1: this.controls.address1.value,
      address2: this.controls.address2.value,
      city: this.controls.city.value,
      state: this.controls.state.value,
      zip: this.controls.zip.value,
      country: this.controls.country.value
    } as Address;
  }

  get selectedCountry() {
    return this.controls.country.value;
  }
}
