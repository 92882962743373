import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DropDownModel } from '@volt/api';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { RetailerConfigType, RETAILER_CONFIG } from 'src/retailer-config';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'department-list',
  templateUrl: './department.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})
export class DepartmentModelComponent implements OnInit {
  @Input() public submitted;
  @Input() public serviceTaskDepartmentMsg: boolean;
  @Input() public group: UntypedFormGroup;
  @Input() public filteredDepartmentList: DropDownModel[];
  @Input() public initialDepartmentList: DropDownModel[];
  @Output() private selectDepartment: EventEmitter<any> = new EventEmitter<any>();
  @Output() private departmentExist: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _dynamicDialogService: DynamicDialogService,
    @Inject(RETAILER_CONFIG) public readonly retailerConfig: RetailerConfigType,
  ) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [`Must match a ${this.retailerConfig.retailer} Department name`],
      },
    });
  }

  public get departmentControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('department');
  }

  public getDepartmentListByFilter(event) {
    this.selectDepartment.emit(event);
  }

  public onClearEvent(event: any) {
    this.serviceTaskDepartmentMsg = false;
    this.departmentExist.emit(event);
  }
}
