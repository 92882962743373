import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { FeatureClient, FeatureViewModel } from '../../../api.client';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from '../../../auth';
import { IAppState } from '../../../store';
import { StateSubject } from '../../../shared/extensions/state-subject';

@Injectable({ providedIn: 'root' })
export class FeatureService {
  constructor(
    private readonly featureClient: FeatureClient,
    private readonly authService: AuthService,
    private readonly appState: NgRedux<IAppState>,
    private readonly _authService: AuthService,
  ) {}

  private $features: ReplaySubject<FeatureViewModel[]>;
  public featuresLoaded$ = new StateSubject<boolean>(false);

  private static featureMatch(featureName: string, lookupFeature: FeatureViewModel): boolean {
    return featureName.toUpperCase() === lookupFeature.name.toUpperCase();
  }

  public get features$() {
    return this.$features.asObservable();
  }

  public get featuresLoaded(): boolean {
    return this.featuresLoaded$.getValue();
  }

  loadFeatures() {
    this.featuresLoaded$.next(false);
    this.$features = new ReplaySubject<FeatureViewModel[]>(1);
    if (this._authService.hasValidToken()) {
      this.featureClient
        .getAllFeatures()
        .pipe(
          catchError(() => {
            this.featuresLoaded$.next(false);
            console.error('Unable to load features');
            return of([]);
          }),
        )
        .subscribe(features => {
          this.$features.next(features);
          this.featuresLoaded$.next(true);
        });
    } else {
      this.featuresLoaded$.next(false);
      console.error('Unable to load features in not authenticated');
      this.$features.next([]);
    }
  }

  /** Check if a feature is enabled pass the name of the feature. If nested then dot the features. Ex: feature.child **/
  public isFeatureEnabled(featureName: string): boolean {
    let featureEnabled = false;
    this.isFeatureEnabled$(featureName).subscribe(value => {
      featureEnabled = value;
    });
    return featureEnabled;
  }

  public isFeatureEnabled$(featureName: string): Observable<boolean> {
    if (!featureName || featureName.length === 0) {
      console.error('isFeatureEnabled: Invalid argument featureName is required');
      return of(false);
    }

    return this.features$.pipe(
      map(features => {
        let featureEnabled = true;
        const user = this.authService.getCurrentUser();

        features.forEach(feature => {
          if (FeatureService.featureMatch(featureName, feature)) {
            if (feature.accounts?.length) {
              if (!feature.accounts?.includes(user.accountId)) {
                featureEnabled = false;
                return;
              }
            }

            featureEnabled = feature.enabled;
            return;
          }

          if (feature.subFeatures.length) {
            feature.subFeatures.forEach(subFeature => {
              if (FeatureService.featureMatch(featureName, subFeature)) {
                featureEnabled = subFeature.enabled;
                return;
              }
            });
          }
        });

        return featureEnabled;
      }),
      take(1),
    );
  }
}
