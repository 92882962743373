<v-loading-container [working]="loading">
  <div class="container-fluid dashboard-page">
    <div class="row flex-xl-nowrap">
      <div class="col-xl-12">
        <div class="">
          <form [formGroup]="addServiceDeploymentsGroup" (submit)="submitServiceDeployments()">
            <div class="sep-form">
              <h2 data-cy="add-ser-dep-page-title">Add Service Deployments</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row align-items-center">
                      <div class="col-sm-4 " style="text-align: right;">
                        <label class="mb-0 me-1" for="banner-field">Banner</label>
                        <span class="required" aria-required="true">*</span>
                        <span>
                          <i class="fa fa-info-circle" (click)="showBannerInfo()" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div class="col-sm-8">
                        <input
                          class="form-control rounded-0"
                          id="banner-field"
                          readonly
                          [value]="currentAddedServiceDeployment.banner"
                        />
                      </div>
                    </div>

                    <field-group-dropdown
                      [fieldTypeDropModel]="fieldTypeDropModel"
                      [checkFieldGroupRole]="checkFieldGroupRole"
                      [fieldGroupName]="currentAddedServiceDeployment"
                      [group]="addServiceDeploymentsGroup"
                      [submitted]="submitted"
                      [isEdit]="currentAddedServiceDeployment.edit"
                      [isProvider]="isProvider"
                    ></field-group-dropdown>

                    <service-deployment-name
                      [group]="addServiceDeploymentsGroup"
                      [submitted]="submitted"
                    ></service-deployment-name>

                    <service-deployment-reference-number
                      [group]="addServiceDeploymentsGroup"
                      [isEdit]="currentAddedServiceDeployment.edit"
                      (serviceDepReferNumberExist)="checkServiceRefNumberAlreadyExist($event)"
                      [submitted]="submitted"
                    ></service-deployment-reference-number>

                    <service-deployment-start-date
                      [setStartDate]="currentAddedServiceDeployment"
                      [group]="addServiceDeploymentsGroup"
                      (setEndDate)="setEndDate($event)"
                      [submitted]="submitted"
                    ></service-deployment-start-date>

                    <service-deployment-end-date
                      [serviceDeploymentDateMsg]="serviceDeploymentDateMsg"
                      (setEndDateMsg)="setEndDateMsg($event)"
                      [setEndDate]="currentAddedServiceDeployment"
                      [group]="addServiceDeploymentsGroup"
                      [minDate]="minDate"
                      [submitted]="submitted"
                    ></service-deployment-end-date>

                    <note [group]="addServiceDeploymentsGroup"></note>

                    <visit-overview [group]="addServiceDeploymentsGroup"></visit-overview>

                    <status-list
                      [group]="addServiceDeploymentsGroup"
                      [statusDropDownModel]="statusDropDownModel"
                      [selectedStatus]="selectedStatus"
                    ></status-list>

                    <div class="form-group row mt-3">
                      <div class="col-sm-4"></div>
                      <div class="col-sm-8">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-cy="submit-button"
                          [disabled]="(isEdit && !canEdit) || (!isEdit && !canCreate)"
                        >
                          Submit
                        </button>
                        &nbsp;
                        <button type="button" (click)="formReset()" class="btn btn-cancel" data-cy="cancel-button">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
