import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { CustomDynamicContentDialogDirective } from './custom-dynamic-content-dialog.directive';
import { CustomDynamicDialogBaseComponent } from './custom-dynamic-dialog-base.component';
import { DynamicConfirmDialogBaseComponent } from './dynamic-confirm-dialog-base.component';
import { DynamicDialogBaseComponent } from './dynamic-dialog-base.component';
import { DynamicDialogContainerComponent } from './dynamic-dialog-container.component';
import { DynamicDialogService } from './dynamic-dialog.service';
import { GenericErrorDialogComponent } from './generic-error-dialog.component';

@NgModule({
  declarations: [
    DynamicDialogBaseComponent,
    DynamicConfirmDialogBaseComponent,
    GenericErrorDialogComponent,
    DynamicDialogContainerComponent,
    CustomDynamicContentDialogDirective,
    CustomDynamicDialogBaseComponent,
  ],
  imports: [CommonModule, PanelModule, DynamicDialogModule, OverlayModule],
  providers: [DynamicDialogService, DialogService],
  exports: [
    PanelModule,
    DynamicDialogBaseComponent,
    DynamicConfirmDialogBaseComponent,
    CustomDynamicDialogBaseComponent,
  ],
})
export class CustomDynamicDialogModule {}
