import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, debounceTime, filter, finalize, map, switchMap, take, tap } from "rxjs/operators";
import { UserAccountViewModel, UserInformation } from '../api.client';
import { RoleConstant } from '../auth/shared/roles.constants';
import { ArrayUtils } from './utils/array.utils';
import { isEmpty, nameOf } from '@volt/shared/utils/common.utils';
import { ControlErrorComponent } from './components/forms/control-error.component';

type ValidateFnCallback<T> = (self: UntypedFormControl, target: UntypedFormControl) => T;

const controlToTargetsMap = new WeakMap<AbstractControl, AbstractControl[]>();

function addTarget(control: AbstractControl, target: AbstractControl) {
  const targets = controlToTargetsMap.get(control);
  if (!targets) {
    controlToTargetsMap.set(control, [target]);
  } else {
    targets.push(target);
  }
}

function removeTarget(control: AbstractControl, target: AbstractControl) {
  const targets = controlToTargetsMap.get(control);
  if (targets) {
    const i = targets.indexOf(target);
    if (i > -1) targets.splice(i, 1);
  }
}

function isCircular(control: AbstractControl, target: AbstractControl) {
  const targets = controlToTargetsMap.get(target);
  return !!targets && targets.some(t => t === control);
}

Object.defineProperty(UntypedFormControl.prototype, nameOf<UntypedFormControl>('targets$'), {
  set: function (value: AbstractControl[]) {
    const self = this;
    self._targets$ = value || [];
  },
  get: function () {
    const self = this;
    return self._targets$ || (self._targets$ = []);
  },
});

function temp3(control: AbstractControl) {
  if (control.parent instanceof UntypedFormGroup) {
    return Object.entries(control.parent.controls)
      .filter(([key, c]) => c === control)
      .map(([key, c]) => ({ key, control: c }))[0];
  }

  return { control, key: 'unknown' };
}
const watchControl = <T extends ValidatorFn = ValidatorFn>(
  targetControlName: string,
  validate: ValidateFnCallback<ReturnType<T>>,
) => {
  let cachedTarget: UntypedFormControl = null;
  return (self: UntypedFormControl) => {
    let form: UntypedFormGroup;

    // support nested form groups
    if (self.parent instanceof UntypedFormGroup) {
      form = <UntypedFormGroup>self.parent;
    } else {
      form = <UntypedFormGroup>self.root;
    }
    let target: UntypedFormControl;

    if (!form || !form.controls || !(target = <UntypedFormControl>form.get(targetControlName))) {
      return of(null);
    }

    if (cachedTarget !== target) {
      cachedTarget = target;
      addTarget(self, target);

      let current: { value: any } | undefined;

      cachedTarget.valueChanges
        .pipe(
          filter(value => {
            if (!!current && current.value === value) return false;
            if (isCircular(self, target)) current = { value: value };
            return true;
          }),
          finalize(() => removeTarget(self, target)),
        )
        .subscribe(_ => {
          self.updateValueAndValidity({ onlySelf: true });
          current = undefined;
        });
    }

    return validate(self, cachedTarget);
  };
};

const EMAIL_REGEX_WITH_DOMAIN = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/;

const MOBILE_TEN_DIGIT = /^\(?\d{3}\)? *-? *\d{3} *-? *\d{4}$/;

export class VoltValidators {
  static matchConfirmPassword(passwordKey: string = 'password') {
    return (control: UntypedFormControl): ValidationErrors => {
      const parent = control.root as UntypedFormGroup;
      const passwordControl = parent?.get(passwordKey);
      if (!passwordControl) {
        return null;
      }

      if (passwordControl.value == null) {
        return null;
      }

      if (control.value == null || control.value !== passwordControl.value) {
        return { matchPassword: true };
      }

      return null;
    };
  }

  static matchPassword(passwordKey: string, confirmedPasswordKey: string) {
    return (form: UntypedFormGroup): { [key: string]: any } => {
      while (form.parent != null) {
        form = <UntypedFormGroup>form.parent;
      }
      const validatorFn = VoltValidators.matchFormControl(passwordKey, confirmedPasswordKey) as any;
      const invalid = validatorFn(form);
      if (invalid) {
        return { matchPassword: invalid.matchFormControl };
      }
      return null;
    };
  }

  static matchFormControl(controlName1: string, controlName2: string) {
    return (form: UntypedFormGroup): { [key: string]: any } => {
      if (form.controls == null) {
        return null;
      }
      const control1 = form.controls[controlName1];
      if (control1 == null) {
        return null;
      }
      const control2 = form.controls[controlName2];
      if (control2 == null) {
        return null;
      }

      if (control1.value !== control2.value) {
        return {
          matchFormControl: { value: control2.value, mustMatch: control1.value },
        };
      }
      return null;
    };
  }

  static emailOrMobile(fg: UntypedFormGroup): ValidationErrors | null {
    const email = fg.get('email');
    const mobile = fg.get('mobile');
    return (email.value !== '' && email.errors === null) || (mobile.value !== '' && mobile.errors === null)
      ? null
      : { emailOrMobile: true };
  }

  static emailOrMobileOrUserName(fieldEmployeeRoleId): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isSsoEnabled = control.get('isSsoEnabled').value;
      const role = control.get('rolesList')? control.get('rolesList').value: control.get('role').value
      const isRoleFieldEmployee = role == fieldEmployeeRoleId;

      const email = control.get('email');
      const mobile = control.get('mobile');
      const userName = control.get('userName');

      if (isSsoEnabled) {
        if (!isRoleFieldEmployee) {
          // userName and (email or mobile) required
          if (
            (email.value === '' && mobile.value === '') ||
            email.errors !== null ||
            mobile.errors !== null ||
            userName.value === '' ||
            userName.errors !== null
          ) {
            return { userNameAndEmailOrMobile: true };
          }
        } else {
          // userName required
          if (userName.value === '' || userName.errors !== null) {
            return { userNameRequired: true };
          }
        }
      } else {
        // email or mobile required
        if ((email.value === '' && mobile.value === '') || (email.errors !== null && mobile.errors !== null)) {
          return { emailOrMobile: true };
        }
      }

      return null;
    };
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    return EMAIL_REGEX_WITH_DOMAIN.test(control.value) ? null : { email: true };
  }

  static mobile(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    return MOBILE_TEN_DIGIT.test(control.value) ? null : { mobile: true };
  }

  static emailOrMobileControl(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const email = VoltValidators.email(control);
    const mobile = VoltValidators.mobile(control);

    return email == null || mobile == null ? null : { emailOrMobile: true };
  }

  static requiredWhenLessThan(targetControlName: string, targetControlValue: number) {
    // TODO:  Use this to get the caller in my uniqueAcross validator, then the rest should just work already, make it
    // (callerName, targetName, fieldName) to make it more generic, too possibly.
    return (control: UntypedFormControl) => {
      const form = <UntypedFormGroup>control.root;
      if (!form || !form.controls || !form.controls[targetControlName]) {
        return null;
      }

      const control2 = form.controls[targetControlName];
      if (control2.value < targetControlValue) {
        return { requiredWhenGreaterThan: 'This field is required.' };
      }
      return null;
    };
  }

  static requiredWhen(targetControlName: string, targetControlValue: any, acceptZero: boolean = false) {
    return watchControl(targetControlName, (self, target) => {
      const shouldEmpty = acceptZero ? this.nonNull(self) : !self.value;
      if (target.value === targetControlValue && shouldEmpty) {
        return { requiredWhen: true };
      }

      return null;
    });
  }

  static requiredWhenAnyHasValue(...targetControlNames: string[]) {
    return Validators.compose(
      targetControlNames.map(targetControlName =>
        watchControl(targetControlName, (self, target) => {
          if (!isEmpty(self.value) || isEmpty(target.value)) {
            return null;
          }
          return { requiredWhen: true };
        }),
      ),
    );
  }

  static requiredWhenNull(targetControlName: string): ValidatorFn {
    return control => {
      const target = control.root && (control.root as UntypedFormGroup).get(targetControlName);
      if (!target) {
        return null;
      }

      if (control.value) {
        target.setErrors(null);
        return null;
      }

      if (!control.value) {
        target.setErrors(Validators.required(target));
      }

      return !!target.value ? null : { requiredWhenNull: true };
    };
  }

  static requiredWhenNotNull(control: AbstractControl): ValidationErrors | null {
    if (!control || control.value === null) {
      return null;
    }

    return Validators.required(control);
  }

  static requiredAddressOnAccount(
    accounts: Observable<UserAccountViewModel[]>,
    accountControlName: string = 'accountId',
  ): AsyncValidatorFn {
    return watchControl<AsyncValidatorFn>(accountControlName, (self, target) => {
      if (!target) {
        return of(null);
      }

      return accounts.pipe(
        map(accts => accts.some(a => a.accountId === parseInt(target.value, 10) && a.shipDirectToRep > 0)),
        map(isShipDirect => (isShipDirect && !self.value ? { requiredAddress: true } : null)),
      );
    });
  }
  static uniqueMobile(
    mobileCheck: (value: string, accountId: number) => Observable<boolean>,
    accountControlName: string = 'account',
    currentMobile: string = '',
  ): AsyncValidatorFn {
    // these are to address a known issue with primeng's inputMask that triggers value changes on the input
    // blur event - https://github.com/primefaces/primeng/issues/11566
    // Once this issue is closed, the previousValue/previousErrors are no longer needed.
    // This will prevent the async validation from running on blur for values that are already validated.
    let validatedValue = null;
    let validatedErrors = null;
    return watchControl<AsyncValidatorFn>(accountControlName, (self, target) => {
      if (!self.value || !target) {
        return of(null);
      }

      if (currentMobile && self.value === currentMobile) {
        return of(null);
      }

      if (validatedValue && self.value === validatedValue) {
        return of(validatedErrors);
      }

      return self.valueChanges.pipe(
        debounceTime(300),
        take(1),
        switchMap((value: string) => mobileCheck(value, parseInt(target.value, 10)).pipe(catchError(() => of(false)))),
        map(isNotUnique => (isNotUnique ? { uniqueMobile: true } : null)),
        tap((errors) => {
          validatedErrors = errors;
          validatedValue = self.value;
        })
      );
    });
  }

  static uniqueEmail(
    emailCheck: (value: string, accountId: number) => Observable<boolean>,
    accountControlName: string = 'account',
    currentEmail: string = '',
  ): AsyncValidatorFn {
    return watchControl<AsyncValidatorFn>(accountControlName, (self, target) => {
      if (!self.value || !target) {
        return of(null);
      }

      if (currentEmail && self.value === currentEmail) {
        return of(null);
      }

      return self.valueChanges.pipe(
        debounceTime(300),
        take(1),
        switchMap((value: string) => emailCheck(value, parseInt(target.value, 10)).pipe(catchError(() => of(false)))),
        map(isExisted => (isExisted ? { uniqueEmail: true } : null)),
      );
    });
  }

  static uniqueEmployeeNumber(
    employeeNumberCheck: (value: string, accountId: number) => Observable<boolean>,
    accountControlName: string = 'account',
    currentEmployeeNumber: string = '',
  ): AsyncValidatorFn {
    return watchControl<AsyncValidatorFn>(accountControlName, (self, target) => {
      if (!self.value || !target) {
        return of(null);
      }

      if (currentEmployeeNumber && self.value === currentEmployeeNumber) {
        return of(null);
      }

      return self.valueChanges.pipe(
        debounceTime(300),
        take(1),
        switchMap((value: string) => employeeNumberCheck(value, parseInt(target.value, 10)).pipe(catchError(() => of(false)))),
        map(isExisted => (isExisted ? { uniqueEmployeeNumber: true } : null)),
      );
    });
  }

  static uniqueUserName(
    userNameCheck: (value: string, accountId: number) => Observable<boolean>,
    accountControlName: string = 'account',
    currentUserName: string = '',
  ): AsyncValidatorFn {
    return watchControl<AsyncValidatorFn>(accountControlName, (self, target) => {
      if (!self.value || !target) {
        return of(null);
      }

      if (currentUserName && self.value === currentUserName) {
        return of(null);
      }

      return self.valueChanges.pipe(
        debounceTime(300),
        take(1),
        switchMap((value: string) => userNameCheck(value, target.value)),
        map(isExisted => (isExisted ? { uniqueUserName: true } : null)),
      );
    });
  }

  static uniqueValue(uniqueCheck: (value: string) => Observable<boolean>, currentValue?: string): AsyncValidatorFn {
    return control => {
      if (!(control.value as string).trim()) {
        return of(null);
      }

      if (currentValue && control.value === currentValue) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(300),
        take(1),
        switchMap(uniqueCheck),
        map(existed => (existed ? { unique: true } : null)),
      );
    };
  }

  static ensureValidDateRange(startDateControlName: string = 'startDate'): ValidatorFn {
    return watchControl(startDateControlName, (self, target) => {
      if (!self.value || !target) {
        return null;
      }

      if (moment(self.value).isSameOrBefore(moment(target.value), 'minute')) {
        return { invalidEndDate: true };
      }

      return null;
    });
  }

  static requiredWithUserRoles(currentUser: Observable<UserInformation>, ...roles: RoleConstant[]): AsyncValidatorFn {
    return control => {
      if (!control) {
        return null;
      }

      return currentUser.pipe(
        take(1),
        map(user => roles.some(r => r === user.roles)),
        map(isInRole => (isInRole && !control.value ? { requiredWithRole: true } : null)),
      );
    };
  }

  static consecutiveRetailerWeeks(items: SelectItem[], maxNumberSelections: number): ValidatorFn {
    return control => {
      if (!control || control.value.length === maxNumberSelections || !control.value.length) {
        return null;
      }

      const selectedValueIndices = (control.value as string[]).map(val => items.findIndex(item => item.value === val));
      return ArrayUtils.isConsecutive(selectedValueIndices).isConsecutive ? null : { requiredConsecutive: true };
    };
  }

  static nonNull(control: AbstractControl): ValidationErrors | null {
    if (!control) {
      return null;
    }

    if (control.value === 0 || !!control.value) {
      return null;
    }

    return Validators.required(control);
  }

  static nonEmpty(control: AbstractControl): ValidationErrors | null {
    if (!control) {
      return null;
    }

    if (control.value && !Array.isArray(control.value)) {
      return null;
    }

    if (!control.value.length) {
      return { nonEmpty: true };
    }

    return null;
  }

  static uniqueUpcs(control: AbstractControl): ValidationErrors | null {
    if (!control) {
      return null;
    }
    const errorList = []; // return object for capturing input validation errors

    if (control.value?.length > 0) {
      const vSplitList = control.value.split('\n');
      if (vSplitList.length === 0 || vSplitList[0].length === 0) {
        return null;
      }
      const visitedUpcs = [];

      vSplitList.forEach(valueInner => {
        // Now we check the uniqueness on the specified upc field on items
        if (visitedUpcs.includes(valueInner)) {
          errorList.push({ currentValue: valueInner, errorMessageKey: 'nonUniqueUpcs' });
        } else {
          visitedUpcs.push(valueInner);
        }
      });

      if (errorList.length === 0) {
        return null;
      }
      return { uniqueUpcs: errorList };
    }
    return null;
  }

  static mustBe<TValue = unknown>(targetValue: TValue, useLabel = true): ValidatorFn {
    return control => {
      if (!control) {
        return null;
      }

      if (control.value !== targetValue) {
        return { mustBe: { current: control.value, target: targetValue, useLabel } };
      }

      return null;
    };
  }

  static validUpcs(control: AbstractControl): ValidationErrors | null {
    if (!control) {
      return null;
    }

    // return object for capturing input validation errors
    const errorList = [];

    // embedded function to calculate the checksum and compare to the last digit of the passed upc candidate
    const isChecksumValid = function (upcString: string): boolean {
      let oddTotal = 0;
      let evenTotal = 0;

      const vAsChars = Array.from(upcString);
      vAsChars.forEach((val, index) => {
        if (index % 2 === 1 && index < 11 /* skip last one for check digit */) {
          evenTotal += Number(val);
        } else if (index % 2 === 0) {
          oddTotal += Number(val);
        }
      });

      const vCheckSum = 10 - ((3 * oddTotal + evenTotal) % 10);
      return vCheckSum.toString() === vAsChars[11];
    };

    if (control.value?.length > 0) {
      const vSplitList = control.value.split('\n');

      if (vSplitList.length === 0 || vSplitList[0].length === 0) {
        return null;
      }
      // process the rows
      vSplitList.forEach(valueInner => {
        // example valid UPCs:  883028594054, 123456789456, 123654789654
        // example IN-valid UPCs:  111211121112
        // @ts-ignore
        if (isNaN(valueInner)) {
          errorList.push({ currentValue: valueInner, errorMessageKey: 'notNumeric' });
        } else if (valueInner.length === 0) {
          errorList.push({ currentValue: valueInner, errorMessageKey: 'blankOrEmpty' });
        } else if (valueInner.length > 12) {
          errorList.push({ currentValue: valueInner, errorMessageKey: 'tooManyDigits' });
        } else if (valueInner.length < 12) {
          errorList.push({ currentValue: valueInner, errorMessageKey: 'tooFewDigits' });
        } else {
          // fell through, must be valid looking UPC (scrutinize further) so check the checksum, before you look dumb!
          if (isChecksumValid(valueInner)) {
            // do nothing
          } else {
            // must be a bad one, log it and move on to the next one(s)
            errorList.push({ currentValue: valueInner, errorMessageKey: 'invalidChecksum' });
          }
        }
      });

      if (errorList.length === 0) {
        return null;
      }

      return { invalidUpcs: errorList };
    }

    return null;
  } // end of validUpcs

  /**
   * Use this when you expect a control to contain an object value.
   * For example, a value of:
   *
   * {
   *   id: string | null,
   *   label: string
   * }
   *
   * VoltValidators.propertyRequired('id') creates a validator that checks whether
   * the 'id' property is truthy (not null, undefined, empty string, or 0)
   * @param propertyName - the name of the property to check
   */
  static propertyRequired(propertyName: string) {
    return (control: AbstractControl<object>): ValidationErrors | null => {
      if (!control.value || !control.value?.[propertyName]) {
        return {
          propertyRequired: propertyName
        };
      }

      return null;
    };
  }
}
