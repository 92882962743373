<ng-container>
  <modal-header [title]="'Info'" (onClose)="onClose()" [closeable]="false"></modal-header>

  <div class="modal-body">
    <div *ngFor="let message of messages">
      {{ message }}
      <br />
      <br />
    </div>
  </div>

  <modal-footer [footerStyle]="'single_btn'" (onSubmit)="onClose()" [saveLabel]="'Ok'"></modal-footer>
</ng-container>
