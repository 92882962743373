import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApiError } from '../../api.client';

import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../configuration';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private _appInsights: ApplicationInsights;

  constructor(
    private _router: Router,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  public initialize() {
    if (!this._appConfig?.appInsights?.instrumentationKey) return;

    const angularPlugin = new AngularPlugin();

    this._appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this._appConfig.appInsights.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this._router },
        },
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: [
          '*.googleapis.com'
        ],
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
      },
    });

    this._appInsights.loadAppInsights();
    this._appInsights.trackPageView();
  }

  public trackEvent(
    eventName: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
  ) {
    this._appInsights?.trackEvent({
      name: eventName,
      properties: {
        ...measurements,
        ...properties,
      },
    });
  }

  public logApiException(error: ApiError) {
    const formattedError = new Error();
    formattedError.message = error.error;
    formattedError.stack = error.detail;

    if (!this._appInsights) {
      console.error(formattedError);
      return;
    }

    this._appInsights.trackException({
      exception: formattedError,
    });
  }

  public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    if (!this._appInsights) {
      console.error(error);
      return;
    }

    this._appInsights.trackException({
      exception: error,
      properties: {
        ...measurements,
        ...properties,
      },
    });
  }

  public setAuthenticatedUserId(userId, accountId) {
    this._appInsights?.setAuthenticatedUserContext(userId, accountId, true);
  }
}
