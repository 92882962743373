<div style="display: flex; flex-direction: column; gap: 1rem">

    <div [class.hidden]="!usePersonalContactComponent.selected">
        <use-personal-contact #usePersonalContactComponent [type]="translations.billing" [selected]="!disabled"></use-personal-contact>
    </div>
    <div [class.hidden]="usePersonalContactComponent.selected">
        <account-contact
                [translations]="translations"
                [contact]="usePersonalContactComponent.selected ? personalContact : _billing.contact"
                [emailRequired]="true"
                [phoneRequired]="true"
                [disabled]="disabled"
        ></account-contact>
    </div>

    <div [class.hidden]="!useCompanyAddressComponent.selected">
        <use-company-address #useCompanyAddressComponent [type]="translations.billing" [selected]="!disabled"></use-company-address>
    </div>
    <div [class.hidden]="useCompanyAddressComponent.selected">
        <account-address [translations]="translations" [address]="useCompanyAddressComponent.selected ? companyAddress : _billing?.address" [disabled]="disabled"></account-address>
    </div>
</div>
