import { OnInit, Directive } from '@angular/core';
import { ServiceDeploymentReportParams } from '../../models/servicedeployment-report-parameters';
import { ServiceDeploymentsService } from '../servicedeployments/services/service-deployments.service';

@Directive()
export abstract class TableDisplay implements OnInit {
  public totalRecords = 0;
  public cols: any[];
  public data: any;
  public loading = true;
  public searchBy: string;
  public serviceDeploymentFilterParams: ServiceDeploymentReportParams;

  private serviceDeploymentsService: ServiceDeploymentsService;
  ngOnInit() {}

  protected constructor(service: ServiceDeploymentsService) {
    this.serviceDeploymentsService = service;
  }

  public abstract pullData($event: ServiceDeploymentReportParams);

  pagination(page: number) {
    const updateFilter = Object.assign(this.serviceDeploymentFilterParams, {
      page: page,
    });
    this.serviceDeploymentsService.setFilter(updateFilter);
    this.pullData(updateFilter);
  }
}
