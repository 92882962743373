import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FeatureService } from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanLoad, CanActivate {
  constructor(private readonly _featureService: FeatureService) {}

  canLoad(route: Route): Observable<boolean> {
    return this._isFeatureEnabled(route);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._isFeatureEnabled(route);
  }

  private _isFeatureEnabled(route: Route | ActivatedRouteSnapshot) {
    if (!this._featureService.featuresLoaded) {
      this._featureService.loadFeatures();
    }
    if (route.data['feature']) {
      return this._featureService.isFeatureEnabled$(route.data['feature']);
    }

    return of(true);
  }
}
