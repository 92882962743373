import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private _translocoService: TranslocoService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = this._translocoService.getActiveLang();

    if (language) {
      request = request.clone({
        setHeaders: { 'Accept-Language': language },
      });
    }

    return next.handle(request);
  }
}

export const languageInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LanguageInterceptor,
  multi: true,
};
