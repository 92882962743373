import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { BaseControlModule } from '@volt/shared/components/forms/base-control.module';

@NgModule({
  declarations: [GlobalSearchComponent],
  exports: [GlobalSearchComponent],
  imports: [CommonModule, AutoCompleteModule, FormsModule, RouterModule, BaseControlModule],
})
export class GlobalSearchModule {}
