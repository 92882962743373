import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'view-error-status-modal',
  templateUrl: './view-error-status-modal.component.html',
  styleUrls: [],
})
export class ViewErrorStatusModalComponent extends DynamicDialogBaseComponent implements OnInit {
  public errorList: string[];

  constructor(
    private _router: Router,
    private readonly _viewErrorDetailsDialogRef: DynamicDialogRef,
    private readonly _viewErrorDetailsDialogConfig: DynamicDialogConfig,
  ) {
    super(_viewErrorDetailsDialogRef, _viewErrorDetailsDialogConfig);
  }

  ngOnInit() {
    this._router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => this._viewErrorDetailsDialogRef.close(false));
    const { errorList } = this._viewErrorDetailsDialogConfig.data;
    this.errorList = errorList;
  }

  onDialogClose() {
    this._viewErrorDetailsDialogRef && this._viewErrorDetailsDialogRef.close(false);
  }
}
