import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslationService } from '@volt/shared/services/translation.service';
import { AvailableLangs } from '@volt/shared/types';
import { SelectItem } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RETAILER_CONFIG, RetailerConfigType } from '../../../../retailer-config';
import { LayoutService, PanelType } from '../services/layout.service';
import { StateSubject } from '@volt/shared/extensions/state-subject';

interface NavbarTemplateData {
  langOptions: SelectItem[];
  activeLang: AvailableLangs;
  inLargeViewport: boolean;
  searchMinimized: boolean;
}

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() showMainNav = true;
  @Input() showGlobalNav = true;

  searchMinimized: StateSubject<boolean> = new StateSubject<boolean>(true);

  public templateData$: Observable<NavbarTemplateData>;

  constructor(
    @Inject(RETAILER_CONFIG) public readonly retailer: RetailerConfigType,
    private _layoutService: LayoutService,
    private _translateService: TranslocoService,
    private _translationService: TranslationService,
    private _breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.templateData$ = combineLatest([
      this._translationService.activeLang$,
      this._translationService.langOptions$,
      this._breakpointObserver.observe('(min-width: 992px)'),
      this.searchMinimized,
    ]).pipe(
      map(
        ([activeLang, langOptions, breakpoint, minimized]: [
          AvailableLangs,
          SelectItem[],
          BreakpointState,
          boolean,
        ]) => ({
          activeLang,
          langOptions,
          inLargeViewport: breakpoint.matches,
          searchMinimized: minimized && !breakpoint.matches,
        }),
      ),
    );
  }

  public toggleNav() {
    this._layoutService.togglePanel(PanelType.MainNav);
  }

  public showFlyout() {
    this._layoutService.togglePanel(PanelType.Support);
  }

  onLangChange($event: { value: string }) {
    this._translateService.setActiveLang($event.value);
  }

  onSearchMinimizedChange(isMinimized: boolean) {
    this.searchMinimized.setState(isMinimized);
  }
}
