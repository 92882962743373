import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '@volt/api';
import { DataPassingService } from '../services/data-passing.service';

@Component({
  selector: 'products-list',
  templateUrl: './products-list.component.html',
})
export class ProductsListComponent implements OnInit {
  @Input() public products: ProductModel[] = [];
  @Output() onSearch = new EventEmitter<string>();
  @Output() onClear = new EventEmitter<boolean>();

  constructor(
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _dataPassingService: DataPassingService,
  ) {}

  ngOnInit() {}

  public search(input: string): void {
    this.onSearch.emit(input);
  }

  public addProduct(): void {
    this._router.navigate(['../addproduct'], {
      relativeTo: this.activatedRoute,
    });
  }

  public editProduct(product: ProductModel): void {
    this._router.navigate(['../editproduct', product.id], {
      relativeTo: this.activatedRoute,
    });
  }

  public clearSearch(): void {
    this.onClear.emit(true);
  }
}
