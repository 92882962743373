import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingContainerComponent } from './loading-container.component';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { ListLoadingComponent } from './list-loading.component';

@NgModule({
  declarations: [LoadingContainerComponent, LoadingSpinnerComponent, ListLoadingComponent],
  imports: [CommonModule],
  exports: [LoadingContainerComponent, LoadingSpinnerComponent, ListLoadingComponent],
})
export class LoadingContainerModule {}
