import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'license-agreement',
  templateUrl: './license-agreement.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseAgreementComponent  {
  @Input() translations: Translations;

  agreed = false;

  get invalid() {
    return !this.agreed;
  }
}
