import { DynamicDialogService } from './../../../../shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { AccountAppLinkModel } from '../../../../api.client';
import { OnInit, Component, Output, EventEmitter } from '@angular/core';
import { AccountViewService } from '../../../services/accountview.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationLinkModalComponent } from '../application-link-modal/application-link-modal.component';
import { Message } from 'primeng/api';

@Component({
  selector: 'applink-list',
  templateUrl: './applink-list.component.html',
})
export class ApplicationLinkComponent implements OnInit {
  public appLinkdata: AccountAppLinkModel[] = [];
  public accountAppLinkdata: AccountAppLinkModel[] = [];
  cols: any[];
  public totalRecords: number;
  public tempEncryptId: string;
  public msgs: Message[] = [];
  @Output() appLinkData: EventEmitter<Message[]> = new EventEmitter<Message[]>();

  constructor(private _accountViewService: AccountViewService, private readonly _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  getAccountAppLinkList(id: any) {
    this.tempEncryptId = id;
    this.getColumns();

    this._accountViewService.getAccountAppLinkListByAccountId(id).subscribe(response => {
      if (response) {
        this.appLinkdata = response;
        this.totalRecords = this.appLinkdata.length;
      }
    });
  }

  editAccountAppLink(id: any) {
    this._accountViewService.getAccountAppLinkListById(id).subscribe(response => {
      if (response) {
        this.accountAppLinkdata = response;
        
        this._dynamicDialogService.open(ApplicationLinkModalComponent, {
          data: {
            name: 'Application Links(Deep Links)',
            accountAppLinkdata: this.accountAppLinkdata,
            tempEncryptId: this.tempEncryptId,
          }
        });
      }
    });
  }

  deleteAccountAppLinkById(id: any) {
    this._accountViewService.deleteAccountAppLinkById(id).subscribe(response => {
      if (response) {
        this.msgs = [];
        this._accountViewService.getAppsLinkAccountId(this.tempEncryptId);
        this.msgs.push({
          severity: 'success',
          summary: 'Record Deleted Successfully',
        });
        this.appLinkData.emit(this.msgs);
      }
    });
  }

  getColumns() {
    this.cols = [];

    this.cols = [
      { field: 'activity', header: 'Button Name' },
      { field: 'note', header: 'Note to Field Rep' },
      { field: 'linkType', header: 'Link Type' },
      { field: 'link', header: 'Link' },
      { field: 'Action', header: 'Action' },
    ];
  }
}
