import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { ConfirmModalComponent } from '@volt/shared/components/modal/confirm-modal/confirm-modal.component';
import { PC, PermissionCollection } from '@volt/shared/services/permissions.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth';
import { Translations } from '../../../shared/services/translations.types';
import { AdminLayoutTranslationService } from '../services/admin-layout-translation.service';
import { LayoutService } from '../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'profile-menu',
  templateUrl: './profile-menu.component.html',
  styles: ['img.mobile { height: 2em; width: 2em;}'],
})
export class ProfileMenuComponent implements OnInit {
  @Input() mobileView: boolean = false;
  @ViewChild('profiledropdown')
  private dropDownRef: ElementRef;
  @ViewChild('profilefab') private fab: ElementRef;

  public profileOpen = false;
  public userDisplayName: string;
  public userEmail: string;
  public userRole: string;

  public profileImageUrl: string;
  public clientOnboardingUrl: string;
  public readonly noProfileImageUrl = '../../../../assets/img/headshots/no-profile.jpg';
  public translatedData$: Observable<Translations>;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _layoutService: LayoutService,
    private _dynamicDialogService: DynamicDialogService,
    private readonly _adminLayoutTranslationService: AdminLayoutTranslationService,
    @Inject(PC) public pc: PermissionCollection,
  ) {}

  ngOnInit(): void {
    const user = this._authService.getCurrentUser();
    this.profileOpen = false;
    this.userDisplayName = `${user.firstName} ${user.lastName}`;
    this.userEmail = user.email;
    this.userRole = user.roles;
    this.profileImageUrl = user.imageUrl;
    this.clientOnboardingUrl = '/client-onboarding-guide/Walmart_Volt-Client_Onboarding_Guide.pdf';
    this.translatedData$ = this._adminLayoutTranslationService.getTranslatedData$();
  }

  @HostListener('document:click', ['$event'])
  private clickWatch(event: any) {
    if (this._layoutService.isClickInsideElement(event, this.fab)) {
      return;
    }
    if (!this._layoutService.isClickInsideElement(event, this.dropDownRef)) {
      this.profileOpen = false;
    }
  }

  public goToMyAccount() {
    this.profileOpen = false;
    this._router.navigate(['/edit-account', this._authService.getCurrentUser().accountId]).then();
  }

  public showProfile() {
    this.profileOpen = !this.profileOpen;
  }

  public confirmSignOut() {
    this.translatedData$.pipe(take(1)).subscribe(data => {
      this._dynamicDialogService.open(ConfirmModalComponent, {
        data: {
          header: data.signOut + '?',
          message: data.pleaseConfirmSignOut,
          acceptLabel: data.signOut,
          accept: () => {
            this._authService.logout();
          },
          rejectLabel: data.cancel,
        },
      });
    });
  }
}
