<ng-container [ngSwitch]="data.relationship">
  <ng-container *ngSwitchCase="'Child'">
    <span style="color: green">{{ data.relationship }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'Related'">
    <span style="color: blue">{{ data.relationship }}</span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ data.relationship }}
  </ng-container>
</ng-container>
