import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { RETAILER_CONFIG, RetailerConfigType } from '../../../retailer-config';
import { UserClient, UserInformation } from '../../api.client';
import { AllowedView, allowedViews } from '../../shared/allowed-view';
import { StateSubject } from '../../shared/extensions/state-subject';
import { MonitoringService } from '../../shared/services/monitoring.service';
import { RoleConstant } from '../shared/roles.constants';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private _isLoggingOut$ = new Subject();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  private _authUserSubject$: StateSubject<UserInformation> = new StateSubject<UserInformation>(null);
  public authUser$: Observable<UserInformation> = this._authUserSubject$.asObservable();
  public isAuthorizedForClient: boolean = false;
  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errored, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map(values => values.every(b => b)));

  public get isAuthenticated(): boolean {
    return this.isAuthenticatedSubject$.getValue();
  }

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this._router.navigateByUrl('/login').then();
  }

  constructor(
    private readonly _oauthService: OAuthService,
    private readonly _userClient: UserClient,
    private readonly _router: Router,
    @Inject(RETAILER_CONFIG) private readonly _retailerConfig: RetailerConfigType,
    private readonly _monitoringService: MonitoringService,
  ) {
    // Useful for debugging:
    this._oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', event => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());

      if (!this._oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this._oauthService.events.subscribe(_ => {
      this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());
    });

    this._oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this._oauthService.loadUserProfile());

    this._oauthService.events
      .pipe(takeUntil(this._isLoggingOut$))
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => {
        this.navigateToLoginPage();
      });

    this._oauthService.setupAutomaticSilentRefresh();
  }

  public async runInitialLoginSequence(): Promise<void> {
    // Try to run the core login sequence three times.
    // If that still fails, clear the local storage and reload the page.

    let tries = 0;
    const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

    while (tries < 3) {
      try {
        await this.runCoreInitialLoginSequence();
        return;
      } catch (error) {
        this._monitoringService.logError(error);
        tries++;
        await delay(1_000);
      }
    }

    this._monitoringService.logError(new Error('Initial login sequence failed three times. Restarting.'));

    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  }

  private async runCoreInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    await this._oauthService.loadDiscoveryDocument();

    // 1. HASH LOGIN:
    // Try to log in via hash fragment after redirect back
    // from IdServer from initImplicitFlow:
    await this._oauthService.tryLogin();

    if (!this._oauthService.hasValidAccessToken()) {
      // User interaction is needed to log in, we will wait for the user to manually log in.
      this.isAuthorizedForClient = false;
      this.isDoneLoadingSubject$.next(true);
      return;
    }

    // JAS: Removed automatic silent refresh for init login as it was timing out.
    const clientAccess = this._oauthService.getIdentityClaims()['client_access'];
    if (clientAccess === null || clientAccess.toLowerCase() !== this._oauthService.clientId.toLocaleLowerCase()) {
      this.isAuthorizedForClient = false;
      this.isDoneLoadingSubject$.next(true);
      return;
    }

    this.isAuthorizedForClient = true;

    await this.loadUserInformation();
  }

  private loadUserInformation(): Promise<unknown> {
    return this._getUserInformation()
      .pipe(
        tap((ui: UserInformation) => {
          this._authUserSubject$.next(ui);
          this.isDoneLoadingSubject$.next(true);
          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this._oauthService.state &&
            this._oauthService.state !== 'undefined' &&
            this._oauthService.state !== 'null'
          ) {
            let stateUrl = this._oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            if (stateUrl.includes('login-callback')) {
              this._router.navigateByUrl('/dashboard').then();
            } else {
              console.log(`There was state of ${this._oauthService.state}, so we are sending you to: ${stateUrl}`);
              this._router.navigateByUrl(stateUrl).then();
            }
          }
        }),
      )
      .toPromise();
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    const params: any = { };
    let email: undefined | string;
    if (window.location?.search) {
      email = new URLSearchParams(window.location.search).get('email');
    }
    if (email) {
      params.email = email;
    }
    this._oauthService.initLoginFlow(targetUrl || this._router.url, params);
  }

  public logout() {
    this._isLoggingOut$.next();
    this._oauthService.logOut();
  }

  public refresh() {
    this._oauthService.silentRefresh().then();
  }

  public hasValidToken() {
    return this._oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this._oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this._oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this._oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this._oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this._oauthService.logoutUrl;
  }

  public getUserAllowedViews(): any {
    const role = this.getCurrentUserRole();
    return this.getAllowedViews(role);
  }

  public getCurrentUser(): UserInformation {
    return this._authUserSubject$.getValue();
  }

  public getCurrentRole(): string {
    return <RoleConstant>this.getCurrentUserRole();
  }

  public isUserAllowedView(views: AllowedView[]): boolean {
    const _allowedViews = this.getUserAllowedViews();
    for (const av of _allowedViews) {
      for (const cv of views) {
        if (av === cv) {
          return true;
        }
      }
    }
    return false;
  }

  private _getUserInformation(): Observable<UserInformation> {
    return this._userClient.retrieveCurrentUser();
  }

  public getCurrentUserRole(): string {
    return this.getCurrentUser().roles;
  }

  public getAllowedViews(role: string): AllowedView[] {
    const allowed: AllowedView[] = [];
    for (const rv of allowedViews) {
      const found = rv.Roles.filter(r => r.toLowerCase() === role.toLowerCase());
      if (found.length > 0) {
        allowed.push(rv.AllowedView);
      }
    }
    return allowed;
  }
}
