import { Params } from "@angular/router";
import { StatusTypes } from "@volt/api";

export class ActivityFilterParams {
  status: StatusTypes;
  filterText: string = '';
  
  static fromParams(params: Params): ActivityFilterParams {
    const filter = new ActivityFilterParams();
    filter.filterText = params['filterText'];
    filter.status = params['status'];
    
    return filter;
  }
}
