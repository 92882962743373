import * as moment from 'moment';

export class ServicedeploymentTaskLocationSearchParameters {
  constructor() {
    this.numberSearch = '';
    this.referenceNumberSearch = '';
    this.employeeNumbersSearch = '';
    this.statusSearch = '';
    this.serviceStartDate = null;
    this.serviceEndDate = null;
  }

  public numberSearch: string;
  public referenceNumberSearch: string;
  public employeeNumbersSearch: string;
  public statusSearch: string;
  public serviceStartDate: moment.Moment;
  public serviceEndDate: moment.Moment;
}
