import { Component, OnDestroy, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { SubscriptionLike as ISubscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { select, NgRedux } from '@angular-redux/store';
import { TableDisplay } from '../table-display/table-display.component';
import { DropDownModel, UserInformation, AccountsClient, ServiceLogReportOfActionLogsViewModel } from '@volt/api';
import { IAppState } from '../../../store';
import { ActionLogFilterParams } from '../../action-log-filter-parameters';
import { RoleConstant } from '../../../auth/shared/roles.constants';
import { ActionLogsService } from '../../services/action-log.service';
import { DataPassingService } from '../../services/data-passing.service';
import { ActionLogStateConstant } from '../../actions/action-log.constants';
import { ActionLogReduxService } from '../../services/action-log.redux.service';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { AuthService } from '../../../auth';

@Component({
  selector: 'action-log',
  templateUrl: 'action-log.component.html',
  styleUrls: ['./action-log.component.scss'],
})
export class ActionLogComponent extends TableDisplay implements OnInit, OnDestroy {
  public pspAccountList: DropDownModel[];
  public status;
  public minDate: Date;
  public showEndDate: boolean;
  public showServiceDeployment: boolean;
  public currentUser: UserInformation;
  public role: string;
  public initialAccountId: string;
  private dataSubscription: ISubscription;
  private reduxSubscription: ISubscription;

  @select([ActionLogStateConstant.FilterStateName])
  private readonly actionLogData: Observable<ActionLogFilterParams>;

  constructor(
    private readonly _authService: AuthService,
    private _accountsClient: AccountsClient,
    private _actionLogService: ActionLogsService,
    private _dataPassingService: DataPassingService,
    private _actionLogReduxService: ActionLogReduxService,
    private readonly monitoringService: MonitoringService,
  ) {
    super();
  }

  ngOnInit() {
    this.reduxSubscription = this.actionLogData.subscribe(
      (data: ActionLogFilterParams) => {
        this.actionlogfilterParams = data;
        this.pullData(this.actionlogfilterParams);
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );

    this.currentUser = this.getCurrentUser();
    this.role = this.currentUser.roles;
    this.checkRole(this.role);

    this._accountsClient.getAccountList().subscribe(
      (data: DropDownModel[]) => {
        this.pspAccountList = data;
        this.pspAccountList.splice(
          0,
          1,
          new DropDownModel({
            label: 'Select Account',
            value: 'Select Account',
          }),
        );
        this.initialAccountId = this.pspAccountList[0].value;
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  ngOnDestroy() {
    this._actionLogReduxService.setFilter(new ActionLogFilterParams());
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.reduxSubscription.unsubscribe();
  }

  public refresh() {
    const actionLogParams = new ActionLogFilterParams();
    this.actionlogfilterParams = Object.assign({}, this.actionlogfilterParams, actionLogParams);
    this._dataPassingService.passRefreshServiceLog(true);
    this.pullData(this.actionlogfilterParams);
  }

  getCurrentUser(): UserInformation {
    return this._authService.getCurrentUser();
  }

  // changed the access role from RetailerOperationalManager to ProviderAdmin --Ashok
  public checkRole(roleName: any) {
    if (
      roleName === RoleConstant.SystemAdministrator ||
      roleName === RoleConstant.RetailerAdmin ||
      roleName === RoleConstant.ProviderAdmin
    ) {
      this.showServiceDeployment = true;
    } else {
      this.showServiceDeployment = false;
    }
  }

  sortdata(event: LazyLoadEvent) {
    let order;
    if (event.sortField == undefined) event.sortField = '-updatedDate';

    if (
      event.sortField !== 'transactionId' &&
      event.sortField !== '-transactionId' &&
      event.sortField !== 'aname' &&
      event.sortField !== '-aname' &&
      event.sortField !== 'message' &&
      event.sortField !== '-message'
    ) {
      let sortFieldwithOrdering = '';
      if (event.sortOrder === -1) {
        sortFieldwithOrdering = sortFieldwithOrdering.concat('-').concat(event.sortField);
        order = false;
      } else {
        sortFieldwithOrdering = sortFieldwithOrdering.concat(event.sortField);
        order = true;
      }
      const updateFilter = Object.assign(
        this.actionlogfilterParams,
        { ascending: order },
        { sortColumn: sortFieldwithOrdering },
      );

      this.pullData(updateFilter);
    }
  }

  pullData(actionLogFilterParams: ActionLogFilterParams) {
    this.loading = true;

    if (actionLogFilterParams.accountId === 0) {
      this.loading = false;
      this.data = null;
      return;
    }

    this.dataSubscription = this._actionLogService
      .getServiceLogData(actionLogFilterParams)
      .subscribe((data: ServiceLogReportOfActionLogsViewModel) => {
        this.cols = data.columnList;
        this.data = data.results;
        this.totalRecords = data.totalCount;
        this.loading = false;
      });
  }
}
