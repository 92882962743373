<label for="search">{{ label || translatedData.search }}</label>
<input
  id="search"
  type="text"
  class="form-control"
  [formControl]="control"
  [placeholder]="placeholder || translatedData.search"
  [disableControl]="disabled"
  [ngbTooltip]="tooltipText"
  [disableTooltip]="disableTooltip"
/>
