import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserInformation } from '../../api.client';
import { SideNav } from './models/sidenav';
import { LayoutService } from './services/layout.service';
import { AuthService } from '../../auth';

@Injectable()
export class SideNavResolver implements Resolve<any> {
  constructor(private _layoutService: LayoutService, private _authService: AuthService) {}

  public model = new SideNav();
  public sidenav: any;
  public currentUser: UserInformation;

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.currentUser = this._authService.getCurrentUser();

    this.model.role = this.currentUser.roles;
    const accountId = this.currentUser.accountId;

    this._layoutService.getAccountHideTransactionByAccountId(accountId).subscribe(data => {
      if (data) {
        this.model.checkAccountHideTransaction = true;
      }

      this._layoutService.getAccountByAccountId(accountId).subscribe(response => {
        if (response) {
          this.model.fieldGroupType = response.fieldGroupType;
          this.model.accountTypeId = response.accountTypeId;
        }
      });
    });

    this.sidenav = this.model;
    return this.sidenav;
  }
}
