import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { iif, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { RETAILER_CONFIG, RetailerConfigType } from '../../../retailer-config';

@Injectable({ providedIn: 'root' })
export class PageTitleService {
  constructor(private _title: Title, private _translateService: TranslocoService,
    @Inject(RETAILER_CONFIG) private readonly _retailerConfig: RetailerConfigType,) {}

  public setPageTitle(pageTitle: string) {
    this._title.setTitle(`${pageTitle} | ${this._retailerConfig.retailerSystemAppName}`);
  }

  public setPageTitleByRoute(route: ActivatedRoute) {
    if (!route.data) {
      return;
    }

    route.data
      .pipe(
        filter(data => !!data['title'] || !!data['titleKey']),
        switchMap(data => {
          return iif(
            () => !!data['titleKey'],
            this._translateService.selectTranslate(data['titleKey'], data['translateParams'] || {}),
            of(data['title']),
          );
        }),
      )
      .subscribe(this.setPageTitle.bind(this));
  }

  public setPageTitleByRouteSnapshot(snapshot: ActivatedRouteSnapshot, lang: string) {
    let deepestSnapshot: ActivatedRouteSnapshot = snapshot.firstChild;

    while (deepestSnapshot.firstChild != null) {
      deepestSnapshot = deepestSnapshot.firstChild;
    }

    const { data } = deepestSnapshot;

    if (!data) {
      return;
    }

    const title = data['titleKey']
      ? this._translateService.translate(data['titleKey'], data['translateParams'] || {}, lang)
      : data['title']
      ? data['title']
      : this._translateService.translate('dashboard', {}, lang);

    this.setPageTitle(title);
  }
}
