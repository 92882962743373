import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { ApiCancelService } from '@volt/shared/services/api-cancel.service';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class ApiCancelInterceptor implements HttpInterceptor {
  private allowedRequests = [
    'assets',
    'token/refresh',
    'permissions/me',
    'locations/allowed',
    'products/getproductlist',
    'feature/getallfeatures',
    'globalsearch/history',
    'getaccountapplist',
    'sas',
    'image',
  ];

  constructor(router: Router, private readonly apiCancelService: ApiCancelService) {
    router.events
      .pipe(
        filter(ev => ev instanceof ActivationEnd),
        filter((ev: ActivationEnd) => ev.snapshot.children.length === 0), // Only look at leaf nodes
        filter((ev: ActivationEnd) => ev.snapshot.pathFromRoot.every(s => s.outlet === PRIMARY_OUTLET)), // Only look at the primary outlet
      )
      .subscribe({
        next: ({ snapshot }: ActivationEnd) => {
          if (!this.apiCancelService.hasCurrentUrl()) {
            this.apiCancelService.setCurrentUrl(snapshot);
            return;
          }

          if (!this.apiCancelService.isOnCurrentUrl(snapshot)) {
            this.apiCancelService.cancelPendingRequests();
            this.apiCancelService.setCurrentUrl(snapshot);
          }
        },
      });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.allowedRequests.some(ar => request.url.includes(ar))) {
      return next.handle(request);
    }

    return next.handle(request).pipe(takeUntil(this.apiCancelService.apiCancel$));
  }
}

export const apiCancelInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiCancelInterceptor,
  multi: true,
};
