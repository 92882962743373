import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, UrlSegment} from '@angular/router';
import { Observable, of } from 'rxjs';
import {TagsGuardService} from "@volt/shared/services/tags-guard.service";
import { AuthService } from 'src/app/auth';

@Injectable({
  providedIn: 'root'
})
export class TagsGuard implements CanActivate {
  constructor(
    private readonly _tagsGuardService: TagsGuardService,
    private readonly _authService: AuthService,
  ) {}

  private readonly tagDataKey = 'requiredTag';
  private systemAdminRole = "System Administrator";

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this._hasTags(route.data[this.tagDataKey], segments);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._hasTags(route.data[this.tagDataKey]);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    const tagData = childRoute.data[this.tagDataKey] || childRoute.parent?.data[this.tagDataKey];
    return this._hasTags(tagData);
  }

  private _hasTags(requiredTag: string, segments: UrlSegment[] = []): Observable<boolean> {
    if (!this._tagsGuardService.tagsLoaded) {
      this._tagsGuardService.loadTags();
    }

    if (this._authService.getCurrentRole() === this.systemAdminRole) {
      return of(true);
    }

    if(requiredTag){
      return this._tagsGuardService.hasTag$(requiredTag);
    }

    return of(true)
  }
}
