<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" for="serviceVistOverview" data-cy="ser-dep-visit-overview-label">
      Visit Overview
      <span class="required" aria-required="true"></span>
      <span data-cy="ser-dep-visit-overview-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <textarea
      class="form-control rounded-0"
      id="serviceVistOverview"
      [formControlName]="'serviceVistOverview'"
      data-cy="ser-dep-visit-overview"
    ></textarea>
  </div>
</div>
