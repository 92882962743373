import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataPassingService {
  private refreshLogSubject = new Subject<boolean>();

  constructor() {}

  public passRefreshServiceLog(param: boolean) {
    this.refreshLogSubject.next(param);
  }
}
