import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DataPassingService } from '../../../../services/data-passing.service';
import { AddServiceDeployment } from '../../../../models/addServiceDeployment';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'service-deployment-end-date',
  templateUrl: './service-deployment-end-date.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class ServiceDeployementEndDateComponent implements OnInit {
  public endDate: Date;
  maxDate: Date;
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public minDate;
  @Input() public serviceDeploymentDateMsg: boolean;
  @Output() public setEndDateMsg: EventEmitter<any> = new EventEmitter<any>();

  public showEndDate = true;

  @Input() set setEndDate(currentServiceDeployment: AddServiceDeployment) {
    this.endDate = currentServiceDeployment.serviceEnd ? new Date(currentServiceDeployment.serviceEnd.toDate()) : null;
    if (currentServiceDeployment.serviceEnd) {
      // this.showEndDate = true;
      this.showEndDate = false;
    }
  }

  constructor(private _dataPassingService: DataPassingService, private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {
    this._dataPassingService.serviceDeploymentEndDate.subscribe((data: any) => {
      this.endDate = null;
      this.showEndDate = false;
      this.serviceDeploymentDateMsg = false;
    });
  }

  public get serviceEndDateControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('serviceEndDate');
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: { messages: ['End of Service Deployment.'] },
    });
  }

  public onSelectEndDate(date: Date) {
    this.serviceDeploymentDateMsg = false;
    this.setEndDateMsg.emit('msg');
  }
}
