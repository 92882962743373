<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-instructions-label">
      Instruction
      <span class="required" aria-required="true"></span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-instructions-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <input
      class="form-control rounded-0"
      placeholder="File name"
      style="display: inline-block; width: 60%"
      id="instructionName"
      (blur)="checkInstructionExist(ref.value)"
      #ref
      data-cy="task-instructions"
    />

    <div *ngIf="serviceTaskInstructionMsg" data-cy="task-instructions-error">
      <span *ngIf="serviceTaskInstructionMsg" class="text-danger">Instruction Name is required</span>
    </div>

    <input
      type="file"
      id="files"
      class="form-control-file"
      [(ngModel)]="files"
      [formControlName]="'files'"
      id="instruction"
      style="display: inline-block; width: 39%"
      accept="application/pdf"
      [allowedExt]="'(.pdf)'"
      data-cy="task-instructions-button"
    />
    <br />

    <div>
      <table class="table pdf-table" *ngFor="let item of selectedInstructions">
        <tr>
          <td>
            <a [href]="item.url | secureUrl | async" target="_blank">{{ item.name }}</a>
          </td>
          <td>
            <div class = "d-flex justify-content-center">
              <button [disabled]="!canEdit" type="button" (click)="deleteInstruction(item.name, this.serviceTaskReferenceNumber)" class="cross-btn">
                X
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
