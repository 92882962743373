<p-toolbar>
    <div style="width: 100%; display: flex; justify-content: flex-end">
        <button
                *ngIf="canGoBack"
                pButton
                [label]="translations.previous"
                icon="pi pi-angle-left"
                style="margin-right: 1rem"
                (click)="previous.emit()"
        ></button>

        <button
                pButton
                [label]="nextLabel || translations.next"
                [disabled]="nextDisabled"
                [icon]="nextButtonIcon"
                iconPos="right"
                (click)="next.emit()"
                [class]="nextButtonStyleClass"
        ></button>

    </div>
</p-toolbar>
