import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import startCase from 'lodash-es/startCase';
import { MultiSelect } from 'primeng/multiselect';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from "rxjs/operators";
import { EFieldGroupType, UserAccountViewModel } from '@volt/api';
import { TranslationService } from '../../../../services/translation.service';
import { Translations } from '../../../../services/translations.types';
import { CommonUtils } from '../../../../utils/common.utils';
import { OperatorUtils } from '../../../../utils/operator.utils';
import { BaseFormField } from '../../base-form-field.component';
import { AccountDropdownService } from '@volt/shared/services/account-dropdown.service';

@Component({
  selector: 'v-accounts-field',
  templateUrl: './accounts-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AccountDropdownService]
})
export class AccountsFieldComponent extends BaseFormField implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MultiSelect, { static: true }) mSelect: MultiSelect;
  @Input() maxNumberOfSelections?: number = undefined;

  private destroy$ = new Subject();

  constructor(
    private readonly _accountDropdownService: AccountDropdownService,
    private readonly _translationService: TranslationService,
    private readonly _cd: ChangeDetectorRef,
  ) {
    super(_translationService);
  }

  @Input()
  set accountTypes(value: EFieldGroupType[]) {
    this._accountDropdownService.accountTypes = value;
  }

  @Input()
  set banners(banners: string[]) {
    this._accountDropdownService.banners = banners;
  }

  @Input() useDataWarehouse(useDataWarehouse: boolean) {
    this._accountDropdownService.useDataWarehouse = useDataWarehouse;
  }

  protected get filterDisplayLabel(): string {
    return this.translatedData.accounts;
  }

  ngOnInit() {
    combineLatest([
      this.getTranslations$(),
      this._accountDropdownService.accounts$,
      this._accountDropdownService.shouldDisableDropdown$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([translations, accounts, shouldDisableDropdown]: [Translations, UserAccountViewModel[], boolean]) => {
        this.setItems(accounts);
        this.updateControlState(accounts, shouldDisableDropdown);

        this.updateSelectedItems();
        this._cd.markForCheck();
      });
  }

  private updateControlState(accounts: UserAccountViewModel[], shouldDisableDropdown: boolean) {
    if (this.control.enabled && shouldDisableDropdown) {
      this.control.disable();
      this.control.defaultValues = accounts.map(a => a.accountId.toString());
      this.control.setValue(this.control.defaultValues);
    }
  }

  private setItems(accounts: UserAccountViewModel[]) {
    this.items = CommonUtils.mapArrayToMultiSelectItem<UserAccountViewModel>(
      accounts,
      item => startCase(item.aname),
      item => item.accountId,
      true,
    );
  }

  private getTranslations$() {
    return this._translationService.getTranslatedObject().pipe(
      tap(translatedData => {
        this.translatedData = translatedData;
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.firstChange) {
      this.updateSelectedItems();
      this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateSelectedItems());
      this.control.registerOnDisabledChange((isDisabled: boolean) => this._cd.markForCheck());
      this.control.valueCleared$
        .asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe(this.modifyMultiSelectInstance.bind(this));
    }
  }

  resetSelection() {
    this.control.setValue([]);
    this.modifyMultiSelectInstance();
    this._cd.markForCheck();
  }

  private modifyMultiSelectInstance() {
    this.mSelect.hide();
    this.mSelect.maxSelectionLimitReached = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

}
