import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['../../../assets/auth/auth.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
  private _returnUrl: string;
  protected $destroyed = new Subject();

  constructor(private _route: ActivatedRoute, private _router: Router, private _authService: AuthService) {}

  ngOnDestroy(): void {
    this.$destroyed.next();
  }

  ngOnInit(): void {
    this._authService.canActivateProtectedRoutes$.pipe(takeUntil(this.$destroyed)).subscribe(yes => {
      if (yes && !this._authService.isAuthorizedForClient) {
        this._router.navigate(['/not-authorized']).then();
        return;
      }
      this._router.navigate(['/dashboard']).then();
    });
  }
}
