<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="ser-dep-start-date-label">
      Start Date
      <span class="required" aria-required="true">*</span>
      <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="ser-dep-start-date-tooltip"></i>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-calendar
      [(ngModel)]="startDate"
      [showIcon]="true"
      name="serviceStartDate"
      [readonlyInput]="true"
      (onSelect)="onSelectStartDate(ref.value)"
      dateFormat="mm/dd/yy"
      [formControlName]="'serviceStartDate'"
      #ref
      required
      data-cy="ser-dep-start-date"
    ></p-calendar>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && serviceStartDateControl.hasError('required')"
        class="text-danger"
        data-cy="ser-dep-start-date-error"
      >
        A valid start date is required.
      </span>
    </div>
  </div>
</div>
