export enum RoleConstant {
  SystemAdministrator = 'System Administrator',
  VoltAccounting = 'VOLT Accounting',
  VoltCustomerService = 'VOLT Customer Service',
  ProviderAdmin = 'Provider Admin',
  ProviderCustomerService = 'Provider Customer Service',
  ProviderDistrictManager = 'Provider District Manager',
  ProviderExecutive = 'Provider Executive',
  ProviderRegionalManager = 'Provider Regional Manager',
  RetailerAdmin = 'Retailer Admin',
  RetailerCalendarAdmin = 'Retailer Calendar Admin',
  RetailerStoreManager = 'Retailer Store Manager',
  RetailerOperationalManager = 'Retailer Operational Manager',
  RetailOperationsAdmin = 'Retail Operations Admin',
  RetailerMarketManager = 'Retailer Market Manager',
  FieldEmployee = 'Field Employee',
  EOCAdmin = 'EOC Admin',
  EOCManager = 'EOC Manager',
  SupplierAdmin = 'Supplier Admin',
  ProviderScheduleAdmin = 'Provider Schedule Admin',
}
