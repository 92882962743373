import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DateRange, DropDownModel, IUserInformation, UserInformation } from '@volt/api';
import { Translations } from "@volt/shared/services/translations.types";
import { DataPassingService } from '../../services/data-passing.service';
import { AllowedView } from '@volt/shared/allowed-view';
import { ActionLogReduxService } from '../../services/action-log.redux.service';
import { DateDropDown } from '../../models/date-range';
import * as moment from 'moment';
import { RangeDirection } from '@volt/shared/components/misc/date-range-selector/range-setting';
import { ActionLogFilterParams } from '../../action-log-filter-parameters';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store';
import { RoleConstant } from '../../../auth/shared/roles.constants';
import { AuthService } from '../../../auth';

@Component({
  selector: 'search-filter',
  templateUrl: 'search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchServiceLogFilterComponent implements OnInit, OnDestroy {
  constructor(
    private _modal: NgbModal,
    private _dataPassingService: DataPassingService,
    private _actionLogReduxService: ActionLogReduxService,
    private errorModal: NgbModal,
    private _appState: NgRedux<IAppState>,
    private readonly _authService: AuthService,
  ) {}

  public allowedView = AllowedView;
  public cols;
  public endDate: Date;
  public startDate: Date;
  public newList = [];
  public isAccountSelected = false;
  @Input() public currentApiLogParams: ActionLogFilterParams;
  @Input() public pspAccountList: DropDownModel[];
  @Output() private pspSearchSubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() public searchStartDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() public searchEndDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() private searchBySubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() private clearClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public minDate;
  @Input() translations: Translations;

  public datesDropDownList: DateDropDown[];
  public searchTypes: DropDownModel[];
  public days = 7;
  public selectedOption = '';
  public showEndDate = true;
  public currentUser: UserInformation;
  public isProviderAdmin = false;
  public typeDropModel: any[];

  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  public selectedPSP;
  public searchText: string;
  public transactionId: string;
  public referenceNumber: string;
  private type: string;
  status = 'Failed';

  private static buildDateRangeAsHours(days: number, direction: RangeDirection = RangeDirection.Past): DateRange {
    if (days === null) {
      const rangeD = new DateRange();
      rangeD.startDate = null;
      return rangeD;
    }

    const totalHours = days * 24;
    const now = moment();
    const then =
      direction === RangeDirection.Past ? moment().subtract(totalHours, 'hours') : moment().add(totalHours, 'hours');
    const range = new DateRange();
    range.startDate = direction === RangeDirection.Past ? then : now;
    return range;
  }

  ngOnInit(): void {
    this.getDatesDropDownList();
    this.getDropdownOptions();
    this.getTypeDropDownList();
    this.currentUser = this.getCurrentUser();

    if (this.currentUser.roles === RoleConstant.ProviderAdmin) {
      this.isProviderAdmin = true;
    }

    if (this.isProviderAdmin) this.isAccountSelected = true;
  }

  getCurrentUser(): UserInformation {
    return this._authService.getCurrentUser();
  }

  ngOnDestroy(): void {}

  public FilterByPSP() {
    if (this.selectedPSP === 'Select Account') {
      this.isAccountSelected = false;
      this.selectedOption = '';
      this.clearSearch();
      return;
    }

    this.isAccountSelected = true;
    this.currentApiLogParams = Object.assign(this.currentApiLogParams, {
      accountId: this.selectedPSP,
    });
  }

  public search() {
    // Global items
    const accountNumber = this.currentApiLogParams.accountId;
    const sortColumn = this.currentApiLogParams.sortColumn;

    this.currentApiLogParams = Object.assign(new ActionLogFilterParams(), {
      accountId: accountNumber,
      sortColumn: sortColumn,
      searchType: this.selectedOption,
    });

    if (this.selectedOption === 'reference') {
      this.currentApiLogParams = Object.assign(this.currentApiLogParams, {
        referenceNumber: this.referenceNumber,
      });
    }

    if (this.selectedOption === 'transaction') {
      this.currentApiLogParams = Object.assign(this.currentApiLogParams, {
        transactionId: this.transactionId,
      });
    }

    if (this.selectedOption === 'statusAndType') {
      this.currentApiLogParams = Object.assign(this.currentApiLogParams, {
        type: this.type,
        status: this.status,
      });
    }

    // force the accountId
    if (this.isProviderAdmin) {
      this.currentApiLogParams = Object.assign(this.currentApiLogParams, {
        accountId: this.currentUser.accountId,
      });
    }

    // set the days
    const dateRange = SearchServiceLogFilterComponent.buildDateRangeAsHours(this.days);
    this.currentApiLogParams = Object.assign(this.currentApiLogParams, {
      start: dateRange.startDate,
    });
    this._actionLogReduxService.setFilter(this.currentApiLogParams);
  }

  public getSelectedOption(option) {
    this.selectedOption = option.value;
    this.transactionId = '';
    this.referenceNumber = '';
    this.type = '';
  }

  public canSearch(): boolean {
    switch (this.selectedOption) {
      case 'transaction':
        return this.isAccountSelected && this.days > 0 && !!this.transactionId;
      case 'reference':
        return this.isAccountSelected && this.days > 0 && !!this.referenceNumber;
      case 'statusAndType':
        return this.isAccountSelected && this.days > 0 && !!this.status && !!this.type;
      default:
        return false;
    }
  }

  public clearSearch() {
    this.currentApiLogParams = new ActionLogFilterParams();
    this.selectedPSP = 'Select Account';
    this.selectedOption = '';
    this.searchText = null;
    this.isAccountSelected = this.isProviderAdmin;
    this._actionLogReduxService.setFilter(this.currentApiLogParams);
  }

  public setDays(days) {
    this.days = days.value;
  }

  public setType(dd) {
    this.type = dd.value;
  }

  public getDatesDropDownList() {
    this.datesDropDownList = DateDropDown.defaultRange;
  }

  public getDropdownOptions() {
    this.searchTypes = [
      new DropDownModel({ label: 'Search by ...', value: '' }),
      new DropDownModel({
        label: 'Search by transaction',
        value: 'transaction',
      }),
      new DropDownModel({ label: 'Search by reference', value: 'reference' }),
      new DropDownModel({
        label: 'Search failed by type',
        value: 'statusAndType',
      }),
    ];
  }

  public getTypeDropDownList() {
    this.typeDropModel = [
      { label: 'Select Type', value: '' },
      { label: 'Service Deployments', value: 'ServiceDeployment' },
      { label: 'Service Tasks', value: 'ServiceTask' },
      { label: 'Task Locations', value: 'TaskLocation' },
      { label: 'Questions', value: 'Question' },
      { label: 'Task Results', value: 'TaskResult' },
      { label: 'Question Results', value: 'QuestionResult' },
    ];
  }
}
