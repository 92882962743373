<div class="login-container">
  <div class="login-box">
    <div class="login-body">
      <h2>
        <i class="fa fa-exclamation-triangle"></i>
        Not Authorized
      </h2>

      <p
        *ngIf="accountSupport$ | async as accountSupport; else defaultAccessDeniedMessage"
        [innerHtml]="accountSupport.accessDeniedMessage"
      ></p>

      <ng-template #defaultAccessDeniedMessage>
        You are not authorized to access the section requested. If you feel you have received this message in error,
        contact support.
      </ng-template>

      <div>
        <button type="button" class="btn btn-primary w-100 rounded-0" (click)="goHome()">Go To Home</button>
        <button type="button" class="mt-3 btn btn-primary w-100 rounded-0" (click)="goBack()">
          Go Back to Previous Location
        </button>
        <p class="text-center mt-2 mb-2">Or</p>
        <button type="button" class="btn btn-primary w-100 rounded-0" (click)="confirmSignOut()">
          Sign Out and Try another Account
        </button>
      </div>
    </div>
  </div>
</div>
