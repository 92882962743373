import { IFilterCriteria } from './filter-criteria.interface';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth';
import { RoleConstant } from '../../auth/shared/roles.constants';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'psp-admin-report',
  template: `
      <powerbi-report
              [reportId]="reportId"
              [groupId]="groupId"
              [title]="title"
              [iconClass]="iconClass"
              [filterCriteria]="filterCriteria"
              [filterPaneEnabled]="filterPaneEnabled"
      ></powerbi-report>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PspAdminReportComponent implements OnInit {
  public readonly reportId: string = '06c38488-da94-4bb1-a0d8-0cdc9c867592';
  public readonly groupId: string = 'fa95d733-158e-48b1-adcb-2c7fc1f83f15';

  public title: string = null;
  public iconClass: string = null;
  public filterCriteria: IFilterCriteria = null;
  public filterPaneEnabled = false;

  constructor(private readonly route: ActivatedRoute, private readonly authService: AuthService) {}

  public ngOnInit(): void {
    const routeData = this.route.snapshot.data;

    if (routeData) {
      this.title = routeData.title;
      this.iconClass = routeData.iconClass;
    }

    const isSystemAdmin = this.authService.getCurrentRole() === RoleConstant.SystemAdministrator;

    this.filterPaneEnabled = isSystemAdmin;

    if (!isSystemAdmin) {
      const user = this.authService.getCurrentUser();
      const accountId = user && user.accountId ? user.accountId : -1;

      this.filterCriteria = {
        table: 'Query2',
        column: 'AccountID',
        values: [accountId],
      };
    }
  }
}

