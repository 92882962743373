import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiBaseUrl } from '@volt/api';
import { BlobBlobClient} from '@volt/api-blob';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { of } from 'rxjs/internal/observable/of';
import { delay, shareReplay, switchMap, tap } from 'rxjs/operators';

import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from '../../../configuration';
import { environment } from '../../../environments/environment';

export interface BlobReference {
  exists?: boolean;
  key?: string | null;
  fullPath?: string | null;
  container?: string | null;
}
export interface ContainerReference{
  exists?: boolean;
  key?: string |null;
  container?: string|null;
}

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  private readonly basePath = '/client/v1';
  private containerSasTokens: Record<string, Observable<any>> = {}

  constructor(
    private readonly httpClient: HttpClient,
    private _blobClient: BlobBlobClient,
    @Inject(ApiBaseUrl) private readonly apiBaseUrl: string,
    @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration,
  ) {}

  getBlobReference(container: string, path: string): Observable<BlobReference> {
    const url = this.buildUrl(this.basePath, 'meta', container, path);
    return this.httpClient.get<BlobReference>(url);
  }

  downloadBlob(path: string): Observable<Blob> {
    return this.httpClient.get(this.buildUrl(path), { responseType: 'blob' });
  }

  private buildUrl(...paths: string[]) {
    const base = this.apiBaseUrl;
    if (paths.length) {
      const route = paths.join('/');
      return base + (route.startsWith('/') ? route : '/' + route);
    }
    return base;
  }

  downloadBlobFromContainer(container: string, path: string) {
    return this._blobClient.download(`${container}/${path}`).pipe(
      tap(file => {
        const fileName = path.replace(/^.*[\\\/]/, '');
        const dataUrl = window.URL.createObjectURL(file.data);
        const anchor = document.createElement('a');
        anchor.href = dataUrl;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(dataUrl);
      }),
    );
  }

  referencesVoltBlob(url: string): boolean {
    if (url == null) {
      return false;
    }

    const fullUrl = this.getFullUrl(url);
    return fullUrl.startsWith(this._appConfig.blobUrl);
  }

  isSecured(url: string): boolean {
    return this.referencesVoltBlob(url) && url.toLowerCase().includes(environment.uploadsSecureContainer.toLowerCase());
  }

  getFullUrl(url: string) {
    // If an origin is not present, then blobUrl is set
    const uri = new URL(url, this._appConfig.blobUrl);
    return uri.href;
  }
  /**
   * @description Returns an observable string of blob url with a sas token on for the container, inserting "/$path"
   * as a regex target
   * @param container is the container name
   */
  getContainerSasTokenWithRefresh(container:string){
    return this.getContainerTokenWithRefresh<string>(
      container,
      this._blobClient.getSasTokenForContainer.bind(this._blobClient),
      sasToken =>  this.getExpiration(new URL(sasToken, this.apiBaseUrl)),
    );
  }

  private getExpiration(url: URL): number {
    const sasExpiration = url.searchParams.get('se');
    const expiration = moment(sasExpiration);
    const now = moment().utc();
    return expiration.diff(now) * 0.8;
  }

  private getContainerTokenWithRefresh<J>(
    container: string,
    obs: (_: string) => Observable<J>,
    timeoutCallback: (data: J) => number,
  ): Observable<J> {
    if (Object.keys(this.containerSasTokens).includes(container))
      return this.containerSasTokens[container];

    const timeoutSubject = new BehaviorSubject<number>(0);

    const refreshObservable = timeoutSubject.pipe(
      switchMap(timeOut => {
        return of('').pipe(delay(timeOut));
      }),
      switchMap(() => obs(container)),
      tap(result => {
        const expiration = timeoutCallback(result);
        timeoutSubject.next(expiration);
      }),
      shareReplay(),
    );

    this.containerSasTokens[container] = refreshObservable;
    return refreshObservable;
  }
}
