import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ContainerWrapperToastService {
  private readonly _messageSubject: Subject<Message> = new Subject<Message>();
  private readonly _clearMessage: Subject<null> = new Subject<null>();

  get message$(): Observable<Message> {
    return this._messageSubject.asObservable();
  }

  get clear$(): Observable<null> {
    return this._clearMessage.asObservable();
  }

  addMessage(message: Message) {
    setTimeout(() => this._messageSubject.next(message), 0);
  }

  clearMessage() {
    this._clearMessage.next();
  }
}
