import { Moment } from 'moment';

export class ActionLogFilterParams {
  constructor() {
    this.page = 1;
    this.pageSize = 20;
    this.sortColumn = '-updatedDate';
    this.referenceNumber = '';
    this.accountId = 0;
    this.transactionId = '';
    this.status = '';
    this.type = '';
    this.start = null;
    this.searchType = 'transaction';
  }
  public referenceNumber: string;
  public page: number;
  public pageSize: number;
  public sortColumn: string;
  public ascending: boolean;
  public fromDate: any;
  public toDate: any;
  public providerId: number;
  public accountId: number;
  public transactionId: string;
  public status: string;
  public type: string;
  public start: Moment;
  public searchType: string;
}
