import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { RedirectUserService } from '@volt/shared/services/redirect-user.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '..';
import { AllowedView } from '../../shared/allowed-view';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedForModule implements CanLoad {
  constructor(private _authService: AuthService, private _redirectService: RedirectUserService) {}

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this._canActivate(route)
      .pipe(
        tap(canActivate => {
          if (!canActivate) {
            this._redirectService.redirectUnauthorizedUser();
          }
        }),
      )
      .pipe(take(1));
  }

  private _canActivate(route: Route): Observable<boolean> {
    return this._authService.canActivateProtectedRoutes$.pipe(
      map((val: boolean) => {
        if (!val) return false;

        if (!route.data || !route.data['requiredView']) {
          return true;
        }
        const reqViews = route.data['requiredView'] as AllowedView[];
        const views = this._authService.getUserAllowedViews();
        let allowed = false;
        for (const view of views) {
          if (reqViews.indexOf(view) > -1) {
            allowed = true;
            break;
          }
        }
        return allowed;
      }),
    );
  }
}
