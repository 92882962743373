export class DomUtils {
  public static scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public static scrollElementIntoView(element: HTMLElement) {
    this.runOnNextRender(() => {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    });
  }

  public static scrollToElement(element: HTMLElement) {
    this.runOnNextRender(() => window.scroll({ top: element.offsetTop, behavior: 'smooth' }));
  }

  public static runOnNextRender(cb: () => void) {
    setTimeout(cb, 0);
  }
}
