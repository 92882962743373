import { Location } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsClient } from '@volt/api';

import { AuthService } from '..';

@Component({
  selector: 'not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['../../../assets/auth/auth.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotAuthorizedComponent {
  constructor(
    private _location: Location,
    private readonly _authService: AuthService,
    private _router: Router,
    private readonly _accountClient: AccountsClient,
  ) {}

  accountSupport$ = this._accountClient.getAccountSupport();

  public goBack() {
    this._location.back();
  }

  public goHome() {
    this._router.navigate(['/dashboard']).then();
  }

  public confirmSignOut() {
    this._authService.logout();
  }
}
