import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import {
  ApiError,
  CreateGlobalFilterViewModel,
  FilterDefinition,
  GlobalFilterClient,
  GlobalFilterResourceType,
  GlobalFilterValueType,
  GlobalFilterViewModel,
  UpdateGlobalFilterViewModel,
} from '../api.client';
import { EnumUtils } from '../shared/utils/enum.utils';
import { BehaviorSubject, iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IAppState } from '../store';
import { AuthService } from '../auth';

export type GlobalFilter = { [key in keyof typeof GlobalFilterResourceType]?: any };

@Injectable({ providedIn: 'root' })
export class GlobalFilterService {
  private readonly $filterSidebarVisible = new BehaviorSubject(false);
  private readonly $globalFilter = new BehaviorSubject<GlobalFilter>({});
  private hasGlobalFilter = true;

  constructor(
    private readonly globalFilterClient: GlobalFilterClient,
    private readonly authService: AuthService,
    private readonly appState: NgRedux<IAppState>,
  ) {}

  get filterSidebarVisible$(): Observable<boolean> {
    return this.$filterSidebarVisible.asObservable();
  }

  get globalFilter$(): Observable<GlobalFilter> {
    return this.$globalFilter.asObservable();
  }

  getFilterSidebarVisible(): boolean {
    return this.$filterSidebarVisible.getValue();
  }

  getGlobalFilter(): GlobalFilter {
    return this.$globalFilter.getValue();
  }

  loadGlobalFilter() {
    if (this.authService.isAuthenticated) {
      this.globalFilterClient
        .retrieve(this.authService.getCurrentUser().id)
        .pipe(
          catchError(() => of(new GlobalFilterViewModel({ filterDefinitions: [] }))),
          tap(gf => {
            this.hasGlobalFilter = !!gf.filterDefinitions.length;
          }),
          map(globalFilter => this.processGlobalFilter(globalFilter)),
        )
        .subscribe(globalFilter => {
          this.$globalFilter.next(globalFilter);
        });
    } else {
      this.$globalFilter.next({});
    }
  }

  open() {
    this.$filterSidebarVisible.next(true);
  }

  close() {
    this.$filterSidebarVisible.next(false);
  }

  saveFilter(filter: GlobalFilter) {
    const filterDefinitions = Object.entries(filter).map(
      ([resourceName, rawValue]: [keyof typeof GlobalFilterResourceType, any]) => {
        const { value, valueType } = this.processRawValue(rawValue);
        return new FilterDefinition({
          resource: GlobalFilterResourceType[resourceName],
          value,
          valueType,
        });
      },
    );

    iif(
      () => this.hasGlobalFilter,
      this.globalFilterClient.update(
        new UpdateGlobalFilterViewModel({
          userId: this.authService.getCurrentUser().id,
          definitions: filterDefinitions,
        }),
      ),
      this.globalFilterClient.create(
        new CreateGlobalFilterViewModel({
          definitions: filterDefinitions,
        }),
      ),
    )
      .pipe(
        catchError(err => {
          console.log(err);
          return throwError(err);
        }),
        map(globalFilter => this.processGlobalFilter(globalFilter)),
      )
      .subscribe(globalFilter => {
        this.$globalFilter.next(globalFilter);
        this.close();
      });
  }

  private processGlobalFilter(globalFilter: GlobalFilterViewModel) {
    const { filterDefinitions } = globalFilter;
    return filterDefinitions.reduce((acc, cur) => {
      acc[EnumUtils.nameOf(GlobalFilterResourceType, cur.resource)] = this.processGlobalFilterValue(
        cur.value,
        cur.valueType,
      );
      return acc;
    }, {} as GlobalFilter);
  }

  private processGlobalFilterValue(value: string, valueType: GlobalFilterValueType) {
    switch (valueType) {
      case GlobalFilterValueType.String:
        return value ?? undefined;
      case GlobalFilterValueType.Number: {
        const num = Number(value);
        return isNaN(num) ? undefined : num;
      }
      case GlobalFilterValueType.NumberList:
      case GlobalFilterValueType.StringList: {
        const parsed = JSON.parse(value);
        if (Array.isArray(parsed) && parsed.length) {
          return parsed;
        }
      }
    }
  }

  private processRawValue(rawValue: any): { value: string; valueType: GlobalFilterValueType } {
    const value = JSON.stringify(rawValue);
    if (Array.isArray(rawValue)) {
      const [firstItem] = rawValue;
      if (typeof firstItem === 'string' || firstItem == null) {
        return { value, valueType: GlobalFilterValueType.StringList };
      }

      if (typeof firstItem === 'number') {
        return { value, valueType: GlobalFilterValueType.NumberList };
      }
    }

    if (typeof rawValue === 'number') {
      return { value, valueType: GlobalFilterValueType.Number };
    }

    return { value, valueType: GlobalFilterValueType.String };
  }
}
