import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslationService } from '../../services/translation.service';
import { Translations } from '../../services/translations.types';

@Injectable({
  providedIn: 'root',
})
export class BaseControlService {
  private _formResetSubject: Subject<null> = new Subject<null>();

  constructor(private readonly _translationService: TranslationService) {}

  get translatedData$(): Observable<Translations> {
    return this._translationService.getTranslatedObject();
  }

  get formReset$(): Observable<null> {
    return this._formResetSubject.asObservable();
  }

  resetForm() {
    this._formResetSubject.next();
  }
}
