
// THIS FILE IS AUTO-GENERATED BY @architectnow/cli
// ANY CHANGES MADE TO THIS FILE WILL BE LOST

export enum PermissionNames {
  AccountsLimitedAccountOverview = "accounts.limited-overview",
  AccountsManage = "accounts.manage",
  AccountsManageAlternativeSupplierNumbers = "accounts.manage.alternativesuppliernumbers",
  AccountsManageApiKey = "accounts.manage.apikey",
  AccountsManageAppLinks = "accounts.manage.applinks",
  AccountsManageApps = "accounts.manage.apps",
  AccountsManageBadgeShipping = "accounts.manage.badgeshipping",
  AccountsManageCertifications = "accounts.manage.certifications",
  AccountsManageExport = "accounts.manage.export",
  AccountsManageHierarchy = "accounts.manage.hierarchy",
  AccountsManageInviteSupplier = "accounts.manage.invitesupplier",
  AccountsManageMoreDetails = "accounts.manage.moredetails",
  AccountsManageProducts = "accounts.manage.products",
  AccountsManageTags = "accounts.manage.tags",
  ActivitiesManage = "activities.manage",
  ActivitiesManageAssociateActivities = "activities.manage.associateactivities",
  ActivityResponsibilityManage = "activityresponsibility.manage",
  AdminDashboardManage = "admindashboard.manage",
  AssemblerPriorityManage = "assemblerpriority.manage",
  BadgeReprintForFree = "badgereprint.manage.reprintforfree",
  BadgeReprintManage = "badgereprint.manage",
  BadgesManage = "badges.manage",
  CertificationsManage = "certifications.manage",
  CertificationsManageCoassembleCourses = "certifications.manage.coassemblecourses",
  DashboardManage = "dashboard.manage",
  DemoRetailtainmentManage = "demoretailtainment.manage",
  DepartmentsManage = "departments.manage",
  DiagnosticsManage = "diagnostics.manage",
  EmergenciesManage = "emergencies.manage",
  EmergenciesManageOrders = "emergencies.manage.orders",
  EmergenciesManageProducts = "emergencies.manage.products",
  EmergenciesManagePushNotification = "emergencies.manage.pushnotification",
  FaqManage = "faq.manage",
  FeatureAvailabilityManage = "feature-availability.manage",
  FieldNotificationsManage = "fieldnotifications.manage",
  InvoicesManage = "invoices.manage",
  LocationsManage = "locations.manage",
  LocationsManageAcknowledgeAlert = "locations.manage.acknowledgealert",
  LocationsManageClosures = "locations.manage.closures",
  LocationsManageDetail = "locations.manage.detail",
  LocationsManageEmergencyBanner = "locations.manage.emergencybanner",
  LocationsManageEocOrder = "locations.manage.eocorder",
  LocationsManageEocStatus = "locations.manage.eocstatus",
  LocationsManageFeatures = "locations.manage.features",
  LocationsManageGrouping = "locations.manage.grouping",
  LocationsManageLegacyFeatures = "locations.manage.legacyfeatures",
  LocationsManageReceivingHoursCalendar = "locations.manage.receiving-hours-calendar",
  LocationsManageStoreHierarchy = "locations.manage.storehierarchy",
  LocationsManageTaskFeedback = "locations.manage.taskfeedback",
  MassCommunicationsAccountCategories = "mass-communications.accountcategories",
  MassCommunicationsHistory = "mass-communications.history",
  MassCommunicationsManage = "mass-communications.manage",
  MassCommunicationsSend = "mass-communications.send",
  MassCommunicationsTemplates = "mass-communications.templates",
  MyAccountManage = "myaccount.manage",
  MyAccountManageApiKey = "myaccount.manage.apikey",
  MyAccountManageMoreDetails = "myaccount.manage.moredetails",
  OnboardingGuideManage = "onboardingguide.manage",
  PfsInventoryManage = "pfsinventory.manage",
  PhotoGalleryManage = "photogallery.manage",
  ReceivingCalendarManage = "receiving-calendar.manage",
  ReportsAssemblyActivity = "reports.assemblyactivity",
  ReportsCheckInLocation = "reports.checkinlocation",
  ReportsCheckinByAccount = "reports.checkinbyaccount",
  ReportsCommunicationLog = "reports.communicationlog",
  ReportsCommunicationLogViewDetails = "reports.communicationlog.view-details",
  ReportsCoverage = "reports.coverage",
  ReportsDataScorecard = "reports.datascorecard",
  ReportsDueDatePerformance = "reports.duedateperformance",
  ReportsEmergencies = "reports.emergencies",
  ReportsExceptions = "reports.exceptions",
  ReportsExternalUsers = "reports.externalusers",
  ReportsFieldGroupScorecard = "reports.fieldgroupscorecard",
  ReportsFieldGroupTypeSummary = "reports.fieldgrouptypesummary",
  ReportsGeneralFeedback = "reports.generalfeedback",
  ReportsImportLog = "reports.importLog",
  ReportsLaptopRestore = "reports.laptoprestore",
  ReportsManage = "reports.manage",
  ReportsPosAccess = "reports.posaccess",
  ReportsPspAdmin = "reports.pspadmin",
  ReportsPspSurvey2019 = "reports.pspsurvey2019",
  ReportsPspSurvey2020 = "reports.pspsurvey2020",
  ReportsRealTimeCheckin = "reports.realtimecheckin",
  ReportsRetailerUsage = "reports.retailerusage",
  ReportsSsrFeedback = "reports.ssrfeedback",
  ReportsSsrUsage = "reports.ssrusage",
  ReportsStoreFeedback = "reports.storefeedback",
  ReportsSyndicatedRep = "reports.syndicatedrep",
  ReportsTaskDetailAnalytics = "reports.taskdetailanalytics",
  ReportsTaskExport = "reports.taskexport",
  ReportsUserAccessReview = "reports.useraccessreview",
  ReportsUserChangeLog = "reports.userchangelog",
  ReportsWmtAdmin = "reports.wmtadmin",
  ReportsWspCoverage = "reports.wspcoverage",
  RolesDetails = "roles.details",
  RolesManage = "roles.manage",
  SchedulesActionLog = "schedules.actionlog",
  SchedulesEventReview = "schedules.eventreview",
  SchedulesEventReviewFoodSample = "schedules.eventreview.foodsample",
  SchedulesManage = "schedules.manage",
  SchedulesManageDetails = "schedules.manage.details",
  SchedulesManageMoreDetails = "schedules.manage.moredetails",
  SupplierRegistrationManage = "supplierregistration.manage",
  SupplierSignageDashboard = "suppliersignage.dashboard",
  SupplierSignageManage = "suppliersignage.manage",
  SupplierSignageRequest = "suppliersignage.request",
  SuppliersManage = "suppliers.manage",
  SuppliersManageDuns = "suppliers.manage.duns",
  SuppliersManageExport = "suppliers.manage.export",
  SupportBotManage = "supportbot.manage",
  SupportManage = "support.manage",
  TagsManage = "tags.manage",
  TagsManagePage = "tags-page.manage",
  TodoListManage = "todolist.manage",
  UsersActAsSystem = "users.system.actassystem",
  UsersLegacy = "users.legacy",
  UsersManage = "users.manage",
  UsersManageAccess = "users.manage.access",
  UsersManageAddress = "users.manage.address",
  UsersManageAdvancedExport = "users.manage.advancedexport",
  UsersManageAssociateAcct = "users.manage.associateacct",
  UsersManageBadge = "users.manage.badge",
  UsersManageBadgeEvaluation = "users.manage.badgeevaluation",
  UsersManageBadgeShipping = "users.manage.badgeshipping",
  UsersManageCertStatus = "users.manage.certstatus",
  UsersManageDetail = "users.manage.detail",
  UsersManageEmail = "users.manage.email",
  UsersManageExport = "users.manage.export",
  UsersManageLoginAs = "users.manage.loginas",
  UsersManageMobile = "users.manage.mobile",
  UsersManageMoreDetail = "users.manage.moredetail",
  UsersManagePhone = "users.manage.phone",
  UsersManageSendInvitation = "users.manage.sendinvitation",
  UsersManageStatus = "users.manage.status",
  UsersManageUnlock = "users.manage.unlock",
  UsersManageUserName = "users.manage.username",
  VendorLogManage = "vendorlog.manage"
}
