import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslationService } from '@volt/shared/services/translation.service';

@Component({
  selector: 'use-company-address',
  templateUrl: './use-company-address.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UseCompanyAddressComponent implements OnInit {
  @Input() selected = true;
  @Input() set type(t: string) {
    this.label = this._translationService.translate('addressSameAsCompanyAddress', {name: t});
  }

  label: string;

  constructor(private readonly _translationService: TranslationService) { }

  ngOnInit(): void {
  }

}
