import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'v-refresh-button',
  template: `
    <div class="clickable text-primary" (click)="refreshClick.emit()">
      <i class="fa fa-lg fa-refresh h-line-height"></i>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshButtonComponent {
  @Output() public refreshClick = new EventEmitter();
}
