import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { TranslationService } from '@volt/shared/services/translation.service';
import { Translations } from '@volt/shared/services/translations.types';
import { FileUpload } from 'primeng/fileupload';
import { Observable } from 'rxjs';

import { LastImportComponent } from './last-import.component';

@Component({
  selector: 'shared-import',
  template: `
    <div class="card rounded-0 h-100" *ngIf="hasCreatePermissions && translations$ | async as translations">
      <div class="card-header d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <h2 class="card-title me-2" data-cy="import-title">{{ translations.import }}</h2>
        </div>
        <div>
          <p-button
            *ngIf="hasReadPermissions && exportClicked.observers.length > 0"
            data-cy="export-button"
            [label]="translations.export"
            icon="fa fa-file-export"
            (click)="exportClicked.emit($event)"
          ></p-button>
        </div>
      </div>
      <div class="card-body">
        <ng-content select=".shared-import-description"></ng-content>
        <p-fileUpload
          data-cy="toolbar"
          [multiple]="multiple"
          [accept]="accept"
          [maxFileSize]="maxSizeBytes"
          (uploadHandler)="onImportClick($event.files)"
          [customUpload]="true"
          [chooseLabel]="translations.choose"
          [uploadLabel]="translations.import"
          [cancelLabel]="translations.clear"
        >
          <ng-template pTemplate="toolbar">
            <ng-container *ngTemplateOutlet="downloadTemplateRef"></ng-container>
            <p-button
              data-cy="download-template-button"
              *ngIf="downloadTemplateClicked.observers.length > 0 || downloadTemplateUrl"
              (click)="onDownloadTemplateClicked($event)"
              [label]="translations.downloadTemplate"
              icon="fa fa-download"
            ></p-button>
          </ng-template>
        </p-fileUpload>
        <div class="mt-3">
          <v-last-import [pageName]="pageName"></v-last-import>
        </div>
      </div>
    </div>

    <div *ngIf="!hasCreatePermissions && hasReadPermissions">
      <div class="card rounded-0 h-100" *ngIf="translations$ | async as translations">
        <div class="card-header d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <h2 class="card-title me-2">{{ translations.export }}</h2>
          </div>
        </div>
        <div class="card-body">
          <p-button
            data-cy="export-button"
            [label]="translations.export"
            icon="fa fa-file-export"
            (click)="exportClicked.emit($event)"
          ></p-button>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedImportComponent implements OnInit {
  translations$: Observable<Translations>;
  hasCreatePermissions: boolean = true;
  hasReadPermissions: boolean = true;
  @Input() createPermissions: [PermissionNames, Privilege, boolean?];
  @Input() readPermissions: [PermissionNames, Privilege, boolean?];
  @Input() multiple = false;
  @Input() accept = '.csv,text/csv';
  @Input() maxSizeBytes = 10000 * 1024;
  @Input() downloadTemplateUrl: string;
  @Input() pageName: string;
  @Input() showLastImport = false;
  @Input() downloadTemplateRef: TemplateRef<any>;
  @Output() importClicked: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() exportClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadTemplateClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(LastImportComponent) lastImportComponent: LastImportComponent;
  @ViewChild(FileUpload) fileUpload: FileUpload;

  constructor(
    private readonly _translationService: TranslationService,
    private readonly _permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.translations$ = this._translationService.getTranslatedObject();

    this.hasCreatePermissions = !this.createPermissions || this.verifyPermissions(this.createPermissions);
    this.hasReadPermissions = !this.readPermissions || this.verifyPermissions(this.readPermissions);
  }

  private verifyPermissions(value: [PermissionNames, Privilege, boolean?]) {
    const [permission, privilege, condition = true] = value;
    return this._permissionsService.hasPermission(permission, privilege);
  }

  onImportClick(files: FileList) {
    this.importClicked.emit(Array.from(files));
  }

  onDownloadTemplateClicked(event) {
    if (this.downloadTemplateUrl) {
      window.open(this.downloadTemplateUrl, '_blank');
    }

    this.downloadTemplateClicked.emit(event);
  }

  reset() {
    this.fileUpload.clear();
    this.lastImportComponent.refresh();
  }
}
