import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, UrlSegment } from '@angular/router';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { RedirectUserService } from '@volt/shared/services/redirect-user.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(
    private readonly redirectUserService: RedirectUserService,
    private readonly permissionsService: PermissionsService,
  ) {}

  private readonly permissionDataKey = 'requiredPermission';

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this._hasPermission(route.data[this.permissionDataKey], segments);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._hasPermission(route.data[this.permissionDataKey]);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    const permissionData = childRoute.data[this.permissionDataKey] || childRoute.parent?.data[this.permissionDataKey];
    return this._hasPermission(permissionData);
  }

  private _hasPermission(
    permissionData?: [PermissionNames, Privilege],
    segments: UrlSegment[] = [],
  ): Observable<boolean> {
    if (!this.permissionsService.permissionsLoaded) {
      this.permissionsService.loadPermissions();
    }
    if (permissionData) {
      const [permission, privilege] = permissionData;
      return this.permissionsService.hasPermission$(permission, privilege).pipe(
        tap(hasPermission => {
          if (!hasPermission) {
            // TODO: This is a hack since using Router cannot navigate to an already activated outlet
            //  (marketing-signage-request is on the same outlet as any other non-auth route).
            //  Using window.location.href to force reload also makes sense for unauthenticated user.
            if (permission === PermissionNames.SupplierSignageManage && segments.length > 1) {
              // If permission is suppliersignage.manage, then it is the Request Detail route.
              window.location.href = `${window.location.origin}/marketing-signage-request/${segments.pop().path}`;
            } else if (permission === PermissionNames.SupplierSignageRequest) {
              // If permission is suppliersignage.request, then it is the Request route.
              window.location.href = `${window.location.origin}/marketing-signage-request/`;
            } else {
              this.redirectUserService.redirectUnauthorizedUser();
            }
          }
        }),
      );
    }

    return of(true);
  }
}
