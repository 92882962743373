import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'v-loading-container',
  template: `
    <div class="an-loading-container" [ngStyle]="style">
      <ng-container *ngIf="working">
        <div class="an-loading-cover" [style.position]="isFixed ? 'fixed' : 'absolute'"></div>
        <v-loading-spinner
          [marginTop]="marginTop"
          [spinnerColor]="spinnerColor"
          [isOnTop]="isOnTop"
        ></v-loading-spinner>
      </ng-container>
      <ng-container *ngIf="!!error">
        <div class="an-loading-cover" [style.position]="isFixed ? 'fixed' : 'absolute'"></div>
        <div class="d-flex error-text-container w-100 h-100 justify-content-center align-items-center">
          <p class="m-0 p-0 text-danger">{{ error }}</p>
        </div>
      </ng-container>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./loading-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingContainerComponent {
  @Input() working: boolean;
  @Input() error = '';
  @Input() spinnerColor = '#ffc220';
  @Input() marginTop: string;
  @Input() isOnTop = false;
  @Input() isFixed = false;

  @Input() set minHeight(value: string) {
    if (value && value.length) {
      if (value.endsWith('px')) {
        this.style = { 'min-height': value };
      } else {
        console.warn('minHeight value must have "px" unit in ' + this.constructor.name);
      }
    }
  }

  public style = {};
}
