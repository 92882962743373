import { Injectable, EventEmitter } from '@angular/core';
import {
  InvitedAccountClient,
  InvitedAccountModel,
  AccountsClient,
  CertificationClient,
  SupplierClient,
  FieldGroupTypeViewModel,
  CourseModel,
} from '../../api.client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvitedAccountService {
  navchange: EventEmitter<number> = new EventEmitter();
  constructor(
    private _invitedAccountClient: InvitedAccountClient,
    private _supplierClient: SupplierClient,
    private _accountClient: AccountsClient,
    private _certificationClient: CertificationClient,
  ) {}

  public getStatusList(): Observable<any> {
    return this._invitedAccountClient.getStatusList();
  }

  public GetSupplierByNo(supplierNo: string): Observable<any> {
    return this._supplierClient.getSupplierByNumber(supplierNo);
  }

  public saveInvitedAccount(params: InvitedAccountModel): Observable<any> {
    return this._invitedAccountClient.saveInvitedAccount(params);
  }

  public getFieldTypeDropDownList(): Observable<FieldGroupTypeViewModel[]> {
    return this._accountClient.getFieldGroupTypeList();
  }

  public getCertificationDropDownList(): Observable<CourseModel[]> {
    return this._certificationClient.getRetailerCertificationList();
  }

  public getInvitedAccountById(id: string): Observable<InvitedAccountModel> {
    return this._invitedAccountClient.getInvitedAccountById(id);
  }

  public getInvitedAccountByAccountId(id: string): Observable<any> {
    return this._invitedAccountClient.getInvitedAccountByAccountId(id);
  }
  public approvedOrRejectInvitedAccount(params: InvitedAccountModel): Observable<any> {
    return this._invitedAccountClient.approvedOrRejectInvitedAccount(params);
  }

  public getStateDropDownList(): Observable<any> {
    return this._invitedAccountClient.getStateList();
  }

  public getCountryDropDownList(): Observable<any> {
    return this._invitedAccountClient.getCountryList();
  }

  public checkEmailAlreadyExist(email: string, id: number = 0): Observable<any> {
    return this._invitedAccountClient.checkInvitedEmailAlreadyExist(email, id);
  }

  public checkAccountNoAlreadyExist(accountNo: string, id: number = 0): Observable<any> {
    return this._invitedAccountClient.checkAccountNoAlreadyExist(accountNo, id);
  }

  public checkAccountNameAlreadyExist(accountName: string): Observable<any> {
    return this._invitedAccountClient.checkAccountNameAlreadyExist(accountName);
  }

  public checkAdminEmailAlreadyExist(email: string): Observable<any> {
    return this._invitedAccountClient.checkEmailExistInPerson(email);
  }

  public checkEmailDomainInPerson(email: string): Observable<any> {
    return this._invitedAccountClient.checkEmailDomainInPerson(email);
  }

  public saveInvitedAccountANote(params: InvitedAccountModel): Observable<any> {
    return this._invitedAccountClient.saveInvitedAccountANote(params);
  }

  public getCourseIdByCourseName(course: string): Observable<number> {
    return this._certificationClient.getCourseIdByCourseName(course);
  }

  public resendInvitationAccountEmail(id: number): Observable<any> {
    return this._invitedAccountClient.resendInvitationAccountEmail(id);
  }
  public getCertificationList(): Observable<any> {
    return this._certificationClient.getAccountRetailerCertificationList();
  }
  public getRetailerAccountList(): Observable<any> {
    return this._accountClient.getRetailerAccount();
  }

  public GetSelectedAccountDetailByAccountId(accountId: number): Observable<any> {
    return this._invitedAccountClient.getSelectedAccountDetailByAccountId(accountId);
  }

  public getPrimaryAdminDropDownList(id: string): Observable<any> {
    return this._accountClient.getProviderAdminList(id);
  }

  emitChangeStatus(data: number) {
    this.navchange.emit(data);
  }
  getChangeStatusEmitter() {
    return this.navchange;
  }
}
