import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { Contact } from '../../../../../models/volt-account';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'account-contact',
  templateUrl: './account-contact.component.html',
  styles: [
    `
        .inline {
            display: grid; 
            grid-template-columns: 1fr 1fr; 
            grid-gap: 1rem; 
            margin-bottom: 1rem;
        }
        
        .vertical {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountContactComponent extends BaseFilterForm<Contact> implements OnInit {
  @Input() translations: Translations;
  @Input() inline = true;

  _emailRequired = false;
  @Input() set emailRequired(r: boolean) {
    this._emailRequired = r;
    if(r) {
      this.controls.email.addValidators(Validators.required)
    } else {
      this.controls.email.removeValidators(Validators.required)
    }
  }

  _phoneRequired = false;
  @Input() set phoneRequired(r: boolean) {
    this._phoneRequired = r;
    if(r) {
      this.controls.phone.addValidators(Validators.required)
    } else {
      this.controls.phone.removeValidators(Validators.required)
    }
  }

  @Input() set contact(c: Contact) {
    this.controls.firstName.setValue(c?.firstName);
    this.controls.lastName.setValue(c?.lastName);
    this.controls.email.setValue(c?.email);
    this.controls.phone.setValue(c?.phone);
  }

  @Input() set disabled(d: boolean) {
    if(d) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  constructor(private readonly _fb: UntypedFormBuilder) {
    super();
    this.initForm();
  }

  ngOnInit(): void {
  }

  initForm(): void {
    this.form = this._fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      phone: [''],
    })
  }

  get invalid() {
    return this.form.invalid;
  }

  get value() {
    return this.form.value as Contact;
  }
}
