import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AllowedView } from '../../shared/allowed-view';

@Directive({
  selector: '[authorize]',
})
export class AuthorizedDirective {
  constructor(
    private _authService: AuthService,
    private viewRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  @Input()
  set authorize(reqViews: AllowedView[]) {
    if (!reqViews || !reqViews.length) {
      this.viewRef.createEmbeddedView(this.templateRef);
      return;
    }

    const views = this._authService.getUserAllowedViews();
    let allowed = false;
    for (const view of views) {
      if (reqViews.indexOf(view) > -1) {
        allowed = true;
        break;
      }
    }

    if (allowed) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef.clear();
    }
  }
}
