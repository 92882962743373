<div>
    <div>
        <div>
            <ngx-extended-pdf-viewer
                    src="https://walmart-prod-app-api.voltsystems.net/public/v1/assets/eulas//wmtvolt_eula.pdf"
                    useBrowserLocale="false"
                    height="800px"
                    width="100%"
                    [showHandToolButton]="false"
                    [handTool]="false"
            ></ngx-extended-pdf-viewer>
        </div>

        <div style="padding: 1rem; background-color: lightgray; border-radius: 5px; margin-top: 1rem; margin-bottom: 1rem">
            <p-checkbox [label]="translations['accounts.licenceAgreement']" [(ngModel)]="agreed" [binary]="true"></p-checkbox>
        </div>
    </div>
</div>
