import { Reducer } from 'redux';
import { IAction, KeyValue } from '../../store/actions/tile.actions';
import { DashboardState, dashboardInitState } from '../store/dashboard.state';
import { DashboardActions } from '../actions/dashboard.actions';

export const DashboardReducer: Reducer<DashboardState> = (
  state: DashboardState = dashboardInitState,
  action: IAction<KeyValue>,
): DashboardState => {
  const keyValue = <KeyValue>action.payload;
  switch (action.type) {
    case DashboardActions.SetWorking: {
      const copy = Object.assign(state);
      copy[keyValue.key].working = keyValue.value;
      copy[keyValue.key].error = false;
      return Object.assign({}, copy);
    }
    case DashboardActions.SetError: {
      const copy = Object.assign(state);
      copy[keyValue.key].error = keyValue.value;
      copy[keyValue.key].working = false;
      return Object.assign({}, copy);
    }
    case DashboardActions.SetCurrent: {
      const copy = Object.assign(state);
      copy[keyValue.key].currentValue = keyValue.value;
      return Object.assign({}, copy);
    }
    case DashboardActions.AddResult: {
      const copy = Object.assign(state);
      const result = keyValue.value;
      copy[keyValue.key].working = false;
      copy[keyValue.key].error = false;
      copy[keyValue.key].currentValue = result;
      copy[keyValue.key].storedValues.push(result);
      return Object.assign({}, copy);
    }
    case DashboardActions.UpdateResult: {
      const copy = Object.assign(state);
      const result = keyValue.value;
      const idx = copy[keyValue.key].storedValues.findIndex(r => r.id === result.id);
      copy[keyValue.key].storedValues[idx] = result;
      copy[keyValue.key].currentValue = result;
      return Object.assign({}, copy);
    }
    default:
      return state;
  }
};
