import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { Address, Contact, Shipping } from '../../../../../models/volt-account';
import { Translations } from '@volt/shared/services/translations.types';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { AccountAddressComponent } from '../account-address/account-address.component';
import { AccountContactComponent } from '../account-contact/account-contact.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { ConfirmModalComponent } from '@volt/shared/components/modal/confirm-modal/confirm-modal.component';

@Component({
  selector: 'account-shipping',
  templateUrl: './account-shipping.component.html',
  styles: [
    `
      .hidden {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountShippingComponent extends BaseFilterForm<Shipping> {
  @Input() translations: Translations;
  @Input() personalContact: Contact;

  _shipping: Shipping;
  @Input() set shipping(s: Shipping) {
    this._shipping = s;
    this.controls.shouldShipDirectToRep.setValue(s?.shouldShipDirectToRep || false);
  }

  @Input() set disabled(d: boolean) {
    if (d) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() companyAddress: Address;
  @ViewChild(AccountAddressComponent) address: AccountAddressComponent;
  @ViewChild(AccountContactComponent) contact: AccountContactComponent;

  constructor(private readonly _fb: UntypedFormBuilder, private readonly _dynamicDialogService: DynamicDialogService) {
    super();
    this.initForm();
  }

  confirmShipDirectToRep(selected: boolean) {
    if (!selected) return;

    this._dynamicDialogService.open(ConfirmModalComponent, {
      data: {
        message: this.translations['accounts.shipDirectToRep.Confirmation'],
        header: 'Confirmation',
        acceptLabel: this.translations.yes,
        rejectLabel: this.translations.no,
        reject: () => this.controls.shouldShipDirectToRep.setValue(false),
      },
    });
  }

  initForm(): void {
    this.form = this._fb.group({
      shouldShipDirectToRep: [false, Validators.required],
    });
  }

  get invalid() {
    return (
      this.form.invalid || this.address == null || this.address.invalid || this.contact == null || this.contact.invalid
    );
  }

  get value() {
    return {
      address: this.address.value,
      shouldShipDirectToRep: this.controls.shouldShipDirectToRep.value,
      contact: this.contact.value,
    } as Shipping;
  }
}
