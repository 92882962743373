<a *ngIf="column.link && column.link(data) as link; else noLink" [href]="link">
  <ng-container *ngTemplateOutlet="baseDisplay"></ng-container>
</a>
<ng-template #noLink>
  <ng-container *ngTemplateOutlet="baseDisplay"></ng-container>
</ng-template>

<ng-template #baseDisplay>
  <ng-container *ngIf="column.value; else noValue">
    {{ column.value(data) || 'N/A' }}
  </ng-container>

  <ng-template #noValue>
    <ng-container *ngIf="column.dateFormat; else noDate">
      {{ (data[column.field] | date: column.dateFormat:timezone) || 'N/A' }}
    </ng-container>

    <ng-template #noDate>
      <ng-container *ngIf="column.showAsHours; else noHours">
        {{ data[column.field] | asHours }}
      </ng-container>
    </ng-template>

    <ng-template #noHours>
      {{ data[column.field] || 'N/A' }}
    </ng-template>
  </ng-template>
</ng-template>
