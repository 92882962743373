<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="emp-num-label">
      Employee Number(s)
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="emp-num-tooltip"></i>
      </span>
    </label>
  </div>

  <div class="col-sm-8" [formGroup]="group">
    <p-autoComplete
      [(ngModel)]="selectedEmployee"
      (onClear)="onClearEvent($event)"
      styleClass="w-100"
      field="label"
      [suggestions]="filteredEmpList"
      (completeMethod)="getEmployeeListByFilter($event)"
      [formControlName]="'employeeNo'"
      [multiple]="true"
      data-cy="emp-num"
    ></p-autoComplete>
  </div>
</div>
