import { Routes } from '@angular/router';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';
import { AddServicedeploymentTaskLocationComponent } from './components/addservicedeployments-task-location/addservicedeployment-task-location.component';
import { AddServiceDeploymentsComponent } from './components/addservicedeployments/addservicedeployments.component';
import { AddServiceDeploymentTaskComponent } from './components/addservicedeploymenttask/components/addservicedeploymenttask.component';
import { ImportTaskLocationComponent } from './components/import-task-location/import-task-location.component';
import { ServicedeploymentTaskLocationComponent } from './components/locations/components/servicedeployment-task-location.component';
import { ServicedeploymentsComponent } from './components/servicedeployments/components/servicedeployments.component';
import { ServiceTaskResultsComponent } from './components/task-results/components/task-results.component';
import { ServicedeploymentTaskComponent } from './components/tasks/components/servicedeployment-task.component';

// tslint:disable-next-line:variable-name
export const ServiceDeploymentsTasksLocationsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'data',
        component: ServicedeploymentsComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Service Deployments',
          titleKey: translationKey('serviceDeployment'),
          requiredPermission: [PermissionNames.SchedulesManage, Privilege.Read],
          searchKey: translationKey('search.serviceDeployments'),
        },
      },
      {
        path: 'event-review',
        canActivate: [PermissionsGuard],
        data: {
          titleKey: translationKey('eventReview'),
          title: 'Event Review',
          requiredPermission: [PermissionNames.SchedulesEventReview, Privilege.Read],
          searchKey: translationKey('search.eventReview'),
        },
        loadChildren: () => import('./event-review/event-review.module').then(m => m.EventReviewModule),
      },
      {
        path: 'event-review/:eventId',
        canActivate: [PermissionsGuard],
        data: {
          titleKey: translationKey('eventDetail'),
          title: 'Event Detail',
          requiredPermission: [PermissionNames.SchedulesEventReview, Privilege.Read],
        },
        loadChildren: () =>
          import('./event-review-detail/event-review-detail.module').then(m => m.EventReviewDetailModule),
      },
      {
        path: 'retailtainment-task',
        data: {
          titleKey: translationKey('retailtainmentEventCreation'),
          title: 'Retailtainment Event Creation',
        },
        loadChildren: () =>
          import('./retailtainment-task-creation/retailtainment-task-creation.module').then(
            m => m.RetailtainmentTaskCreationModule,
          ),
      },
      {
        path: 'fromLocations/getServicedeployment/:serviceDeploymentId',
        component: ServicedeploymentsComponent,
        data: {
          title: 'Service Deployments',
        },
      },
      {
        path: 'addservicedeployments',
        component: AddServiceDeploymentsComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Add Service Deployments',
          requiredPermission: [PermissionNames.SchedulesManage, Privilege.Read],
        },
      },
      {
        path: 'addservicedeploymenttask/:serviceDeploymentRefId',
        component: AddServiceDeploymentTaskComponent,
        canActivate: [PermissionsGuard],
        data: {
          title: 'Add Service Deployment Task',
          requiredPermission: [PermissionNames.SchedulesManage, Privilege.Read],
        },
      },
      {
        path: 'addservicedeploymenttasklocation/:serviceDeploymentRefId/:taskRefId',
        component: AddServicedeploymentTaskLocationComponent,
        canActivate: [PermissionsGuard],

        data: {
          title: 'Add Service Deployment Task Location',
          requiredPermission: [PermissionNames.SchedulesManage, Privilege.Read],
        },
      },
      {
        path: ':refid',
        component: ServicedeploymentTaskComponent,
        data: {
          title: 'Service Deployments',
        },
      },
      {
        path: 'importtasklocation/:serviceDeploymentRefId/:taskRefId',
        canActivate: [PermissionsGuard],
        component: ImportTaskLocationComponent,
        data: {
          title: 'Import Task Location',
          requiredPermission: [PermissionNames.SchedulesManage, Privilege.Create],
        },
      },

      {
        path: 'action-log',
        loadChildren: () => import('../action-log/action-log.module').then(m => m.ActionLogsModule),
      },
      {
        path: ':refid/task/:taskId/tasklocation/:taskLocationId',
        component: ServiceTaskResultsComponent,
        data: {
          title: 'Service Deployments',
        },
      },

      {
        path: ':refid/task/:taskId',
        component: ServicedeploymentTaskLocationComponent,
        data: {
          title: 'Service Deployments',
        },
      },
    ],
  },
];
