import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';
import { UntypedFormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Country } from '../../models/models';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';

@Component({
  selector: 'account-country',
  templateUrl: './account-country.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCountryComponent implements OnInit, OnDestroy {
  @Input() translations: Translations;
  @Input() control: UntypedFormControl;

  options: SelectItem[];

  constructor() { }

  ngOnInit(): void {
    this.options = Object.keys(Country).map(c => {
      return {
        label: c,
        value: c
      } as SelectItem
    });

    this.control.setValue(this.options[0].value);

    this.control.valueChanges.pipe(
      OperatorUtils.takeUntilDisposed(this)
    ).subscribe(value => {
      if(value == null) {
        this.control.setValue(this.options[0].value);
      }
    })
  }

  ngOnDestroy() {
  }
}
