import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'account-submitted',
  templateUrl: './account-submitted.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSubmittedComponent implements OnInit {
  @Input() translations: Translations;

  constructor() { }

  ngOnInit(): void {
  }

}
