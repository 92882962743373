import { ServiceTaskInput } from '../../api.client';

export class AddServiceTask extends ServiceTaskInput {
  public accountId: number;
  public add: boolean;
  public edit: boolean;

  constructor() {
    super();
    this.activity = null;
    this.add = true;
    this.edit = false;
    this.referenceNumber = null;
    this.name = null;
    this.suppliers = null;
    this.departments = null;
    this.status = null;
    this.serviceModel = null;
    this.estimate = null;
    this.instructionUrls = null;
    this.note = null;
  }
}
