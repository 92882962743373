import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Translations } from '@volt/shared/services/translations.types';
import { RegistrationRetailerAccountViewModel } from '../../../../models/registration-retailer-account-view-model';

@Component({
  selector: 'registration-retailer-account-info',
  templateUrl: './registration-retailer-account-info.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationRetailerAccountInfoComponent extends BaseFilterForm<RegistrationRetailerAccountViewModel> implements OnInit {
  @Input() translations: Translations;

  _account: RegistrationRetailerAccountViewModel;
  @Input() set account(a: RegistrationRetailerAccountViewModel) {
    this._account = a;
    this.controls.estimatedNumberOfUsers.setValue(a?.estimatedNumberOfUsers);
    this.controls.supplierNumber.setValue(a?.supplierNumber);
    this.controls.usersHaveBadges.setValue(a?.usersHaveBadges);
    this.controls.usersAreDirectlyEmployed.setValue(a?.usersAreDirectlyEmployed);
    this.controls.usersAreMobileEnabled.setValue(a?.usersAreMobileEnabled);
    this.controls.usersHaveUniqueEmails.setValue(a?.usersHaveUniqueEmails);
  }

  constructor(private readonly _fb: UntypedFormBuilder) {
    super();
    this.initForm();
  }

  ngOnInit(): void {

  }

  initForm(): void {
    this.form = this._fb.group({
      estimatedNumberOfUsers: ['', Validators.required],
      supplierNumber: [''],
      usersHaveBadges: ['', Validators.required],
      usersAreDirectlyEmployed: ['', Validators.required],
      usersAreMobileEnabled: ['', Validators.required],
      usersHaveUniqueEmails: ['', Validators.required],
    });
  }

  get invalid() {
    return this.form.pending || this.form.invalid;
  }

  get value() {
    return {
      ...this.form.value,
    } as RegistrationRetailerAccountViewModel;
  }
}
