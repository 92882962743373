<div style="display: flex; flex-direction: column; gap: 1rem">
  <div>
    <label>{{ translations['accounts.shipDirectToRep.label'] }}</label>
    <select-button
      [control]="controls.shouldShipDirectToRep"
      [translations]="translations"
      (selectionChanged)="confirmShipDirectToRep($event)"
      [disabled]="form.disabled"
    ></select-button>
  </div>

  <div [class.hidden]="!usePersonalContactComponent.selected">
    <use-personal-contact
      #usePersonalContactComponent
      [type]="translations.shipping"
      [selected]="!form.disabled"
    ></use-personal-contact>
  </div>
  <div [class.hidden]="usePersonalContactComponent.selected">
    <account-contact
      [translations]="translations"
      [contact]="usePersonalContactComponent.selected ? personalContact : _shipping.contact"
      [disabled]="form.disabled"
    ></account-contact>
  </div>

  <div [class.hidden]="!useCompanyAddressComponent.selected">
    <use-company-address
      #useCompanyAddressComponent
      [type]="translations.shipping"
      [selected]="!form.disabled"
    ></use-company-address>
  </div>
  <div [class.hidden]="useCompanyAddressComponent.selected">
    <account-address
      [translations]="translations"
      [address]="useCompanyAddressComponent.selected ? companyAddress : _shipping?.address"
      [disabled]="form.disabled"
    ></account-address>
  </div>
</div>
