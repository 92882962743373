import { InjectFlags, InjectionToken, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { StateSubject } from './shared/extensions/state-subject';

export class RootInjector {
  private static rootInjector: Injector;
  private static injectorReady = new StateSubject(false);

  static setInjector(injector: Injector) {
    if (this.rootInjector) {
      return;
    }

    this.rootInjector = injector;
    this.injectorReady.setState(true);
  }

  static get injectorReady$(): Observable<boolean> {
    return this.injectorReady.asObservable();
  }

  static get<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T, flags?: InjectFlags): T {
    try {
      return this.rootInjector.get(token);
    } catch (e) {
      console.error(
        `Error getting ${token} from RootInjector. This is likely due to RootInjector is undefined. Please check RootInjector.rootInjector value.`,
      );
      return null;
    }
  }
}
