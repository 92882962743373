<p-panel [toggleable]="true">
    <ng-template pTemplate="header">
        <div>
            <strong><i class="fa fa-info"></i> {{translations.additionalDetails}}</strong>
        </div>
    </ng-template>

    <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem">
        <div class="w-100">
            <input-label [label]="translations['accounts.estimatedNumberOfUsers.label']"></input-label>
            <div class="p-inputgroup w-100">
                <span class="p-inputgroup-addon"><i class="fa fa-users"></i></span>
                <p-inputNumber [formControl]="controls.estimatedNumberOfUsers"></p-inputNumber>
            </div>
        </div>

        <supplier-number
                [translations]="translations"
                [control]="controls.supplierNumber"
                [required]="false"
        ></supplier-number>

        <div>
            <input-label [label]="translations['accounts.usersHaveUniqueEmails.label']"></input-label>
            <select-button [control]="controls.usersHaveUniqueEmails" [translations]="translations"></select-button>
        </div>

        <div>
            <input-label [label]="translations['accounts.usersHaveBadges.label']"></input-label>
            <select-button [control]="controls.usersHaveBadges" [translations]="translations"></select-button>
        </div>

        <div>
            <input-label [label]="translations['accounts.usersAreDirectlyEmployed.label']"></input-label>
            <select-button [control]="controls.usersAreDirectlyEmployed" [translations]="translations"></select-button>
        </div>

        <div>
            <input-label [label]="translations['accounts.usersMobileEnabled.label']"></input-label>
            <select-button [control]="controls.usersAreMobileEnabled" [translations]="translations"></select-button>
        </div>
    </div>
</p-panel>
