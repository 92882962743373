import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { ActionLogFilterActions } from '../actions/action-log.constants';

@Injectable()
export class ActionLogReduxService {
  constructor() {}

  public setFilter(filter: object) {
    this._setFilter(filter);
  }

  @dispatch()
  private _setFilter = (filter: object) => ({
    type: ActionLogFilterActions.SetFilter,
    payload: filter,
  });
}
