<div class="container-fluid">
  <header class="page-header d-flex align-items-center">
    <h2 data-cy="emergency-products-management-title" class="page-title">
      <span>Emergency Products Management</span>
    </h2>
  </header>

  <p-toast [baseZIndex]="100"></p-toast>

  <shared-import
    (importClicked)="uploadFile($event)"
    (downloadTemplateClicked)="downloadTemplate()"
    [maxSizeBytes]="5000000"
    [accept]="'.text/csv,.csv'"
    [pageName]="pageName"
    [showLastImport]="true"
  >
    <v-info-box
      data-cy="shared-import-description-product-management"
      class="shared-import-description"
      [infoTitle]="'Your spreadsheet is the primary source of data.'"
      [infoMessage]="
        'When you upload a new sheet, data in the system is replaced by your sheet. If you delete data from your sheet, then upload it, the data will also be deleted from the system.'
      "
    ></v-info-box>
  </shared-import>

  <div class="d-flex flex-row row mt-3">
    <div class="col-12">
      <div class="card rounded-0">
        <div class="card-header">
          <h2 data-cy="products-title" class="card-title">Products</h2>
        </div>
        <div class="card-body">
          <products-list
            [products]="products"
            (onSearch)="filterProducts($event)"
            (onClear)="clearSearch()"
          ></products-list>
        </div>
      </div>
    </div>
  </div>
</div>
