
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 2rem">
    <p-progressSpinner></p-progressSpinner>

    <div *ngIf="randomFact$ | async as randomFact">
        <small>Did you know?</small>
        <br>
        <strong>{{randomFact}}</strong>
    </div>
</div>


