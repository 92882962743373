export interface PayloadMetaAction<TType extends TypeConstant, TPayload, TMeta> {
  type: TType;
  payload: TPayload;
  meta: TMeta;
}

export type TypeConstant = string;

export interface Action<TType extends TypeConstant = TypeConstant> {
  type: TType;
}

export type ActionCreator<TType extends TypeConstant> = (...args: any[]) => Action<TType>;

export type ActionType<TActionCreatorOrMap extends any> = TActionCreatorOrMap extends ActionCreator<TypeConstant>
  ? ReturnType<TActionCreatorOrMap>
  : TActionCreatorOrMap extends { [key: string]: ActionCreator<TypeConstant> }
  ? {
      [K in keyof TActionCreatorOrMap]: ActionType<TActionCreatorOrMap[K]>;
    }[keyof TActionCreatorOrMap]
  : TActionCreatorOrMap extends infer R
  ? never
  : never;

export function checkIsEmpty(arg: unknown, argPosition: number = 1) {
  return arg == null;
}

export function throwIsEmpty(argPosition: number = 1): never {
  throw new Error(`Argument ${argPosition} is empty.`);
}

export function checkValidActionType(arg: unknown): arg is string | symbol {
  return typeof arg === 'string' || typeof arg === 'symbol';
}

export function checkInvalidActionType(arg: unknown): arg is string | symbol {
  return !checkValidActionType(arg);
}

export function throwInvalidActionCreator(argPosition: number = 1): never {
  throw new Error(
    `Argument ${argPosition} is invalid, it should be an action-creator instance from "typesafe-actions"`,
  );
}

export function action<T extends TypeConstant, P = undefined, M = undefined, E = undefined>(
  type: T,
  payload?: P,
  meta?: M,
  error?: E,
) {
  if (checkIsEmpty(type)) {
    throwIsEmpty(1);
  }

  if (checkInvalidActionType(type)) {
    throwInvalidActionCreator(1);
  }

  return { type, payload, meta, error } as any;
}

export function createAction<T extends TypeConstant, AC extends ActionCreator<T> = () => { type: T }>(
  type: T,
  createHandler?: (
    actionCallback: <P = undefined, M = undefined>(payload?: P, meta?: M) => PayloadMetaAction<T, P, M>,
  ) => AC,
): AC {
  // validation is already done in action function

  const actionCreator: AC =
    createHandler == null
      ? ((() => action(type)) as AC)
      : createHandler(action.bind(null, type) as Parameters<typeof createHandler>[0]);

  return Object.assign(actionCreator, {
    getType: () => type,
    // redux-actions compatibility
    toString: () => type,
  });
}
