import { dispatch } from '@angular-redux/store';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from "@angular/router";
import { FeatureService } from './shared/directives/features/feature.service';
import * as Highcharts from 'highcharts';
import { PermissionsService } from './shared/services/permissions.service';
import { combineLatest, forkJoin } from 'rxjs';
import { filter, shareReplay, take, tap } from 'rxjs/operators';

import { RETAILER_CONFIG, RetailerConfigType } from '../retailer-config';
import { ActivitiesService } from './activities/services/activities.service';
import { ProductClient, UserInformation } from './api.client';
import { AuthService } from './auth';
import { DepartmentService } from './department/services/department.service';
import { GlobalFilterService } from './global-filter/global-filter.service';
import { LayoutService } from './layouts/admin/services/layout.service';
import { pieChartColors } from './shared/constants/charts.constant';
import { EVENTS } from './shared/constants/events.constants';
import { AllowedLocationsService } from './shared/services/allowed-locations.service';
import { MonitoringService } from './shared/services/monitoring.service';
import { PageTitleService } from './shared/services/page-title.service';
import { TranslationService } from './shared/services/translation.service';
import { OperatorUtils } from './shared/utils/operator.utils';
import { settingsActions } from './store/actions/settings.actions';
import { TagService } from './shared/services/tag.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public themeClass: string;

  @dispatch()
  private _setProductsList = settingsActions.setProductLists;

  constructor(
    private _layoutService: LayoutService,
    private _activitiesService: ActivitiesService,
    private _deptService: DepartmentService,
    private _monitoringService: MonitoringService,
    private _productClient: ProductClient,
    private _translationService: TranslationService,
    private _pageTitleService: PageTitleService,
    private _allowedLocationsService: AllowedLocationsService,
    private _permissionsService: PermissionsService,
    private _tagService: TagService,
    private _router: Router,
    private _featureService: FeatureService,
    private readonly _authService: AuthService,
    private _globalFilterService: GlobalFilterService,
    @Inject(RETAILER_CONFIG) public readonly retailerConfig: RetailerConfigType,
  ) {
    this.themeClass = 'walmart-light';
  }

  ngOnInit(): void {
    this._translationService.setupTranslation();
    this.setupRouteTitleListener();

    this._router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd)
    ).subscribe((ev: NavigationEnd) => {
      gtag('config', 'G-JRD2MPTED9', {'page-path': ev.urlAfterRedirects});
    });

    Highcharts.setOptions({
      colors: pieChartColors,
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {
            enabled: true,
            distance: -50,
            format: '{point.percentage:.0f}% ({point.y})',
          },
        },
      },
    });

    this._translationService
      .selectTranslate('noDataToDisplay')
      .pipe(OperatorUtils.takeUntilDisposed(this))
      .subscribe(noData => {
        Highcharts.setOptions({
          lang: { noData },
        });
      });

    this._layoutService.onSetTheme.subscribe(theme => {
      switch (theme) {
        case 'light': {
          this.themeClass = 'walmart-light';
          break;
        }
        case 'dark': {
          this.themeClass = 'walmart-dark';
          break;
        }
      }
    });

    this._authService.canActivateProtectedRoutes$
      .pipe(
        filter((yes: boolean) => yes),
        take(1),
      )
      .pipe(
        tap(val => {
          this.setMonitoringUserId();
          this._allowedLocationsService.loadLocations();
          if (!this._permissionsService.permissionsLoaded) this._permissionsService.loadPermissions();
          if (!this._tagService.userTagsLoaded) this._tagService.getUserTagList();
          if (!this._featureService.featuresLoaded) this._featureService.loadFeatures();
          this._loadSettings();
          this._globalFilterService.loadGlobalFilter();
        }),
      )
      .subscribe();
  }

  private setupRouteTitleListener() {
    combineLatest([
      this._translationService.activeLang$,
      this._router.events.pipe(filter(ev => ev instanceof ResolveEnd)),
    ])
      .pipe(OperatorUtils.takeUntilDisposed(this))
      .subscribe(([lang, ev]: [string, ResolveEnd]) => {
        this._pageTitleService.setPageTitleByRouteSnapshot(ev.state.root, lang);
      });
  }

  private setMonitoringUserId() {
    this._authService.authUser$.pipe(filter(user => !!user)).subscribe((user: UserInformation) => {
      this._monitoringService.setAuthenticatedUserId(user.id, user.accountId.toString());
      this._monitoringService.trackEvent(EVENTS.LOGGED_IN, {
        Dashboard: 'New',
      });
    });
  }

  private _loadSettings(): void {
    forkJoin([this._productClient.getProductList().pipe(shareReplay(1))]).subscribe(([products]) => {
      this._setProductsList(products);
    });
  }

  ngOnDestroy(): void {}
}
