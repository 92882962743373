<v-loading-container>
  <p-table
    [columns]="cols"
    [value]="appLinkdata"
    [rows]="20"
    [totalRecords]="totalRecords"
    [responsive]="true"
    [lazy]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div *ngIf="col.field != 'link'">
            {{ rowData[col.field] }}
          </div>

          <div *ngIf="col.field === 'link'" style="overflow:scroll;overflow-y:hidden;overflow-x: auto;">
            {{ rowData[col.field] }}
          </div>

          <div *ngIf="col.field == 'Action'">
            <p-button (onClick)="editAccountAppLink(rowData['id'])" label="Edit"></p-button>
            /
            <p-button (onClick)="deleteAccountAppLinkById(rowData['id'])" label="Delete"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</v-loading-container>
