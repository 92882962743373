<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="task-activity-label">
      Activity
      <span class="required" aria-required="true">*</span>
      <span>
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true" data-cy="task-activity-tooltip"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-dropdown
      [filter]="true"
      [style]="{ width: '100%' }"
      [options]="activeModel"
      [(ngModel)]="selectedActivity"
      #p
      (select)="test(p)"
      name="activeType"
      [formControlName]="'activeType'"
      [placeholder]="'Select Activity'"
      data-cy="task-activity"
    ></p-dropdown>

    <div *ngIf="submitted">
      <span
        *ngIf="submitted && activeTypeControl.hasError('required')"
        class="text-danger"
        data-cy="task-activity-error"
      >
        Activity is required
      </span>
    </div>
  </div>
</div>
