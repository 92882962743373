<ng-container>
<div class="modal-body missing-modal">

  <modal-header
    [title]="'Required fields identified below are missing:'"
    (onClose)="onClose()">
  </modal-header>
    <ul>
      <li *ngFor="let item of requiredfield">{{ item }}</li>
    </ul>
  </div>
</ng-container>
