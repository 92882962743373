import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ColSpanModule } from '../../directives/col-span/col-span.module';
import { DisableControlModule } from '../../directives/disable-control/disable-control.module';
import { BaseControlComponent } from './base-control.component';
import { ControlErrorComponent } from './control-error.component';

@NgModule({
  declarations: [BaseControlComponent, ControlErrorComponent],
  imports: [CommonModule, ReactiveFormsModule, ColSpanModule, DisableControlModule],
  exports: [BaseControlComponent, ControlErrorComponent, ReactiveFormsModule, ColSpanModule, DisableControlModule],
})
export class BaseControlModule {}
