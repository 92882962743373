<div class="modal-header mb-2">
  <div class="modal-title">
    <h3>{{ title }}</h3>
  </div>
  <button
    *ngIf="closeable"
    type="button"
    class="btn-close ms-5"
    [attr.aria-label]="'close'"
    (click)="onClickButtonClose()"
  ></button>
</div>
