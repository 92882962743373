import { Injectable } from '@angular/core';
import {
  AccountHierarchyClient,
  AccountResponse,
  AddRequest,
  ERelationship,
  LimitedResultOfGetResponse,
} from '@volt/api';
import { SharedTableState } from '@volt/shared/components/table/models/shared-table-state';
import { StateSubject } from '@volt/shared/extensions/state-subject';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountHierarchyService {
  private $loading = new StateSubject<boolean>(false);
  public loading$ = this.$loading.asObservable();

  constructor(private readonly _accountHierarchyClient: AccountHierarchyClient) {}

  getRelationsForAccountDetailsPage(
    sharedTableState: SharedTableState,
    accountId: number,
  ): Observable<LimitedResultOfGetResponse> {
    return this._accountHierarchyClient.getRelationsForAccountDetailsPage(
      sharedTableState.sortBy,
      accountId,
      sharedTableState.limit,
      sharedTableState.offset,
      sharedTableState.sortAscending,
    );
  }

  add(sourceAccountId: number, targetAccountId: number, relationship: ERelationship) {
    const addRequest = new AddRequest({
      sourceAccountId: sourceAccountId,
      targetAccountId: targetAccountId,
      relationshipType: relationship,
    });
    return this._accountHierarchyClient.add(addRequest);
  }

  delete(id: number) {
    return this._accountHierarchyClient.delete(id);
  }

  setLoader(loading: boolean) {
    this.$loading.next(loading);
  }

  getAllAccountsExceptServiceChannel(): Observable<AccountResponse[]> {
    return this._accountHierarchyClient.getAllAccountsExceptServiceChannel();
  }

  getAllRelationships(): Observable<string[]> {
    return this._accountHierarchyClient.getAllRelationships();
  }
}
