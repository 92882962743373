<div>
  <button (click)="closeSettings()" type="button" class="close closer text-end" aria-label="Close"></button>
  <p class="title">Site Settings</p>
  <hr />

  <div class="row">
    <div class="col-8">Dark Theme</div>
    <div class="col-4 text-end">
      <button
        type="button"
        class="btn btn-toggle-primary btn-toggle btn-toggle-no-label"
        data-toggle="button"
        aria-pressed="false"
        autocomplete="off"
        (click)="toggleTheme()"
      >
        <div class="handle"></div>
      </button>
    </div>
  </div>
</div>
