<ng-container *ngIf="translatedData">
  <label data-cy="activity-label" for="activity">{{ translatedData?.activity }}</label>
  <p-multiSelect
    [formControl]="control"
    [maxSelectedLabels]="displayLength"
    [options]="items"
    [selectionLimit]="maxNumberOfSelections"
    [showToggleAll]="false"
    [placeholder]="translatedData.allActivities"
    id="activity"
    styleClass="w-100"
  >
    <ng-template let-selectedItems pTemplate="selectedItems">
      <ng-container *ngIf="!selectedItems">
        <span *ngIf="items.length">{{ translatedData?.all }} {{ translatedData?.activities }}</span>
        <span *ngIf="!items.length">{{ translatedData?.no }} {{ translatedData?.activity }}</span>
      </ng-container>
      <ng-container *ngIf="selectedItems">
        <ng-container *ngIf="selectedItems.length == 0">
          {{ translatedData?.all }} {{ translatedData?.activities }}
        </ng-container>
        <ng-container *ngIf="selectedItems.length > displayLength && selectedItems.length < items.length">
          {{ selectedItems.length }} {{ translatedData?.itemsSelected }}
        </ng-container>
        <ng-container *ngIf="selectedItems.length == items.length">
          {{ translatedData?.all }} {{ translatedData?.activities }}
        </ng-container>
        <ng-container *ngIf="selectedItems.length <= displayLength">
          <span *ngFor="let activity of selectedItems; last as isLast">
            {{ activity }}
            <span *ngIf="!isLast">,</span>
          </span>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="footer">
      <multi-select-clear-button
        [visible]="control?.value?.length"
        (clear)="reset();"
      ></multi-select-clear-button>
    </ng-template>
  </p-multiSelect>
</ng-container>
