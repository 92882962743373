<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" for="serviceDepRefNUmber" data-cy="ser-dep-ref-num-label">
      Service Deployment Reference Number
      <span class="required" aria-required="true">*</span>
      <span data-cy="ser-dep-ref-num-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <div *ngIf="!isEdit">
      <input
        class="form-control rounded-0"
        id="serviceDepRefNUmber"
        [formControlName]="'serviceDepRefNumber'"
        (blur)="checkServiceRefNumberAlreadyExist(ref.value)"
        required
        #ref
        data-cy="ser-dep-ref-num"
      />
    </div>
    <div *ngIf="isEdit">
      <input
        class="form-control rounded-0"
        id="serviceDepRefNUmber"
        [formControlName]="'serviceDepRefNumber'"
        readonly
        #ref
        data-cy="ser-dep-ref-num"
      />
    </div>

    <div *ngIf="submitted" data-cy="ser-dep-ref-num-error">
      <span *ngIf="serviceDepRefNumberControl.hasError('required')" class="text-danger">
        A valid Reference Number is required.
      </span>
      <span *ngIf="serviceDepRefNumberControl.hasError('pattern')" class="text-danger">
        Invalid Reference Number. Allowed characters: A-Z a-z 0-9 . - _
      </span>
    </div>

    <div
      data-cy="ser-dep-ref-num-error"
      class="text-danger"
      *ngIf="
        serviceDepRefNumberExist &&
        !serviceDepRefNumberControl.hasError('required') &&
        !serviceDepRefNumberControl.hasError('pattern')
      "
    >
      Service Deployment Reference Number already exists for the selected account
    </div>
  </div>
</div>
