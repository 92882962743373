import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { InvitedAccountModel } from '../../../../api.client';
import { InvitedAccountService } from '../../../services/invitedsupplier.service';
import { AccountViewService } from '../../../services/accountview.service';
import isNil from 'lodash-es/isNil';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'invited-anote',
  templateUrl: './invited-anote-modal.component.html',
  styleUrls: ['./invited-anote-modal.component.scss'],
})
export class InvitedAnoteModalComponent extends DynamicDialogBaseComponent implements OnInit {
  public invitedAccountId;
  public retailLink;
  public companyNote: string;

  public invitedGroup: UntypedFormGroup;
  public invitedModel = new InvitedAccountModel();
  public loading = false;

  constructor(
    private _invitedAccountService: InvitedAccountService,
    private _accountViewService: AccountViewService,
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _dialogReference: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig
  ) {
    super(_dialogReference, _dialogConfig)
      this.invitedAccountId = this._dialogConfig.data.invitedAccountId,
      this.retailLink = this._dialogConfig.data.retailLink,
      this.companyNote = this._dialogConfig.data.companyNote 
  }

  ngOnInit() {
    this.initForm();

    if (!isNil(this.companyNote)) {
      this.invitedModel.invitedNote = this.companyNote;
    }

    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this._dialogReference.close(false));
  }

  public submit() {
    this.loading = true;
    const invitedModel = new InvitedAccountModel();

    invitedModel.invitedAccountId = this.invitedAccountId;
    invitedModel.retailLink = this.retailLink;
    invitedModel.aNote = this.invitedGroup.get('aNote').value;

    this._invitedAccountService.saveInvitedAccountANote(invitedModel).subscribe(
      response => {
        if (response) {
          this.loading = false;
          this.invitedGroup.reset();
          this._accountViewService.getInvitedAccountsANote(invitedModel.aNote);
          this._dialogReference.close(false);
        }
      },
      err => {
        console.error(err);
      },
    );
  }

  public formReset() {
    this.invitedGroup.reset();
    this._dialogReference.close(false);
  }

  public get aNoteControl(): UntypedFormControl {
    if (!this.invitedGroup) {
      return;
    }

    return <UntypedFormControl>this.invitedGroup.get('aNote');
  }

  private initForm() {
    this.invitedGroup = this._fb.group({
      aNote: this._fb.control('', Validators.required),
    });
  }
}
