import { Reducer } from 'redux';
import { SettingsActions } from '../actions/settings.actions';
import { SettingsState } from '../states/settings.state';

export const SettingsReducer: Reducer<SettingsState, SettingsActions> = (state = new SettingsState(), action) => {
  switch (action.type) {
    case 'SET_PRODUCT_LISTS':
      return { ...state, productsList: action.payload };
    default:
      return state;
  }
};
