import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AccountViewService } from '../../../services/accountview.service';
import { Tag, Tag3 } from '@volt/api';
import { InvitedAccountService } from '../../../services/invitedsupplier.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, iif, Observable } from 'rxjs';
import { Translations } from '@volt/shared/services/translations.types';
import { TableCol } from '@volt/shared/types';
import { map, pluck, switchMap } from 'rxjs/operators';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';

@Component({
  selector: 'account-tags',
  templateUrl: './account-tags.component.html',
})
export class AccountTagsComponent implements OnInit, OnChanges, OnDestroy {
  public data$: Observable<Tag[]>;
  cols: TableCol[];
  public selectedTag: string[] = [];

  @Output() tagData: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() public translatedData: Translations;
  @Input() public useAccount = true;

  constructor(
    private _accountViewService: AccountViewService,
    private _InvitedAccountService: InvitedAccountService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const paramId$ = this.activatedRoute.params.pipe(pluck('id'));
    this.data$ = this._accountViewService.getTagList();

    combineLatest([this.data$, paramId$])
      .pipe(
        switchMap(([tags, id]) =>
          iif(
            () => this.useAccount,
            this.getAssignedTagsFromAccount(tags, id),
            this.getAssignedTagsFromInvitedAccount(id),
          ),
        ),
        OperatorUtils.takeUntilDisposed(this),
      )
      .subscribe(tags => (this.selectedTag = tags || []));
  }

  ngOnDestroy() {}

  getAssignedTagsFromAccount(tagList: Tag3[], id: string) {
    return this._InvitedAccountService.getInvitedAccountByAccountId(id).pipe(
      switchMap(account => this._InvitedAccountService.GetSelectedAccountDetailByAccountId(account.accountId)),
      map(accountDetail => {
        return accountDetail.accountTags
          .filter(tag => tagList.some(t => t.id === tag.tagId))
          .map(tag => tag.tagId.toString());
      }),
    );
  }

  getAssignedTagsFromInvitedAccount(id: string) {
    return this._InvitedAccountService
      .getInvitedAccountById(id)
      .pipe(map(invitedAccount => invitedAccount.selectedAccountTags?.split(',')));
  }

  getTagAssignedId(event, tagId: number) {
    this.tagData.emit(this.selectedTag);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.translatedData) {
      this.cols = [
        { field: 'checkBoxes', header: this.translatedData.tagAssigned },
        { field: 'name', header: this.translatedData.tagName },
      ];
    }
  }
}
