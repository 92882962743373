import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'v-loading-spinner',
  template: `
    <div class="an-spinner" [ngStyle]="style" [ngClass]="{ 'on-top': isOnTop }">
      <div class="text" *ngIf="spinnerText">{{ spinnerText }}</div>
      <div class="bar rect1" [ngStyle]="spinnerStyle"></div>
      <div class="bar rect2" [ngStyle]="spinnerStyle"></div>
      <div class="bar rect3" [ngStyle]="spinnerStyle"></div>
      <div class="bar rect4" [ngStyle]="spinnerStyle"></div>
      <div class="bar rect5" [ngStyle]="spinnerStyle"></div>
    </div>
  `,
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
  @Input() set spinnerColor(color: string) {
    color = color || '#ffc220';
    this.spinnerStyle = {
      'background-color': color,
    };
  }

  @Input() set marginTop(value: string) {
    if (value && value.length) {
      if (value.endsWith('px')) {
        this.style = { 'margin-top': value };
      } else {
        console.warn('marginTop value must have "px" unit in ' + this.constructor.name);
      }
    }
  }

  @Input() spinnerText = '';
  @Input() isOnTop = false;

  public spinnerStyle = {};
  public style = {};
}
