export class ProductListParams {
  public sort: string;
  public sortColumn: string;
  public ascending: boolean;
  public page: number;
  public pageSize: number;
  public filterText: string;

  constructor() {
    this.sort = 'updatedDate';
    this.sortColumn = 'updatedDate';
    this.ascending = false;
    this.page = 1;
    this.pageSize = 25;
    this.filterText = '';
  }
}
