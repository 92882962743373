import { select } from '@angular-redux/store';
import * as moment from 'moment';
import { from as observableFrom, Observable, Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DynamicDialogService } from '../../../../shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { serviceDeploymentsReportSelector } from '../../../reducers/service-deployments-report.reducer';
import { FormcreationService } from '../../advanced-search/services/formcreation.service';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedSearchComponent } from '../../advanced-search/components/advanced-search.component';
import { DropDownModel } from '../../../../api.client';
import { AllowedView } from '../../../../shared/allowed-view';
import { DataPassingService } from '../../../services/data-passing.service';

@Component({
  selector: 'search-filter',
  templateUrl: 'search-filter.component.html',
  styles: [
    `
      :host::ng-deep .p-toolbar {
        display: block;
      }
      :host ::ng-deep p-dropdown .p-dropdown-label-container {
        overflow: hidden;
      }

      :host ::ng-deep p-dropdown .p-dropdown-label-container .p-dropdown-label {
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class SearchFilterComponent implements OnInit, OnDestroy {
  public allowedView = AllowedView;
  public cols;
  public endDate: Date;
  public startDate: Date;
  public colsHide = [
    'serviceDeploymentTaskCount',
    'serviceStart',
    'serviceEnd',
    'createdDate',
    'lastUpdated',
    'name',
    'serviceDeploymentTaskLocationCount',
    'estimate',
    'instructionUrls',
    'start',
    'end',
    'completionDate',
    'number',
    'updatedDate',
    'aname',
  ];

  public advancedSearchFilters = [];
  @Input() public unFilteredList;
  @Input() public showDateFilter;
  @Input() public searchBy;
  @Output() private searchBySubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() public searchEndDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() public searchStartDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Output() private export: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() private clearClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() private pspSearchSubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Input() public pspAccountList: DropDownModel[];
  public selectedPSP;
  public advancedSearchSelectedParams;
  public keys;
  public filterText: string;
  public form: UntypedFormGroup;
  @Input() public minDate;
  public showEndDate = true;

  @select(serviceDeploymentsReportSelector)
  private serviceDeploymentsReport$: Observable<ServiceDeploymentReportParams>;

  private filterTextSub: Subject<string> = new Subject<string>();
  private destroySub: Subject<null> = new Subject<null>();

  constructor(
    private _formCreationService: FormcreationService,
    private _dataPassingService: DataPassingService,
    private readonly dynamicDialogService: DynamicDialogService,
  ) {}

  ngOnInit(): void {
    this.filterTextSub
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.destroySub))
      .subscribe(query => {
        this.searchByInputText(query);
      });

    this._dataPassingService.serviceDeploymentEndDate.subscribe((data: boolean) => {
      this.endDate = null;
      this.showEndDate = data;
    });

    this.serviceDeploymentsReport$.pipe(takeUntil(this.destroySub)).subscribe(filters => {
      this.selectedPSP = filters.servicedeploymentSearchParameters.accountName;
      this.startDate = filters.servicedeploymentSearchParameters.startDate;
      this.endDate = filters.servicedeploymentSearchParameters.endDate;
      this.filterText = filters.servicedeploymentSearchParameters.accountName;
      this.showEndDate = !!this.startDate;
    });
  }

  ngOnDestroy(): void {
    this.destroySub.next();
    this.destroySub.complete();
  }

  public FilterByPSP() {
    if (this.selectedPSP === '' && this.selectedPSP == null && this.selectedPSP == undefined) {
      this.selectedPSP = null;
    }
    this.pspSearchSubmitted.emit(this.selectedPSP);
  }

  createFilteredList() {
    if (this.unFilteredList.length !== 0) {
      this.advancedSearchFilters = [];
      this.unFilteredList.forEach(i => {
        if (this.colsHide.indexOf(i.field) === -1) {
          this.advancedSearchFilters.push({ key: i.field, value: '', header: i.header });
        }
      });
    } else {
      this.advancedSearchFilters = [];
    }

    this.form = this._formCreationService.toFormGroup(this.advancedSearchFilters);

    this.dynamicDialogService.open(AdvancedSearchComponent, {
      data: { form: this.form, advancedSearchFilters: this.advancedSearchFilters },
      closeOnEscape: true,
    });
  }

  public searchByInputText(name: string) {
    this.searchBySubmitted.emit(name);
  }

  public clearSearch(input: HTMLInputElement) {
    this.showEndDate = false;
    input.value = null;
    this.selectedPSP = 'All';
    this.advancedSearchSelectedParams = null;
    this.startDate = null;
    this.endDate = null;
    this.clearClicked.emit(true);
  }

  public onDownload() {
    this.export.emit(true);
  }

  public onSelectStartDate(date: Date) {
    this.startDate = date;
    this.searchStartDate.emit(this.startDate);
  }

  public onSelectEndDate(date: Date) {
    this.endDate = date;
    this.searchEndDate.emit(this.endDate);
  }

  onFilterTextChanged(value: string) {
    this.filterTextSub.next(value);
  }
}
