import { Routes } from '@angular/router';
import { PermissionsGuard } from '@volt/shared/guards/permissions.guard';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { Privilege } from '@volt/shared/services/permissions.service';
import { translationKey } from '@volt/shared/utils/common.utils';

export const marketingSignageRoutes: Routes = [
  {
    path: 'request/:id',
    canLoad: [PermissionsGuard],
    loadChildren: () => import('./marketing-signage-request-details/msrd/msrd.module').then(m => m.MsrdModule),
    data: {
      requiredPermission: [PermissionNames.SupplierSignageManage, Privilege.Read],
      titleKey: translationKey('supplierSignageRequestDetails'),
    },
  },
  {
    path: 'request',
    canLoad: [PermissionsGuard],
    loadChildren: () => import('./marketing-signage-request/msr/msr.module').then(m => m.MsrModule),
    data: {
      requiredPermission: [PermissionNames.SupplierSignageRequest, Privilege.Create],
      titleKey: translationKey('supplierSignageRequest'),
      searchKey: translationKey('search.supplierSignageRequest'),
    },
  },
  {
    path: 'dashboard',
    canLoad: [PermissionsGuard],
    data: {
      requiredPermission: [PermissionNames.SupplierSignageDashboard, Privilege.Read],
      titleKey: translationKey('marketingSignageDashboard'),
      searchKey: translationKey('search.supplierSignageDashboard'),
    },
    loadChildren: () =>
      import('./marketing-signage-dashboard/marketing-signage-dashboard.module').then(
        m => m.MarketingSignageDashboardModule,
      ),
  },
];
