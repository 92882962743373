import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { RETAILER_CONFIG, RetailerConfigType } from '../../../../retailer-config';
import { TranslationService } from '../../../shared/services/translation.service';
import { Translations } from '../../../shared/services/translations.types';

@Injectable()
export class AdminLayoutTranslationService {
  private _cachedTranslatedData$: Observable<Translations>;

  constructor(private readonly _translationService: TranslationService,
    @Inject(RETAILER_CONFIG) private readonly _retailerConfig: RetailerConfigType,) {}

  public getTranslatedData$(): Observable<Translations> {
    if (!this._cachedTranslatedData$) {
      this._cachedTranslatedData$ = this._translationService
        .getTranslatedObject({
          key: 'pleaseConfirmSignOut',
          params: { retailerSystem: this._retailerConfig.retailerSystemAppName },
        })
        .pipe(shareReplay());
    }
    return this._cachedTranslatedData$;
  }
}
