import { ProductsModel } from '../../api.client';
import { createFeatureSelector, createSelector } from '../../shared/redux-utils';

export class SettingsState {
  productsList: ProductsModel[];

  constructor() {
    this.productsList = [];
  }
}

export const settingsStateSelector = createFeatureSelector('settingsState');
export const productListSelector = createSelector(settingsStateSelector, state => state.productsList);
