import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { TranslationService } from '@volt/shared/services/translation.service';
import { Observable } from 'rxjs';
import { Translations } from '@volt/shared/services/translations.types';

@Component({
  selector: 'multi-select-clear-button',
  templateUrl: './multi-select-clear-button.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectClearButtonComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() clear = new EventEmitter();

  translations$: Observable<Translations>;

  constructor(private readonly _translationService: TranslationService) { }

  ngOnInit(): void {
    this.translations$ = this._translationService.getTranslatedObject();
  }

}
