import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AccountViewService } from '../../../services/accountview.service';
import { AppModel } from '@volt/api';

@Component({
  selector: 'app-list',
  templateUrl: './app-list.component.html',
})
export class AppListComponent implements OnInit {
  public data: AppModel[] = [];
  cols: any[];
  public totalRecords: number;
  selectedApp: string[] = [];
  @Output() appData: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  public appSelected: string;

  constructor(private _accountViewService: AccountViewService) {}

  ngOnInit(): void {}

  getAppListById() {
    this.getColumns();

    this._accountViewService.getAppList().subscribe(response => {
      if (response) {
        this.data = response;

        this.totalRecords = this.data.length;

        if (this.appSelected) {
          this.selectedApp = this.appSelected.split(',');
          this.selectedApp = this.selectedApp.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
        }
      }
    });
  }

  getColumns() {
    this.cols = [];

    this.cols = [
      { field: 'checkBoxes', header: 'App Assigned' },
      { field: 'appName', header: 'App Name' },
      { field: 'appNote', header: 'App Note' },
      { field: 'statusName', header: 'Status' },
    ];
  }

  getAppAssignedId() {
    this.validateSelections();
    this.appData.emit(this.selectedApp);
  }

  private validateSelections() {
    const validSelections: string[] = [];
    for(const selectedAppId of this.selectedApp){
      const app = this.data.find(s => s.appId === parseInt(selectedAppId));

      if(this.canSelectApp(app)) {
        validSelections.push(selectedAppId);
      }
    }

    this.selectedApp = validSelections;
  }

  private canSelectApp(app: AppModel): boolean {
    if(app === undefined) {
      return false;
    }

    if(app.parentAppIds == null || app.parentAppIds.length == 0) {
      return true;
    }

    for(const parentAppId of app.parentAppIds) {
      if(!this.selectedApp.includes(parentAppId.toString())) {
        return false;
      }
    }

    return true;
  }
}
