import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InvitedAccountService } from '../../../services/invitedsupplier.service';
import { CertificationClient, CourseModel } from '../../../../api.client';

@Component({
  selector: 'certification-list',
  templateUrl: './certification-list.component.html',
})
export class CertificationListComponent implements OnInit {
  cols: any[];
  public certificationList: CourseModel[] = [];
  selectedCertification: string[] = [];

  public totalRecords: number;

  @Output() certificationData: EventEmitter<string[]> = new EventEmitter<string[]>();
  constructor(
    private _InvitedAccountService: InvitedAccountService,
    private certificationClient: CertificationClient,
  ) {}
  @Input()
  public certificationselected: string;
  ngOnInit(): void {}
  // this function call once user click on checkbox
  getCertificationId() {
    this.certificationData.emit(this.selectedCertification);
  }

  // this function is used to call to fill the list at the time of load
  public getCertificationList() {
    this.getColumns();

    this.certificationClient.getRetailerCertificationList().subscribe(response => {
      if (response) {
        this.certificationList = response;
        this.totalRecords = this.certificationList.length;
        if (this.certificationselected) {
          this.selectedCertification = this.certificationselected.split(',');
        }
      }
    });
  }

  getColumns() {
    this.cols = [
      { field: 'checkBoxes', header: 'Certification Assigned', width: '150px' },
      { field: 'courseName', header: 'Certification Name', width: '300px' },
      { field: 'courseNote', header: 'Certification Note', width: '300px' },
      { field: 'statusName', header: 'Status', width: '100px' },
    ];
  }
}
