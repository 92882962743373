import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { ImageEditDialogComponent } from '@volt/shared/components/misc/image-edit/image-edit-dialog.component';
import { Status } from '@volt/shared/constants/status.constant';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PC, PermissionCollection, PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { TranslationService } from '@volt/shared/services/translation.service';
import { ICustomFile } from 'file-input-accessor';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, pluck, switchMap, take, tap } from 'rxjs/operators';
import { RETAILER_CONFIG, RetailerConfigType } from '../../../../retailer-config';

import {
  CourseModel,
  DropDownModel,
  EBadgePrint,
  FieldGroupTypeViewModel,
  InvitedAccountClient,
  InvitedAccountModel,
  UpdateActivities,
  UploadResponse,
  UserInformation,
} from '../../../api.client';
import { AuthService } from '../../../auth';
import { RoleConstant } from '../../../auth/shared/roles.constants';
import { AccountIdType } from '../../models/account-id-type.enum';
import { AccountRejectionResponse } from '../../models/account-rejection-response';
import { AccountViewService } from '../../services/accountview.service';
import { InvitedAccountService } from '../../services/invitedsupplier.service';
import { SupplierNumberService } from '../../services/supplier-number.service';
import { EBadgeShipping } from '../models/EBadgeShipping';
import { StateEnum } from '../models/StateEnum';
import { RegistrationModalComponent } from '../registration-modal/registration-modal.component';
import { AccountRejectionModalComponent } from './account-rejection-modal/account-rejection-modal.component';
import { AccountTagsComponent } from './account-tags/account-tag.component';
import { AppListComponent } from './app-list/app-list.component';
import { ApplicationLinkModalComponent } from './application-link-modal/application-link-modal.component';
import { ApplicationLinkComponent } from './applink-list/applink-list.component';
import { CertificationListComponent } from './certification-list/certification-list.component';
import { InvitedAnoteModalComponent } from './invited-anote-modal/invited-anote-modal.component';
import { FieldRolesListComponent } from './non-field-roles-list/non-field-roles-list.component';
import { ProductListComponent } from './product-list/product-list.component';
import { RetailerListComponent } from './retailer-account-list/retailer-account-list.component';
import { RequiredModalComponent } from './show-required-control/show-required-modal.component';

@Component({
  selector: 'view-account',
  templateUrl: './viewaccount.component.html',
  styleUrls: ['./viewaccount.component.scss'],
  providers: [SupplierNumberService],
})
export class ViewAccountComponent implements OnInit {
  result: any;
  public inviteSupplierGroup: UntypedFormGroup;
  public form: UntypedFormGroup;
  public files: ICustomFile[] = [];
  public fileCtrl = this._fb.control('');
  public appLinkMsgs: Message[] = [];
  public registrationGroup: UntypedFormGroup;
  public errorMessage: string;
  public working = false;
  public error: string = null;
  public fieldTypeDropModel = [{ label: '--Select--', value: null }];
  public certificationDropModel = [{ label: '--Select--', value: null }];
  public yesNoDropDownModel: SelectItem[] = [
    { label: '--Select--', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public primaryAdminDropModel: DropDownModel[];
  public data: InvitedAccountModel;
  public accountNumber: string;
  public companyName: string;
  public invitedemail: string;
  public invitedAccountId: any;
  public firstName: any;
  public fieldGroupType: string;
  public certification: string;
  public invitedAccount = new InvitedAccountModel();
  public currentUser: UserInformation;
  public phoneNumber: string;
  public stateDropDownModel: DropDownModel[];
  public countryDropDownModel: DropDownModel[];
  public statusDropDownModel: DropDownModel[];
  public showStoreSupportData: any[];
  public showStoreSupportDropDownModel: DropDownModel[];

  accountId: number;

  public printBadgeDropDownModel = [
    new DropDownModel({ label: 'Print', value: EBadgePrint.Print }),
    new DropDownModel({ label: 'No Print', value: EBadgePrint.NoPrint }),
  ];
  public badgeShippingDropDownList = [
    new DropDownModel({
      label: 'Ship Direct To Fulfillment Center',
      value: EBadgeShipping.ShipDirectToFulfillmentCenter.toString(),
    }),
    new DropDownModel({ label: 'Ship Direct To Rep', value: EBadgeShipping.ShipDirectToRep.toString() }),
  ];
  public photoValues: string[];
  public type: string;
  public role: string;
  // these local variables for holding the selected checkboxes value and perform validation
  public selectedProducts: string[] = [];
  public selectedCertifications: string[] = [];
  public selectedRetailers: string[] = [];
  public selectedApps: string[] = [];

  public tempSelectedRetailer: string;
  public tempSelectedProduct = '2,3';
  public tempSelectedCertification: string;
  public tempselectedApp = '1,2,8194,8195';
  tempSelectedTags = '';
  public tempEncryptId: string;
  public vendorIDExist: string;
  public vendorIdExist = true;
  public vendorIdMsg = false;
  public invalidControl: string[] = [];
  public loading = true;
  public zipBillingCodeMsg = false;
  public zipShippingCodeMsg = false;
  public zipUserCodeMsg = false;

  public zipBillingCanMsg = false;
  public zipShippingCanMsg = false;
  public zipUserCanMsg = false;

  public invitedEmailExist = false;
  public invitedEmailMsg = false;
  public invitedVendorIdExist = true;
  public invitedVendorIdMsg = false;
  public vendorIdExistSupplier = false;
  public vendorIdSupplierMsg: string;
  public accountNameExist = false;
  public accountNameMsg: string;
  public showANoteClick = false;
  public emailExist: string;
  public showAnote = false;
  public emailMsg = false;
  public approvedMsg: string;
  public checkstatus: Status;
  private _fieldGroupTypes: FieldGroupTypeViewModel[];
  public translatedData$ = this.translationService.getTranslatedObject();

  public roleEnabled = false;
  public enableApproval = false;

  showAccountHierarchyComponent$: Observable<boolean>;

  @ViewChild('certificatonList')
  certificationData: CertificationListComponent;
  @ViewChild('retailerList')
  retailerData: RetailerListComponent;
  @ViewChild('appList') appData: AppListComponent;

  @ViewChild('accountTagList') accountTags: AccountTagsComponent;
  @ViewChild('nonFieldRolesList')
  fieldRolesData: FieldRolesListComponent;
  @ViewChild('productData')
  productData: ProductListComponent;
  @ViewChild('appLinkData')
  appLinkData: ApplicationLinkComponent;

  public canEditSubscription(): boolean {
    return this.currentUser?.roles === RoleConstant.SystemAdministrator;
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _invitedAccountService: InvitedAccountService,
    private _dynamicDialogService: DynamicDialogService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _invitedAccountClient: InvitedAccountClient,
    private _accountViewService: AccountViewService,
    private readonly monitoringService: MonitoringService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(PC) public pc: PermissionCollection,
    private translationService: TranslationService,
    private permissionService: PermissionsService,
    private readonly _authService: AuthService,
    private readonly _supplierNumberService: SupplierNumberService,
    @Inject(RETAILER_CONFIG) public retailerConfig: RetailerConfigType,
    private readonly _messageService: MessageService,
  ) {}

  public subscriptions = [{ label: 'Volt Basic', value: 'Volt Basic' }, { label: 'Volt Plus', value: 'Volt Plus' }];

  public SetFieldGroupTypeDefaults(event: any) {
    if (event && event.value) {
      const selectedTemplate = this._fieldGroupTypes.find(f => f.name === event.value);

      if (selectedTemplate && selectedTemplate.templateAccount) {
        this.registrationGroup.get('printBadge').setValue(selectedTemplate.templateAccount.printBadge);

        this.selectedApps = selectedTemplate.templateAccount.appLicensingIds.map(i => String(i));
        this.tempselectedApp = selectedTemplate.templateAccount.appLicensingIds.join();
        this.tempSelectedCertification = selectedTemplate.templateAccount.certificationIds.join();
        this.selectedCertifications = selectedTemplate.templateAccount.certificationIds.map(id => String(id));
        this.selectedProducts = selectedTemplate.templateAccount.productIds.map(id => String(id));
        this.tempSelectedProduct = selectedTemplate.templateAccount.productIds.join();
        this.invitedAccount.selectedActivityUpdates.update = true;
        this.invitedAccount.selectedActivityUpdates.activityIds = selectedTemplate.templateAccount.activityIds;

        this.getAppList();
        this.getCertificationList();
        this.getProductList();
      } else {
        this.invitedAccount.selectedActivityUpdates.update = false;
      }
    }
  }

  ngOnInit(): void {
    this.invitedAccount.selectedActivityUpdates = new UpdateActivities();
    this.invitedAccount.selectedActivityUpdates.update = false;
    this._invitedAccountService.getChangeStatusEmitter().subscribe(item => (this.invitedAccount.statusId = item));

    this.initForm();
    this.initInviteSupplierForm();
    this.getStateDropDownList();
    this.getCountryDropDownList();
    this.getCertificationDropDownList();
    this.getStatusDropDownList();
    this.getShowStoreSupportDropDownList();

    this.showAccountHierarchyComponent$ = this.activatedRoute.queryParams.pipe(
      pluck('showAccountHierarchy'),
      map((showAccountHierarchy: string) => Boolean(JSON.parse(showAccountHierarchy))),
    );

    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          const { type, id } = params;
          this.type = type;

          if (this.type === AccountIdType.AccountId) {
            this.tempEncryptId = id;
            this._invitedAccountService.getFieldTypeDropDownList().subscribe((data: FieldGroupTypeViewModel[]) => {
              this._fieldGroupTypes = data;
              this.fieldTypeDropModel.push(
                ...data.map(x => ({
                  label: x.description + ' - ' + x.name,
                  value: x.name,
                })),
              );
              this.cdr.detectChanges();
              this.getAccountById(id);

              this.getNonFieldRolesList(id);
              this.getAccountAppLinkList(id);
              this.getPrimaryAdminDropDownList(id);
            });
            return of(null);
          } else {
            this.invitedAccountId = params['id'];
            return forkJoin([
              this._invitedAccountService.getFieldTypeDropDownList(),
              this.getInvitedAccountById(this.invitedAccountId),
            ]);
          }
        }),
      )
      .subscribe((data: null | [FieldGroupTypeViewModel[], InvitedAccountModel, boolean]) => {
        if (!!data) {
          const [fieldTypes, invitedAccount] = data;
          this._fieldGroupTypes = fieldTypes;
          this.fieldTypeDropModel.push(
            ...fieldTypes.map(x => ({
              label: x.description + ' - ' + x.name,
              value: x.name,
            })),
          );
          this.cdr.detectChanges();
          this.checkstatus = invitedAccount.statusId;

          /// this is used to show AccountNumber required and show message if accountNumber already exist in system
          if (invitedAccount.statusId === 2) {
            if (invitedAccount.invitedCourse) {
              this.getCourseByCourseName(invitedAccount.invitedCourse);
            } else {
              this.getCertificationList();
            }
            this.applyRequired();
            const selectedTemplate = fieldTypes.find(f => f.name === invitedAccount.fieldGroupType);
            if (selectedTemplate && selectedTemplate.templateAccount) {
              this.selectedApps = selectedTemplate.templateAccount.appLicensingIds.map(i => String(i));
              this.tempselectedApp = selectedTemplate.templateAccount.appLicensingIds.join();
              this.selectedCertifications = selectedTemplate.templateAccount.certificationIds.map(id => String(id));
              this.selectedProducts = selectedTemplate.templateAccount.productIds.map(i => String(i));
              this.tempSelectedProduct = selectedTemplate.templateAccount.productIds.join();
              this.tempSelectedRetailer = '5582';
              this.selectedRetailers.push('5582');

              this.invitedAccount.selectedActivityUpdates.update = true;
              this.invitedAccount.selectedActivityUpdates.activityIds = selectedTemplate.templateAccount.activityIds;
            } else {
              const products = this.tempSelectedProduct.split(',');
              this.selectedProducts.push(...products);
              const apps = this.tempselectedApp.split(',');
              this.selectedApps.push(...apps);
              this.tempSelectedRetailer = '5582';
              this.selectedRetailers.push('5582');
            }
          } else {
            this.getCertificationList();
          }

          if (invitedAccount) {
            this.data = invitedAccount;
            this.assignModel(this.data);

            if (
              Status.Pending === invitedAccount.statusId &&
              (this.currentUser.roles === RoleConstant.SystemAdministrator ||
                this.currentUser.roles === RoleConstant.RetailerAdmin)
            ) {
              this.enableApproval = true;
            }
          }

          this.getProductList();
          this.getRetailerList();
          this.getAppList();
          this.checkAccountNameAlreadyExist();

          // this is used to check ANote exist in invitedAccount or not and perform operation based on it
          this.showANoteClick =
            this.invitedAccount.aNote === null ||
            this.invitedAccount.aNote === '' ||
            typeof this.invitedAccount.aNote === undefined;
        }

        this.loading = false;
      });

    this._accountViewService.getAppLinksAccountId.subscribe((data: any) => {
      this.getAccountAppLinkList(data);
    });

    this._accountViewService.getInvitedAccountANote.subscribe((data: any) => {
      this.invitedAccount.aNote = data;
    });
  }

  public get invitedCompanyControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }

    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedCompany');
  }

  public get invitedNameControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }

    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedName');
  }

  public get invitedEmailControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }

    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedEmail');
  }

  public get invitedAccountNumberControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }
    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedAccountNumber');
  }

  // save invitedform info from invited tab
  public saveInviteSupplier() {
    this.loading = true;
    const invitedAccount = new InvitedAccountModel();
    invitedAccount.invitedCompany = this.inviteSupplierGroup.get('invitedCompany').value;
    invitedAccount.invitedName = this.inviteSupplierGroup.get('invitedName').value;
    invitedAccount.invitedEmail = this.inviteSupplierGroup.get('invitedEmail').value;
    invitedAccount.accountNumber = this.inviteSupplierGroup.get('invitedAccountNumber').value;

    invitedAccount.invitedNote =
      this.inviteSupplierGroup.get('invitedNotes').value === ''
        ? null
        : this.inviteSupplierGroup.get('invitedNotes').value;

    if (this.showAnote) {
      invitedAccount.aNote =
        this.inviteSupplierGroup.get('empNotes').value === '' ? null : this.inviteSupplierGroup.get('empNotes').value;
    } else {
      invitedAccount.aNote = this.invitedAccount.aNote;
    }

    invitedAccount.fieldGroupType =
      this.inviteSupplierGroup.get('fieldGroupType').value === ''
        ? null
        : this.inviteSupplierGroup.get('fieldGroupType').value;

    invitedAccount.invitedCourse =
      this.inviteSupplierGroup.get('certification').value === ''
        ? null
        : this.inviteSupplierGroup.get('certification').value;

    invitedAccount.shipDirectToRep = 0;
    invitedAccount.aname = invitedAccount.invitedCompany;
    invitedAccount.invitedAccountId = this.invitedAccount.invitedAccountId;
    invitedAccount.accountId = 0;
    invitedAccount.retailLink = this.invitedAccount.retailLink;
    invitedAccount.isSeasonal = this.invitedAccount.isSeasonal;

    this._invitedAccountService.saveInvitedAccount(invitedAccount).subscribe((response: any) => {
      if (response) {
        this.loading = false;

        this._dynamicDialogService.open(RegistrationModalComponent, {
          data: { message: 'The updated information has been sent to the supplier.' },
        });
      }
    });
  }

  public checkEmailAlreadyExist() {
    if (this.invitedAccount.invitedEmail == undefined) {
      this.invitedAccount.invitedEmail = '';
    }

    this._invitedAccountService
      .checkEmailAlreadyExist(this.invitedAccount.invitedEmail, this.invitedAccount.invitedAccountId)
      .subscribe(
        (data: any) => {
          this.invitedEmailExist = data;
          this.invitedEmailMsg = !!data;
        },
        (err: any) => {
          console.error(err);
          this.monitoringService.logApiException(err);
        },
      );
  }

  public checkEmailDomainInPerson() {
    this.emailExist = '';
    if (
      this.invitedAccount.email1 === '' ||
      this.invitedAccount.email1 === undefined ||
      this.invitedAccount.email1 === null
    ) {
      this.emailExist = '';
      return (this.emailMsg = false);
    }

    this._invitedAccountService.checkEmailDomainInPerson(this.invitedAccount.email1).subscribe(
      (data: any) => {
        this.emailExist = data;
        if (data != null && data !== '' && data !== undefined) {
          this.emailMsg = true;
        } else {
          this.emailMsg = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  // check invitedAccountNumber already exit for invitedForm in invitedTab
  public checkInvitedAccountNoAlreadyExist() {
    if (
      this.invitedAccount.accountNumber === '' ||
      this.invitedAccount.accountNumber === undefined ||
      this.invitedAccount.accountNumber === null
    ) {
      this.vendorIdExistSupplier = false;
      this.vendorIdSupplierMsg = '';
      return (this.invitedVendorIdMsg = false);
    }

    this._invitedAccountService
      .checkAccountNoAlreadyExist(this.invitedAccount.accountNumber, this.invitedAccount.invitedAccountId)
      .subscribe(
        (data: any) => {
          this.invitedVendorIdExist = data;

          this.invitedVendorIdMsg = data === true;
        },
        (err: any) => {
          console.error(err);
          this.monitoringService.logApiException(err);
        },
      );

    this._invitedAccountService.GetSupplierByNo(this.invitedAccount.accountNumber).subscribe(
      (data: any) => {
        if (data != null) {
          this.vendorIdSupplierMsg = data.supplierName;
          this.vendorIdExistSupplier = true;
        } else {
          this.vendorIdSupplierMsg = '';
          this.vendorIdExistSupplier = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public UpdateInvitedAnote() {
    this._dynamicDialogService.open(InvitedAnoteModalComponent, {
      data: {
        invitedAccountId: this.invitedAccount.invitedAccountId,
        retailLink: this.invitedAccount.retailLink,
        companyNote: this.invitedAccount.aNote,
      },
    });
  }

  // check AccountName already exist in Account table
  public checkAccountNameAlreadyExist() {
    this.accountNameMsg = '';
    if (
      this.invitedAccount.invitedCompany === '' ||
      this.invitedAccount.invitedCompany === undefined ||
      this.invitedAccount.invitedCompany === null
    ) {
      this.accountNameExist = false;
      this.accountNameMsg = '';
      return (this.accountNameExist = false);
    }

    this._invitedAccountService.checkAccountNameAlreadyExist(this.invitedAccount.invitedCompany).subscribe(
      (data: any) => {
        if (data != null && data !== '' && data !== undefined) {
          this.accountNameMsg = data;
          this.accountNameExist = true;
        } else {
          this.accountNameMsg = '';
          this.accountNameExist = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getAppList() {
    let value;
    value = this.appData.getAppListById();
  }

  public getNonFieldRolesList(id) {
    let value;
    value = this.fieldRolesData.getNonFieldRolesListById(id);
  }

  public getAccountAppLinkList(id) {
    let value;
    value = this.appLinkData.getAccountAppLinkList(id);
  }

  public getAppLinkMsg(data) {
    this.appLinkMsgs = [];
    this.appLinkMsgs = data;
  }

  public getPrimaryAdminDropDownList(id) {
    this._invitedAccountService.getPrimaryAdminDropDownList(id).subscribe(
      (data: any) => {
        this.primaryAdminDropModel = data;
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getCertificationDropDownList() {
    this._invitedAccountService.getCertificationDropDownList().subscribe(
      (data: CourseModel[]) => {
        this.certificationDropModel = [
          ...this.certificationDropModel,
          ...data.map(x => ({
            label: x.courseName,
            value: x.courseName,
          })),
        ];
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getStateDropDownList() {
    this._invitedAccountService.getStateDropDownList().subscribe(
      (data: any) => {
        this.stateDropDownModel = data;
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getShowStoreSupportDropDownList() {
    this.showStoreSupportData = [
      { label: 'False', value: false },
      { label: 'True', value: true },
    ];

    this.showStoreSupportDropDownModel = this.showStoreSupportData;
  }

  public getCountryDropDownList() {
    this._invitedAccountService.getCountryDropDownList().subscribe(
      (data: any) => {
        this.countryDropDownModel = data;
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getStatusDropDownList() {
    this._invitedAccountService.getStatusList().subscribe(
      (data: any) => {
        this.statusDropDownModel = data;
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getRetailerList() {
    let value;
    value = this.retailerData.getRetailerList();
  }

  public getProductList() {
    let value;
    value = this.productData.getProductsList();
  }

  public getCertificationList() {
    let value;
    value = this.certificationData.getCertificationList();
  }

  public getInvitedAccountById(id) {
    this.currentUser = this._authService.getCurrentUser();
    this.role = this.currentUser.roles;
    this.checkRole(this.role);

    return this._invitedAccountService.getInvitedAccountById(id);
  }

  public getCourseByCourseName(course: string) {
    this._invitedAccountService.getCourseIdByCourseName(course).subscribe(
      (response: any) => {
        if (response) {
          this.tempSelectedCertification = response.toString();
          this.selectedCertifications.push(response.toString());
        }
        this.getCertificationList();
      },
      err => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getAccountById(id) {
    this.invitedAccount.retailLink = false;
    this.currentUser = this._authService.getCurrentUser();
    this.role = this.currentUser.roles;
    this.checkRole(this.role);
    this.removeValidation();
    this._accountViewService.getAccountById(id).subscribe(response => {
      this.data = response;
      this.accountId = response.accountId;
      this._invitedAccountService.getInvitedAccountByAccountId(id).subscribe((result: any) => {
        if (result) {
          this.invitedAccount.estimatedUsers = result.estimatedUsers;
          this.invitedAccount.userSpecificEmailsForCommunication = result.userSpecificEmailsForCommunication;
          this.invitedAccount.provideBadgeWithPhoto = result.provideBadgeWithPhoto;
          this.invitedAccount.uDirectlyEmployed = result.uDirectlyEmployed;
          this.invitedAccount.uMobileEnabled = result.uMobileEnabled;
          this.invitedAccount.aaddress1 = result.aaddress1;
          this.invitedAccount.aaddress2 = result.aaddress2;
          this.invitedAccount.acity = result.acity;
          this.invitedAccount.astate = result.astate;
          this.invitedAccount.acountry = result.acountry;
          this.invitedAccount.azip = result.azip;
          this.invitedAccount.awebsite = result.awebsite;
          this.invitedAccount.invitedAccountId = result.invitedAccountId;
          this.invitedAccount.retailLink = result.retailLink;
          this.invitedAccount.aNote = result.aNote;
          this.invitedAccount.invitedDate = result.invitedDate;
          this.invitedAccount.fName = result.fName;
          this.invitedAccount.lName = result.lName;
          this.invitedAccount.primaryAdminId = result.primaryAdminId !== null ? result.primaryAdminId.toString() : null;
          this.invitedAccount.email1 = result.email1;
          this.invitedAccount.printBadge = result.printBadge;
          this.invitedAccount.fieldGroupType = result.fieldGroupType
          this.invitedAccount.isSeasonal = result.isSeasonal;
          this.invitedAccount.subscriptionType = result.subscriptionType;
          this.fieldGroupTypeControl.setValue(result.fieldGroupType)
          this.registrationGroup
            .get('badgeShipping')
            .setValue(
              result.badgeShipping
                ? result.badgeShipping.toString()
                : EBadgeShipping.ShipDirectToFulfillmentCenter.toString(),
            );
        }
      });

      if (response) {
        this.invitedAccount.accountTypeId = this.data.accountTypeId;
        if (this.data.statusId !== 1) this.invitedAccount.invitedAccountId = 0;
        if (!this.invitedAccount.invitedAccountId) this.invitedAccount.invitedAccountId = 0;
        this.invitedAccount.dateCreated = this.data.dateCreated;
        this.invitedAccount.invitedCompany = this.data.aname;
        this.invitedAccount.aname = this.data.aname;
        this.invitedAccount.accountNumber = this.data.accountNumber;
        this.invitedAccount.invitedDate = this.data.invitedDate;
        this.invitedAccount.authToken = this.data.authToken;
        this.invitedAccount.fieldGroupType = this.data.fieldGroupType;
        this.invitedAccount.aabbrev = this.data.aabbrev;
        this.invitedAccount.aaddress1 = this.data.aaddress1;
        this.invitedAccount.aaddress2 = this.data.aaddress2;
        this.invitedAccount.accountId = this.data.accountId;
        this.invitedAccount.billAttnToFname = this.data.billAttnToFname;
        this.invitedAccount.billingEmail = this.data.billingEmail;
        this.invitedAccount.bphone = this.data.bphone;
        this.invitedAccount.baddress1 = this.data.baddress1;
        this.invitedAccount.baddress2 = this.data.baddress2;
        this.invitedAccount.bcity = this.data.bcity;
        this.invitedAccount.bstate = this.data.bstate;
        this.invitedAccount.bcountry = this.data.bcountry;
        this.invitedAccount.bzip = this.data.bzip;

        this.invitedAccount.shipAttnTo = this.data.shipAttnTo;
        this.invitedAccount.saddress1 = this.data.saddress1;
        this.invitedAccount.saddress2 = this.data.saddress2;
        this.invitedAccount.scity = this.data.scity;
        this.invitedAccount.sstate = this.data.sstate;
        this.invitedAccount.scountry = this.data.scountry;
        this.invitedAccount.szip = this.data.szip;
        this.invitedAccount.subscriptionType = this.data.subscriptionType;

        this.invitedAccount.itcontactEmail = this.data.itcontactEmail;
        this.invitedAccount.supportEmail = this.data.supportEmail;
        this.invitedAccount.itcontactPhone = this.data.itcontactPhone;
        this.invitedAccount.supportPhone = this.data.supportPhone;
        this.invitedAccount.supportNote = this.data.supportNote;
        this.invitedAccount.statusId = this.data.statusId;
        this.checkstatus = this.data.statusId;
        this.invitedAccount.aimageUrl = this.data.aimageUrl;
        this.invitedAccount.accountId = this.data.accountId;
        this.invitedAccount.aNote = response.anote;
        this.invitedAccount.primaryAdminId = this.data.primaryAdminId;
        this.invitedAccount.printBadge = this.data.printBadge;
        this.registrationGroup.get('badgeShipping').setValue(this.data.badgeShipping);

        this.invitedAccount.showStoreSupportInfo = this.data.showStoreSupportInfo;
        this.invitedAccount.storeSupportNumber = this.data.storeSupportNumber;
        this.invitedAccount.storeSupportEmail = this.data.storeSupportEmail;
      }

      this._invitedAccountService.GetSelectedAccountDetailByAccountId(response.accountId).subscribe(result => {
        if (result) {
          // used to send these certificate to child component for preselection/checked
          this.tempSelectedCertification = result.courseIds;
          this.tempSelectedProduct = result.productIds;
          this.tempSelectedRetailer = result.retailerIds;
          this.tempselectedApp = result.appIds; // show the selected apps
          this.tempSelectedTags = result.accountTags;
          this.selectedRetailers = result && result.retailerIds ? result.retailerIds.split(',') : [];
          this.selectedApps = result && result.appIds ? result.appIds.split(',') : [];
          this.selectedCertifications = result && result.courseIds ? result.courseIds.split(',') : [];
          this.selectedProducts = result && result.productIds ? result.productIds.split(',') : [];
          this.invitedAccount.selectedAccountTags = result.accountTags?.map(r => r.tagId).join(',') || '';
        }
        this.getProductList();
        this.getCertificationList();
        this.getRetailerList();
        this.getAppList();
      });
    });
  }

  public checkRole(roleName: any) {
    if (this.permissionService.hasPermission(PermissionNames.AccountsManage, Privilege.Update)) {
      this.showAnote = true;
      this.roleEnabled = true;
    } else {
      this.showAnote = false;
    }
  }

  public assignModel(data: InvitedAccountModel) {
    this.invitedAccount.invitedDate = this.data.invitedDate;
    this.invitedAccount.retailLink = this.data.retailLink;
    this.invitedAccount.aNote = this.data.aNote;
    this.invitedAccount.aabbrev = this.data.aabbrev;
    this.invitedAccount.accountTypeId = this.data.accountTypeId;
    this.invitedAccount.invitedAccountId = this.invitedAccountId;
    this.invitedAccount.statusId = this.data.statusId;
    this.invitedAccount.pPhone = this.data.pPhone;
    this.invitedAccount.invitedAccountId = this.data.invitedAccountId;
    this.invitedAccount.invitedCompany = this.data.invitedCompany;
    this.invitedAccount.aname = this.data.aname;
    this.invitedAccount.accountNumber = this.data.accountNumber;
    this.invitedAccount.fName = this.data.fName;
    this.invitedAccount.lName = this.data.lName;
    this.invitedAccount.invitedName = this.data.invitedName;
    this.invitedAccount.invitedEmail = this.data.invitedEmail;
    this.invitedAccount.email1 = this.data.email1;
    this.invitedAccount.fieldGroupType = this.data.fieldGroupType;
    this.invitedAccount.invitedCourse = this.data.invitedCourse;
    this.invitedAccount.aaddress1 = this.data.aaddress1;
    this.invitedAccount.aaddress2 = this.data.aaddress2;
    this.invitedAccount.invitedNote = this.data.invitedNote;

    this.invitedAccount.billAttnToFname = this.data.billAttnToFname;
    this.invitedAccount.billingEmail = this.data.billingEmail;
    this.invitedAccount.bphone = this.data.bphone;
    this.invitedAccount.baddress1 = this.data.baddress1;
    this.invitedAccount.baddress2 = this.data.baddress2;
    this.invitedAccount.bcity = this.data.bcity;
    this.invitedAccount.bstate = this.data.bstate;
    this.invitedAccount.bcountry = this.data.bcountry;
    this.invitedAccount.bzip = this.data.bzip;
    this.invitedAccount.printBadge = this.data.printBadge;
    this.invitedAccount.badgeShipping = this.data.badgeShipping;

    this.invitedAccount.shipAttnTo = this.data.shipAttnTo;
    this.invitedAccount.saddress1 = this.data.saddress1;
    this.invitedAccount.saddress2 = this.data.saddress2;
    this.invitedAccount.scity = this.data.scity;
    this.invitedAccount.sstate = this.data.sstate;
    this.invitedAccount.scountry = this.data.scountry;
    this.invitedAccount.szip = this.data.szip;

    this.invitedAccount.itcontactEmail = this.data.itcontactEmail;
    this.invitedAccount.supportEmail = this.data.supportEmail;
    this.invitedAccount.itcontactPhone = this.data.itcontactPhone;
    this.invitedAccount.supportPhone = this.data.supportPhone;
    this.invitedAccount.supportNote = this.data.supportNote;

    this.invitedAccount.estimatedUsers = this.data.estimatedUsers;
    this.invitedAccount.userSpecificEmailsForCommunication = this.data.userSpecificEmailsForCommunication;
    this.invitedAccount.provideBadgeWithPhoto = this.data.provideBadgeWithPhoto;
    this.invitedAccount.uDirectlyEmployed = this.data.uDirectlyEmployed;
    this.invitedAccount.uMobileEnabled = this.data.uMobileEnabled;

    this.invitedAccount.aaddress1 = this.data.aaddress1;
    this.invitedAccount.aaddress2 = this.data.aaddress2;
    this.invitedAccount.acity = this.data.acity;
    this.invitedAccount.astate = this.data.astate;
    this.invitedAccount.acountry = this.data.acountry;
    this.invitedAccount.azip = this.data.azip;
    this.invitedAccount.awebsite = this.data.awebsite;
    this.invitedAccount.aimageUrl = this.data.aimageUrl;
    this.invitedAccount.invitedDate = this.data.invitedDate;
    this.invitedAccount.accountId = this.data.accountId;
    this.invitedAccount.primaryAdminId = this.data.primaryAdminId;
    this.invitedAccount.registrationCompletionDate = this.data.registrationCompletionDate;

    this.invitedAccount.showStoreSupportInfo = this.data.showStoreSupportInfo;
    this.invitedAccount.storeSupportNumber = this.data.storeSupportNumber;
    this.invitedAccount.storeSupportEmail = this.data.storeSupportEmail;
    this.invitedAccount.isSeasonal = this.data.isSeasonal;
    this.checkAccountNoAlreadyExist();
    this.checkEmailDomainInPerson();

    this.cdr.detectChanges();
  }

  statusChange(event: any) {
    if (event.value === '3') {
      this._rejectInvitedAccount({
        name: 'Inactive Reason',
        accountId: this.invitedAccount.accountId,
        invitedAccountId: this.invitedAccount.invitedAccountId,
      });
    } else {
      return true;
    }
  }

  public approveInvitedAccount() {
    if (!this.checkControlValidation()) {
      return;
    }
    this.loading = true;

    this.updateInvitedAccount('Approved')
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this._messageService.add({
          severity: 'success',
          detail: this.approvedMsg,
        });
        this._router.navigate(['/registration/account-list']);
      }, this.updateErrorHandler);
  }

  public rejectInvitedAccount() {
    this._rejectInvitedAccount({ name: 'Rejection Reason', invitedAccountId: this.invitedAccount.invitedAccountId });
  }

  private _rejectInvitedAccount(rejectionData: { name: string; invitedAccountId?: number; accountId?: number }) {
    this.loading = true;

    this._dynamicDialogService
      .open<AccountRejectionResponse>(AccountRejectionModalComponent, {
        data: rejectionData,
      })
      .pipe(
        take(1),
        mergeMap(result => {
          if (result.rejected) {
            this.invitedAccount.retailLink = false;
            this.invitedAccount.reject = result.reason;
            return this.updateInvitedAccount('Rejected');
          }
          return EMPTY;
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe(() => {
        this._messageService.add({
          severity: 'success',
          detail: 'Account rejected',
        });
        this._router.navigate(['/registration/account-list']);
      }, this.updateErrorHandler);
  }

  private updateInvitedAccount(status) {
    if (!this.enableApproval) {
      this.invitedAccount.checkStatus = 'Save';
      this.approvedMsg = 'Account changes have been saved';
    } else {
      this.invitedAccount.checkStatus = status;
      this.approvedMsg = 'Account approved';
    }

    this.fillFrom();

    return this.registerFiles().pipe(
      mergeMap(() => this._invitedAccountService.approvedOrRejectInvitedAccount(this.invitedAccount)),
      mergeMap(() => this._supplierNumberService.save()),
    );
  }

  private registerFiles(): Observable<UploadResponse> {
    if (this.files?.length) {
      return this._invitedAccountClient
        .registerFileUpload({
          data: this.files[0],
          fileName: this.files[0].name,
        })
        .pipe(
          tap((uploadResponse: UploadResponse) => {
            this.photoValues = [uploadResponse.data];
            this.invitedAccount.aimageUrl = uploadResponse.data;
          }),
        );
    }
    return of(null);
  }

  private updateErrorHandler(err) {
    this._messageService.add({
      severity: 'error',
      summary: 'Error occurred when updating Account Status',
    });
    this.monitoringService.logApiException(err);
  }

  isNumberKey(evt) {
    // tslint:disable-next-line:prefer-const
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  public onBlurPhoneNumber() {
    if (this.invitedAccount.pPhone !== undefined) {
      const flag = this.replacePhoneNumber(this.invitedAccount.pPhone);
      if (flag) {
        this.invitedAccount.pPhone = '';
      }
    }

    if (this.invitedAccount.storeSupportNumber !== undefined) {
      const flag = this.replacePhoneNumber(this.invitedAccount.storeSupportNumber);
      if (flag) {
        this.invitedAccount.storeSupportNumber = '';
      }
    }

    if (this.invitedAccount.bphone !== undefined) {
      const flag = this.replacePhoneNumber(this.invitedAccount.bphone);
      if (flag) {
        this.invitedAccount.bphone = '';
      }
    }

    if (this.invitedAccount.itcontactPhone !== undefined) {
      const flag = this.replacePhoneNumber(this.invitedAccount.itcontactPhone);
      if (flag) {
        this.invitedAccount.itcontactPhone = '';
      }
    }

    if (this.invitedAccount.supportPhone !== undefined) {
      const flag = this.replacePhoneNumber(this.invitedAccount.supportPhone);
      if (flag) {
        this.invitedAccount.supportPhone = '';
      }
    }
  }

  public onBlurBillingZipCode() {
    if (this.invitedAccount.bzip !== undefined && this.invitedAccount.bzip !== '') {
      if (this.invitedAccount.bcountry.toString() === StateEnum.USA) {
        const flag = this.replaceZipCode(this.invitedAccount.bzip, 'Billing');
        if (flag) {
          this.invitedAccount.bzip = '';
        }
      }

      if (this.invitedAccount.bcountry.toString() === StateEnum.CANADA) {
        const flag = this.replaceCanadaZipCode(this.invitedAccount.bzip, 'Billing');
        if (flag) {
          this.invitedAccount.bzip = '';
        }
      }
    }
  }

  public onBlurShippingZipCode() {
    if (this.invitedAccount.szip !== undefined && this.invitedAccount.szip !== '') {
      if (this.invitedAccount.scountry.toString() === StateEnum.USA) {
        const flag = this.replaceZipCode(this.invitedAccount.szip, 'Shipping');
        if (flag) {
          this.invitedAccount.szip = '';
        }
      }

      if (this.invitedAccount.scountry.toString() === StateEnum.CANADA) {
        const flag = this.replaceCanadaZipCode(this.invitedAccount.szip, 'Shipping');
        if (flag) {
          this.invitedAccount.szip = '';
        }
      }
    }
  }

  public onBlurUserZipCode() {
    if (this.invitedAccount.azip !== undefined && this.invitedAccount.azip !== '') {
      if (this.invitedAccount.acountry.toString() === StateEnum.USA) {
        const flag = this.replaceZipCode(this.invitedAccount.azip, 'User');
        if (flag) {
          this.invitedAccount.azip = '';
        }
      }

      if (this.invitedAccount.acountry.toString() === StateEnum.CANADA) {
        const flag = this.replaceCanadaZipCode(this.invitedAccount.azip, 'User');
        if (flag) {
          this.invitedAccount.azip = '';
        }
      }
    }
  }

  // this method is used for masking validation for PhoneNumber
  public replacePhoneNumber(contactNumber: any) {
    const len = contactNumber.replace(/[^0-9]/g, '').length;

    if (len < 10) {
      return true;
    } else {
      return false;
    }
  }

  // This method is used for USA Zip Code masking
  public replaceZipCode(zipCode: any, zipName: string) {
    let temp = zipCode.trim();
    const len = zipCode.replace(/[^0-9]/g, '').length;

    if (len === 5) {
      temp = zipCode.replace(/[^0-9]/g, '').trim();
    } else {
      temp = zipCode.trim();
    }

    if (len === 0) {
      if (zipName === 'Billing') {
        this.zipBillingCodeMsg = false;
        this.invitedAccount.bzip = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCodeMsg = false;
        this.invitedAccount.szip = temp;
      } else if (zipName === 'User') {
        this.zipUserCodeMsg = false;
        this.invitedAccount.azip = temp;
      }

      return true;
    } else if (len < 5) {
      if (zipName === 'Billing') {
        this.zipBillingCodeMsg = true;
        this.invitedAccount.bzip = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCodeMsg = true;
        this.invitedAccount.szip = temp;
      } else if (zipName === 'User') {
        this.zipUserCodeMsg = true;
        this.invitedAccount.azip = temp;
      }
      return true;
    } else {
      if (zipName === 'Billing') {
        this.zipBillingCodeMsg = false;
        setTimeout(() => {
          this.invitedAccount.bzip = temp;
        }, 100);
      } else if (zipName === 'Shipping') {
        this.zipShippingCodeMsg = false;
        setTimeout(() => {
          this.invitedAccount.szip = temp;
        }, 100);
      } else if (zipName === 'User') {
        this.zipUserCodeMsg = false;
        setTimeout(() => {
          this.invitedAccount.azip = temp;
        }, 100);
      }
      return false;
    }
  }

  // This method is used for Canada Zip Code masking
  public replaceCanadaZipCode(zipCode: any, zipName: string) {
    const temp = zipCode.trim();
    const len = zipCode.replace(/[^0-9]/g, '').length;

    if (len === 0) {
      if (zipName === 'Billing') {
        this.zipBillingCanMsg = false;
        this.invitedAccount.bzip = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCanMsg = false;
        this.invitedAccount.szip = temp;
      } else if (zipName === 'User') {
        this.zipUserCanMsg = false;
        this.invitedAccount.azip = temp;
      }

      return true;
    } else if (len < 6) {
      if (zipName === 'Billing') {
        this.zipBillingCanMsg = true;
        this.invitedAccount.bzip = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCanMsg = true;
        this.invitedAccount.szip = temp;
      } else if (zipName === 'User') {
        this.zipUserCanMsg = true;
        this.invitedAccount.azip = temp;
      }
      return true;
    } else {
      if (zipName === 'Billing') {
        this.zipBillingCanMsg = false;
        this.invitedAccount.bzip = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCanMsg = false;
        this.invitedAccount.szip = temp;
      } else if (zipName === 'User') {
        this.zipUserCanMsg = false;
        this.invitedAccount.azip = temp;
      }
      return false;
    }
  }

  resetZipBillingCodeMsg(event: any) {
    this.invitedAccount.bzip = '';
    this.zipBillingCodeMsg = false;
    this.zipBillingCanMsg = false;
  }

  resetZipShippingCodeMsg(event: any) {
    this.invitedAccount.szip = '';
    this.zipShippingCodeMsg = false;
    this.zipShippingCanMsg = false;
  }

  resetZipUserCodeMsg(event: any) {
    this.invitedAccount.azip = '';
    this.zipUserCodeMsg = false;
    this.zipUserCanMsg = false;
  }

  public get companyNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('companyName');
  }

  public get aabbrevControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('aabbrev');
  }

  public get phoneNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('phoneNumber');
  }

  public get firstNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('firstName');
  }

  public get lastNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('lastName');
  }

  public get emailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('adminEmail');
  }

  public get biilingContactNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingContactName');
  }

  public get biilingStreetAddressControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingStreetAddress');
  }

  public get billingCityControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingCity');
  }

  public get billingZipCodeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingZipCode');
  }

  public get billingEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingEmail');
  }

  public get billingContactControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingPhone');
  }

  public get billingStateControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }
    return <UntypedFormControl>this.registrationGroup.get('billingState');
  }

  public get billingCountryControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }
    return <UntypedFormControl>this.registrationGroup.get('billingCountry');
  }

  public get shippingContactNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingContactName');
  }

  public get shippingStreetAddressControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingStreetAddress');
  }

  public get shippingCityControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingCity');
  }

  public get shippingStateControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingState');
  }

  public get shippingCountryControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingCountry');
  }

  public get showStoreSupportInfoControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('showStoreSupportInfo');
  }

  public get storeSupportNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('storeSupportNumber');
  }

  public get storeSupportEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('storeSupportEmail');
  }

  public get shippingZipCodeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingZipCode');
  }

  public get technicalContactEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('technicalContactEmail');
  }

  public get userSpecificEmailForCommunicationControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userSpecificEmails');
  }

  public get provideBadgeWithPhotoControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('provideBadgeWithPhoto');
  }

  public get estimatedUserControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('estimatedUser');
  }

  public get technicalContactNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('technicalPhone');
  }

  public get supportContactEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('supportContactEmail');
  }

  public get supportContactNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('supportPhone');
  }

  public get AuthTokenControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('authToken');
  }

  public get userZipCodeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userZipCode');
  }

  public get fieldGroupTypeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('fieldGroupType');
  }

  public get certificationControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('certification');
  }

  public get fileControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('files');
  }

  public get accountNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('accountNumber');
  }

  public get userMobileEnabledControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userMobileEnabled');
  }

  public get userEmployedControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userEmployed');
  }

  public redirectToAccountList() {
    this._router.navigate(['/registration/account-list']);
  }

  public fillFrom() {
    if (this.type === AccountIdType.AccountId || this.roleEnabled === true) {
      this.invitedAccount.showStoreSupportInfo = this.registrationGroup.get('showStoreSupportInfo').value;
      this.invitedAccount.storeSupportNumber = this.registrationGroup.get('storeSupportNumber').value;
      this.invitedAccount.storeSupportEmail = this.registrationGroup.get('storeSupportEmail').value;
    }

    this.invitedAccount.invitedCompany = this.registrationGroup.get('companyName').value.toString().trim();
    this.invitedAccount.aname = this.registrationGroup.get('companyName').value.toString().trim();
    this.invitedAccount.accountNumber = this.registrationGroup.get('accountNumber').value;
    if (this.type === AccountIdType.AccountId)
      this.invitedAccount.aabbrev =
        this.registrationGroup.get('aabbrev').value === '' ? null : this.registrationGroup.get('aabbrev').value;

    this.invitedAccount.number = this.registrationGroup.get('phoneNumber').value;

    this.invitedAccount.fName =
      this.registrationGroup.get('firstName').value === '' ? null : this.registrationGroup.get('firstName').value;

    this.invitedAccount.lName =
      this.registrationGroup.get('lastName').value === '' ? null : this.registrationGroup.get('lastName').value;

    this.invitedAccount.email1 =
      this.registrationGroup.get('adminEmail').value === '' ? null : this.registrationGroup.get('adminEmail').value;

    if (this.showAnote) {
      this.invitedAccount.aNote =
        this.registrationGroup.get('empNotes').value === '' ? null : this.registrationGroup.get('empNotes').value;
    } else {
      this.invitedAccount.aNote = this.invitedAccount.aNote;
    }

    this.invitedAccount.invitedAccountId = this.invitedAccount.invitedAccountId;

    this.invitedAccount.billAttnToFname = this.registrationGroup.get('billingContactName').value;
    this.invitedAccount.billingEmail = this.registrationGroup.get('billingEmail').value;
    this.invitedAccount.bphone = this.registrationGroup.get('billingPhone').value;
    this.invitedAccount.baddress1 = this.registrationGroup.get('billingStreetAddress').value;
    this.invitedAccount.baddress2 = this.registrationGroup.get('billingStreetAddress2').value;
    this.invitedAccount.bcity = this.registrationGroup.get('billingCity').value;
    this.invitedAccount.bstate = this.registrationGroup.get('billingState').value;
    this.invitedAccount.bcountry = this.registrationGroup.get('billingCountry').value;
    this.invitedAccount.bzip = this.registrationGroup.get('billingZipCode').value;

    this.invitedAccount.printBadge = this.registrationGroup.get('printBadge').value;
    this.invitedAccount.badgeShipping = this.registrationGroup.get('badgeShipping').value;

    this.invitedAccount.shipAttnTo = this.registrationGroup.get('shippingContactName').value;
    this.invitedAccount.saddress1 = this.registrationGroup.get('shippingStreetAddress').value;
    this.invitedAccount.saddress2 = this.registrationGroup.get('shippingStreetAddress2').value;
    this.invitedAccount.scity = this.registrationGroup.get('shippingCity').value;
    this.invitedAccount.sstate = this.registrationGroup.get('shippingState').value;
    this.invitedAccount.scountry = this.registrationGroup.get('shippingCountry').value;
    this.invitedAccount.szip = this.registrationGroup.get('shippingZipCode').value;

    this.invitedAccount.itcontactEmail = this.registrationGroup.get('technicalContactEmail').value;
    this.invitedAccount.supportEmail = this.registrationGroup.get('supportContactEmail').value;
    this.invitedAccount.itcontactPhone = this.registrationGroup.get('technicalPhone').value;
    this.invitedAccount.supportPhone = this.registrationGroup.get('supportPhone').value;
    this.invitedAccount.supportNote = this.registrationGroup.get('supportContactNote').value;

    this.invitedAccount.estimatedUsers = this.registrationGroup.get('estimatedUser').value;
    this.invitedAccount.userSpecificEmailsForCommunication = this.registrationGroup.get('userSpecificEmails').value;
    this.invitedAccount.provideBadgeWithPhoto = this.registrationGroup.get('provideBadgeWithPhoto').value;
    this.invitedAccount.uDirectlyEmployed = this.registrationGroup.get('userEmployed').value;
    this.invitedAccount.uMobileEnabled = this.registrationGroup.get('userMobileEnabled').value;
    this.invitedAccount.subscriptionType = this.registrationGroup.get('subscriptionType').value || null;

    this.invitedAccount.aaddress1 =
      this.registrationGroup.get('userAddressLine1').value === ''
        ? null
        : this.registrationGroup.get('userAddressLine1').value;

    this.invitedAccount.aaddress2 =
      this.registrationGroup.get('userAddressLine2').value === ''
        ? null
        : this.registrationGroup.get('userAddressLine2').value;

    this.invitedAccount.acity =
      this.registrationGroup.get('userCity').value === '' ? null : this.registrationGroup.get('userCity').value;

    this.invitedAccount.astate =
      this.registrationGroup.get('userState').value === '' ? null : this.registrationGroup.get('userState').value;

    this.invitedAccount.acountry =
      this.registrationGroup.get('userCountry').value === '' ? null : this.registrationGroup.get('userCountry').value;

    this.invitedAccount.azip =
      this.registrationGroup.get('userZipCode').value === '' ? null : this.registrationGroup.get('userZipCode').value;

    this.invitedAccount.awebsite =
      this.registrationGroup.get('userCorporateWebsite').value === ''
        ? null
        : this.registrationGroup.get('userCorporateWebsite').value;

    this.invitedAccount.fieldGroupType =
      this.registrationGroup.get('fieldGroupType').value === ''
        ? null
        : this.registrationGroup.get('fieldGroupType').value;

    if (this.type === AccountIdType.AccountId) {
      this.invitedAccount.primaryAdminId = this.registrationGroup.get('primaryAdmin').value;
      this.invitedAccount.statusId = this.registrationGroup.get('status').value;
    } else {
      this.invitedAccount.statusId = this.invitedAccount.statusId;
      this.invitedAccount.invitedCourse = this.registrationGroup.get('certification').value;
    }

    this.invitedAccount.selectedApps = this.selectedApps.join(',');
    this.invitedAccount.selectedCertifications = this.selectedCertifications.join(',');

    this.invitedAccount.selectedProducts = this.selectedProducts.join(',');
    this.invitedAccount.selectedRetailers = this.selectedRetailers.join(',');
    this.invitedAccount.authToken = this.invitedAccount.authToken;
    this.invitedAccount.retailLink = this.invitedAccount.retailLink;
    this.invitedAccount.shipDirectToRep = 0;
  }

  private initForm() {
    this.fileCtrl.valueChanges.subscribe(val => {
      this.files = val ? val : [];
    });

    this.registrationGroup = this._fb.group({
      companyName: this._fb.control('', Validators.required),
      accountNumber: this._fb.control(''),
      aabbrev: this._fb.control('', Validators.required),
      empNotes: this._fb.control(''),
      authToken: this._fb.control(''),
      phoneNumber: this._fb.control('', Validators.required),
      firstName: this._fb.control('', Validators.required),
      lastName: this._fb.control('', Validators.required),
      adminEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      billingContactName: this._fb.control('', Validators.required),
      billingEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      billingStreetAddress: this._fb.control('', Validators.required),
      billingCity: this._fb.control('', Validators.required),
      billingZipCode: this._fb.control('', Validators.required),
      billingPhone: this._fb.control('', Validators.required),
      billingStreetAddress2: this._fb.control(''),
      billingState: this._fb.control('', Validators.required),
      billingCountry: this._fb.control('', Validators.required),
      printBadge: this._fb.control(''),
      shippingContactName: this._fb.control('', Validators.required),
      shippingStreetAddress: this._fb.control('', Validators.required),
      shippingCity: this._fb.control('', Validators.required),
      shippingZipCode: this._fb.control('', Validators.required),
      shippingStreetAddress2: this._fb.control(''),

      showStoreSupportInfo: this._fb.control(''),
      storeSupportNumber: this._fb.control(''),
      storeSupportEmail: this._fb.control('', Validators.compose([Validators.email])),

      shippingState: this._fb.control('', Validators.required),
      shippingCountry: this._fb.control('', Validators.required),
      technicalContactEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      supportContactEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      technicalPhone: this._fb.control('', Validators.required),
      supportPhone: this._fb.control('', Validators.required),
      supportContactNote: this._fb.control(''),
      estimatedUser: this._fb.control('', Validators.required),
      userSpecificEmails: this._fb.control(null, Validators.required),
      provideBadgeWithPhoto: this._fb.control(null, Validators.required),
      userEmployed: this._fb.control('', Validators.required),
      userMobileEnabled: this._fb.control('', Validators.required),

      userAddressLine1: this._fb.control(''),
      userAddressLine2: this._fb.control(''),
      userCity: this._fb.control(''),
      userState: this._fb.control(''),
      userCountry: this._fb.control(''),
      userZipCode: this._fb.control(''),
      fieldGroupType: this._fb.control('', Validators.required),
      certification: this._fb.control('', Validators.required),
      primaryAdmin: this._fb.control(''),
      status: this._fb.control(''),
      isSeasonal: this._fb.control(''),
      userCorporateWebsite: this._fb.control(''),
      badgeShipping: this._fb.control(''),
      subscriptionType: this._fb.control(''),

      files: this.fileCtrl,
    });
  }

  private initInviteSupplierForm() {
    this.inviteSupplierGroup = this._fb.group({
      invitedCompany: this._fb.control('', Validators.required),
      invitedEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      invitedName: this._fb.control('', Validators.required),
      invitedNotes: this._fb.control(''),
      empNotes: this._fb.control(''),
      invitedAccountNumber: this._fb.control(''),
      fieldGroupType: this._fb.control(''),
      certification: this._fb.control(''),
    });
  }

  public getRetailerData(retailerData) {
    this.selectedRetailers = retailerData;
    this.invitedAccount.selectedRetailers = retailerData.join(',');
  }

  public getAppListData(appData) {
    this.selectedApps = appData;
    this.invitedAccount.selectedApps = appData.join(',');
  }

  public getTagListData(tagData) {
    this.invitedAccount.selectedAccountTags = tagData.join(',');
  }

  public accountAppLinkModal() {
    this._dynamicDialogService.open(ApplicationLinkModalComponent, {
      data: {
        name: 'Application Links(Deep Links)',
        accountId: this.invitedAccount.accountId,
        tempEncryptId: this.tempEncryptId,
      },
    });
  }

  public getCertificationData(certificationData) {
    this.selectedCertifications = certificationData; // this is used to allow multiselect the certifications
    this.invitedAccount.selectedCertifications = certificationData.join(','); // used to send certicate to api to save
  }

  public getProductData(productData) {
    this.selectedProducts = productData;
    this.invitedAccount.selectedProducts = productData.join(',');
  }

  public removeValidation() {
    const control: UntypedFormControl = new UntypedFormControl('', Validators.nullValidator);
    const control1: UntypedFormControl = new UntypedFormControl('', Validators.nullValidator);
    const control3: UntypedFormControl = new UntypedFormControl('', Validators.nullValidator);
    this.registrationGroup.setControl('fieldGroupType', control);
    this.registrationGroup.setControl('certification', control1);
    this.registrationGroup.setControl('phoneNumber', control3);
  }

  public applyRequired() {
    const control: UntypedFormControl = new UntypedFormControl('', Validators.required);
    this.registrationGroup.setControl('accountNumber', control);
  }

  public checkControlValidation() {
    this.invalidControl = [];
    const status = this.checkstatus;
    this.CheckValidationForStatus(status);
    if (this.invalidControl.length > 0) {
      const modalRef = this._dynamicDialogService.open(RequiredModalComponent, {
        data: {
          requiredfield: this.invalidControl,
        },
      });
      return false;
    }
    return true;
  }

  // this is used to check the required field control are filled or not and valid or not
  public CheckValidationForStatus(status: number) {
    if (this.type === AccountIdType.AccountId || status === 2) {
      if (status === 2) {
        if (!this.registrationGroup.get('accountNumber').valid) {
          this.invalidControl.push(`${this.retailerConfig.retailer} Supplier Number`);
        } else if (this.vendorIdMsg === true) {
          this.invalidControl.push(`${this.retailerConfig.retailer} Supplier Number Already Exists`);
        }
      }

      if (!this.registrationGroup.get('estimatedUser').valid) {
        this.invalidControl.push('Estimated # Users');
      }

      if (!this.registrationGroup.get('userSpecificEmails').valid) {
        this.invalidControl.push(
          'Do you utilize user-specific emails they can use for communications from Volt such as password resets?',
        );
      }

      if (!this.registrationGroup.get('provideBadgeWithPhoto').valid) {
        this.invalidControl.push('Do you provide your employees with a badge that contains a picture of the user?');
      }

      if (!this.registrationGroup.get('userEmployed').valid) {
        this.invalidControl.push('Are Users Directly Employed?');
      }
      if (!this.registrationGroup.get('userMobileEnabled').valid) {
        this.invalidControl.push('Are Users Mobile Enabled?');
      }

      // }

      // tslint:disable-next-line:max-line-length
      if (this.selectedRetailers.length === 0) {
        this.invalidControl.push('Select atleast one Retailer');
      }
      if (this.selectedCertifications.length === 0 && this.data.fieldGroupType?.toLocaleLowerCase() != 'retailer') {
        this.invalidControl.push('Select atleast one Certification');
      }
      if (this.selectedProducts.length === 0 && this.data.fieldGroupType?.toLocaleLowerCase() != 'retailer') {
        this.invalidControl.push('Select atleast one Product');
      }
      if (this.selectedApps.length === 0) {
        this.invalidControl.push('Select atleast one App');
      }
    }
    if (this.type !== AccountIdType.AccountId) {
      if (status !== 2) {
        if (!this.registrationGroup.get('certification').valid) {
          this.invalidControl.push('Certification.');
        }
      }

      if (!this.registrationGroup.get('fieldGroupType').valid) {
        this.invalidControl.push('Field Group Type.');
      }
      if (!this.registrationGroup.get('phoneNumber').valid) {
        this.invalidControl.push('Phone Number');
      }

      if (!this.registrationGroup.get('estimatedUser').valid) {
        this.invalidControl.push('Estimated # Users');
      }

      if (!this.registrationGroup.get('userSpecificEmails').valid) {
        this.invalidControl.push(
          'Do you utilize user-specific emails they can use for communications from Volt such as password resets?',
        );
      }

      if (!this.registrationGroup.get('provideBadgeWithPhoto').valid) {
        this.invalidControl.push('Do you provide your employees with a badge that contains a picture of the user?');
      }

      if (!this.registrationGroup.get('userEmployed').valid) {
        this.invalidControl.push('Are Users Directly Employed?');
      }
      if (!this.registrationGroup.get('userMobileEnabled').valid) {
        this.invalidControl.push('Are Users Mobile Enabled?');
      }
    }

    if (this.enableApproval) {
      if (!this.registrationGroup.get('firstName').valid) {
        this.invalidControl.push('Admin First Name');
      }

      if (!this.registrationGroup.get('lastName').valid) {
        this.invalidControl.push('Admin Last Name');
      }

      if (!this.registrationGroup.get('adminEmail').valid) {
        this.invalidControl.push('Admin Email.');
      }

      if (!this.registrationGroup.get('companyName').valid) {
        this.invalidControl.push('Company Name');
      }
    }

    if (!this.registrationGroup.get('aabbrev').valid) {
      this.invalidControl.push('Abbreviation.');
    }

    if (!this.registrationGroup.get('billingEmail').valid) {
      this.invalidControl.push('Billing Email');
    }
    if (!this.registrationGroup.get('billingContactName').valid) {
      this.invalidControl.push('Billing Contact Name');
    }

    if (!this.registrationGroup.get('billingStreetAddress').valid) {
      this.invalidControl.push('Billing Street Address');
    }
    if (!this.registrationGroup.get('billingCity').valid) {
      this.invalidControl.push('Billing City');
    }
    if (!this.registrationGroup.get('billingZipCode').valid) {
      this.invalidControl.push('Billing ZipCode');
    }

    if (!this.registrationGroup.get('billingPhone').valid) {
      this.invalidControl.push('Billing Phone');
    }

    if (!this.registrationGroup.get('billingState').valid) {
      this.invalidControl.push('Billing State');
    }

    if (!this.registrationGroup.get('billingCountry').valid) {
      this.invalidControl.push('Billing Country');
    }
    if (!this.registrationGroup.get('shippingContactName').valid) {
      this.invalidControl.push('Shipping Contact Name');
    }
    if (!this.registrationGroup.get('shippingStreetAddress').valid) {
      this.invalidControl.push('Shipping Street Address');
    }

    if (!this.registrationGroup.get('shippingCity').valid) {
      this.invalidControl.push('Shipping City');
    }
    if (!this.registrationGroup.get('shippingZipCode').valid) {
      this.invalidControl.push('Shipping ZipCode.');
    }

    if (!this.registrationGroup.get('shippingState').valid) {
      this.invalidControl.push('Shipping State');
    }
    if (!this.registrationGroup.get('shippingCountry').valid) {
      this.invalidControl.push('Shipping Country.');
    }
    if (!this.registrationGroup.get('technicalContactEmail').valid) {
      this.invalidControl.push('Technical Email');
    }
    if (!this.registrationGroup.get('supportContactEmail').valid) {
      this.invalidControl.push('Support Contact Email.');
    }

    if (!this.registrationGroup.get('technicalPhone').valid) {
      this.invalidControl.push('Technical Phone.');
    }

    if (!this.registrationGroup.get('supportPhone').valid) {
      this.invalidControl.push('Support Phone.');
    }
  }

  public checkShowHideValidation(): string {
    if (
      this.type === AccountIdType.AccountId &&
      (this.checkstatus === Status.Active || this.checkstatus === Status.Inactive)
    ) {
      return '';
    } else if (this.type === AccountIdType.InvitedAccountId && this.checkstatus === Status.Pending) {
      return '';
    } else return 'display-none';
  }

  public checkCertification(): string {
    if (
      this.type === AccountIdType.AccountId &&
      (this.checkstatus === Status.Active || this.checkstatus === Status.Inactive)
    ) {
      return 'display-none';
    } else if (this.type === AccountIdType.InvitedAccountId && this.checkstatus === Status.Pending) {
      return 'display-none';
    } else return '';
  }

  public checkDeepLink(): string {
    if (this.type === AccountIdType.AccountId && this.checkstatus !== Status.Invited) {
      return '';
    } else return 'display-none';
  }

  public checkInviteSupplier(): string {
    if (this.invitedAccount.statusId === Status.Invited) {
      return '';
    } else return 'display-none';
  }

  public checkNotInviteSupplier(): string {
    if (this.invitedAccount.statusId !== Status.Invited) {
      return '';
    } else return 'display-none';
  }

  // this is used to check the vendor id already exist or not in database
  public checkVendorExist() {
    this.vendorIDExist = '';
    if (
      this.invitedAccount.accountNumber == undefined ||
      this.invitedAccount.accountNumber === '' ||
      this.invitedAccount.accountNumber == null
    )
      return (this.vendorIDExist = '');
    if (this.invitedAccount.accountNumber)
      this._invitedAccountService.GetSupplierByNo(this.invitedAccount.accountNumber).subscribe(
        (data: any) => {
          if (data != null) {
            this.vendorIDExist = data.supplierName;
          }
        },
        (err: any) => {
          console.error(err);
          this.monitoringService.logApiException(err);
        },
      );

    this.checkAccountNoAlreadyExist();
  }

  // this is used to check AccountNumber is already exist or not in database
  public checkAccountNoAlreadyExist() {
    const accountNumber = this.invitedAccount.accountNumber;
    const id = this.invitedAccount.invitedAccountId;
    if (id == undefined || id == null || id === 0) return (this.vendorIdMsg = false);

    if (accountNumber === '' || accountNumber == undefined || accountNumber == null) return (this.vendorIdMsg = false);

    this._invitedAccountService.checkAccountNoAlreadyExist(accountNumber, id).subscribe(
      (data: any) => {
        this.vendorIdExist = data;

        if (data === true) {
          this.vendorIdMsg = true;
        } else {
          this.vendorIdMsg = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public onClickImage(imageUrl: string) {
    return this._dynamicDialogService
      .open(ImageEditDialogComponent, {
        closeOnEscape: true,
        data: { title: 'Edit Company Logo', imageUrl },
      })
      .pipe(take(1))
      .subscribe(canvas => {
        if (canvas) {
          this.loading = true;

          canvas.toBlob((blob: Blob) => {
            this._invitedAccountClient
              .registerFileUpload({
                fileName: 'image.png',
                data: blob,
              })
              .pipe(
                finalize(() => (this.loading = false)),
                catchError(err => {
                  console.error(err);
                  this.monitoringService.logApiException(err);
                  return null;
                }),
              )
              .subscribe((response: UploadResponse) => {
                if (response && response.data) {
                  this.invitedAccount.aimageUrl = response.data;
                  this.files = [];
                }
              });
          });
        }
      });
  }
}
