export const pieChartColors = [
  '#0071ce',
  '#ffc220',
  '#041e42',
  '#de1c24',
  '#fff200',
  '#fa6400',
  '#5fb446',
  '#1D97FC',
  '#28790F',
  '#6fac32',
  '#f6a602',
];
