/**
 * @file tenant-id.service.ts
 * @description {@link https://jira.walmart.com/browse/WDE-6256}
 * The service to provide the `tenant-id` value for TenantIdInterceptor to use.
 * As per {@link https://jira.walmart.com/browse/WDE-6036}, the value is a hard-coded
 * value of tenant-code US which is dependent on the environment (dev/prod/etc) for the time being.
 * The values can be retrieved from {@link https://identity.tenant.dev.walmart.com/v1/identity/tenants}
 * {@link https://identity.tenant.staging.walmart.com/v1/identity/tenants}
 * {@link https://identity.tenant.prod.walmart.com/v1/identity/tenants}
 */

import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";

/**
 * The hard-coded value provided in main.ts for the time being
 */
export const TenantIdValue = new InjectionToken<String>('TenantIdValue');


/**
 * The service to provide the tenant id value to TenantIdInterceptor
 * The service is here to support possible dynamic tenant id value in the future.
 */
@Injectable({
    providedIn: 'root'
})
export class TenantIdService {
    private _tenantId = '';
    constructor(
        @Optional() @Inject(TenantIdValue) _hardcodeValue: string
    ) {
        if(_hardcodeValue)
            this._tenantId = _hardcodeValue;
     }
    get tenantId() {
        return this._tenantId;
    }
}
