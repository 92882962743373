import { AddServiceDeploymentTaskFilterActions } from '../actions/servicedeployment.constants';
import { IAction } from '../../store/actions/tile.actions';
import { Reducer } from 'redux';
import { AddServiceTask } from '../models/addServiceTask';

export const AddServiceDeploymentTaskReducer: Reducer<AddServiceTask> = (
  state: AddServiceTask = new AddServiceTask(),
  action: IAction<AddServiceTask>,
) => {
  const params = <AddServiceTask>action.payload;
  switch (action.type) {
    case AddServiceDeploymentTaskFilterActions.SetFilter: {
      const newstate = Object.assign({}, state, params);
      return newstate;
    }
    default: {
      return state;
    }
  }
};
