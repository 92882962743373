import { Subject, Subscriber, Subscription, SubscriptionLike } from 'rxjs';
import { SetStateArgs, SetStateFn } from '../types';

export class StateSubject<T> extends Subject<T> {
  private _state: T;

  constructor(initialState: T | (() => T)) {
    super();
    if (typeof initialState === 'function') {
      this._state = (initialState as () => T)();
    } else {
      this._state = initialState;
    }
  }

  get value(): T {
    return this.getValue();
  }

  /** @deprecated This is an internal implementation detail, do not use. */
  _subscribe(subscriber: Subscriber<T>): Subscription {
    const subscription = super._subscribe(subscriber);
    if (subscription && !(<SubscriptionLike>subscription).closed) {
      subscriber.next(this._state);
    }
    return subscription;
  }

  getValue(): T {
    if (this.hasError) {
      throw this.thrownError;
    } else if (this.closed) {
      throw this.thrownError;
    } else {
      return this._state;
    }
  }

  next(value: T): void {
    super.next((this._state = value));
  }

  setState(arg: SetStateArgs<T>): void {
    let updatedState: T;

    if (typeof arg === 'function') {
      updatedState = (arg as SetStateFn<T>)(this.value);
    } else {
      updatedState = arg;
    }

    if (updatedState !== undefined && updatedState !== this.value) {
      super.next((this._state = updatedState));
    }
  }
}
