import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ImageFlowBlobClient } from '@volt/api-blob';
import { BlobService } from '@volt/shared/services/blob.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { APPLICATION_CONFIGURATION, ApplicationConfiguration } from 'src/configuration';
@Pipe({
  name: 'imageFlow',
})
export class ImageFlowPipe implements PipeTransform {
  constructor(private readonly _blobService: BlobService, private readonly _imageFlowService: ImageFlowBlobClient,
  @Inject(APPLICATION_CONFIGURATION) private _appConfig: ApplicationConfiguration
  ) {}
  /**
   * @description returns observable of imageflow encoded url appending imageflow transformation
   * @param url is a path to a blob ex: {container}/{path}/{file}
   * @param transforms is image flow styling ex: h=25
  */
  transform(url: string, transforms: string): Observable<string> {
    if (!this._blobService.referencesVoltBlob(url)) {
      return of(url);
    }
    const blobPath = `${this._appConfig.blobUrl}/`
    if(url.startsWith(blobPath)) {
      url = url.replace(blobPath, "")
    }
    if(url){
      // Extracting container from url
      const container = url.match(/[a-zA-Z1-9]*/)[0];
      // per Microsoft, container names must be lower case
      const lowered = container.toLowerCase();
      // format our URL so the container name is lowercase
      const loweredUrl = url.replace(container, lowered);
      // generating Regex for targeting {container}/$path from getContainerSasTokenWithRefresh output
      const regex = new RegExp(lowered + "/\\$path");

      return  this._blobService.getContainerSasTokenWithRefresh(lowered).pipe(
        // encoding full blob path
        switchMap((result) => this._imageFlowService.getImageFlowEncodedUrl(result.replace(regex, loweredUrl))),
        map((imageFlowUrl)=>`${imageFlowUrl.imageFlowUrl}?${transforms}` ))
    }
    else return of(null);
  }
}
