import { Injectable } from "@angular/core";
import { ActivityClient, PagedFilterOfActivityFilterParams, ActivityFilterParams as ApiActivityFilterParams } from '@volt/api';
import { SharedTableState } from "@volt/shared/components/table/models/shared-table-state";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ActivityAggregateFilterParams } from "../models/activity-aggregate-filter-params";
import { ActivityFilterParams } from "../models/activity-filter-params";

@Injectable()
export class ActivityService {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isAggregateLoading$ = new BehaviorSubject<boolean>(false);

  get isLoading$(): Observable<boolean> {
    return this._isLoading$;
  }

  get isAggregateLoading$(): Observable<boolean> {
    return this._isAggregateLoading$;
  }

  constructor(private _activityClient: ActivityClient) {}

  getActivities(tableState: SharedTableState, filters: ActivityFilterParams) {
    this._isLoading$.next(true);

    return this._activityClient
      .getFilteredActivityList(new PagedFilterOfActivityFilterParams({
        limit: tableState.limit,
        offset: tableState.offset,
        filter: new ApiActivityFilterParams({
          filterText: filters.filterText,
          status: filters.status,
          sortBy: tableState.sortBy,
          sortAscending: tableState.sortAscending
        })
      }))
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
        }),
      );
  }

  getAggregateSummary(filters: ActivityAggregateFilterParams) {
    this._isAggregateLoading$.next(true);
    return this._activityClient.getActivityTaskSummary(
      undefined,
      filters.activities,
      moment(filters.startDate).startOf('day'),
      moment(filters.endDate).startOf('day')
    )
      .pipe(
        finalize(() => {
          this._isAggregateLoading$.next(false);
        })
      )
  }

}
