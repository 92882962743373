import { Reducer } from 'redux';
import { IAction } from '../../store/actions/tile.actions';
import { ServiceDeploymentAndServiceTaskParams } from '../models/servicedeployment-and-servicetaskparams';
import { ServiceDeploymentFilterActions } from '../actions/servicedeployment.constants';

// tslint:disable-next-line:max-line-length
export const ServiceDeploymentReducer: Reducer<ServiceDeploymentAndServiceTaskParams> = (
  state: ServiceDeploymentAndServiceTaskParams = new ServiceDeploymentAndServiceTaskParams(),
  action: IAction<ServiceDeploymentAndServiceTaskParams>,
) => {
  const params = <ServiceDeploymentAndServiceTaskParams>action.payload;
  switch (action.type) {
    case ServiceDeploymentFilterActions.SetFilter: {
      const newstate = Object.assign({}, state, params);
      return newstate;
    }
    default: {
      return state;
    }
  }
};
