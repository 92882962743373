import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { OperatorUtils } from '@volt/shared/utils/operator.utils';
import { DataPassingService } from '../../../services/data-passing.service';
import { StatusClient } from '@volt/api';
import { ServiceDeploymentsClient } from '@volt/api';

@Component({
  selector: 'advanced-search-filer',
  templateUrl: 'advanced-search.component.html',
  styleUrls: ['../../../../shared/components/modal/modal.scss'],
})
export class AdvancedSearchComponent extends DynamicDialogBaseComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() advancedSearchFilters: any;

  public status$: Observable<SelectItem[]>;
  public serviceModels$: Observable<SelectItem[]>;

  constructor(
    private readonly dataPassingService: DataPassingService,
    private readonly statusClient: StatusClient,
    private readonly serviceDeploymentsClient: ServiceDeploymentsClient,
    private readonly advancedSearchDialogRef: DynamicDialogRef,
    private readonly advancedSearchDialogConfig: DynamicDialogConfig,
  ) {
    super(advancedSearchDialogRef, advancedSearchDialogConfig);
  }

  ngOnInit(): void {
    const { form, advancedSearchFilters } = this.advancedSearchDialogConfig.data;
    this.form = form;
    this.advancedSearchFilters = advancedSearchFilters;
    this.status$ = this.statusClient.getStatus().pipe(
      CommonUtils.mapToSelectItem(
        'Select Status',
        null,
        item => item.statusType,
        item => item.statusType,
      ),
      OperatorUtils.logAndRethrowError(),
    );

    this.serviceModels$ = this.serviceDeploymentsClient.getServiceModelTypes().pipe(
      CommonUtils.mapToSelectItem(
        'Select ServiceModel',
        null,
        item => item,
        item => item,
      ),
      OperatorUtils.logAndRethrowError(),
    );
  }

  public onClose() {
    this.advancedSearchDialogRef.close();
  }

  public onSubmit() {
    const advancedSearchParams = {};
    this.advancedSearchFilters.forEach(column => {
      advancedSearchParams[column.key + 'Search'] = this.form.get(column.key).value.trim();
    });
    this.advancedSearchDialogRef.close(true);
    this.dataPassingService.passAdvancedSearchParams(advancedSearchParams);
  }
}
