import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { AddServiceDeploymentTaskFilterActions } from '../../../actions/servicedeployment.constants';

@Injectable()
export class AddServicedeploymentTaskReduxService {
  constructor() {}
  public setFilter(filter: object) {
    this._setFilter(filter);
  }

  @dispatch()
  private _setFilter = (filter: object) => ({
    type: AddServiceDeploymentTaskFilterActions.SetFilter,
    payload: filter,
  });
}
