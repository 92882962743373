<div class="login-container">
  <div class="login-box">
    <div class="login-body">
      <div class="alert alert-danger">This password reset link has expired. Please request a new password reset.</div>
      <div class="text-center">
        <a [routerLink]="['/forgot-password', emailOrMobileValue]">Reset Password</a>
      </div>
    </div>
  </div>
</div>
