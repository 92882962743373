import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AccountAppLinkModel, DropDownModel } from '../../../../api.client';
import { AccountViewService } from '../../../services/accountview.service';
import { MonitoringService } from '../../../../shared/services/monitoring.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogBaseComponent } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog-base.component';

@Component({
  selector: 'app-link-modal',
  templateUrl: './application-link-modal.component.html',
  styleUrls: ['./application-link-modal.component.scss'],
})
export class ApplicationLinkModalComponent extends DynamicDialogBaseComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public name: string;
  public accountId: number;
  public tempEncryptId: string;
  public accountAppLinkdata: AccountAppLinkModel[] = [];

  public appLinkGroup: UntypedFormGroup;
  public linkTypeDropModel: DropDownModel[];
  public accountAppLinkModel = new AccountAppLinkModel();
  public loading = false;

  constructor(
    private readonly _dialogReference: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    private _accountViewService: AccountViewService,
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private readonly monitoringService: MonitoringService,
  ) {
    super(_dialogReference, _dialogConfig)
      this.name = this._dialogConfig.data.name,
      this.accountId = this._dialogConfig.data.accountId,
      this.tempEncryptId = this._dialogConfig.data.tempEncryptId,
      this.accountAppLinkdata = this._dialogConfig.data.accountAppLinkdata
  }

  ngOnInit() {
    this.initForm();
    this.getlinkTypeDropDownList();
    this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this._dialogReference.close(false));

    if (this.accountAppLinkdata) {
      this.accountAppLinkModel.activity = this.accountAppLinkdata[0].activity;
      this.accountAppLinkModel.note = this.accountAppLinkdata[0].note;
      this.accountAppLinkModel.linkType = this.accountAppLinkdata[0].linkType;
      this.accountAppLinkModel.link = this.accountAppLinkdata[0].link;
    }
  }

  public submitAppLink() {
    this.loading = true;
    const accountAppLinkModel = new AccountAppLinkModel();

    if (this.accountAppLinkdata) {
      accountAppLinkModel.id = this.accountAppLinkdata[0].id;
      accountAppLinkModel.activity = this.appLinkGroup.get('activityName').value;
      accountAppLinkModel.note = this.appLinkGroup.get('noteField').value;
      accountAppLinkModel.linkType = this.appLinkGroup.get('linkType').value;
      accountAppLinkModel.link = this.appLinkGroup.get('link').value;
      accountAppLinkModel.accountId = this.accountAppLinkdata[0].accountId;
    } else {
      accountAppLinkModel.id = 0;
      accountAppLinkModel.activity = this.appLinkGroup.get('activityName').value;
      accountAppLinkModel.note = this.appLinkGroup.get('noteField').value;
      accountAppLinkModel.linkType = this.appLinkGroup.get('linkType').value;
      accountAppLinkModel.link = this.appLinkGroup.get('link').value;
      accountAppLinkModel.accountId = this.accountId;
    }

    this._accountViewService.saveAccountAppLink(accountAppLinkModel).subscribe(
      response => {
        if (response) {
          this.loading = false;
          this.appLinkGroup.reset();
          this._accountViewService.getAppsLinkAccountId(this.tempEncryptId);
          this._dialogReference.close(false);
        }
      },
      err => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public formReset() {
    this.appLinkGroup.reset();
    this._dialogReference.close(false);
  }

  public get activityNameControl(): UntypedFormControl {
    if (!this.appLinkGroup) {
      return;
    }

    return <UntypedFormControl>this.appLinkGroup.get('activityName');
  }

  public get noteFieldControl(): UntypedFormControl {
    if (!this.appLinkGroup) {
      return;
    }

    return <UntypedFormControl>this.appLinkGroup.get('noteField');
  }

  public get linkTypeControl(): UntypedFormControl {
    if (!this.appLinkGroup) {
      return;
    }

    return <UntypedFormControl>this.appLinkGroup.get('linkType');
  }

  public get linkControl(): UntypedFormControl {
    if (!this.appLinkGroup) {
      return;
    }

    return <UntypedFormControl>this.appLinkGroup.get('link');
  }

  public getlinkTypeDropDownList() {
    this._accountViewService.getLinkTypeDropDownList().subscribe(
      (data: any) => {
        this.linkTypeDropModel = data;
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  private initForm() {
    this.appLinkGroup = this._fb.group({
      activityName: this._fb.control('', Validators.required),
      noteField: this._fb.control('', Validators.required),
      linkType: this._fb.control('', Validators.required),
      link: this._fb.control('', Validators.required),
    });
  }

  public submit() {
    this.submitAppLink();
    this._dialogReference.close(false);
  }

  public cancel() {
    this._dialogReference.close(false);
  }
}
