<p-toolbar>
  <div class="v-filter-grid">

    <v-search-field
      [label]="translations.search"
      [control]="controls.filterText"
    ></v-search-field>
  
    <div>
      <label class="w-100" for="status-filter">{{translations.status}}</label>
      <p-dropdown
        [options]="statusOptions"
        inputId="status-filter"
        styleClass="w-100"
        [formControl]="controls.status"
      ></p-dropdown>
    </div>

  </div>
  
  <div class="filter-footer">
    <button pButton class="btn-primary p-button-sm" 
            [label]="translations.clearFilters"
            (click)="clearFilters()"
    ></button>
  </div>
  
</p-toolbar>

