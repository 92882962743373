import { compose } from 'redux';
import { IAppState } from '../store';

export const createFeatureSelector: <K extends keyof IAppState>(key: K) => (state: IAppState) => IAppState[K] = <
  K extends keyof IAppState
>(
  key: K,
) => {
  return (state: IAppState) => state[key];
};

export const createSelector = <T, K, R>(
  rootSelector: (root: T) => K,
  selector: (state: ReturnType<typeof rootSelector>) => R,
) => {
  return compose(selector, rootSelector);
};
