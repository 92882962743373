import { Injectable } from '@angular/core';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { Observable } from 'rxjs';
import {
  GenericTableReportOfServiceDeploymentTaskLocationViewModel,
  ServiceTaskLocationRetrievalClient,
  UserClient,
  TaskLocationClient,
  LocationsClient,
} from '@volt/api';

@Injectable({
  providedIn: 'root',
})
export class ServicedeploymentTaskLocationService {
  constructor(
    private _serviceDeploymentTaskLocationClient: ServiceTaskLocationRetrievalClient,
    private _userClient: UserClient,
    private _taskLocationClient: TaskLocationClient,
    private _locationClient: LocationsClient,
  ) {}

  public getServiceDeploymentTaskLocation(
    params: ServiceDeploymentReportParams,
  ): Observable<GenericTableReportOfServiceDeploymentTaskLocationViewModel> {
    let offset = params.page;
    const limit = params.pageSize;
    offset = (offset - 1) * limit;
    return this._serviceDeploymentTaskLocationClient.getServiceDeploymentTaskLocationbyRefNumber(
      params.serviceDeploymentRefId,
      params.serviceDeploymentTaskRefId,
      offset,
      limit,
      params.sortColumn,
      params.serviceDeploymnetTaskLocationSearchParameters.numberSearch,
      params.serviceDeploymnetTaskLocationSearchParameters.referenceNumberSearch,
      params.serviceDeploymnetTaskLocationSearchParameters.employeeNumbersSearch,
      params.serviceDeploymnetTaskLocationSearchParameters.statusSearch,
      params.serviceDeploymnetTaskLocationSearchParameters.serviceStartDate,
      params.serviceDeploymnetTaskLocationSearchParameters.serviceEndDate,
    );
  }

  public getEmployeeListByFilter(searchFilterText, accountId: number): Observable<any> {
    return this._userClient.getEmployeeNumberListByFilter(searchFilterText, accountId);
  }

  public checkStoreNumberAlreadyExist(
    storeNumber: string,
    accountId: number,
    serviceDeploymentRefNumber: string,
    taskRefNumber: string,
    taskLocationId: string,
  ): Observable<boolean> {
    return this._serviceDeploymentTaskLocationClient.checkStoreNumberAlreadyExist(
      storeNumber,
      accountId,
      serviceDeploymentRefNumber,
      taskRefNumber,
      taskLocationId,
    );
  }

  public getLocationTimeZoneByNumber(locationNumber: string): Observable<any> {
    return this._locationClient.getLocationByStoreNumber(locationNumber);
  }

  public checkTaskLocationRefNumberAlreadyExist(
    taskLocaitonRefNumber: string,
    accountId: number,
    serviceDeploymentRefNumber: string,
    taskRefNumber: string,
  ): Observable<any> {
    return this._serviceDeploymentTaskLocationClient.checkTaskLocationRefNumberAlreadyExist(
      taskLocaitonRefNumber,
      accountId,
      serviceDeploymentRefNumber,
      taskRefNumber,
    );
  }

  public sendEmailFailedImportTaskLocation(errorMessage: string): Observable<any> {
    return this._serviceDeploymentTaskLocationClient.failedImportTaskLocation(errorMessage);
  }
}
