import { Injectable } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class FormcreationService {
  constructor() {}

  public toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach(element => {
      group[element.key] = new UntypedFormControl(element.value || '');
    });
    return new UntypedFormGroup(group);
  }
}
