import { ServiceDeploymentInput } from '../../api.client';

export class AddServiceDeployment extends ServiceDeploymentInput {
  public banner: string;
  public accountId: number;
  public add: boolean;
  public edit: boolean;

  constructor() {
    super();
    this.banner = null;
    this.accountId = null;
    this.add = true;
    this.edit = false;
    this.referenceNumber = null;
    this.name = null;
    this.retailer = 'Walmart';
    this.note = '';
    this.visitOverview = '';
    this.status = null;
    this.serviceEnd = null;
    this.serviceStart = null;
  }
}
