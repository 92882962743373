<v-loading-container>
  <p-table [columns]="cols" [value]="data" [rows]="20" [totalRecords]="totalRecords" [responsive]="true" [lazy]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngStyle]='{width: col.width || "100px"}'>
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngStyle]='{width: col.width || "100px"}'>
          <div *ngIf="col.field !== 'email'">
            {{ rowData[col.field] }}
          </div>

          <div *ngIf="col.field === 'email'" style="overflow:scroll;overflow-y:hidden;overflow-x: auto;">
            {{ rowData[col.field] }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</v-loading-container>
