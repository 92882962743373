import { TableEnum } from './TableEnum';
import { ServiceDeploymentSearchParameters } from './servicedeployment-search-parameters';
import { ServicedeploymnetTaskSearchParameters } from './servicedeploymnet-task-search-parameters';
import { ServicedeploymentTaskLocationSearchParameters } from './servicedeployment-task-location-search-parameters';

export class ServiceDeploymentReportParams {
  constructor() {
    this.page = 1;
    this.pageSize = 20;
    this.sortColumn = '';
    this.filterText = '';
    this.tableName = TableEnum.ServiceDeployments;
    this.servicedeploymentSearchParameters = new ServiceDeploymentSearchParameters();
    this.servicedeploymentTaskSearchParameters = new ServicedeploymnetTaskSearchParameters();
    this.serviceDeploymnetTaskLocationSearchParameters = new ServicedeploymentTaskLocationSearchParameters();
  }
  public filterText: string;
  public page: number;
  public pageSize: number;
  public sortColumn: string;
  public ascending: boolean;
  public fromDate: any;
  public toDate: any;
  public providerId: number;
  public serviceDeploymentRefId: string;
  public serviceDeploymentTaskRefId: string;
  public tableName: string;
  public servicedeploymentSearchParameters: ServiceDeploymentSearchParameters;
  public servicedeploymentTaskSearchParameters: ServicedeploymnetTaskSearchParameters;
  public serviceDeploymnetTaskLocationSearchParameters: ServicedeploymentTaskLocationSearchParameters;
}
