<div [class.inline]="inline" [class.vertical]="!inline">
    <div>
        <input-label [label]="translations.firstName" [required]="true"></input-label>
        <div class="p-inputgroup w-100">
            <span class="p-inputgroup-addon"><i class="fa fa-user"></i></span>
            <input autocomplete="text" pInputText [formControl]="controls.firstName" class="w-100">
        </div>
    </div>

    <div>
        <input-label [label]="translations.lastName" [required]="true"></input-label>
        <div class="p-inputgroup w-100">
            <span class="p-inputgroup-addon"><i class="fa fa-user"></i></span>
            <input autocomplete="text" pInputText [formControl]="controls.lastName" class="w-100">
        </div>
    </div>

    <div>
        <input-label [label]="translations.contactEmail" [required]="_emailRequired"></input-label>
        <div class="p-inputgroup w-100">
            <span class="p-inputgroup-addon"><i class="fa fa-envelope"></i></span>
            <input autocomplete="email" pInputText [formControl]="controls.email" class="w-100">
        </div>
    </div>

    <div>
        <input-label [label]="translations.contactPhone" [required]="_phoneRequired"></input-label>
        <div class="p-inputgroup w-100">
            <span class="p-inputgroup-addon"><i class="fa fa-phone"></i></span>
            <p-inputMask [formControl]="controls.phone" mask="(999) 999-9999? x99999" class="w-100" styleClass="w-100"></p-inputMask>
        </div>
    </div>
</div>
