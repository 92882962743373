<p-toast [baseZIndex]="100"></p-toast>

<div class="row">
  <div class="col">
    <h5>Primary Vendor ID: {{ primaryNumber }}</h5>
    <p>This account can also be associated with the following supplier numbers for some features of VOLT.</p>
  </div>
</div>

<form [formGroup]="addForm" *permissions="[pc.names.AccountsManage, pc.privilege.Update, editable]">
  <div class="col-xl-7 col-sm-12">
    <div class="form-group row">
      <div class="col-3">
        <label>Supplier Number</label>
      </div>
      <div class="col-6 mb-2">
        <input class="form-control rounded-0" maxlength="20" required formControlName="number" />

        <div *ngIf="addForm.get('number').errors?.required && addForm.get('number').touched">
          <small class="text-danger">Supplier number is required</small>
        </div>

        <div *ngIf="addForm.get('number').touched && addForm.get('number').errors?.unique as unique">
          <small class="text-danger" *ngIf="unique.supplier === SUPPLIER_SELF; else elseBlock">
            Supplier number {{ unique.supplierNumber }} is already assigned to this supplier
          </small>

          <ng-template #elseBlock>
            <small class="text-danger">
              Supplier number {{ unique.supplierNumber }} is already in use by {{ unique.supplier }}
            </small>
          </ng-template>
        </div>
      </div>
      <div class="col-3">
        <button type="button" class="btn btn-success btn-sm rounded-0" (click)="onAddRecord()">Add</button>
      </div>
    </div>
  </div>
</form>

<v-loading-container [working]="loading$ | async" class="mt-5">
  <shared-table
    [columns]="columns"
    [data]="data$ | async"
    [state]="tableState$ | async"
    (stateChanged)="onTableStateChanged($event)"
  >
    <ng-template sharedTable="deleteRecord" let-row>
      <span *permissions="[pc.names.AccountsManage, pc.privilege.Update, editable]" style="cursor: pointer">
        <i class="fa fa-trash-alt fa-2x" (click)="onDeleteRecord(row)"></i>
      </span>
    </ng-template>
  </shared-table>
</v-loading-container>
