import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { LazyLoadEvent } from 'primeng/api';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AccountActivityClient,
  FileResponse,
  RetailtainmentServiceEventTaskDto,
  ServiceTaskEventClient,
  ServiceTaskRetrievalClient,
} from '@volt/api';
import { RoleConstant } from '../../../../auth/shared/roles.constants';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { AddServiceTask } from '../../../models/addServiceTask';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { ServicedeploymnetTaskSearchParameters } from '../../../models/servicedeploymnet-task-search-parameters';
import { ServicedeploymentReduxService } from '../../../services/servicedeployment.redux.service';
import { AddServicedeploymentTaskReduxService } from '../../addservicedeploymenttask/services/add-servicedeploymenttask.redux.service';
import { ServiceDeploymentsService } from '../../servicedeployments/services/service-deployments.service';
import { TableDisplay } from '../../table-display/table-display.component';
import { ServicedeploymentTaskService } from '../services/servicedeployment-task.service';

@Component({
  selector: 'servicedeployemt-task',
  templateUrl: 'servicedeployment-task.component.html',
})
export class ServicedeploymentTaskComponent extends TableDisplay implements OnInit, OnDestroy {
  public serviceDeploymentRefId: string;
  public serviceDeploymentRefNumber: string;
  public fieldGroupName: string;
  public serviceDeploymentName: string;
  public statusType: string;
  public accountId: number;
  public status;
  public currentServiceTask: AddServiceTask;
  public showDateFilter: boolean;
  public role = RoleConstant;

  canAdd = this._permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);

  hasRetailtainmentActivity$: Observable<boolean>;

  private readonly _serviceDeploymentFilterParams$ = new Subject<ServiceDeploymentReportParams>();
  private readonly _onDestroy$ = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _serviceDeploymentTaskService: ServicedeploymentTaskService,
    private _servicedeploymentReduxService: ServicedeploymentReduxService,
    private _router: Router,
    private _servicedeploymentTaskClient: ServiceTaskRetrievalClient,
    private _addServiceTaskReduxService: AddServicedeploymentTaskReduxService,
    private _serviceDeploymentsService: ServiceDeploymentsService,
    private _accountActivityClient: AccountActivityClient,
    private _serviceTaskEventClient: ServiceTaskEventClient,
    private readonly _permissionsService: PermissionsService,
  ) {
    super(_serviceDeploymentsService);
  }

  ngOnInit() {
    this.searchBy = 'Search By Task Name';
    this.showDateFilter = false;

    this._serviceDeploymentFilterParams$.pipe(
      takeUntil(this._onDestroy$),
      tap(() => this.loading = true),
      switchMap((filterParams) => this._serviceDeploymentTaskService.getServiceDeploymentTask(filterParams)),
      finalize(() => this.loading = false)
    ).subscribe((serviceDeploymentTasks) => {
      this.cols = serviceDeploymentTasks.columnList;
      this.data = serviceDeploymentTasks.results;
      this.totalRecords = serviceDeploymentTasks.totalCount;
      this.loading = false;
    });

    combineLatest([
      this._route.queryParamMap,
      this._route.paramMap.pipe(filter(pm => pm.has('refid')))
    ]).subscribe(([queryParams, params]: [ParamMap, ParamMap]) => {
      // init page data
      this.serviceDeploymentName = queryParams.get('serviceDeploymentName');
      this.serviceDeploymentRefId = params.get('refid');
      this.serviceDeploymentRefNumber = queryParams.get('serviceDeploymentRefNumber');
      this.fieldGroupName = queryParams.get('fieldGroupName');
      this.accountId = +queryParams.get('accountId');

      // init filtering based on params and queryParams
      const filters = new ServiceDeploymentReportParams();
      filters.serviceDeploymentRefId = this.serviceDeploymentRefId;

      // trigger data fetch
      this.serviceDeploymentFilterParams = filters;
      this._serviceDeploymentFilterParams$.next(filters);

      this.hasRetailtainmentActivity$ = this._accountActivityClient.hasRetailtainmentActivity(this.accountId);

      // still need this for adding / editing tasks
      this._servicedeploymentReduxService.setFilter({
        serviceDeploymentRefNumber: this.serviceDeploymentRefNumber,
        fieldGroupName: this.fieldGroupName,
        status: this.statusType,
        accountId: this.accountId,
        serviceDeploymentName: this.serviceDeploymentName
      })
    });
  }

  pullData() {
  }

  public refresh() {
    // refresh, maintain existing filtering state
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  sortdata(event: LazyLoadEvent) {
    this.serviceDeploymentFilterParams.sortColumn = `${event.sortOrder === -1 ? '-' : ''}${event.sortField ?? 'name'}`;
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
  }

  searchName(name: string) {
    name = name.trim();
    this.serviceDeploymentFilterParams.servicedeploymentTaskSearchParameters.nameSearch = name;
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  public passData(data: AddServiceTask) {
    const params = Object.assign(
      {},
      {
        serviceDeploymentTaskRefNumber: data.referenceNumber,
        status: data.status,
        serviceDeploymentName: this.serviceDeploymentName,
        serviceTaskName: data.name,
        fieldGroupName: this.fieldGroupName,
        serviceDeploymentRefNumber: this.serviceDeploymentRefNumber,
        accountId: this.accountId
      },
    );
    this._servicedeploymentReduxService.setFilter(params);
  }

  clearFilter() {
    this.serviceDeploymentFilterParams.servicedeploymentTaskSearchParameters = new ServicedeploymnetTaskSearchParameters();
    this._serviceDeploymentFilterParams$.next(this.serviceDeploymentFilterParams);
  }

  onDownLoad() {
    this.loading = true;
    this._servicedeploymentTaskClient.getFile(this.serviceDeploymentRefId).subscribe((file: FileResponse) => {
      const date = new Date();
      const cleanFieldGroupName = this.fieldGroupName.replace(/[^a-zA-Z ]/g, '');
      const fileName = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${cleanFieldGroupName}-${
        this.serviceDeploymentRefNumber
      }-tasks.csv`;
      CommonUtils.download(file.data, fileName);
      this.loading = false;
    });
  }

  public addServiceDeploymentTasks() {
    this.currentServiceTask = new AddServiceTask();
    this.currentServiceTask.add = true;
    this.currentServiceTask.edit = false;
    this._addServiceTaskReduxService.setFilter(this.currentServiceTask);
    this._router.navigate(['/servicedeployments', 'addservicedeploymenttask', this.serviceDeploymentRefId], { queryParamsHandling: 'preserve' });
  }

  public editServiceTask(data: AddServiceTask) {
    data.add = false;
    data.edit = true;
    data.accountId = this.accountId;
    this._addServiceTaskReduxService.setFilter(data);
    if (data.activity !== 'Retailtainment') {
      this._router.navigate(['/servicedeployments', 'addservicedeploymenttask', this.serviceDeploymentRefId], {queryParamsHandling: 'preserve'});
    } else {
      this._serviceTaskEventClient
        .getSingleEvent(data['id'])
        .pipe(
          catchError(() => {
            return of(null);
          }),
        )
        .subscribe((task: RetailtainmentServiceEventTaskDto) => {
          if (task) {
            this._router.navigate(['/servicedeployments/retailtainment-task/edit', task.id], {queryParamsHandling: 'preserve'});
          } else {
            this._router.navigate(['/servicedeployments', 'addservicedeploymenttask', this.serviceDeploymentRefId], {queryParamsHandling: 'preserve'});
          }
        });
    }
  }

  addRetailtainmentEvent() {
    this._router.navigate([
      '/servicedeployments/retailtainment-task/create',
      this.serviceDeploymentRefId,
      this.accountId,
      this.serviceDeploymentRefNumber,
    ], {queryParamsHandling: 'preserve'});
  }

  getSupplierLabel(data: any[]): string {
    return data.map(s => `${s.name}, ${s.number}`).join('\n');
  }

}
