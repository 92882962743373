import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'long-text-limit',
  template: `
    <p-dialog [header]="title" [(visible)]="expanded">
      <div id="dialog-text">{{ text }}</div>
    </p-dialog>
    <div
      id="text"
      [title]="text"
      [class]="{ expanded: expanded }"
      [pTooltip]="expanded ? 'Click to View Less' : 'Click to View More'"
      (click)="expanded = !expanded"
    >
      {{ text }}
    </div>
  `,
  styleUrls: ['./long-text-limit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongTextLimitComponent implements OnInit {
  @Input() text: string;
  @Input() title: string;
  @Input() expanded: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
