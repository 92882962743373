import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DataPassingService } from '../../../../services/data-passing.service';
import { AddTaskLocation } from '../../../../models/addTaskLocation';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'task-location-end-date',
  templateUrl: './task-location-end-date.component.html',
  styleUrls: ['../../addservicedeployment-task-location.component.scss'],
})
export class TaskLocationEndDateComponent implements OnInit {
  public endDate: Date;
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public minDate;
  @Input() public serviceDeploymentDateMsg: boolean;
  @Output() public setEndDateMsg: EventEmitter<any> = new EventEmitter<any>();
  public showEndDate = true;

  @Input() set setEndDate(currentTaskLocation: AddTaskLocation) {
    this.endDate = currentTaskLocation.end ? new Date(currentTaskLocation.end.toDate()) : null;
    if (currentTaskLocation.end) {
      this.showEndDate = false;
    }
  }

  constructor(private _dataPassingService: DataPassingService, private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {
    this._dataPassingService.serviceDeploymentEndDate.subscribe((data: any) => {
      this.endDate = null;
      this.showEndDate = false;
      this.serviceDeploymentDateMsg = false;
    });
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Select task end date and time for the location.',
          'The date time is store in UTC. The system add the local timezone of the selected store',
          'i.e iif store is in California and time selected is 08:00, the time is shown as pacific ' +
            'timezone and saved in database as 16:00 (including daylight saving).',
          'Please note the scheduled hours, WMT VOLT check in hours, and reported hours should align.',
        ],
      },
    });
  }

  public get endDateControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('endDate');
  }

  public onSelectEndDate(date: Date) {
    this.serviceDeploymentDateMsg = false;
    this.setEndDateMsg.emit('msg');
  }
}
