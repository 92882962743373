<div *ngIf="translations$ | async as translations">
  <div *ngIf="showPaginator && state != null" class="d-flex pagination-container d-flex align-items-center">
    <div class="align-self-center">
      <p-paginator
        [totalRecords]="state.totalCount"
        [rows]="state.limit"
        [first]="state.offset"
        (onPageChange)="onPageChanged($event)"
        class="d-block"
      ></p-paginator>
    </div>
    <div class="ms-1">
      {{ translations.result }} {{ state.offset + 1 }} - {{ min(state.offset + state.limit, state.totalCount) }}
      {{ translations.of }}
      {{ state.totalCount }}
    </div>
    <div *ngIf="export.observers.length > 0" class="ms-auto">
      <button class="btn btn-sm btn-primary" (click)="export.emit()">
        <i class="fa fa-file-export me-1"></i>
        {{ translations.export }}
      </button>
    </div>
  </div>

  <p-table
    [columns]="enabledColumns"
    [value]="data || []"
    [responsive]="true"
    [customSort]="true"
    [sortField]="state?.sortBy"
    [sortOrder]="state?.sortAscending ? 1 : -1"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    (sortFunction)="onSortChanged($event)"
  >
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          {{ translations.noDataToDisplay }}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of enabledColumns">
          <th
            [pSortableColumn]="col.sortable ? (!!col.sortField ? col.sortField : col.field) : ''"
            [ngStyle]="{ width: col.width }"
          >
            <shared-table-header-cell
              *ngIf="!col.headerSelector"
              [column]="col"
              [data]="data"
              [translations]="translations"
            ></shared-table-header-cell>

            <ng-container
              *ngIf="col.headerSelector"
              [ngTemplateOutlet]="getTemplate(col.headerSelector)"
            ></ng-container>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
      <tr>
        <td
          *ngFor="let col of enabledColumns"
          class="shared-table-td"
          [ngClass]="{
            'center-content': col.centerContent
          }"
        >
          <shared-table-cell
            *ngIf="!col.fieldSelector"
            [column]="col"
            [data]="row"
            (deleteClicked)="deleteClicked.emit($event)"
          ></shared-table-cell>
          <ng-container
            *ngIf="col.fieldSelector"
            [ngTemplateOutlet]="getTemplate(col.fieldSelector)"
            [ngTemplateOutletContext]="{ $implicit: row, rowIndex: rowIndex }"
          ></ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
