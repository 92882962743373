import { select } from '@angular-redux/store';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { ICustomFile } from 'file-input-accessor';
import isNil from 'lodash-es/isNil';
import { combineLatest, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, filter, map, mergeMap, takeLast, takeUntil } from 'rxjs/operators';
import {
  DropDownModel,
  FileParameter,
  Link,
  Log,
  ServiceTaskInput,
  ServiceTasksClient,
  StatusTypes,
  Supplier2,
  UploadResponse,
  UserInformation,
} from '../../../../api.client';
import { AuthService } from '../../../../auth';
import { ServiceModels } from '../../../../shared/constants/service-models.constant';
import { Status } from '../../../../shared/constants/status.constant';
import {
  AddServiceDeploymentTaskStateConstant,
  ServiceDeploymentStateConstant,
} from '../../../actions/servicedeployment.constants';
import { AddServiceTask } from '../../../models/addServiceTask';
import { ServiceDeploymentAndServiceTaskParams } from '../../../models/servicedeployment-and-servicetaskparams';
import { DataPassingService } from '../../../services/data-passing.service';
import { RequireStatusModalComponent } from '../../require-status-modal/require-status-modal.component';
import { ServiceDeploymentsService } from '../../servicedeployments/services/service-deployments.service';
import {
  IServiceDeploymentLogStatus,
  ViewLogStatusModalComponent,
} from '../../view-log-status-modal/view-log-status-modal.component';

@Component({
  selector: 'addservice-deployment-form',
  templateUrl: './addservicedeploymenttask.component.html',
  styleUrls: ['./addservicedeploymenttask.component.scss'],
})
export class AddServiceDeploymentTaskComponent implements OnInit, OnDestroy {
  public addServiceDeploymentTaskGroup: UntypedFormGroup;
  public errorMessage: string;
  public serviceModel: DropDownModel[] = [];
  public activeModel: DropDownModel[];
  public statusDropDownModel: DropDownModel[];
  public filteredDepartmentList: DropDownModel[] = [];
  public filteredSupplierList: DropDownModel[] = [];
  public loading = false;
  public accountId: number;
  public taskReferenceNumber: string;
  public serviceDepRefNumberExist = false;
  public serviceDepRefNumberMsg = false;
  public serviceTaskDepartmentMsg = false;
  public serviceTaskSupplierMsg = false;
  public serviceTaskInstructionMsg = false;
  public files: ICustomFile[] = [];
  public pdfValues: string[] = [];
  public departmentValues: DropDownModel[] = [];
  public supplierValues: DropDownModel[] = [];
  public submitted = false;
  public invalidControl: string[] = [];
  public currentaddedServiceDeploymentTask: AddServiceTask;

  public serviceDeploymentReferenceNumber: string;
  public fieldGroupName: string;
  public statusType: string;
  public serviceDeploymentAndServiceTaskParams: ServiceDeploymentAndServiceTaskParams;
  public currentUser: UserInformation;
  public selectedStatus: string;
  public serviceDeploymentName: string;
  public selectedInstructions: Link[];
  public serviceTaskInstructionName: string;

  private serviceDeploymentRefId;

  public initialSupplierList: DropDownModel[] = [];
  public initialDepartmentList: DropDownModel[] = [];

  public serviceTaskInput: ServiceTaskInput[] = [];
  public fileCtrl = this._fb.control(null);

  @select([AddServiceDeploymentTaskStateConstant.FilterStateName])
  private readonly addServiceDeploymentTask: Observable<AddServiceTask>;

  @select([ServiceDeploymentStateConstant.FilterStateName])
  private readonly serviceDeploymentTask: Observable<ServiceDeploymentAndServiceTaskParams>;
  isEdit: boolean;

  canCreate = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);
  canEdit = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Update);

  private readonly _onDestroy$ = new Subject();

  constructor(
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _serviceDeploymentsService: ServiceDeploymentsService,
    private _dataPassingService: DataPassingService,
    private _serviceTaskClient: ServiceTasksClient,
    private _route: ActivatedRoute,
    private readonly permissionsService: PermissionsService,
    private readonly _authService: AuthService,
    private _dynamicDialogService: DynamicDialogService,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    combineLatest([
      this._route.paramMap.pipe(filter(pm => pm.has('serviceDeploymentRefId'))),
      this.serviceDeploymentTask,
      this.addServiceDeploymentTask
    ]).pipe(takeUntil(this._onDestroy$))
      .subscribe(([params, serviceDeploymentTask, addTask]: [ParamMap, ServiceDeploymentAndServiceTaskParams, AddServiceTask]) => {
        this.serviceDeploymentRefId = params.get('serviceDeploymentRefId');

        // deployment task
        this.serviceDeploymentAndServiceTaskParams = serviceDeploymentTask;
        this.serviceDeploymentReferenceNumber = serviceDeploymentTask.serviceDeploymentRefNumber;
        this.fieldGroupName = serviceDeploymentTask.fieldGroupName;
        this.statusType = serviceDeploymentTask.status;
        this.serviceDeploymentName = serviceDeploymentTask.serviceDeploymentName;
        this.accountId = serviceDeploymentTask.accountId;

        // add deployment task
        this.isEdit = addTask.edit === true;
        this.currentaddedServiceDeploymentTask = addTask;
        this.accountId = this.accountId || addTask.accountId;

        if (this.currentaddedServiceDeploymentTask.edit) {
          this.populateSuppliers();
          this.populateDepartments();
          this.populateInstructions();
        }

        if (this.accountId == undefined || this.accountId === 0 || this.accountId == null) {
          this._router.navigate(['/servicedeployments/data']);
        }

        this.initForm();
        this.currentUser = this._authService.getCurrentUser();

        // avoiding changed after checked errors
        this._cdr.detectChanges();
        this.getStatusDropDownList();
        this.getActivityDropdownList(this.accountId);
        this.getServiceModelDropDownList();
    });

    this._dataPassingService.serviceDeploymenttaskInstructionParams.pipe(takeUntil(this._onDestroy$))
      .subscribe((ins) => {
        this.deleteInstruction(ins);
      });
  }

  getInstruction(event: string) {
    if (event !== '' && event !== null && event !== undefined) {
      this.serviceTaskInstructionMsg = false;
      this.serviceTaskInstructionName = event;
    } else {
      this.serviceTaskInstructionName = '';
    }
  }

  private populateInstructions() {
    if (this.currentaddedServiceDeploymentTask.instructionUrls != null) {
      this.selectedInstructions = [];
      this.selectedInstructions = this.currentaddedServiceDeploymentTask.instructionUrls;
    }
  }

  private populateSuppliers() {
    if (this.currentaddedServiceDeploymentTask.suppliers != null) {
      for (let i = 0; i < this.currentaddedServiceDeploymentTask.suppliers.length; i++) {
        const supplier = new DropDownModel();
        supplier.label =
          this.currentaddedServiceDeploymentTask.suppliers[i].name +
          ',' +
          this.currentaddedServiceDeploymentTask.suppliers[i].number;
        supplier.value = this.currentaddedServiceDeploymentTask.suppliers[i].name;
        this.initialSupplierList.push(supplier);
      }
    }
  }

  private populateDepartments() {
    if (this.currentaddedServiceDeploymentTask.departments != null) {
      for (let i = 0; i < this.currentaddedServiceDeploymentTask.departments.length; i++) {
        const department = new DropDownModel();
        department.label = this.currentaddedServiceDeploymentTask.departments[i];
        department.value = this.currentaddedServiceDeploymentTask.departments[i];
        this.initialDepartmentList.push(department);
      }
    }
  }

  public capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }
  public getServiceModelDropDownList() {
    this._serviceDeploymentsService.getServiceModelDropDownList().subscribe(
      (data: DropDownModel[]) => {
        this.serviceModel = data;

        /*  if (this.currentaddedServiceDeploymentTask.serviceModel == null) {
          this.currentaddedServiceDeploymentTask.serviceModel = this.serviceModel[0].value;
        }*/
      },
      (err: any) => {
        console.error(err);
      },
    );
  }
  public getActivityDropdownList(id) {
    this._serviceDeploymentsService
      .getActivityDropdownList(id)
      .pipe(map(dropdownList => dropdownList.filter(d => d.value !== 'Retailtainment')))
      .subscribe(
        (data: DropDownModel[]) => {
          this.activeModel = data;
        },
        (err: any) => {
          console.error(err);
        },
      );
  }

  public supplierExist(event: any) {
    this.serviceTaskSupplierMsg = false;
  }
  public getSupplierListByFilter(event: any) {
    const searchFilterText = event.query;
    this.serviceTaskSupplierMsg = false;
    this._serviceDeploymentsService.getSupplierListByFilterText(searchFilterText).subscribe(
      (data: DropDownModel[]) => {
        this.filteredSupplierList = data;

        if (this.filteredSupplierList.length === 0) {
          this.serviceTaskSupplierMsg = true;
        } else {
          this.serviceTaskSupplierMsg = false;
        }
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public departmentExist(event: any) {
    this.serviceTaskDepartmentMsg = false;
  }

  public getDepartmentListByFilter(event: any) {
    this.serviceTaskDepartmentMsg = false;
    if (event.query.length >= 1) {
      const searchFilterText = event.query;
      this._serviceDeploymentsService.getDepartmentListByFilterText(searchFilterText).subscribe(
        (data: DropDownModel[]) => {
          this.filteredDepartmentList = data;

          if (this.filteredDepartmentList.length === 0) {
            this.serviceTaskDepartmentMsg = true;
          } else {
            this.serviceTaskDepartmentMsg = false;
          }
        },
        (err: any) => {
          console.error(err);
        },
      );
    } else {
      this.serviceTaskDepartmentMsg = false;
    }
  }

  public submitServiceDeploymentTasks() {
    const checkResponse = this.checkControlValidation();
    const activity = this.addServiceDeploymentTaskGroup.get('activeType').value;
    const serviceModel = ServiceModels[this.addServiceDeploymentTaskGroup.get('serviceType').value];

    const tempValue = this.addServiceDeploymentTaskGroup.get('activeType').value;

    if (!checkResponse) {
      this.submitted = true;
      return;
    }

    this.taskReferenceNumber = this.addServiceDeploymentTaskGroup.get('taskReferenceNumber').value.trim();

    const serviceDeploymentTask = Object.assign({}, this.currentaddedServiceDeploymentTask, {
      name: this.addServiceDeploymentTaskGroup.get('taskName').value.trim(),
      referenceNumber: this.addServiceDeploymentTaskGroup.get('taskReferenceNumber').value.trim(),
      serviceModel: ServiceModels[this.addServiceDeploymentTaskGroup.get('serviceType').value],
      activity: this.addServiceDeploymentTaskGroup.get('activeType').value,
      suppliers: this.addServiceDeploymentTaskGroup.get('supplier').value,
      estimate: this.addServiceDeploymentTaskGroup.get('timeEstimate').value,
      note:
        this.addServiceDeploymentTaskGroup.get('serviceNote').value === null
          ? null
          : this.addServiceDeploymentTaskGroup.get('serviceNote').value.trim(),
      status: this.addServiceDeploymentTaskGroup.get('serviceStatus').value,
      departments: this.addServiceDeploymentTaskGroup.get('department').value,
    });

    if (this.files && this.files.length) {
      if (
        this.serviceTaskInstructionName === '' ||
        this.serviceTaskInstructionName === null ||
        this.serviceTaskInstructionName === undefined
      ) {
        this.serviceTaskInstructionMsg = true;
        const checkName = this.checkControlValidation();
        if (!checkName) {
          return;
        }
      }

      if (this.statusType === StatusTypes.Inactive) {
        const checkName = this.checkValidationForStatus();
        if (!checkName) {
          return;
        }
      }

      const uploadTasks = [];
      for (const temp of this.files) {
        const file: FileParameter = {
          data: temp,
          fileName: this.serviceTaskInstructionName,
        };

        this.loading = true;

        uploadTasks.push(this._serviceTaskClient.serviceTaskFileUpload(file));

        combineLatest(uploadTasks)
          .pipe(
            takeLast(uploadTasks.length),
            mergeMap((data: UploadResponse[]) => {
              const urls = data.map(d => d.data);
              this.pdfValues = urls;
              serviceDeploymentTask.instructionUrls = [];

              for (let i = 0; i < this.pdfValues.length; i++) {
                const links = new Link();
                links.name = this.serviceTaskInstructionName;
                links.url = this.pdfValues[i];

                serviceDeploymentTask.instructionUrls.push(links);
              }

              if (!isNil(this.selectedInstructions) && this.selectedInstructions.length > 0) {
                for (let i = 0; i < this.selectedInstructions.length; i++) {
                  const links = new Link();
                  links.name = this.selectedInstructions[i].name;
                  links.url = this.selectedInstructions[i].url;

                  serviceDeploymentTask.instructionUrls.push(links);
                }
              }

              this.createDepartmentsData(serviceDeploymentTask);
              this.createSupplierData(serviceDeploymentTask);

              return this._serviceTaskClient.addTask(
                this.serviceDeploymentReferenceNumber,
                this.accountId,
                this.serviceTaskInput,
              );
            }),
          )
          .pipe(
            catchError(err => {
              return observableOf(err);
            }),
          )
          .subscribe((response: Log) => {
            this.loading = false;
            this._dynamicDialogService.open(ViewLogStatusModalComponent, {
              data: {
                status: 'Pending',
                serviceDeploymentStatusName: 'Service Task Status',
                serviceDeploymentReferenceNumber: 'Service Task Reference #',
                transactionId: response.transactionId,
                accountId: this.accountId,
                referenceNumber: this.taskReferenceNumber,
                serviceDeploymentRefId: this.serviceDeploymentRefId,
                showImportTaskLocation: false,
              } as IServiceDeploymentLogStatus,
            });
          });
      }
    } else {
      if (this.statusType === 'inactive') {
        const checkName = this.checkValidationForStatus();
        if (!checkName) {
          return;
        }
      }

      this.loading = true;
      serviceDeploymentTask.instructionUrls = [];

      if (!isNil(this.selectedInstructions) && this.selectedInstructions.length > 0) {
        // serviceDeploymentTask.instructionUrls = this.currentaddedServiceDeploymentTask.instructionUrls;
        serviceDeploymentTask.instructionUrls = this.selectedInstructions;
      }

      this.createDepartmentsData(serviceDeploymentTask);
      this.createSupplierData(serviceDeploymentTask);

      this._serviceTaskClient
        .addTask(this.serviceDeploymentReferenceNumber, this.accountId, this.serviceTaskInput)
        .subscribe(
          (response: any) => {
            this.loading = false;
            this._dynamicDialogService.open(ViewLogStatusModalComponent, {
              data: {
                status: 'Pending',
                serviceDeploymentStatusName: 'Service Task Status',
                serviceDeploymentReferenceNumber: 'Service Task Reference #',
                transactionId: response.transactionId,
                accountId: this.accountId,
                referenceNumber: this.taskReferenceNumber,
                serviceDeploymentRefId: this.serviceDeploymentRefId,
              } as IServiceDeploymentLogStatus,
            });
          },
          err => {
            console.error(err);
          },
        );
    }
  }

  private createDepartmentsData(serviceDeploymentTask) {
    this.departmentValues = serviceDeploymentTask.departments;
    serviceDeploymentTask.departments = [];

    for (let i = 0; i < this.departmentValues.length; i++) {
      const department = this.departmentValues[i].label.trim();
      serviceDeploymentTask.departments.push(department);
    }
  }
  private createSupplierData(serviceDeploymentTask) {
    this.supplierValues = serviceDeploymentTask.suppliers;
    serviceDeploymentTask.suppliers = [];

    for (let i = 0; i < this.supplierValues.length; i++) {
      const supplier = new Supplier2();
      const supplieValue = this.supplierValues[i].label.split(',');
      supplier.name = supplieValue[0].trim();
      supplier.number = supplieValue[1].trim();
      serviceDeploymentTask.suppliers.push(supplier);
    }
    this.serviceTaskInput.push(serviceDeploymentTask);
  }
  public checkServiceRefNumberAlreadyExist(serviceDepRefNumber: string) {
    if (!isNil(serviceDepRefNumber) && serviceDepRefNumber !== '' && !isNil(this.accountId)) {
      serviceDepRefNumber = serviceDepRefNumber.trim();

      this._serviceDeploymentsService
        .checkServiceTaskRefNumberAlreadyExist(serviceDepRefNumber, this.accountId)
        .subscribe(
          (data: any) => {
            this.serviceDepRefNumberExist = data;

            if (data) {
              this.serviceDepRefNumberMsg = true;
            } else {
              this.serviceDepRefNumberMsg = false;
            }
          },
          (err: any) => {
            console.error(err);
          },
        );
    }
  }

  public getStatusDropDownList() {
    this._serviceDeploymentsService.getStatusList().subscribe(
      (data: any) => {
        this.statusDropDownModel = data;

        if (this.currentaddedServiceDeploymentTask.status == null) {
          this.selectedStatus = Status.Active.toString();
        } else {
          this.selectedStatus = '' + Status[this.capitalizeFirstLetter(this.currentaddedServiceDeploymentTask.status)];
        }
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public checkControlValidation() {
    this.invalidControl = [];
    this.checkValidationForAddServiceDeployment();

    if (this.invalidControl.length > 0) {
      return false;
    }

    return true;
  }

  public checkValidationForStatus() {
    this._dynamicDialogService.open(RequireStatusModalComponent, {
      data: {
        message: 'Task cannot be created for inactive service deployment.',
      },
    });
    return false;
  }

  public checkValidationForAddServiceDeployment() {
    if (this.addServiceDeploymentTaskGroup.invalid) {
      this.invalidControl.push('Invalid');
    } else if (this.serviceDepRefNumberExist === true) {
      this.invalidControl.push('Service Deployment Reference Number Already Exist');
    } else if (this.serviceTaskDepartmentMsg === true) {
      this.invalidControl.push('Department does not exist');
    } else if (this.serviceTaskSupplierMsg === true) {
      this.invalidControl.push('Supplier does not exist');
    } else if (this.serviceTaskInstructionMsg === true) {
      this.invalidControl.push('File name requierd');
    }
  }

  public formReset() {
    this.addServiceDeploymentTaskGroup.reset();
    this._router.navigate(['/servicedeployments', this.serviceDeploymentRefId], {queryParamsHandling: 'preserve'});
  }

  public deleteInstruction(name: string) {
    this.selectedInstructions = this.selectedInstructions.filter(item => item.name !== name);
  }

  private initForm() {
    this.addServiceDeploymentTaskGroup = this._fb.group({
      taskName: this._fb.control(this.currentaddedServiceDeploymentTask.name, Validators.required),
      taskReferenceNumber: this._fb.control(
        this.currentaddedServiceDeploymentTask.referenceNumber,
        Validators.compose([Validators.required, Validators.pattern(/^[\w\.\-\:]*$/)]),
      ),
      supplier: this._fb.control('', Validators.required),
      serviceType: this._fb.control('', Validators.required),
      activeType: this._fb.control('', Validators.required),
      department: this._fb.control(this.currentaddedServiceDeploymentTask.departments, Validators.required),
      timeEstimate: this._fb.control(this.currentaddedServiceDeploymentTask.estimate, Validators.required),
      files: this.fileCtrl,
      serviceStatus: this._fb.control(''),
      serviceNote: this._fb.control(this.currentaddedServiceDeploymentTask.note),
    });
    this.fileCtrl.valueChanges.subscribe(val => {
      this.files = val ? val : [];
    });
    if (this.currentaddedServiceDeploymentTask) {
      if (this.currentaddedServiceDeploymentTask.edit && !this.canEdit) {
        CommonUtils.disableControls(this.addServiceDeploymentTaskGroup.controls);
      } else if (this.currentaddedServiceDeploymentTask.add && !this.canCreate) {
        this._router.navigate(['../data'], {
          relativeTo: this._route,
        });
      }
    }
  }
}
