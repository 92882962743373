import { TileResult } from '../services/dashboard.service';
import { ActivityModel } from '../../api.client';
import { RangeSetting } from '../../shared/components/misc/date-range-selector/range-setting';

export class DashboardState {
  constructor() {
    this.inStoreTime = new DashboardComponentState();
    this.certifications = new DashboardComponentState();
    this.completedVisits = new DashboardComponentState();
    this.recentCheckInActivity = new DashboardComponentState();
    this.repsInStore = new DashboardComponentState();
    this.tasksScheduled = new DashboardComponentState();
  }

  repsInStore: DashboardComponentState;
  inStoreTime: DashboardComponentState;
  certifications: DashboardComponentState;
  completedVisits: DashboardComponentState;
  recentCheckInActivity: DashboardComponentState;
  tasksScheduled: DashboardComponentState;
}

export class DashboardComponentState {
  constructor() {
    this.working = false;
    this.currentValue = null;
    this.storedValues = [];
    this.error = false;
  }

  public working: boolean;
  public error: boolean;
  public currentValue: any;
  public storedValues: TileResult[];
}

export class DashboardTileState<TValType> {
  public currentValue: TValType;
  public rangeSetting: RangeSetting;
}

export const dashboardInitState: DashboardState = {
  repsInStore: new DashboardComponentState(),
  inStoreTime: new DashboardComponentState(),
  certifications: new DashboardComponentState(),
  completedVisits: new DashboardComponentState(),
  recentCheckInActivity: new DashboardComponentState(),
  tasksScheduled: new DashboardComponentState(),
};
