import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { AllowedLocationsClient, LocationDropdown } from '@volt/api';
import { SelectItem } from 'primeng/api';
import { iif, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAppState } from '../../store';
import { CommonUtils } from '../utils/common.utils';
import { AuthService } from '../../auth';

@Injectable({
  providedIn: 'root',
})
export class AllowedLocationsService {
  private $allowedLocations: ReplaySubject<LocationDropdown[]>;

  constructor(
    private readonly allowedLocationsClient: AllowedLocationsClient,
    private readonly appState: NgRedux<IAppState>,
    private readonly _authService: AuthService,
  ) {}

  get allowedLocations$(): Observable<LocationDropdown[]> {
    return this.$allowedLocations.asObservable();
  }

  getLocationByLocationNumber(locationNumber: string): Observable<LocationDropdown> {
    return this.allowedLocations$.pipe(
      map(locations => locations.find(location => location.number === locationNumber)),
    );
  }

  loadLocations(): void {
    this.$allowedLocations = new ReplaySubject<LocationDropdown[]>(1);
    if (this._authService.isAuthenticated) {
      this.allowedLocationsClient
        .getAllowedLocations()
        .pipe(catchError(() => []))
        .subscribe(locations => {
          this.$allowedLocations.next(locations);
        });
    } else {
      this.$allowedLocations.next([]);
    }
  }

  getLocationDetail(id: string): Observable<LocationDropdown> {
    return this.allowedLocationsClient.getAllowedLocationDetails(id);
  }

  getLocationsAsSelectItems(
    isStoreManager: boolean = false,
    prefetchLocation?: LocationDropdown,
  ): Observable<SelectItem[]> {
    return iif(
      () => isStoreManager && !!prefetchLocation,
      of([{ label: prefetchLocation?.number, value: prefetchLocation }]),
      this.allowedLocations$.pipe(
        CommonUtils.mapToMultiSelectItem(
          item => item.number,
          item => item,
          false,
          null,
          null,
          false,
        ),
        map(selectItems => {
          if (prefetchLocation) {
            return [
              { label: prefetchLocation.number, value: prefetchLocation },
              ...selectItems.filter(item => item.label !== prefetchLocation.number),
            ];
          }

          return selectItems;
        }),
        map(selectItems => selectItems.sort((a, b) => Number(a.label) - Number(b.label))),
      ),
    );
  }

  getLocationsAsSelectItemsWithNames(
    isStoreManager: boolean = false,
    prefetchLocation?: LocationDropdown,
  ): Observable<SelectItem[]> {
    return iif(
      () => isStoreManager && !!prefetchLocation,
      of([{ label: prefetchLocation?.number, value: prefetchLocation }]),
      this.allowedLocations$.pipe(
        CommonUtils.mapToMultiSelectItem(
          item => `${item.number} - ${item.type.description}`,
          item => item.id,
          false,
          'Select a Location',
          null,
          false,
        ),
        map(selectItems => {
          if (prefetchLocation) {
            return [
              { label: prefetchLocation.number, value: prefetchLocation },
              ...selectItems.filter(item => item.label !== prefetchLocation.number),
            ];
          }

          return selectItems;
        }),
        map(selectItems => selectItems.sort((a, b) => Number(a.label) - Number(b.label))),
      ),
    );
  }
}
