<div style="display: flex; justify-content: center">
    <div style="width: 275px">
        <p-fileUpload #fileUpload [multiple]="false" [disabled]="disabled" accept="image/*" [showUploadButton]="false" [showCancelButton]="false">
            <ng-template let-file pTemplate="file"></ng-template>
            <ng-template pTemplate="content">
                <div style="background-color: white; border-radius: 5px; display: flex; flex-direction: column; justify-content: center; width: 275px; position: relative" (click)="fileUpload.choose();">
                    <div *ngIf="control.value == null && fileUpload.files.length == 0" style="position: absolute; height: 100%; width: 100%; background-color: rgba(0, 0, 0, .7); backdrop-filter: blur(8px); color: white; display: flex; justify-content: center; flex-direction: column; align-items: center">
                        <div style="background-color: rgba(0, 0, 0, 0.4); margin: 10px; padding: 10px 20px;">
                            <i class="fa fa-image" style="font-size: 40px"></i>
                            <div style="font-size: 20px; margin-bottom: 5px; font-weight: bold">{{translations.addLogo}} <small class="text-danger">*</small></div>
                            <small>{{translations['accounts.logo.helpText']}}</small>
                        </div>
                    </div>

                    <div style="margin-top: 60px; margin-bottom: 10px; height: 75px">
                        <div *ngIf="fileUpload.files.length > 0; else showImage" style="position:relative; display: inline-block;">
                            <img style="max-width: 125px; max-height: 75px" [src]="setControlValueAndGetFileUrl(fileUpload.files[0])">
                            <div style="position:absolute; top: -10px; right: -10px">
                                <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-sm" (click)="fileUpload.clear(); control.setValue(null); $event.stopPropagation()"></button>
                            </div>
                        </div>

                        <ng-template #showImage>
                            <img style="max-width: 125px; max-height: 75px" [src]="control.value | accountImage | async">
                        </ng-template>
                    </div>

                    <div style="text-align: center">
                        <img src="../../../../../../../assets/img/headshots/no-profile.jpg" style="max-width: 125px; border: solid 3px black; border-radius: 5px">
                        <div style="font-weight: bold; font-family: Arial; margin-top: 5px; font-size: 20px">Jane</div>
                    </div>

                    <div style="text-align: center; margin-bottom: 10px; margin-top: 20px">
                        <img src="https://prodvolt.blob.core.windows.net/uploads/accounts/walmart-stacked.png" style="max-height: 45px; max-width: 150px">
                    </div>

                    <div style="font-weight: bold; background-color: #0071ce; color: white; text-align: center; padding: 2px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; font-size: 18px">
                        {{translations.fieldGroup}}
                    </div>
                </div>
            </ng-template>
        </p-fileUpload>
    </div>
</div>
