import { AuthConfig, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  issuer: 'https://localhost:5002',
  clientId: '15cbc242-0d0b-4a65-8fa1-501768d1f654',
  requireHttps: true,
  redirectUri: window.location.origin + '/login-callback',
  scope: 'openid profile email wmv-dashboard wmv-api offline_access',
  responseType: 'code',
  silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issu
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtoolses/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
};

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: ['https://localhost:5100'],
    sendAccessToken: true,
  },
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}
