import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'task-name',
  templateUrl: './task-name.component.html',
  styleUrls: ['../addservicedeploymenttask.component.scss'],
})
export class TaskNameComponent implements OnInit {
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public get taskNameControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('taskName');
  }

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: ['Business friendly name to identify the Task.'],
      },
    });
  }
}
