import { Reducer } from 'redux';
import { IAction } from '../../store/actions/tile.actions';
import { UserVisitsActions } from '../actions/visit.constants';
import { UserVisitsFilterParameters } from '../user-visits-filter-parameters';

export const UserVisitsFilterReducer: Reducer<UserVisitsFilterParameters> = (
  state: UserVisitsFilterParameters = new UserVisitsFilterParameters(),
  action: IAction<UserVisitsFilterParameters>,
): UserVisitsFilterParameters => {
  const params = action.payload;

  switch (action.type) {
    case UserVisitsActions.SetFilter: {
      return Object.assign({}, state, params);
    }
    default:
      return state;
  }
};

export const UserVisitsReducer: Reducer<any[]> = (state: any[] = [], action: IAction<any[]>): any[] => {
  const visits = action.payload;

  switch (action.type) {
    case UserVisitsActions.SetVisits: {
      return Object.assign({}, state, {
        userVisits: visits,
      });
    }
    default:
      return state;
  }
};
