export class ServiceDeploymentAndServiceTaskParams {
  public serviceDeploymentRefNumber: string;
  public serviceDeploymentTaskRefNumber: string;
  public serviceTaskLocationRefNumber: string;
  public serviceDeploymentName: string;
  public serviceTaskName: string;
  public serviceTaskLocationName: string;
  public status: string;
  public fieldGroupName: string;
  public accountId: number;

  constructor() {
    this.serviceDeploymentRefNumber = '';
    this.serviceDeploymentTaskRefNumber = '';
    this.serviceTaskLocationRefNumber = '';
    this.serviceDeploymentName = '';
    this.serviceTaskName = '';
    this.serviceTaskLocationName = '';
    this.fieldGroupName = '';
    this.status = '';
  }
}
