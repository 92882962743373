import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService } from '../../../services/translation.service';
import { Translations } from '../../../services/translations.types';
import { DynamicDialogBaseComponent } from './dynamic-dialog-base.component';

@Component({
  selector: 'v-generic-error-dialog',
  template: `
    <ng-container *ngIf="vm$ | async as vm">
      <v-dynamic-dialog-base [headerText]="vm.translatedData?.error">
        <ng-container ngProjectAs="dynamicDialogContent">
          <p>{{ vm.translatedData.somethingWentWrong }}</p>
          <p class="text-danger" *ngIf="vm.error">{{ vm.error }}</p>
        </ng-container>
        <ng-container ngProjectAs="dynamicDialogFooter">
          <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-secondary btn-sm" type="button" (click)="onClose()">
              {{ vm.translatedData.close }}
            </button>
          </div>
        </ng-container>
      </v-dynamic-dialog-base>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericErrorDialogComponent extends DynamicDialogBaseComponent {
  vm$: Observable<{ translatedData: Translations; error: string }>;

  constructor(
    private readonly _dialogRef: DynamicDialogRef,
    private readonly _dialogConfig: DynamicDialogConfig,
    private readonly _translationService: TranslationService,
  ) {
    super(_dialogRef, _dialogConfig);
    this.vm$ = combineLatest([
      _translationService.getTranslatedObject(),
      of(_dialogConfig.data ? _dialogConfig.data.error : ''),
    ]).pipe(map(([translatedData, error]) => ({ translatedData, error })));
  }
}
