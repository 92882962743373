import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth';

@Injectable({
  providedIn: 'root',
})
export class RedirectUserService {
  constructor(
    private readonly router: Router,
    private readonly _authService: AuthService,
    private readonly _appState: NgRedux<IAppState>,
  ) {}

  redirectUnauthorizedUser(returnUrl?: string) {
    if (this._authService.isAuthenticated) {
      this.router.navigate(['/not-authorized'], { skipLocationChange: true });
    } else {
      if (returnUrl === undefined || returnUrl === null || returnUrl.toLowerCase().includes('/login')) {
        returnUrl = '/dashboard';
      }
      setTimeout(() => {
        this.router.navigate(['/login'], {
          queryParams: { returnUrl },
        });
      });
    }
  }
}
