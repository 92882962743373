import { AnimationEvent } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  Type,
  ViewChild,
} from '@angular/core';
import { CustomDynamicContentDialogDirective } from '@volt/shared/components/dialogs/dynamic-dialog/custom-dynamic-content-dialog.directive';
import { CustomDynamicDialogConfig } from '@volt/shared/components/dialogs/dynamic-dialog/custom-dynamic-dialog-config';
import { CustomDynamicDialogRef } from '@volt/shared/components/dialogs/dynamic-dialog/custom-dynamic-dialog-ref';
import { DomUtils } from '@volt/shared/utils/dom.utils';
import { AnimationState, fadeAnimation, zoomAnimation } from './constants';

@Component({
  selector: 'v-dynamic-dialog-container',
  template: `
    <main [@zoom]="{ value: animationState, params: { timing: customDynamicDialogConfig.contentAnimationTiming } }">
      <ng-container appCustomDynamicContentDialog></ng-container>
    </main>
  `,
  styles: [
    `
      :host {
        min-width: 500px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [zoomAnimation(), fadeAnimation()],
})
export class DynamicDialogContainerComponent<TContentComponent = any> implements AfterViewInit, OnDestroy {
  @ViewChild(CustomDynamicContentDialogDirective) contentInsertionPoint: CustomDynamicContentDialogDirective;

  animationState = AnimationState.Enter;
  animationStateChanged = new EventEmitter<AnimationEvent>();
  contentComponentType: Type<TContentComponent>;
  private contentComponentRef: ComponentRef<TContentComponent>;

  @HostBinding('@fade') get hostAnimation() {
    // [@fade]
    return {
      value: this.animationState,
      params: {
        timing: this.customDynamicDialogConfig.containerAnimationTiming,
        delayChild: this.customDynamicDialogConfig.animationChildDelay,
      },
    };
  }

  @HostListener('@fade.start', ['$event']) // (@fade.start)="onAnimationStart($event)"
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  @HostListener('@fade.done', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  constructor(
    public readonly customDynamicDialogConfig: CustomDynamicDialogConfig,
    private readonly customDynamicDialogRef: CustomDynamicDialogRef,
    private readonly cfr: ComponentFactoryResolver,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    DomUtils.runOnNextRender(() => {
      this.loadContentComponent();
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.contentComponentRef?.destroy();
  }

  onCloseClick(): void {
    this.closeDialog();
  }

  startExitAnimation(): void {
    this.animationState = AnimationState.Leave;
    this.cdr.markForCheck();
  }

  private closeDialog(): void {
    this.customDynamicDialogRef.close();
  }

  private loadContentComponent(): void {
    if (!this.contentComponentRef) {
      const factory = this.cfr.resolveComponentFactory(this.contentComponentType);
      const vcr = this.contentInsertionPoint.viewContainerRef;
      vcr.clear();
      this.contentComponentRef = vcr.createComponent(factory);
    }
  }
}
