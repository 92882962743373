import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { PermissionsModule } from '@volt/shared/directives/permissions/permissions.module';
import { MessageService } from "primeng/api";
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from "primeng/toast";
import { AlertsComponent } from '../alerts/alerts.components';
import { HelpComponent } from '../help/help.component';
import { LoadingContainerModule } from '../shared/components/containers/loading-container/loading-container.module';
import { SiteSettingsComponent } from '../site-settings/site-settings.component';
import { AdminLayoutComponent } from './admin/admin-layout.component';
import { SideNavResolver } from './admin/admin-sidenav.resolvers';
import { AlertIconComponent } from './admin/alert-icon/alert-icon.component';
import { GlobalSearchModule } from './admin/global-search/global-search.module';
import { GlobalNavComponent } from './admin/navbar/global-nav.component';
import { NavbarComponent } from './admin/navbar/navbar.component';
import { NavigationComponent } from './admin/navigation/navigation.component';
import { ProfileMenuComponent } from './admin/profile-menu/profile-menu.component';
import { AdminLayoutTranslationService } from './admin/services/admin-layout-translation.service';
import { LayoutService } from './admin/services/layout.service';
import { SideNavComponent } from './admin/sidenav/sidenav.component';
import { MinimalComponent } from './minimal/minimal.component';
import { PublicLayoutComponent } from './public/public-layout.component';
import { SecureUrlModule } from '@volt/shared/pipes/secure-url/secure-url.module';
import { ImageFlowModule } from '@volt/shared/pipes/image-flow/image-flow.module';
import { SupportBotComponent } from './admin/support-bot/support-bot.component';
import { CustomDynamicDialogModule } from '@volt/shared/components/dialogs/dynamic-dialog/custom-dynamic-dialog.module';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    SelectButtonModule,
    DropdownModule,
    NgbCollapseModule,
    LoadingContainerModule,
    PermissionsModule,
    GlobalSearchModule,
    OverlayPanelModule,
    DataViewModule,
    SecureUrlModule,
    ImageFlowModule,
    CustomDynamicDialogModule,
    ButtonModule,
    ToastModule
  ],
  exports: [
    AdminLayoutComponent,
    PublicLayoutComponent,
    NavigationComponent,
    MinimalComponent,
    SideNavComponent,
    NavbarComponent,
  ],
  declarations: [
    NavigationComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
    SideNavComponent,
    NavbarComponent,
    HelpComponent,
    AlertsComponent,
    SiteSettingsComponent,
    ProfileMenuComponent,
    GlobalNavComponent,
    MinimalComponent,
    AlertIconComponent,
    SupportBotComponent,
  ],
  providers: [
    LayoutService,
    SideNavResolver,
    AdminLayoutTranslationService,
    MessageService
  ],
})
export class LayoutsModule {}
