import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Contact, VoltAccountViewModel } from '../../models/volt-account';
import { BehaviorSubject, Observable } from 'rxjs';
import { Invitation, RegistrationService } from '../../services/registration.service';
import { ContainerWrapperToastService } from '@volt/shared/components/containers/container-wrapper/container-wrapper-toast.service';
import { VoltAccountInfoComponent } from '../components/volt-account-info/volt-account-info.component';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { TranslationService } from '@volt/shared/services/translation.service';
import { TranslocoService } from '@ngneat/transloco';
import { InvitedAccountModel } from '@volt/api';
import { RegistrationRetailerAccountViewModel } from '../../models/registration-retailer-account-view-model';

@Component({
  selector: 'registration',
  templateUrl: './registration-container.component.html',
  styleUrls: [
    '../../../../assets/core/core.scss',
    '../../../../assets/walmart-theme/walmart-light.scss',
    '../../../../assets/walmart-theme/walmart-dark.scss',
    './registration-container.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RegistrationService, ContainerWrapperToastService],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationContainerComponent implements OnInit {
  steps: MenuItem[];
  currentStep = 0;
  invitation$: Observable<Invitation>;
  submitting$ = new BehaviorSubject(false);
  submitted$ = new BehaviorSubject(false);

  constructor(private readonly _registrationService: RegistrationService,
              private readonly _containerWrapperToastService: ContainerWrapperToastService,
              private readonly _translationService: TranslationService,
              private readonly _translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.setLanguage();
    this.invitation$ = this._registrationService.invitation$.pipe(
      catchError(err => {
        this._containerWrapperToastService.addMessage({
          severity: 'error',
          summary: this._translationService.translate('anErrorHasOccurred')
        })
        return of(null);
      })
    );
    this.steps = [
      {
        label: this._translationService.translate('personalInformation')
      },
      {
        label: this._translationService.translate('generalInformation')
      },
      {
        label: this._translationService.translate('retailerInformation')
      },
      {
        label: this._translationService.translate('licenseAgreement')
      }
    ]
  }

  private setLanguage() {
    let language = navigator.language;
    if(language.includes("-")) {
      language = language.substring(0, language.indexOf("-"));
    }
    if(!this._translocoService.getAvailableLangs().includes((language as any))) {
      language = this._translocoService.getDefaultLang()
    }
    this._translocoService.setActiveLang(language)
  }

  previousStep() {
    this.currentStep -= 1;
  }

  nextStep() {
    this.currentStep += 1;
  }

  async submit(voltAccountViewModel: VoltAccountViewModel, retailerAccountViewModel: RegistrationRetailerAccountViewModel, recipientContact: Contact, invitedAccount: InvitedAccountModel) {
    this.submitting$.next(true);

    try {
      const voltAccountId = await this._registrationService.createVoltAccount(voltAccountViewModel).toPromise();
      await this._registrationService.createRetailerAccount(voltAccountId, recipientContact, voltAccountViewModel, retailerAccountViewModel, invitedAccount).toPromise();
      this.submitted$.next(true);
    } catch {
      this._containerWrapperToastService.addMessage({
        severity: 'error',
        summary: this._translationService.translate('anErrorHasOccurred')
      })
    } finally {
      this.submitting$.next(false);
    }
  }

  setupValidators(comp: VoltAccountInfoComponent){
    comp.controls.name.addAsyncValidators(this.validateAccountName.bind(this));
    comp.controls.name.updateValueAndValidity();
  }

  validateAccountName(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if(control.value == null) return of(null);

    return this._registrationService.accountNameIsTaken(control.value).pipe(
      map(isTaken => isTaken ? { taken: 'true' } : null)
    )
  }
}
