import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'login-form',
  templateUrl: './login.component.html',
  styleUrls: ['../../../assets/auth/auth.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  protected $destroyed = new Subject();

  ngOnDestroy(): void {
    this.$destroyed.next();
  }
  constructor(private authService: AuthService, private router: Router) {}

  public ngOnInit() {
    this.authService.canActivateProtectedRoutes$.pipe(takeUntil(this.$destroyed)).subscribe(yes => {
      if (yes) {
        this.router.navigate(['/dashboard']).then();
      } else {
        this.authService.login();
      }
    });
  }
}
