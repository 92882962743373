<v-loading-container [working]="loading">
  <ng-container>
    <modal-header [title] = "'Name'" (onClose)="formReset()"></modal-header>
    <form [formGroup]="appLinkGroup" (submit)="submitAppLink(); (false)">

      <div class="form-and-label">
        <label for="activityName">
          Button Name
          <span class="required" aria-required="true">*</span>
        </label>
        <input
          class="form-control rounded-0"
          id="activityName"
          maxlength="50"
          [(ngModel)]="accountAppLinkModel.activity"
          formControlName="activityName"
          [style]="{ width: '300px'}"
        />
        <div *ngIf="activityNameControl.touched && activityNameControl.errors">
          <span
            *ngIf="
              activityNameControl.touched &&
              (activityNameControl.hasError('required') ||
                activityNameControl.hasError('activityName'))
            "
            class="text-danger"
          >
            A valid Button Name is required.
          </span>
        </div>
      </div>

      <div class="form-and-label">
        <label for="noteField">
          Note to Field Rep
          <span class="required" aria-required="true">*</span>
        </label>
        <textarea
          class="form-control rounded-0"
          id="noteField"
          [(ngModel)]="accountAppLinkModel.note"
          formControlName="noteField"
          [style]="{ width: '300px'}"
        ></textarea>
        <div *ngIf="noteFieldControl.touched && noteFieldControl.errors">
          <span
            *ngIf="
              noteFieldControl.touched &&
              (noteFieldControl.hasError('required') || noteFieldControl.hasError('noteField'))
            "
            class="text-danger"
          >
            A valid Note to Field Rep is required.
          </span>
        </div>
      </div>

      <div class="form-and-label">
        <label for="linkType">
          Link Type
          <span class="required" aria-required="true">*</span>
        </label>
        <p-dropdown
          [options]="linkTypeDropModel"
          [style]="{ width: '300px'}"
          [(ngModel)]="accountAppLinkModel.linkType"
          formControlName="linkType"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
        <div *ngIf="linkTypeControl.touched && linkTypeControl.errors">
          <span
            *ngIf="
              linkTypeControl.touched &&
              (linkTypeControl.hasError('required') || linkTypeControl.hasError('linkType'))
            "
            class="text-danger"
          >
            A valid Link Type is required.
          </span>
        </div>
      </div>

      <div class="form-and-label">
        <label for="link">
          Link
          <span class="required" aria-required="true">*</span>
        </label>
        <input
          class="form-control rounded-0"
          id="link"
          [(ngModel)]="accountAppLinkModel.link"
          formControlName="link"
          [style]="{ width: '300px'}"
        />
        <div *ngIf="linkControl.touched && linkControl.errors">
          <span
            *ngIf="
              linkControl.touched &&
              (linkControl.hasError('required') || linkControl.hasError('link'))
            "
            class="text-danger"
          >
            A valid Link Type is required.
          </span>
        </div>
      </div>
    </form>
    <modal-footer [disabled]="!appLinkGroup.valid" (onSubmit)="submit()" (onClose)="cancel()"></modal-footer>
  </ng-container>
</v-loading-container>
