import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { AddTaskLocation } from '../../../../models/addTaskLocation';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';

@Component({
  selector: 'task-location-start-date',
  templateUrl: './task-location-start-date.component.html',
  styleUrls: ['../../addservicedeployment-task-location.component.scss'],
})
export class TaskLocationStartDateComponent implements OnInit {
  public startDate: Date;
  minDate: Date;
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Output() public setEndDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() set setStartDate(currentTaskLocation: AddTaskLocation) {
    this.startDate = currentTaskLocation.start ? new Date(currentTaskLocation.start.toDate()) : null;
  }

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  ngOnInit(): void {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Select task start date and time for the location.',
          'The date time is store in UTC. The system add the local timezone of the selected store.',
          'i.e if store is in California and time selected is 08:00, ' +
            'the time is shown as pacific timezone and saved in database as 16:00 (including daylight saving).',
          'Please note the scheduled hours, WMT VOLT check in hours, and reported hours should align.',
        ],
      },
    });
  }

  public get startDateControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }
    return <UntypedFormControl>this.group.get('startDate');
  }

  public onSelectStartDate(date: Date) {
    this.minDate = new Date();
    this.minDate = date;
    this.setEndDate.emit(this.minDate);
  }
}
