import { InjectionToken } from '@angular/core';
import InvoiceConfig from './assets/data/invoice.json';

export interface InvoiceConfigType {
  voltName: string;
  voltAddress: string;
  voltPOBox: string;
  voltAccountingEmail: string;
  voltAccountingPhone: string;
}

export const INVOICE_CONFIG = new InjectionToken<InvoiceConfigType>('InvoiceConfig');
export const invoiceConfig: InvoiceConfigType = { ...InvoiceConfig };
