<div class="container-fluid">
  <header class="page-header d-flex align-items-center">
    <h2 class="page-title">
      <span>Task Locations</span>
    </h2>

    <div class="page-title-bar">
      <a [routerLink]="['/servicedeployments', serviceDeploymentRefId]"
         [queryParams]="{
           serviceDeploymentName,
           serviceDeploymentRefNumber,
           fieldGroupName,
           accountId
         }">
        <i class="fa fa-chevron-left"></i>
        Back to Tasks
      </a>
    </div>

    <div class="ms-auto d-flex">
      <button class="btn btn-primary btn-sm me-2" type="button" (click)="onDownLoad()">
        <i class="fa fa-download"></i>
        Export
      </button>
      <button *ngIf="canCreate" class="btn btn-primary btn-sm me-2" (click)="importTaskLocation()">
        Add multiple task locations
      </button>
      <button
        *ngIf="canCreate"
        class="btn btn-primary btn-sm me-2"
        (click)="addServiceDeploymentTaskLocation()"
        data-cy="add-task-location-button"
      >
        Add task location
      </button>
    </div>
  </header>

  <div class="d-flex flex-row row mt-3">
    <div class="col-12">
      <div class="card rounded-0">
        <div class="card-header">
          <h2 class="card-title">
            Field Group:
            <span style="font-style: italic; color: #b3b3b3">{{ fieldGroupName }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Service Deployment:
            <span style="font-style: italic; color: #b3b3b3">
              {{ serviceDeploymentName }}&nbsp;({{ serviceDeploymentRefNumber }} )
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Task :
            <span style="font-style: italic; color: #b3b3b3">
              {{ serviceTaskName }}&nbsp;({{ serviceDeploymentTaskRefNumber }} )
            </span>
            <v-refresh-button (refreshClick)="refresh()"></v-refresh-button>
          </h2>
        </div>
        <div class="card-body">
          <search-filter
            [unFilteredList]="cols"
            [minDate]="minDate"
            (searchStartDate)="searchStartDate($event)"
            (searchEndDate)="searchEndDate($event)"
            [showDateFilter]="showDateFilter"
            (searchBySubmitted)="searchByNumber($event)"
            (clearClicked)="clearFilter()"
            [searchBy]="searchBy"
          ></search-filter>
          <v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
            <p-table
              #dt
              [columns]="cols"
              [value]="data"
              [rows]="20"
              [totalRecords]="totalRecords"
              [responsive]="true"
              [lazy]="true"
              (onLazyLoad)="sortdata($event)"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [pSortableColumn]="
                      col.field !== 'employeeNumbers' && col.field !== 'dueDate' ? col.field : undefined
                    "
                    [attr.data-cy]="'task-loc-table-' + col.field + '-label'"
                  >
                    {{ col.header }}
                    <ng-container *ngIf="col.field !== 'employeeNumbers' && col.field !== 'dueDate'">
                      <p-sortIcon class="p-sortable-column-icon" [field]="col.field"></p-sortIcon>
                    </ng-container>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns" [attr.data-cy]="'task-loc-table-' + col.field">
                    <div *ngIf="col.field === 'completionDate'">
                      <a
                        style="color: cornflowerblue; text-decoration: underline"
                        [routerLink]="[
                          '/servicedeployments',
                          serviceDeploymentRefId,
                          'task',
                          taskRefId,
                          'tasklocation',
                          rowData['id']
                        ]"
                        [queryParams]="{serviceTaskLocationRefNumber: rowData['referenceNumber']}"
                        queryParamsHandling="merge"
                      >
                        {{ rowData[col.field] | dtFormat: rowData['timeZone'] }}
                      </a>
                    </div>

                    <div *ngIf="col.field === 'start'">
                      {{ rowData[col.field] | dtFormat: rowData['timeZone'] }}
                    </div>

                    <div *ngIf="col.field === 'end'">
                      {{ rowData[col.field] | dtFormat: rowData['timeZone'] }}
                    </div>

                    <div *ngIf="col.field === 'referenceNumber'">
                      <span (click)="editTaskLocation(rowData)" style="color: cornflowerblue; text-decoration: underline; cursor: pointer">
                        {{ rowData[col.field] }}
                      </span>
                    </div>

                    <div *ngIf="col.field === 'employeeNumbers'">
                      <div *ngFor="let emp of rowData[col.field]">
                        {{ emp }}
                      </div>
                    </div>

                    <div *ngIf="col.field === 'dueDate'">
                      {{ rowData[col.field] | dtFormat: rowData['timeZone'] }}
                    </div>

                    <div
                      *ngIf="
                        col.field !== 'referenceNumber' &&
                        col.field !== 'employeeNumbers' &&
                        col.field !== 'start' &&
                        col.field !== 'end' &&
                        col.field !== 'completionDate' &&
                        col.field !== 'dueDate'
                      "
                    >
                      {{ rowData[col.field] }}
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </v-loading-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <ngb-pagination
        [collectionSize]="totalRecords"
        class="d-flex justify-content-center"
        [pageSize]="serviceDeploymentFilterParams.pageSize"
        [page]="serviceDeploymentFilterParams.page"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="true"
        (pageChange)="pagination($event)"
      ></ngb-pagination>
    </div>
  </div>
</div>
