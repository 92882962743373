import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'accounts-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
export class AccountsListComponent {
  menuItems: MenuItem[] = [
    { label: 'Invite Supplier', command: this.fnInviteSupplier.bind(this), id: 'inviteSupplierMenuItem' },
  ];

  constructor(private _router: Router) {}

  public fnInviteSupplier() {
    this._router.navigate(['/registration/Invite-Supplier']);
  }
}
